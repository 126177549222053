import styles from "./Unauthorised.module.css";
import commonStyles from "./Common.module.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Unauthorised = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    useEffect(() => {
        document.title = "Avaid - 401";
    }, []);

    return (
        <section className={styles.Unauthorised}>
            <h1 className={styles.heading}>Unauthorised</h1>
            <br />
            <p className={styles.text}>You do not have access to the requested page.</p>
            <button className={`${commonStyles.button} ${styles.button}`} onClick={goBack}>Go Back</button>
        </section>
    )
}

export default Unauthorised;