import { Severity } from "../common/severity";

export function ensureProperties(messageLog, node, nodeName = null, ...propertyNames) {
    return propertyNames.every(propertyName => ensureProperty(messageLog, propertyName, node, nodeName));
}

export function ensureProperty(messageLog, propertyName, node, nodeName = null) {
    const result = node.hasOwnProperty(propertyName);
    if (!result) {
        const msg = nodeName ?
            `Node '${nodeName}' is missing property '${propertyName}'.` :
            `Property '${propertyName}' is missing from the root object.`;
        messageLog.addError(msg);
    };
    return result;
}

export function writeMessageLogContentsToConsole(messageLog) {
    messageLog.getAllMessages().forEach(m => {
        const msg = m.toString();
        if (m.severity === Severity.Info) {
            console.log(msg);
        }
        else {
            console.error(msg);
        }
    });
}