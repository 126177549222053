import { ensureProperties } from "../builder-helper";
import { PerformanceCategoryBuilder } from "./performance-category-builder";

export class Aircraft {
    #id;
    #version;
    #manufacturer;
    #name;
    #description;
    #performanceCategories = [];

    constructor(id, version, manufacturer, model, description) {
        this.#id = id;
        this.#version = version;
        this.#manufacturer = manufacturer;
        this.#name = model;
        this.#description = description;
    }

    get id() {
        return this.#id;
    }

    get version() {
        return this.#version;
    }

    get manufacturer() {
        return this.#manufacturer;
    }

    get name() {
        return this.#name;
    }

    get description() {
        return this.#description;
    }

    static create(metadata, messageLog) {
        if (ensureProperties(messageLog, metadata, null, "summary", "performanceData")) {
            const summary = metadata.summary;
            const performanceData = metadata.performanceData;

            if (ensureProperties(messageLog, summary, "summary", "index", "version", "manufacturer", "model", "description") &&
                ensureProperties(messageLog, performanceData, "performanceData", "transforms", "transformGroups", "parameterMappings", "operations", "performanceCategories"))
            {
                const aircraft = new Aircraft(summary.index, summary.version, summary.manufacturer, summary.model, summary.description);
                messageLog.addMessage(`Created ${aircraft.toString()}.`);
                aircraft.#setPerformanceCategories(performanceData, messageLog);
                return aircraft;
            }
        }
    }

    #setPerformanceCategories(metadata, messageLog) {
        const builder = new PerformanceCategoryBuilder(metadata);
        this.#performanceCategories = builder.createCategories(messageLog);
    }

    getPerformanceCategories() {
        return this.#performanceCategories.slice();
    }

    toString() {
        return `${this.description}, version ${this.version}`;
    }
}