
import styles from "./LeasingPerformanceCategory.module.css";

const LeasingPerformanceCategory = ({ performanceCategory: category }) => {
    return (
        <section className={styles.LeasingPerformanceCategory}>
            <h3>{category.name}</h3>
            <ul className={styles.operationList}>
                {category.getOperations().map((operation, operationIndex) =>
                    <li key={operationIndex}>{operation.description}</li>)
                }
            </ul>
        </section>
    )
};

export default LeasingPerformanceCategory;