import styles from "./PerformanceDataDisclaimer.module.css";
import PerformanceDataDisclaimerContent from "./PerformanceDataDisclaimerContent";

const PerformanceDataDisclaimer = () => {
    return (
        <section className={styles.PerformanceDataDisclaimer}>
            <h2>Performance Data Disclaimer</h2>
            <PerformanceDataDisclaimerContent />
        </section>
    )
};

export default PerformanceDataDisclaimer;