import styles from "./Verify.module.css";
import commonStyles from "./Common.module.css";
import { useParams, Link } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import { useState, useEffect } from "react";

const getErrorMessage = (err) => {
    let message;
    const response = err?.response;
    if (!response) {
        message = err?.message ? err.message : "The server did not respond.  Please check your network connection.";
    }
    else if (response.status === 500) {
        message = "Internal server error."
    }
    else {
        message = "Verification failed.";
    }
    return message;
};

let requested = false;

const Verify = () => {
    const { verificationCode } = useParams();
    const { verify } = useAuthContext();
    const [verificationPending, setVerificationPending] = useState(true);
    const [result, setResult] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        document.title = "Avaid - Verify";
    }, []);

    useEffect(() => {
        if (verificationCode && !requested) {
            requested = true;
            async function requestVerification() {
                try {
                    setVerificationPending(true);
                    const result = await verify(verificationCode);
                    setResult(result);
                    setVerificationPending(false);
                }
                catch (err) {
                    const message = getErrorMessage(err);
                    setErrorMessage(message);
                }
            }
            requestVerification();
        }
    }, [verificationCode]);

    return (
        <section className={styles.Verify}>
            {verificationPending && <h2>Verifying, please wait...</h2>}
            {!verificationPending && errorMessage && <h2>{errorMessage}</h2>}
            {!verificationPending && !errorMessage && result?.found && result?.expired &&
                <div>
                    <h2>Your verification code has expired.</h2>
                    <p>
                        <Link className={commonStyles.link} to="/register">Please re-register</Link>.
                    </p>
                </div>
            }
            {!verificationPending && !errorMessage && result && !result.found &&
                <div>
                    <h2>Your verification code was not recognised.</h2>
                    <p>Have you already verified?</p>
                </div>
            }
            {!verificationPending && !errorMessage && result && result.found && !result.expired &&
                <div>
                    <h2>Thank you, your account has been verified and enabled.</h2>
                    <p>
                        <span><Link className={commonStyles.link} to="/login">Click here to sign in</Link>.</span>
                    </p>
                </div>
            }
        </section>
    )
};

export default Verify;