import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";

export class QuantityAdjusterTransform extends Transform {
    constructor(quantityAdjuster) {
        super();
        this.quantityAdjuster = quantityAdjuster;
    }

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterAdjustmentAmountName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterInName, this.id),
            new Parameter(ParameterNames.ParameterOutName, this.id),
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const parameterName = parameter.name;
        switch (parameterName) {
            case ParameterNames.ParameterInName:
                this._clearParameterValue(ParameterNames.ParameterOutName);
                break;
            case ParameterNames.ParameterOutName:
                this._clearParameterValue(ParameterNames.ParameterInName);
                break;
            // no default
        }

        const count = this._setParameterValueCount;
        if (count === 2) {
            const outputParameterName = this.getUnsetParameterNames()[0];
            let result;
            switch (outputParameterName) {
                case ParameterNames.ParameterInName:
                    result = this.#calculateInput();
                    break;
                case ParameterNames.ParameterOutName:
                    result = this.#calculateOutput();
                    break;
                default:
                    throw new Error(`Unknown output parameter: '${outputParameterName}'.`);
            }
            this._emitParameterValue(outputParameterName, result);
        }
    }

    #calculateInput() {
        const output = this._getParameterValue(ParameterNames.ParameterOutName);
        const adjustmentAmount = this._getParameterValue(ParameterNames.ParameterAdjustmentAmountName);
        return this.quantityAdjuster.getInput(output, adjustmentAmount);
    }

    #calculateOutput() {
        const input = this._getParameterValue(ParameterNames.ParameterInName);
        const adjustmentAmount = this._getParameterValue(ParameterNames.ParameterAdjustmentAmountName);
        return this.quantityAdjuster.getOutput(input, adjustmentAmount);
    }
}