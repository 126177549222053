export class Detent {
    #label;
    #value;

    constructor(label, value) {
        this.#label = label;
        this.#value = value;
    }

    get label() {
        return this.#label;
    }

    get value() {
        return this.#value;
    }
}