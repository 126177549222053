import styles from "./LeasingAction.module.css";
import commonStyles from "./Common.module.css";
import LeasingPerformanceCategory from "./LeasingPerformanceCategory";
import CostPresenter from "./CostPresenter";
import SETTINGS from "../config";

const LeasingAction = ({ aircraft, aircraftSummary, onAction }) => {
    const isLeased = !!Number(aircraftSummary.is_leased);
    const leaseStart = isLeased ? new Date(aircraftSummary.valid_from) : null;
    const leaseEnd = isLeased ? new Date(aircraftSummary.valid_to) : null;
    const now = new Date();
    
    let daysToExpiry;
    let daysToExpiryClassName;
    let earlierstLeaseExtensionDate;
    if (isLeased) {
        daysToExpiry = Math.floor((leaseEnd - now) / (1000 * 3600 * 24));
        daysToExpiryClassName = daysToExpiry <= SETTINGS.LEASE_EXPIRY_CRITICAL_WINDOW_DAYS
            ? styles.danger
            : daysToExpiryClassName = daysToExpiry <= SETTINGS.LEASE_EXPIRY_WINDOW_DAYS
                ? styles.warning
                : styles.safe;
        earlierstLeaseExtensionDate = new Date(aircraftSummary.valid_to);
        earlierstLeaseExtensionDate.setDate(earlierstLeaseExtensionDate.getDate() - SETTINGS.LEASE_EXPIRY_WINDOW_DAYS);
    }
    else {
        daysToExpiry = null;
        daysToExpiryClassName = null;
        earlierstLeaseExtensionDate = null;
    }

    return <section className={styles.LeasingAction}>
        {isLeased &&
            <div>
                <div className={styles.header}>
                    <p className={`${styles.headerRow} ${styles.marginAfter}`}>You are already leasing this aircraft.</p>
                    <div className={styles.headerRow}>
                        <p>Lease start:</p>
                        <p>{leaseStart.toLocaleDateString()}</p>
                    </div>
                    <div className={styles.headerRow}>
                        <p>Lease end:</p>
                        <p>{leaseEnd.toLocaleDateString()}</p>
                    </div>
                    <div className={styles.headerRow}>
                        <p>Expires in:</p>
                        <p className={daysToExpiryClassName}>{daysToExpiry} day{`${daysToExpiry === 1 ? "" : "s"}`}</p>
                    </div>
                    {daysToExpiry <= SETTINGS.LEASE_EXPIRY_WINDOW_DAYS &&
                        <div className={`${styles.headerRow} ${styles.marginBefore}`}>
                            <p>You can extend the lease by another 12 months.</p>
                            <div className={styles.priceAndAction}>
                                <CostPresenter cost={0} />
                                <button className={`${commonStyles.button} ${styles.button}`} onClick={onAction}>Add to Cart</button>
                            </div>
                        </div>
                    }
                    {daysToExpiry > SETTINGS.LEASE_EXPIRY_WINDOW_DAYS &&
                        <div className={`${styles.headerRow} ${styles.marginBefore}`}>
                            <p>You can extend the lease on or after {earlierstLeaseExtensionDate.toLocaleDateString()}.</p>
                        </div>
                    }
                </div>
            </div>
        }
        {!isLeased &&
            <div>
                <div className={`${styles.header} ${styles.header___notLeased}`}>
                    <p>This aircraft is available to lease for a period of 12 months.</p>
                    <div className={styles.priceAndAction}>
                        <CostPresenter cost={0} />
                        <button className={`${commonStyles.button} ${styles.button}`} onClick={onAction}>Add to Cart</button>
                    </div>
                </div>
                <div className={styles.supportedOperations}>
                    <h3>Supported Operations</h3>
                    <div className={styles.categoryContainer}>
                        {aircraft.getPerformanceCategories().map((category, categoryIndex) => {
                            return <LeasingPerformanceCategory key={categoryIndex} performanceCategory={category} />
                        })}
                    </div>
                </div>
            </div>
        }
    </section>
};

export default LeasingAction;