import "../array-extensions";
import { approximates, checkPrecision } from "../double-extensions";
import { UnitBase, Quantity, DimensionlessUnit } from "../units/units";
import { isSomething } from "../common/utilities";
import { ParameterMappingBase } from "./parameter-mapping-base";

export class UnitParameterMapping extends ParameterMappingBase {
    #mappingFactor = 1;
    #unit;
    #invalidUnit;
    #detents;
    #minimumUnit;
    #maximumUnit;
    #precision;
    #outputUnitType;
    
    constructor(name, parameter, quantityType, mappingType, minimumUnit = null, maximumUnit = null) {
        super(name, parameter);
        if (UnitParameterMapping.#validateQuantityType(quantityType)) {
            Object.defineProperty(this, "quantityType", {
                value: quantityType,
                writable: false
            });
        }
        else {
            throw new Error("The quantity type must derive from Quantity.");
        }
        if (this.#validateMappingType(mappingType)) {
            Object.defineProperty(this, "mappingType", {
                value: mappingType,
                writable: false
            });
        }
        else {
            throw new Error("The mapping type must be a sub-type of the quantity type.");
        }
        UnitParameterMapping.#ensureQuantityType(minimumUnit, quantityType);
        UnitParameterMapping.#ensureQuantityType(maximumUnit, quantityType);
        if (minimumUnit && maximumUnit && minimumUnit.greaterThan(maximumUnit)) {
            throw new Error("Minimum must be less than or equal to maximum.");
        }
        this.#invalidUnit = null;
        this.#minimumUnit = minimumUnit;
        this.#maximumUnit = maximumUnit;
        this.mappingOffset = 0;
        this.step = "any";
    }

    get precision() {
        return this.#precision;
    }

    set precision(value) {
        checkPrecision(value);
        this.#precision = value;
        [this.#unit, this.#invalidUnit, this.minimumUnit, this.maximumUnit].forEach(unit => unit?.setPrecision(value));
    }

    get minimumUnit() {
        return this.#minimumUnit;
    }

    get maximumUnit() {
        return this.#maximumUnit;
    }

    get typeName() {
        return "UnitParameterMapping";
    }

    get hasDetents() {
        return isSomething(this.#detents);
    }

    setDetents(detents) {
        this.#detents = detents;
    }

    get detents() {
        if (!this.hasDetents) {
            throw new Error("No detents have been defined for this unit parameter mapping.");
        }
        return this.#detents.slice();
    }

    static #validateQuantityType(type) {
        let result = type?.prototype instanceof Quantity;
        if (result) {
            const u = new type();
            result = u.getQuantityType() === type;
        }
        return result;
    }

    static #ensureQuantityType(unit, quantityType) {
        if (isSomething(unit) && unit.getQuantityType() !== quantityType) {
            throw new Error(`Units must share the quantity '${quantityType.name}.`);
        }
    }

    #validateMappingType(type) {
        const quantityType = this.quantityType;
        return type && ((type === DimensionlessUnit && quantityType === DimensionlessUnit) || (type.prototype instanceof quantityType));
    }

    getMappingFactor() {
        return this.#mappingFactor;
    }

    setMappingFactor(value) {
        if (approximates(value, 0, 1E-10)) {
            throw new Error("Mapping factor must be non-zero.");
        }
        this.#mappingFactor = value;
    }

    #createUnit(value = 0, coerce = true) {
        let unit = UnitBase.create(this.mappingType, value, this.precision);
        let limitingUnit;
        if (coerce) {
            if (this.#isLessThanMinimum(unit)) {
                limitingUnit = this.minimumUnit;
            }
            else if (this.#isGreaterThanMaximum(unit)) {
                limitingUnit = this.maximumUnit;
            }
        }
        return limitingUnit ? limitingUnit.clone().convert(this.mappingType) : unit;
    }

    get unit() {
        const u = this.#unit;
        if (u === undefined) {
            this.#unit = this.#createUnit();
        }
        return this.#unit;
    }

    #setUnitWithOptionalValidation(unit, ensureType) {
        const unitIsSomething = isSomething(unit);
        if (ensureType && unitIsSomething) {
            UnitParameterMapping.#ensureQuantityType(unit, this.quantityType);
        }
        let unitOk = unitIsSomething;
        if (unitOk) {
            if (this.hasDetents) {
                unitOk = this.#detents.any(d => unit.approximates(d.value));
            }
            else {
                unitOk = !(this.#isLessThanMinimum(unit) || this.#isGreaterThanMaximum(unit));
            }
        }
        if (unitOk) {
            this.#unit = unit;
            this.#invalidUnit = null;
            const precision = this.precision;
            if (isSomething(precision)) {
                unit.setPrecision(precision);
            }
            this._setIsInvalid(false);
        }
        else {
            this.#unit = null;
            this.#invalidUnit = unit;
            this._setIsInvalid(true);
        }
    }

    #isLessThanMinimum(unit) {
        return (isSomething(this.minimumUnit) && unit.lessThan(this.minimumUnit));
    }

    #isGreaterThanMaximum(unit) {
        return (isSomething(this.maximumUnit) && unit.greaterThan(this.maximumUnit));
    }

    _getRawValue() {
        const unitValue = this._getUnitValue();
        return this._convertToRawValue(unitValue);
    }

    _getUnitValue() {
        let u = this.unit;
        if (u !== null) {
            u = u.convert(this.mappingType);
            return u.value;
        }
        return null;
    }

    _convertToRawValue(unitValue, factor = null, offset = null) {
        if (unitValue === null) {
            return null;
        }
        factor ??= this.#mappingFactor;
        offset ??= this.mappingOffset;
        return (unitValue - offset) / factor;
    }

    _onParameterValueEmitted(value) {
        let unit;
        if (isSomething(value)) {
            const convertedValue = (value * this.#mappingFactor) + this.mappingOffset;
            unit = this.#createUnit(convertedValue, false);
        }
        else {
            unit = null;
        }
        this.#setUnitWithOptionalValidation(unit, false);
    }

    _setMappingValueInternal(value) {
        this.#setUnitWithOptionalValidation(value, true);
    }

    getMappingValue(allowInvalid = false) {
        let value = this.isInvalid && allowInvalid ? this.#invalidUnit : this.unit;
        if (isSomething(value) && this.#outputUnitType) {
            value = value.convert(this.#outputUnitType);
        }
        return value;
    }

    getOutputMappingType() {
        return this.#outputUnitType;
    }

    // Sets the type to which the unit is to be converted on retrieval of the mapping
    // value.  The absence of such a setting means that the unit will be output as-is.
    // This is useful for clients that want to override the unit type for unit values
    // that have been set on this UnitParameterMapping via an emission by the underlying
    // parameter.
    setOutputMappingType(type) {
        if (isSomething(type) && !this.#validateMappingType(type)) {
            throw new Error("The output mapping type must be a sub-type of the quantity type.");
        }
        this.#outputUnitType = type;
    }
}