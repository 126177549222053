import { Parameter } from "./parameter";
import { ParameterDirection } from "./parameter-direction";
import { BooleanParameterMapping } from "./boolean-parameter-mapping";

export class BooleanMultiParameterMapping extends BooleanParameterMapping {
    #parameterCache = new Set();

    constructor(name) {
        const parameter = new Parameter("Proxy", Symbol(), ParameterDirection.In);
        super(name, parameter);
    }

    get typeName() {
        return "BooleanMultiParameterMapping";
    }

    add(parameter) {
        if (!(parameter instanceof Parameter)) {
            throw new Error("parameter must be an instance of type Parameter.");
        }
        if (parameter.direction === ParameterDirection.Out) {
            throw new Error("parameter must be capable of receiving data.");
        }
        this.#parameterCache.add(parameter);
    }

    pushValue() {
        const value = this._getRawValue();
        this.#parameterCache.forEach(parameter => {
            parameter.receive(value);
        });
    }
}