import "../array-extensions";
import { isSomething, areSomething } from "../common/utilities";
import { TableKey } from "./table-key";
import { TableValue } from "./table-value";
import { TableUtilities } from "./table-utilities";

export class Table {
    #outputs;
    #rows = new Map();
    #orderedKeys = null;
    #reverseOrderedKeys = null;
    
    constructor(outputs) {
        if (!isSomething(outputs) || outputs.length === 0 || !outputs.every(tk => tk instanceof TableKey)) {
            throw new Error("Expected an array of at least one TableKey instance.");
        }
        this.#outputs = outputs.slice();
    }

    add(value, row) {
        if (isSomething(value)) {
            value = Number(value);
            if (isNaN(value)) {
                throw new Error("Value is not a number.");
            }
        }
        else {
            throw new Error("Value has not been defined.");
        }
        if (!isSomething(row) || row.length !== this.#outputs.length || !row.every(tk => tk instanceof TableValue)) {
            throw new Error(`Expected an array of ${this.#outputs.length} TableValue instance(s).`);
        }
        const rowKeys = row.map(tv => tv.key);
        if (TableKey.distinct(rowKeys).length !== rowKeys.length) {
            throw new Error("There are duplicate row value keys.");
        }
        if (rowKeys.except(this.#outputs).length > 0) {
            throw new Error("Some row values have keys that are not defined as outputs.");
        }
        if (this.#rows.has(value)) {
            throw new Error(`A row with value '${value}' has already been added.`);
        }
        this.#rows.set(value, row.slice());
        this.#orderedKeys = this.#reverseOrderedKeys = null;
    }

    #orderKeys() {
        const keys = Array.from(this.#rows.keys());
        this.#orderedKeys = keys.order();
        this.#reverseOrderedKeys = keys.orderDescending();
    }

    #getOrderedKeys() {
        if (this.#orderedKeys === null) {
            this.#orderKeys();
        }
        return this.#orderedKeys;
    }

    #getReverseOrderedKeys() {
        if (this.#reverseOrderedKeys === null) {
            this.#orderKeys();
        }
        return this.#reverseOrderedKeys;
    }

    getRow(value) {
        let row = this.#rows.get(value);
        if (row) {
            return row.slice();
        }
        const keyValues = TableUtilities.getAdjacentValues(value, this.#getOrderedKeys(), this.#getReverseOrderedKeys());
        const highKeyValue = keyValues.highValue;
        const lowKeyValue = keyValues.lowValue;
        if (areSomething(highKeyValue, lowKeyValue)) {
            const highRow = this.#rows.get(highKeyValue);
            const lowRow = this.#rows.get(lowKeyValue);
            const proportion = (value - lowKeyValue) / (highKeyValue - lowKeyValue);
            return TableUtilities.mergeRows(highRow, lowRow, proportion);
        }
        return TableUtilities.createUndefinedRow(this.#outputs);
    }
}