import { areSomething } from "../common/utilities";
import { Knot, DegreeCelsius, DegreeFahrenheit } from "../units/units";
import { AltitudeCalculator } from "./altitude-calculator";
import { TrueAirspeedCalculator } from "./true-airspeed-calculator";
import { IsaDifferenceCalculator } from "./isa-difference-calculator";

export class ObstacleClearanceCalculator {
    static #altitudeCalculator = new AltitudeCalculator();
    static #tasCalculator = new TrueAirspeedCalculator();
    static #isaCalculator = new IsaDifferenceCalculator();

    runwayElevation; // ft
    takeOffDistance; // ft (to a height of 50 ft)
    obstacleDistance; // ft (from start of take-off run)
    obstacleTrueAltitude; // ft
    qnh; // hPa
    aerodromeTemperatureCelsius; // degrees Celsius
    climbCalibratedAirspeed; // kt
    climbRate; // ft/min
    meanClimbWindComponent; // kt: +ve => headwind, -ve => tailwind

    set aerodromeTemperatureFahrenheit(value) {
        const temp = new DegreeFahrenheit(value);
        this.aerodromeTemperatureCelsius = temp.convert(DegreeCelsius).value;
    }

    get #allPropertiesSet() {
        return areSomething(
            this.runwayElevation,
            this.takeOffDistance,
            this.obstacleDistance,
            this.obstacleTrueAltitude,
            this.qnh,
            this.aerodromeTemperatureCelsius,
            this.climbCalibratedAirspeed,
            this.climbRate,
            this.meanClimbWindComponent
        );
    }

    #getPressureAltitude(indicatedAltitude) {
        return ObstacleClearanceCalculator.#altitudeCalculator.getPressureAltitude(indicatedAltitude, this.qnh); // ft
    }

    #getTrueAltitude(indicatedAltitude, temperature) {
        return ObstacleClearanceCalculator.#altitudeCalculator.getTrueAltitude(indicatedAltitude, this.qnh, this.runwayElevation, temperature); // ft
    }

    #getTas(pressureAltitude, temperature) {
        return ObstacleClearanceCalculator.#tasCalculator.getTas(this.climbCalibratedAirspeed, pressureAltitude, temperature); // kt
    }

    #getIsaDifference(pressureAltitude, temperature) {
        return ObstacleClearanceCalculator.#isaCalculator.getIsaDifference(pressureAltitude, temperature); // degrees Celsius
    }

    #getTemperature(pressureAltitude, isaDifference) {
        return ObstacleClearanceCalculator.#isaCalculator.getTemperature(pressureAltitude, isaDifference); // degrees Celsius
    }

    getObstacleClearance() {
        if (this.#allPropertiesSet) {
            const climbDistanceFromHeight50ft = this.obstacleDistance - this.takeOffDistance; // ft
            const runwayPressureAltitude = this.#getPressureAltitude(this.runwayElevation); // ft
            const trueAirspeed = this.#getTas(runwayPressureAltitude, this.aerodromeTemperatureCelsius); // kt
            const groundSpeed = new Knot(trueAirspeed - this.meanClimbWindComponent);
            const groundSpeedFpm = groundSpeed.convert("FootPerMinute").value; // ft/min
            const timeToObstacle = climbDistanceFromHeight50ft / groundSpeedFpm; // min
            const indicatedAltitudeAtObstacle = this.runwayElevation + 50 + this.climbRate * timeToObstacle; // ft
            const pressureAltitudeAtObstacle = this.#getPressureAltitude(indicatedAltitudeAtObstacle); // ft
            const isaDifferenceAtRunway = this.#getIsaDifference(runwayPressureAltitude, this.aerodromeTemperatureCelsius); // degrees Celsius
            const estimatedTemperatureAtObstacle = this.#getTemperature(pressureAltitudeAtObstacle, isaDifferenceAtRunway); // degrees Celsius
            const trueAltitudeAtObstacle = this.#getTrueAltitude(indicatedAltitudeAtObstacle, estimatedTemperatureAtObstacle); // ft
            const obstacleClearanceHeight = trueAltitudeAtObstacle - this.obstacleTrueAltitude; // ft
            return {
                trueAirspeed,
                groundSpeed: groundSpeed.value,
                indicatedAltitudeAtObstacle,
                trueAltitudeAtObstacle,
                obstacleClearanceHeight
            };
        }
        return null;
    }
}