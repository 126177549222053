import styles from "./AccountManager.module.css";
import commonStyles from "./Common.module.css";
import useAccountContext from "../hooks/useAccountContext";
import { useRef, useState, useEffect } from "react";
import InputWithHint from "./InputWithHint";
import { nameOptions } from "./InputWithHintOptions";
import FieldEditor from "./FieldEditor";
import PasswordSetter from "./PasswordSetter";
import { nameRegex } from "../utils/validation";

const AccountManager = () => {
    const { accountInfo, error, isLoading, updateName, updatePassword } = useAccountContext();
    const passwordRef = useRef();
    const [message, setMessage] = useState(null);
    
    const [name, setName] = useState(accountInfo?.name);
    const [backupName, setBackupName] = useState(accountInfo?.name);
    const [nameHasErrors, setNameHasErrors] = useState(true);
    
    const [email, setEmail] = useState(accountInfo?.email);

    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordIsSomething, setOldPasswordIsSomething] = useState(true);
    const [oldPasswordClassName, setOldPasswordClassName] = useState(null);

    const [newPassword, setNewPassword] = useState("");
    const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
    const [newPasswordIsValid, setNewPasswordIsValid] = useState(false);
    const [passwordHasErrors, setPasswordHasErrors] = useState(true);

    useEffect(() => {
        if (isLoading) {
            setMessage("Fetching account details...");
        }
        else if (error || !accountInfo) {
            setMessage("Failed to retrieve account details");
        }
        else {
            setMessage(null);
        }
    }, [accountInfo, error, isLoading]);

    useEffect(() => {
        setName(accountInfo?.name ?? null);
        setBackupName(accountInfo?.name ?? null);
        setEmail(accountInfo?.email ?? null);
    }, [accountInfo]);

    useEffect(() => {
        setNameHasErrors(!nameRegex.test(name));
    }, [name]);

    useEffect(() => {
        setOldPasswordIsSomething(oldPassword.length > 0);
    }, [oldPassword]);

    useEffect(() => {
        const className = getClassName(!oldPasswordIsSomething);
        setOldPasswordClassName(className);
    }, [oldPasswordIsSomething]);

    useEffect(() => {
        setPasswordHasErrors(!(newPasswordIsValid && oldPasswordIsSomething));
    }, [newPasswordIsValid, oldPasswordIsSomething]);

    const getClassName = (invalidCondition) => {
        let className = `${commonStyles.input} ${styles.input}`;
        if (invalidCondition) {
            className += ` ${commonStyles.input___invalid}`;
        }
        return className;
    };

    const handleChangeName = async () => {
        const result = await updateName(name);
        if (result.succeeded) {
            setBackupName(name);
        }
        else {
            setName(backupName);
        }
        return result;
    };

    const handleCancelChangeName = () => {
        setName(backupName);
    };

    const handleChangePassword = async () => {
        const result = await updatePassword(oldPassword, newPassword);
        clearPassword();
        return result;
    };

    const handleCancelChangePassword = () => {
        clearPassword();
    };

    const clearPassword = () => {
        setOldPassword("");
        setNewPassword("");
        setConfirmedNewPassword("");
    };

    const nameEditor = <div className={styles.editor}>
        <InputWithHint
            options={nameOptions}
            value={name}
            setValue={setName}
            valueHasErrors={nameHasErrors} />
    </div>

    const passwordEditor = <div className={styles.editor}>
        <input
            className={oldPasswordClassName}
            type="password"
            placeholder="Old password"
            ref={passwordRef}
            autoComplete="off"
            maxLength={15}
            onChange={e => setOldPassword(e.target.value)}
            value={oldPassword}
            required />
        <PasswordSetter
            password={newPassword}
            setPassword={setNewPassword}
            confirmedPassword={confirmedNewPassword}
            setConfirmedPassword={setConfirmedNewPassword}
            setPasswordIsValid={setNewPasswordIsValid}
            autofocus={false} />
    </div>

    return (
        <section className={styles.AccountManager}>
            {message && <p className={styles.message}>{message}</p>}
            {!message && accountInfo && 
                <div className={styles.fieldContainer}>
                    <div className={styles.editorContainer}>
                        <FieldEditor
                            name="Name"
                            value={name}
                            isReadOnly={false}
                            editDataHasErrors={nameHasErrors}
                            commit={handleChangeName}
                            cancel={handleCancelChangeName}
                            editor={nameEditor}
                        />
                    </div>
                    <div className={styles.editorContainer}>
                        <FieldEditor name="Email" value={email} isReadOnly={true} />
                    </div>
                    <div className={styles.editorContainer}>
                        <FieldEditor
                            name="Password"
                            value="••••••••"
                            isReadOnly={false}
                            editDataHasErrors={passwordHasErrors}
                            commit={handleChangePassword}
                            cancel={handleCancelChangePassword}
                            editor={passwordEditor}
                            focusRef={passwordRef}
                        />
                    </div>
                </div>
            }
        </section>
    )
};

export default AccountManager;