import { isSomething } from "../common/utilities";
import { approximates } from "../double-extensions";
import { ParameterDirection } from "./parameter-direction";

export class TransformShim {
    #parameter1;
    #parameter2;
    #factor;
    #offset;
    
    constructor(parameter1, parameter2, factor = 1, offset = 0) {
        if (!(isSomething(parameter1) && isSomething(parameter2))) {
            throw new Error("Both parameters must be defined.");
        }
        if ((parameter1.direction === ParameterDirection.In && parameter2.direction === ParameterDirection.In) ||
            (parameter1.direction === ParameterDirection.Out && parameter2.direction === ParameterDirection.Out)) {
            throw new Error("The parameter directions are incompatible.");
        }
        this.#parameter1 = parameter1;
        this.#parameter2 = parameter2;
        this.#factor = TransformShim.#ensureFactor(factor);
        this.#offset = offset;
        parameter1.subscribeValueTransferred((s, e) => this.#onValueTransferred(s, e));
        parameter2.subscribeValueTransferred((s, e) => this.#onValueTransferred(s, e));
    }

    static #ensureFactor(value) {
        if (approximates(value, 0, 1E-10)) {
            throw new Error("Factor must be non-zero.");
        }
        return value;
    }

    // TODO: there is an assumption that this shim always deals with numeric values.
    #onValueTransferred(parameter, args) {
        if (args.direction === ParameterDirection.Out) {
            let targetParameter;
            let valueTransform;
            if (parameter === this.#parameter1) {
                targetParameter = this.#parameter2;
                valueTransform = value => (value * this.#factor) + this.#offset;
            }
            else {
                targetParameter = this.#parameter1;
                valueTransform = value => (value - this.#offset) / this.#factor;
            }
            const value = args.value === null ? null : valueTransform(args.value);
            targetParameter.receive(value);
        }
    }
}