import styles from "./Parameter.module.css";
import commonStyles from "./Common.module.css";
import "../lib/array-extensions";
import { UnitBase } from "../lib/units/units";
import { useState, useEffect, useRef, useCallback } from "react";
import { isSomething } from "../lib/common/utilities";
import useParameterContext from "../hooks/useParameterContext";
import UnitConverter from "../lib/units/unit-converter";
import { DimensionlessUnit } from "../lib/units/units";
import { ReactComponent as Warning } from "../assets/warning.svg";

const getUnitInfos = (unit) => {
    const unitTypes = unit?.getImplementingTypesOfSameQuantity() ?? [];
    const infos = unitTypes.map(unitType => {
        const u = UnitBase.create(unitType, 0);
        return ({
            unitType: unitType,
            abbreviation: u.getAbbreviation()
        })
    });
    return infos;
};

const UnitOutputParameter = ({ parameterMapping }) => {
    const [unit, setUnit] = useState(null);
    const [unitSet, setUnitSet] = useState(false);
    const [isNotDimensionless, setIsNotDimensionless] = useState(true);
    const [unitInfos, setUnitInfos] = useState([]);
    const [selectedUnitInfo, setSelectedUnitInfo] = useState(null);
    const { active, available, getCachedInfo, setCachedInfo } = useParameterContext();
    const [cacheAccessed, setCacheAccessed] = useState(false);
    const unitInfosRef = useRef();

    unitInfosRef.current = unitInfos;

    useEffect(() => {
        const initUnit = parameterMapping.getMappingValue();
        const unitIsSomething = isSomething(initUnit);
        setUnit(initUnit);
        setUnitSet(unitIsSomething);
        const quantityType = parameterMapping.quantityType;
        const notDimensionless = quantityType !== DimensionlessUnit;
        setIsNotDimensionless(notDimensionless);
        const uis = getUnitInfos(initUnit);
        setUnitInfos(uis);
        const mappingType = initUnit?.constructor;
        setSelectedUnitInfo(uis.firstOrUndefined(ui => ui.unitType === mappingType));
        setCacheAccessed(false);

        parameterMapping.subscribeParameterValueEmitted(handleParameterValueEmitted);
        return () => parameterMapping.unsubscribeParameterValueEmitted(handleParameterValueEmitted);
    }, [parameterMapping]);

    const updateCache = useCallback(() => {
        if (!active || !available) return;

        const name = parameterMapping.name;
        const u = parameterMapping.getMappingValue();
        const unitAbbreviation = u.getAbbreviation();
        const info = { unitAbbreviation };
        setCachedInfo(name, null, info);
    }, [parameterMapping, active, available, setCachedInfo]);

    useEffect(() => {
        if (active && available && !cacheAccessed && unitInfos.length > 0) {
            const info = getCachedInfo(parameterMapping.name);
            const unitAbbreviation = info?.unitAbbreviation;
            if (unitAbbreviation) {
                const mappingType = UnitConverter.getTypeFromAbbreviation(unitAbbreviation);
                parameterMapping.setOutputMappingType(mappingType);
                const newUnit = parameterMapping.getMappingValue();
                setUnit(newUnit);
                setSelectedUnitInfo(unitInfos.firstOrUndefined(ui => ui.unitType === mappingType));
            }
            setCacheAccessed(true);
        }
    }, [active, available, cacheAccessed, unitInfos, getCachedInfo, parameterMapping])

    const handleParameterValueEmitted = (sender) => {
        const newUnit = sender.getMappingValue();
        const unitIsSomething = isSomething(newUnit);
        let info;
        if (unitIsSomething) {
            let uis;
            if (unitInfosRef.current.length === 0) {
                uis = getUnitInfos(newUnit);
                setUnitInfos(uis);
            }
            else {
                uis = unitInfosRef.current;
            }
            info = uis.firstOrUndefined(ui => ui.unitType === newUnit.constructor);
        }
        else {
            info = undefined;
        }
        setUnit(newUnit);
        setUnitSet(unitIsSomething);
        setSelectedUnitInfo(info);
    };

    const handleUnitTypeChange = useCallback((e) => {
        const abbreviation = e.target.value;
        const info = unitInfos.single(i => i.abbreviation === abbreviation);
        parameterMapping.setOutputMappingType(info.unitType);
        const newUnit = parameterMapping.getMappingValue();
        setUnit(newUnit);
        setSelectedUnitInfo(info);
        updateCache();
    }, [parameterMapping, unitInfos, updateCache]);

    return <div className={styles.outputContainer}>
        <label htmlFor="value" className={`${styles.paddedLabel} ${styles.padRight}`}>{parameterMapping.name}:</label>
        <div className={styles.outputResult}>
            {!unitSet && <Warning title="Parameters outside limits" width="24" height="24" />}

            {unitSet && <div className={styles.unitValueContainer}>
                <label id="value" className={styles.numericOutput}>{unit?.getRoundedValue().toLocaleString()}</label>
                {isNotDimensionless && (
                    <select
                        className={`${commonStyles.selector} ${styles.selector} ${styles.selector___outputUnit}`}
                        value={selectedUnitInfo?.abbreviation}
                        onChange={handleUnitTypeChange}>
                        {unitInfos.map(i => (
                            <option key={i.unitType} value={i.abbreviation}>
                                {i.abbreviation}
                            </option>
                        ))}
                    </select>
                )}
            </div>}
        </div>
    </div>
}

export default UnitOutputParameter;