import styles from "./Register.module.css";
import commonStyles from "./Common.module.css";
import { useState, useEffect } from "react";
import useAuthContext from "../hooks/useAuthContext";
import { nameRegex, emailRegex } from "../utils/validation";
import PasswordSetter from "./PasswordSetter";
import InputWithHint from "./InputWithHint";
import { nameOptions, emailOptions } from "./InputWithHintOptions";

const Register = () => {
    const { register } = useAuthContext();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [canRegister, setCanRegister] = useState(false);
    const [nameHasErrors, setNameHasErrors] = useState(true);
    const [emailHasErrors, setEmailHasErrors] = useState(true);
    const [passwordHasErrors, setPasswordHasErrors] = useState(true);
    const [statusMessageClassName, setStatusMessageClassName] = useState(styles.status);

    useEffect(() => {
        const valid = !(nameHasErrors || emailHasErrors || passwordHasErrors);
        setCanRegister(valid);
    }, [nameHasErrors, emailHasErrors, passwordHasErrors]);

    useEffect(() => {
        updateStatusMessage("");
    }, [name, email, password, confirmedPassword]);

    useEffect(() => {
        setNameHasErrors(!nameRegex.test(name));
    }, [name]);

    useEffect(() => {
        setEmailHasErrors(!emailRegex.test(email));
    }, [email]);

    const updateStatusMessage = (message, isError = false) => {
        setStatusMessage(message);
        let className = styles.status;
        if (isError) {
            className += ` ${styles.status___error}`;
        }
        setStatusMessageClassName(className);
    };

    const handleRegistration = async (e) => {
        e.preventDefault();
        clearEntries();
        try {
            const registered = await register(name, email, password);
            const message = registered
                ? "Thank you. A verification code has been sent to the supplied email address."
                : "The supplied email address has already been verified.  Have you forgotten your password?";
            // TODO link to reset-password page
            updateStatusMessage(message);
        }
        catch (err) {
            const message = getErrorMessage(err);
            updateStatusMessage(message, true);
        }
    };

    const getErrorMessage = (err) => {
        let message;
        const response = err?.response;
        if (!response) {
            message = err?.message ? err.message : "The server did not respond.  Please check your network connection.";
        }
        else if (response.status === 400) { // Bad request
            message = response?.data?.invalidEmail
                ? "The provided email address is invalid."
                : "The provided fields are invalid.";
        }
        else if (response.status === 500) {
            message = "Internal server error."
        }
        else {
            message = "Registration failed.";
        }
        return message;
    };

    const clearEntries = () => {
        updateStatusMessage("");
        setName("");
        setEmail("");
        setPassword("");
        setConfirmedPassword("");
    };

    return (
        <section>
            <form className={styles.form} onSubmit={handleRegistration}>
                <div className={styles.formInputs}>
                    <InputWithHint
                        options={nameOptions}
                        value={name}
                        setValue={setName}
                        valueHasErrors={nameHasErrors} />
                    
                    <InputWithHint
                        options={emailOptions}
                        value={email}
                        setValue={setEmail}
                        valueHasErrors={emailHasErrors} />
                    
                    <PasswordSetter
                        password={password}
                        setPassword={setPassword}
                        confirmedPassword={confirmedPassword}
                        setConfirmedPassword={setConfirmedPassword}
                        setPasswordIsValid={value => setPasswordHasErrors(!value)}
                        autofocus={false} />
                </div>
                <button className={`${commonStyles.button} ${styles.button}`} type="submit" disabled={!canRegister}>Register</button>
            </form>
            <p className={statusMessageClassName}>{statusMessage}</p>
        </section>
    )
}

export default Register;