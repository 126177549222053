import "../../array-extensions";

export class ParameterCategory {
    #name;
    #sequence;
    #parameterMappings = new Map();

    constructor(name, sequence = 1) {
        this.#name = name;
    }

    get name() {
        return this.#name;
    }

    get sequence() {
        return this.#sequence;
    }

    _addParameterMapping(parameterMapping) {
        const cache = this.#parameterMappings;
        const name = parameterMapping.name;
        if (cache.has(name)) {
            throw new Error(`A parameter with the name '${name}' has already been added to this parameter category.`);
        }
        cache.set(name, parameterMapping);
    }

    getParameterMappingCount() {
        return this.#parameterMappings.size;
    }

    getParameterMappings() {
        return Array.from(this.#parameterMappings.values()).orderBy(pm => pm.sequence);
    }

    getParameterMapping(name, throwIfNotFound = true) {
        const parameterMapping = this.#parameterMappings.get(name);
        if (parameterMapping) {
            return parameterMapping;
        }
        if (throwIfNotFound) {
            throw new Error(`A parameter mapping with the name '${name}' is not associated with this parameter category.`);
        }
    }

    setParameterMappingValue(name, value) {
        this.getParameterMapping(name).setMappingValue(value);
    }

    getParameterMappingValue(name) {
        return this.getParameterMapping(name).getMappingValue();
    }
}