import { Segment } from "./segment"

export class LinearInterpolator {
    constructor(curve) {
        this.segments = LinearInterpolator.#createSegments(curve.points);
    }

    static #createSegments(points) {
        let segments = [];
        const limit = points.length - 1;
        for (let i = 0; i < limit; i++) {
            segments.push(new Segment(points[i], points[i + 1]));
        }
        return segments;
    }

    static #getSegmentX(x, segments, extendCurveIfNecessary) {
        for (const segment of segments) {
            if (segment.isXInRange(x)) {
                return segment;
            }
        }
        
        return extendCurveIfNecessary ?
            x < segments[0].x1 ? segments[0] : segments[segments.length - 1] :
            null;
    }

    static #getSegmentY(y, segments, extendCurveIfNecessary) {
        for (const segment of segments) {
            if (segment.isYInRange(y)) {
                return segment;
            }
        }
        
        if (extendCurveIfNecessary) {
            const firstSegment = segments[0];
            const lastSegment = segments[segments.length - 1];

            let result;
            if (firstSegment.y1 < firstSegment.y2) {
                result = y < firstSegment.y1 ? firstSegment : lastSegment;
            }
            else {
                result = y > firstSegment.y1 ? firstSegment : lastSegment;
            }
            return result;
        }
        return null;
    }

    interpolateY(x, extendCurveIfNecessary = true) {
        const segment = LinearInterpolator.#getSegmentX(x, this.segments, extendCurveIfNecessary);
        return segment === null ? null : segment.y1 + (segment.y2 - segment.y1) * (x - segment.x1) / (segment.x2 - segment.x1);
    }

    interpolateX(y, extendCurveIfNecessary = true) {
        const segment = LinearInterpolator.#getSegmentY(y, this.segments, extendCurveIfNecessary);
        return segment === null ? null : segment.x1 + (segment.x2 - segment.x1) * (y - segment.y1) / (segment.y2 - segment.y1);
    }
}