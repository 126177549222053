import { Transform } from "../transforms/transform";
import { AltitudeCalculator } from "./altitude-calculator";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";

export class PressureAltitudeCalculatorTransform extends Transform {
    #altitudeCalculator = new AltitudeCalculator();

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterIndicatedAltitudeName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterQnhName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterPressureAltitudeName, this.id, ParameterDirection.Out)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const count = this._setParameterValueCount;
        if (count === 2) {
            const result = this.#calculateOutput();
            this._emitParameterValue(ParameterNames.ParameterPressureAltitudeName, result);
        }
    }

    #calculateOutput() {
        const indicatedAltitude = this._getParameterValue(ParameterNames.ParameterIndicatedAltitudeName);
        const qnh = this._getParameterValue(ParameterNames.ParameterQnhName);
        return this.#altitudeCalculator.getPressureAltitude(indicatedAltitude, qnh);
    }
}