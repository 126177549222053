export class TableValue {
    #key;
    #undefined;
    #value;
    
    constructor(key, value, isUndefined) {
        if (!isUndefined && !key.isValidValue(value)) {
            throw new Error(`'${value}' is not a valid value.`);
        }
        this.#key = key;
        this.#undefined = isUndefined;
        this.#value = isUndefined ? 0 : value;
    }
    
    get key() {
        return this.#key;
    }

    get undefined() {
        return this.#undefined;
    }

    get value() {
        return this.#value;
    }
}