import styles from "./AircraftSummary.module.css";
import commonStyles from "./Common.module.css";
import React, { useState, useEffect } from "react";
import ExpiryBar from "./ExpiryBar";

const AircraftSummary = ({ handleClick, manufacturer, model, description, validFrom, validTo, leased, selected }) => {
    const hangaredAircraftClassName = `${styles.AircraftSummary}` + (selected ? ` ${styles.AircraftSummary___selected}` : "");
    const [buttonClassName, setButtonClassName] = useState(null);
    const [pathClassName, setPathClassName] = useState(null);
    const [descriptionClassName, setDescriptionClassName] = useState(styles.description);

    useEffect(() => {
        let className = `${commonStyles.button} ${styles.aircraftLink}`;
        if (selected) {
            className += ` ${styles.aircraftLink___selected}`;
        }
        setButtonClassName(className);
        className = `${styles.path}`;
        if (selected) {
            className += ` ${styles.path___selected}`;
        }
        setPathClassName(className);
    }, [selected]);

    useEffect(() => {
        let className = `${styles.description}`;
        if (leased) {
            className += ` ${styles.description___withExpiry}`;
        }
        setDescriptionClassName(className);
    }, [leased]);

    return (
        <article className={hangaredAircraftClassName}>
            <button className={buttonClassName} onClick={handleClick}>
                <p className={styles.manufacturer}>{manufacturer}</p>
                {leased &&
                    <svg className={styles.hangarImage} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
                        <path className={pathClassName} d="M26.718,8.364l-11-4.231c-0.462-0.178-0.974-0.178-1.436,0l-11,4.231C2.51,8.661,2,9.403,2,10.231V23c0,1.105,0.895,2,2,2h4 v-2h14v2h4c1.105,0,2-0.895,2-2V10.231C28,9.403,27.49,8.661,26.718,8.364z M22,21H8v-2h14V21z M22,17H8v-2h14V17z" />
                    </svg>
                }
                <p className={styles.model}>{model}</p>
                <p className={descriptionClassName}>{description}</p>
                {leased && <div className={styles.expiryBarContainer}>
                    <ExpiryBar startDate={validFrom} endDate={validTo} />
                </div>}
            </button>
        </article>
    )
}

export default React.memo(AircraftSummary);