import "../array-extensions";
import { isSomething } from "../common/utilities";
import { TransformBase } from "./transform-base";
import { ParameterDirection } from "./parameter-direction";

export class Transform extends TransformBase {
    #parameters = new Map();
    #parameterValueCache = new Map();
    #predicates = new Map();
    #blockedParameters = new Set();
    
    #ensureParameters() {
        const cache = this.#parameters;
        if (cache.size === 0) {
            const parameters = this._sourceParameters();
            if (parameters.any(p => p === null)) {
                throw new Error("Null parameters exist.");
            }
            const count = parameters.length;
            if (count < 2) {
                throw new Error("At least two parameters are required.");
            }
            if (parameters.map(p => p.name).distinct().length !== count) {
                throw new Error("Duplicate parameters exist.");
            }
            parameters.forEach(parameter => {
                cache.set(parameter.name, parameter);
                parameter.subscribeValueTransferred((s, e) => this.#onValueTransferred(s, e));
            });
        }
    }

    getParameters() {
        this.#ensureParameters();
        return Array.from(this.#parameters.values()).orderBy(parameter => parameter.name);
    }

    _sourceParameters() {
        throw new Error(`This method must be overridden in a derived class, returning this transform's parameters.`);
    }

    registerPredicate(parameterName, predicate) {
        if (!isSomething(predicate)) {
            throw new Error("A predicate has not been supplied.");
        }
        const parameter = this.getParameter(parameterName);
        if (this.#predicates.has(parameter)) {
            throw new Error(`A preciate has already been registered for parameter '${parameterName}'.`);
        }
        this.#predicates.set(parameter, predicate);
    }

    #onValueTransferred(parameter, args) {
        if (args.direction === ParameterDirection.In) {
            const value = args.value;
            const predicate = this.#predicates.get(parameter);
            if (isSomething(predicate)) {
                const testResult = predicate.test(value);
                if (testResult) {
                    this.#blockedParameters.delete(parameter);
                }
                else {
                    this.#blockedParameters.add(parameter);
                }
            }
            this.#parameterValueCache.set(parameter.name, value);
            if (this.#blockedParameters.size === 0) {
                this._onParameterValueReceived(parameter, value);
            }
        }
    }

    _onParameterValueReceived(parameter, value) {
        this._throwOverrideRequired();
    }

    _throwCannotIdentifyOutputParameters() {
        throw new Error("Too many parameter values have been set: cannot determine the output parameter(s).");
    }

    getParameter(name) {
        this.#ensureParameters();
        const p = this.#parameters.get(name);
        if (p === undefined) {
            throw new Error(`No parameter exists with the name '${name}'.`);
        }
        return p;
    }

    _getParameterValue(name) {
        const value = this.#parameterValueCache.get(name);
        return isSomething(value) ? value : null;
    }

    _clearParameterValue(name) {
        this.#parameterValueCache.delete(name)
    }

    get _setParameterValueCount() {
        return this.#parameterValueCache.size;
    }

    getUnsetParameterNames() {
        this.#ensureParameters();
        return Array.from(this.#parameters.keys()).except(Array.from(this.#parameterValueCache.keys()));
    }

    getSetParameterNames() {
        return Array.from(this.#parameterValueCache.keys());
    }

    _emitParameterValue(name, value) {
        const parameter = this.getParameter(name);
        parameter.emit(value);
    }

    reset() {
        this.#parameterValueCache.clear();
    }
}