// https://stackoverflow.com/questions/18936774/javascript-equivalent-to-c-sharp-linq-select

export function isArrayWithAtLeastOneElement(obj) {
    return isArrayWithAtLeastNumberOfElements(obj, 1);
}

export function isArrayWithAtLeastNumberOfElements(obj, number) {
    return getElementCount(obj) >= number;
}

export function isArrayWithExactNumberOfElements(obj, number) {
    return getElementCount(obj) === number;
}

export function getElementCount(obj) {
    return Array.isArray(obj) ? obj.length : -1; // -1 if obj is not an array
}

function checkExisting(member) {
    if (Array.prototype[member]) {
        console.warn("Overriding existing Array.prototype." + member);
    }
}

checkExisting("where");
checkExisting("firstOrUndefined");
checkExisting("equals");
checkExisting("order");
checkExisting("orderDescending");
checkExisting("orderBy");
checkExisting("orderByDescending");
checkExisting("orderWithComparers");
checkExisting("distinct");
checkExisting("any");
checkExisting("except");
checkExisting("union");
checkExisting("intersect");
checkExisting("single");
checkExisting("last");
checkExisting("max");
checkExisting("min");

Array.prototype.where = function (filter) {
    let arr = this;
    switch (typeof filter) {
        case 'function':
            let matches = [];
            arr.forEach(e => {
                if (filter(e) === true) {
                    matches.push(e);
                }
            })
            return matches;

        case 'object':
            for (const property in filter) {
                if (!filter.hasOwnProperty(property)) {
                    continue; // Ignore inherited properties
                }
                arr = arr.where(e => e[property] === filter[property]);
            }
            return arr.slice(0);

        default:
            throw new Error("filter must be either a function or an object of properties and values.");
    }
}

Array.prototype.firstOrUndefined = function (predicate = null) {
    predicate ??= e => true;
    const matches = this.where(predicate);
    return matches.length === 0 ? undefined : matches[0];
}

Array.prototype.equals = function (other) {
    const arr = this;
    return arr.length === other.length && arr.every((value, index) => value === other[index]);
}

Array.prototype.order = function () {
    return this.orderBy(e => e);
}

Array.prototype.orderDescending = function () {
    return this.orderByDescending(e => e);
}

Array.prototype.orderBy = function (selector) {
    let arr = this.slice().map(e => ({
        element: e,
        sortValue: selector(e)
    }));
    arr.sort((a, b) => a.sortValue - b.sortValue);
    return arr.map(e => e.element);
}

Array.prototype.orderByDescending = function (selector) {
    return this.orderBy(selector).reverse();
}

export function standardComparer(a, b) {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}

Array.prototype.orderWithComparers = function (...comparers) {
    let arr = this.slice();
    if (comparers.length === 0) {
        arr.sort();
    }
    else {
        const compositeComparer = (a, b) => {
            let result = 0;
            comparers.every(comparer => {
                result = comparer(a, b);
                return result === 0;
            });
            return result;
        }
        arr.sort(compositeComparer);
    }
    return arr;
}

Array.prototype.distinct = function (selector = null) {
    let mappedArray = this;
    if (selector) {
        mappedArray = mappedArray.map(o => selector(o));
    }
    return this.filter((v, i, a) => {
        if (selector) {
            v = selector(v);
        }
        return mappedArray.indexOf(v) === i;
    });
}

Array.prototype.any = function (predicate) {
    return this.firstOrUndefined(predicate) !== undefined;
}

Array.prototype.except = function (other, selector = null) {
    let result = [];
    this.forEach(e => {
        const v = selector ? selector(e) : e;
        if (other.indexOf(v) < 0) {
            result.push(e);
        }
    });
    return result;
}

Array.prototype.union = function (other, selector = null) {
    var concat = [...this, ...other];
    return concat.distinct(selector);
}

Array.prototype.intersect = function (other) {
    return this.filter(x => other.includes(x)).distinct();
}

Array.prototype.single = function (predicate = null) {
    predicate ??= e => true;
    const matches = this.where(predicate);
    if (matches.length === 1) {
        return matches[0];
    }
    throw new Error("This array is either empty or contains more than one matching element.");
}

Array.prototype.last = function () {
    return this[this.length - 1];
}

Array.prototype.max = function () {
    return Math.max.apply(null, this);
};

Array.prototype.min = function () {
    return Math.min.apply(null, this);
};