import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";
import { isSomething } from "../common/utilities";

export class ConditionalRouterTransform extends Transform {
    #predicate;
    #testResult;

    constructor(predicate) {
        super();
        this.#predicate = predicate;
    }

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterInName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterTestName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterPassName, this.id, ParameterDirection.Out),
            new Parameter(ParameterNames.ParameterFailName, this.id, ParameterDirection.Out)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const count = this._setParameterValueCount;
        if (parameter.name === ParameterNames.ParameterTestName) {
            this.#testResult = isSomething(value) ? this.#predicate.test(value) : null;
        }
        if (count === 2) {
            const inValue = this._getParameterValue(ParameterNames.ParameterInName);
            switch (this.#testResult) {
                case true:
                    this._emitParameterValue(ParameterNames.ParameterPassName, inValue);
                    break;
                case false:
                    this._emitParameterValue(ParameterNames.ParameterFailName, inValue);
                    break;
                default:
                    this._emitParameterValue(ParameterNames.ParameterPassName, null);
                    this._emitParameterValue(ParameterNames.ParameterFailName, null);
                    break;
            }
        }
    }
}