export class PerformanceCategory {
    #name;
    #operations = [];

    constructor(name) {
        this.#name = name;
    }

    get name() {
        return this.#name;
    }

    _addOperation(operation) {
        this.#operations.push(operation);
    }

    getOperations() {
        return this.#operations.slice();
    }
}