import { createContext } from "react";
import useAuthContext from "../hooks/useAuthContext";
import { useAuthorisedApiGet } from "../hooks/useApi";
import { isSomething } from "../lib/common/utilities";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { axiosPut } from "../api/axios";

const AccountContext = createContext({});

const ACCOUNT_INFO_URL = "/account-info";

export const AccountProvider = ({ children }) => {
    const { auth } = useAuthContext();
    const axiosPrivate = useAxiosPrivate();
    
    const { data: accountInfo, error, isLoading } = useAuthorisedApiGet(ACCOUNT_INFO_URL, auth.accessToken, null, null, isSomething(auth.accessToken));

    const updateName = async (name) => {
        const data = {
            "name": name
        };
        return await update(data);
    };
    
    const updatePassword = async (oldPassword, newPassword) => {
        const data = {
            "oldPassword": oldPassword,
            "newPassword": newPassword
        };
        return await update(data);
    };

    const update = async (data) => {
        let result;
        const onSuccess = () => {
            result = { succeeded: true };
        };
        const onError = (err) => {
            let message;
            switch (err.response.status) {
                case 400: // Bad request
                    message = "The new information was rejected by the server."
                    break;
                case 401: // Unauthorised
                    message = "Password mismatch.";
                    break;
                case 404:
                    message = "User not found."
                    break;
                default:
                    message = "Unknown error.";
                    break;
            }
            result = {
                succeeded: false,
                message : message
            };
        }
        await axiosPut(axiosPrivate, ACCOUNT_INFO_URL, data, onSuccess, onError);
        return result;
    };

    return (
        <AccountContext.Provider value={{ accountInfo, error, isLoading, updateName, updatePassword }}>
            {children}
        </AccountContext.Provider>
    )
}

export default AccountContext;