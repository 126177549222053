import styles from "./Sidebar.module.css";

const Sidebar = ({ children }) => {
    return (
        <aside className={styles.Sidebar}>
            {children}
        </aside>
    )
};

export default Sidebar;