import { getCurveFunnel } from "./extensions";
import { isSomething } from "../common/utilities";
import { CurveSetBoundaryPolicy } from "./curve-set-boundary-policy";
import { CurveSelectionMethod } from "./curve-selection-method";

export class CurveSet {
    constructor(interpolatorFactory, boundaryPolicy = CurveSetBoundaryPolicy.Reject) {
        this.interpolatorFactory = interpolatorFactory;
        this.boundaryPolicy = boundaryPolicy;
        this.curves = [];
    }

    add(curve) {
        this.curves.push(curve);
    }

    getCurveInfo(point) {
        const funnel = getCurveFunnel(this.curves, point);
        const uc = funnel.upperCurve;
        const lc = funnel.lowerCurve;
        const lowerCurveExists = isSomething(lc);
        const upperCurveExists = isSomething(uc);

        let curve, method;
        if (lowerCurveExists && upperCurveExists) {
            curve = uc.createInterpolatedCurve(lc, point, this.interpolatorFactory);
            method = CurveSelectionMethod.Interpolation;
        }
        else {
            switch (this.boundaryPolicy) {
                case CurveSetBoundaryPolicy.Shadow:
                    curve = (uc ?? lc).createShadowCurve(point, lowerCurveExists, this.interpolatorFactory);
                    method = CurveSelectionMethod.Shadow;
                    break;
                case CurveSetBoundaryPolicy.UseBoundaryCurve:
                    /* If the funnel's lower curve is undefined then point must be below the CurveSet's
                       lower boundary curve, hence that curve would be returned.  Equally,
                       if the funnel's upper curve is undefined then point must be above the CurveSet's
                       upper boundary curve and that curve would be returned.
                    */
                    curve = uc ?? lc;
                    method = uc ? CurveSelectionMethod.LowerBoundary : CurveSelectionMethod.UpperBoundary;
                    break;
                default: // CurveSetBoundaryPolicy.Reject
                    curve = null;
                    method = CurveSelectionMethod.None;
                    break;
            }
        }
        return { curve, method };
    }
}