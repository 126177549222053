export class TransformBase {
    #id;
    
    constructor() {
        this.#id = Symbol();
    }

    get id() {
        return this.#id;
    }

    getParameters() {
        this._throwOverrideRequired();
    }
    
    reset() {
        this._throwOverrideRequired();
    }

    _throwOverrideRequired() {
        throw new Error("This method must be overridden in a derived class.");
    }

    _throwCannotIdentifyOutputParameters() {
        throw new Error("Failed to identify the output parameter(s).  Reset the transform.");
    }
}