import styles from "./App.module.css";
import { ROLES } from "../utils/roleEvaluator";
import Layout from "./Layout";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import Hangar from "./Hangar";
import UserEntry from "./UserEntry";
import Unauthorised from "./Unauthorised";
import Missing from "./Missing";
import { Route, Routes, Navigate } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import useAuthContext from "../hooks/useAuthContext";
import Leasing from "./Leasing";
import Ops from "./Ops";
import { AccessibleAircraftProvider } from "../context/AccessibleAircraftContext";
import { AircraftProvider } from "../context/AircraftContext";
import { CartProvider } from "../context/CartContext";
import LandingPage from "./LandingPage";
import Verify from "./Verify";
import ResetPassword from "./ResetPassword";
import { useHangarPath } from "../hooks/useHangarPath";
import AccountLayout from "./AccountLayout";
import AllAircraftLayout from "./AllAircraftLayout";
import AdminLayout from "./AdminLayout";
import ParameterLayout from "./ParameterLayout";
import Admin from "./Admin";
import Help from "./help/Help";
import { HelpProvider } from "../context/HelpContext";
import { HELP_TOPICS } from "../utils/help/helpTopics";

function App() {
  const { auth } = useAuthContext();
  const { pathRoot } = useHangarPath();

  return (
    <div className={styles.App}>
      <AccessibleAircraftProvider>
        <AircraftProvider>
          <CartProvider>
            <HelpProvider>
              <Header />
              <Routes>
                <Route path="/" element={<Layout />}>
                  {/* public routes */}
                  <Route path="verify/:verificationCode" element={<Verify />} />
                  <Route path="landing" element={<LandingPage />} />;
                  <Route path="/" element={<Home />} />
                  <Route path="reset-password/:token" element={<ResetPassword />} />
                  {["login", "register"].map(path =>
                    <Route key={path} path={path} element={auth.accessToken ? <Navigate to="/" replace={true} /> : <UserEntry path={path} />} />)}
                  <Route path="terms" element={<Navigate to={`/help/${HELP_TOPICS.TERMS}`} replace />} />
                  <Route path="about" element={<Navigate to={`/help/${HELP_TOPICS.ABOUT}`} replace />} />
                  <Route path="unauthorised" element={<Unauthorised />} />
                  
                  <Route element={<ParameterLayout />}>
                    <Route path={pathRoot} element={<Hangar />} />
                    <Route path={`${pathRoot}/:id`} element={<Hangar />} />
                    <Route path={`${pathRoot}/:id/category/:categoryIndex/operation/:operationIndex`} element={<Hangar />} />
                  </Route>

                  <Route element={<AccountLayout />}>
                    <Route path="ops" element={<Ops />} />
                  </Route>

                  <Route path="help" element={<Help />} />
                  <Route path="help/:index" element={<Help />} />
                  
                  {/* protected routes */}
                  <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                    <Route element={<AllAircraftLayout />}>
                      <Route path="leasing" element={<Leasing />} />
                    </Route>
                  </Route>

                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                    <Route element={<AdminLayout />}>
                      <Route path="admin" element={<Admin />} />
                    </Route>
                  </Route>

                  {/* catch-all */}
                  <Route path="*" element={<Missing />} />
                </Route>
              </Routes>
            </HelpProvider>
          </CartProvider>
        </AircraftProvider>
      </AccessibleAircraftProvider>
      <Footer />
    </div>
  );
}

export default App;