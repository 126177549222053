import styles from "./Parameter.module.css";
import { useState, useEffect, useCallback, useRef } from "react";
import useParameterContext from "../hooks/useParameterContext";
import useInputAutofocus from "../hooks/useInputAutofocus";

const BooleanInputParameter = ({
    parameterMapping,
    title,
    refreshToggle,
    isGrouped = false,
    groupName = null,
    handleChange = null,
    handleKeyPress = null,
    focusOnParameterChange = true }
) => {
    const [name, setName] = useState(null);
    const [value, setValue] = useState(false);
    const [inputClassName, setInputClassName] = useState(null);
    const { active, available, setCachedInfo } = useParameterContext();
    const inputRef = useRef();
    useInputAutofocus(inputRef, focusOnParameterChange, [parameterMapping]);

    useEffect(() => {
        setInputClassName(isGrouped ? styles.groupedCheckbox : styles.checkbox);
    }, [isGrouped]);
    
    useEffect(() => {
        const mappingName = parameterMapping.name;
        setName(mappingName);
        setValue(parameterMapping.getMappingValue(true));
    }, [parameterMapping, refreshToggle]);

    const updateCache = useCallback((newValue) => {
        if (!active || !available) return;

        const mappingName = parameterMapping.name;
        const info = { value: newValue.toString() };
        if (groupName) {
            setCachedInfo(groupName, mappingName, info);
        }
        else {
            setCachedInfo(mappingName, null, info);
        }
    }, [parameterMapping, groupName, active, available, setCachedInfo]);

    const handleValueChange = useCallback(() => {
        const newValue = !value;
        setValue(newValue);
        parameterMapping.setMappingValue(newValue);
        if (handleChange) {
            handleChange();
        }
        updateCache(newValue);
    }, [value, parameterMapping, handleChange, updateCache]);

    const handleKeyDown = useCallback((e) => {
        if (handleKeyPress) {
            handleKeyPress(e);
        }
    }, [handleKeyPress]);

    return <>
        {!isGrouped && <label htmlFor="value" className={styles.paddedLabel}>{name}</label>}
        <input
            ref={inputRef}
            id="value"
            className={inputClassName}
            type="checkbox"
            checked={value}
            title={title}
            onChange={handleValueChange}
            onKeyDown={handleKeyDown} />
    </>
}

export default BooleanInputParameter;