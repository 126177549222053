// See https://www.digitalocean.com/community/tutorials/react-tabs-component

import styles from "./Tabs.module.css";
import Tab from "./Tab";
import { useState, useEffect } from "react";

const Tabs = ({ children, vertical, initialTabLabel = null }) => {
    const [activeTabLabel, setActiveTabLabel] = useState(initialTabLabel ?? children[0].props.label);

    useEffect(() => {
        setActiveTabLabel(initialTabLabel);
    }, [initialTabLabel]);

    const onClickTabItem = (tab) => {
        setActiveTabLabel(tab);
    }

    function getStyleKey(rootName) {
        return `${rootName}___${vertical ? "vertical" : "horizontal"}`;
    }

    return (
        <div className={styles[getStyleKey("Tabs")]}>
            <ol className={`${styles.tabList} ${styles[getStyleKey("tabList")]}`}>
                {children.map((child) => {
                    const { label } = child.props;
                    return (
                        <Tab
                            vertical={vertical}
                            activeTab={activeTabLabel}
                            key={label}
                            label={label}
                            onClick={onClickTabItem}
                        />
                    );
                })}
            </ol>
            <div className={styles[getStyleKey("tabContent")]}>
                {children.map((child) => {
                    const { label } = child.props;
                    const hidden = label === activeTabLabel ? null : "hidden";
                    return (
                        <div key={label} hidden={hidden}>
                            {child.props.children ?? child}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Tabs;