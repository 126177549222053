import styles from "./PerformanceCategoryContainer.module.css";
import Expander from "./Expander";
import OperationContainer from "./OperationContainer";

const PerformanceCategoryContainer = ({ aircraft, handleSelectOperation }) => {
    return (
        <>
            {aircraft &&
                <section className={styles.PerformanceCategoryContainer}>
                    <h2 className={styles.subHeading}>What aspect of performance do you want to explore?</h2>
                    <ul className={styles.categoryList}>
                        {aircraft.getPerformanceCategories().map((category, categoryIndex) => {
                            return (
                                <Expander key={categoryIndex} title={category.name}>
                                    <OperationContainer
                                        category={category}
                                        categoryIndex={categoryIndex}
                                        handleSelect={(operationIndex) => handleSelectOperation(categoryIndex, operationIndex)} />
                                </Expander>
                            )
                        })}
                    </ul>
                </section>
            }
        </>
    )
}

export default PerformanceCategoryContainer;