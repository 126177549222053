import styles from "./Missing.module.css";
import commonStyles from "./Common.module.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Missing = () => {
    useEffect(() => {
        document.title = "Avaid - 404";
    }, []);

    return (
        <section className={styles.container}>
            <h1 className={styles.heading}>Not at this aerodrome!</h1>
            <br />
            <p className={styles.text}>Couldn't find that page.</p>
            <p className={styles.text}>Why not try our <Link className={commonStyles.link} to="/">home</Link> page instead?</p>
        </section>
    )
}

export default Missing;