import styles from "./InputWithHint.module.css";
import commonStyles from "./Common.module.css";
import { useRef, useState, useEffect, useCallback } from "react";
import Expander from "./Expander";

const getClassName = (invalidCondition) => {
    let className = `${commonStyles.input} ${styles.input}`;
    if (invalidCondition) {
        className += ` ${commonStyles.input___invalid}`;
    }
    return className;
};

/* 
    options: {
        type, [e.g. "password"]
        placeholder, [e.g. "Username"]
        autoComplete, [e.g. "off"]
        maxLength,
        required, [true or undefined]
        focusWhenAble, [true or undefined]
        helpHtml
    }
 */
const InputWithHint = ({ options, value, setValue, valueHasErrors }) => {
    const inputRef = useRef();
    const [inputClassName, setInputClassName] = useState(null);
    const [valueIsSomething, setValueIsSomething] = useState(true);
    const [showHint, setShowHint] = useState(false);

    useEffect(() => {
        if (options.focusWhenAble) {
            const current = inputRef.current;
            current.focus();
            try {
                current.select();
            }
            catch { }
        }
    }, [options.focusWhenAble]);

    useEffect(() => {
        const className = getClassName(valueIsSomething && valueHasErrors);
        setInputClassName(className);
    }, [valueIsSomething, valueHasErrors]);

    useEffect(() => {
        setValueIsSomething(value?.length > 0);
    }, [value]);

    const handleReceieveFocus = useCallback(() => {
        setShowHint(true);
    }, []);

    const handleLoseFocus = useCallback(() => {
        setShowHint(false);
    }, []);

    return (
        <div className={styles.InputWithHint}>
            <input
                className={inputClassName}
                type={options.type}
                placeholder={options.placeholder}
                onFocus={handleReceieveFocus}
                onBlur={handleLoseFocus}
                ref={inputRef}
                autoComplete={options.autoComplete}
                maxLength={options.maxLength}
                onChange={e => setValue(e.target.value)}
                value={value}
                required={options.required === true}
            />
            <Expander withContainer={false} checked={showHint}>
                <div className={styles.hintContainer}>
                    {options.helpHtml}
                </div>
            </Expander>
        </div>
    )
};

export default InputWithHint;