import styles from "./PerformanceDataDisclaimerDialogue.module.css";
import ModalDialogue from "./ModalDialogue";
import PerformanceDataDisclaimer from "./PerformanceDataDisclaimer";
import useMediaQuery from "../hooks/useMediaQuery";
import { useEffect, useState } from "react";

const HEADER = <div className={styles.bannerContainer} />;

const PerformanceDataDisclaimerDialogue = ({ isOpen, requestClose }) => {
    const mediumHeight = useMediaQuery("(min-height: 600px)");
    const [header, setHeader] = useState(HEADER);

    useEffect(() => {
        setHeader(mediumHeight ? HEADER : null);
    }, [mediumHeight]);

    return (
        <ModalDialogue isOpen={isOpen} requestClose={requestClose} header={header}>
            <PerformanceDataDisclaimer />
        </ModalDialogue>
    )
};

export default PerformanceDataDisclaimerDialogue;