import { useState, useEffect } from "react";
import { passwordRegex } from "../utils/validation";
import { PasswordHint, ConfirmPasswordHint } from "./InputHints";
import InputWithHint from "./InputWithHint.js";

const PasswordSetter = ({ password, setPassword, confirmedPassword, setConfirmedPassword, setPasswordIsValid = null, autofocus = true }) => {
    const [passwordHasErrors, setPasswordHasErrors] = useState(true);
    const [confirmedPasswordHasErrors, setConfirmedPasswordHasErrors] = useState(true);

    useEffect(() => {
        setPasswordHasErrors(!passwordRegex.test(password));
    }, [password]);

    useEffect(() => {
        setConfirmedPasswordHasErrors(confirmedPassword !== password);
    }, [password, confirmedPassword]);
    
    useEffect(() => {
        if (setPasswordIsValid) {
            const valid = !(passwordHasErrors || confirmedPasswordHasErrors);
            setPasswordIsValid(valid);
        }
    }, [setPasswordIsValid, passwordHasErrors, confirmedPasswordHasErrors]);

    const passwordOptions = {
        type: "password",
        placeholder: "Password",
        autoComplete: "off",
        maxLength: 15,
        required: true,
        focusWhenAble: autofocus,
        helpHtml: <PasswordHint />
    };

    const confirmPasswordOptions = {
        type: "password",
        placeholder: "Confirm password",
        autoComplete: "off",
        maxLength: 15,
        required: true,
        helpHtml: <ConfirmPasswordHint />
    };

    return (
        <>
            <InputWithHint
                options={passwordOptions}
                value={password}
                setValue={setPassword}
                valueHasErrors={passwordHasErrors} />
            <InputWithHint
                options={confirmPasswordOptions}
                value={confirmedPassword}
                setValue={setConfirmedPassword}
                valueHasErrors={confirmedPasswordHasErrors} />
        </>
    )
};

export default PasswordSetter;