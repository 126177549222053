import { createContext, useState } from "react";

const OperationContext = createContext({});

export const OperationProvider = ({ children }) => {
    const [indexes, setIndexes] = useState({});

    return (
        <OperationContext.Provider value={{ indexes, setIndexes }}>
            {children}
        </OperationContext.Provider>
    )
}

export default OperationContext;