/* Defines the range of allowable behaviours for identifying a curve in a 
   CurveSet when the input point is either above the highest curve or below
   the lowest curve in the set.
*/
export const CurveSetBoundaryPolicy = Object.freeze(
    {
        Reject: Symbol(), // Do not use a curve
        Shadow: Symbol(), // Use a curve extrapolated from the boundary curve
        UseBoundaryCurve: Symbol() // Use the boundary curve itself
    }
);