import styles from "./Footer.module.css";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import PerformanceDataDisclaimerDialogue from "./PerformanceDataDisclaimerDialogue";
import { HELP_TOPICS } from "../utils/help/helpTopics";

const Footer = () => {
    const [disclaimerDialogueIsOpen, setDisclaimerDialogueIsOpen] = useState(false);
    
    const openDisclaimerDialogue = useCallback(() => {
        setDisclaimerDialogueIsOpen(true);
    }, []);

    const closeDisclaimerDialogue = useCallback(() => {
        setDisclaimerDialogueIsOpen(false);
    }, []);

    return (
        <>
            <footer className={styles.Footer}>
                <ul className={styles.links}>
                    <li>
                        <button className={styles.buttonLink} onClick={openDisclaimerDialogue}>Performance Data Disclaimer</button>
                    </li>
                    <li>
                        <Link className={styles.link} to={`/help/${HELP_TOPICS.TERMS_OF_USE}`}>Terms of Use</Link>
                    </li>
                    <li>
                        <Link className={styles.link} to={`/help/${HELP_TOPICS.PRIVACY_POLICY}`}>Privacy Policy</Link>
                    </li>
                </ul>
            </footer>
            <PerformanceDataDisclaimerDialogue isOpen={disclaimerDialogueIsOpen} requestClose={closeDisclaimerDialogue} />
        </>
    )
}

export default Footer;