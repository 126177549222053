import styles from "./Tab.module.css";

export default function Tab({ vertical, activeTab, label, onClick }) {
    const onSelected = () => {
        onClick(label);
    }

    function getStyleKey(rootName) {
        return `${rootName}___${vertical ? "vertical" : "horizontal"}`;
    }

    let className = `${styles.tabListItem} ${styles[getStyleKey("tabListItem")]} ${styles.preventSelect}`;
    if (activeTab === label) {
        className += ` ${styles.tabListItem___active} ${styles[getStyleKey("tabListItem___active")]}`;
    }

    return (
        <li className={className} onClick={onSelected}>
            {label}
        </li>
    );
}