import { TransformBase } from "./transform-base";
import { isSomething, areSomething } from "../common/utilities";
import { TransformShim } from "./transform-shim";

export class TransformGroup extends TransformBase {
    #transforms = new Map();
    #shims = [];

    add(transform) {
        if (!isSomething(transform)) {
            throw new Error("Transform must be non-null.");
        }
        this.#transforms.set(transform.id, transform);
    }

    associate(parameter1, parameter2, factor = 1, offset = 0) {
        if (!areSomething(parameter1, parameter2)) {
            throw new Error("Both parameters must be defined.");
        }
        this.#checkParameterHost(parameter1, "parameter1");
        this.#checkParameterHost(parameter2, "parameter2");
        const shim = new TransformShim(parameter1, parameter2, factor, offset);
        this.#shims.push(shim);
    }

    #checkParameterHost(parameter, parameterName) {
        if (!this.#transforms.has(parameter.hostTransformId)) {
            throw new Error(`${parameterName} has a host transform that has not been added to this group.`);
        }
    }

    getTransforms() {
        return Array.from(this.#transforms.values());
    }

    getParameters() {
        const parameters = [];
        for (let transform of this.#transforms.values()) {
            parameters.push(transform.getParameters());
        }
        return parameters;
    }

    reset() {
        for (let transform of this.#transforms.values()) {
            transform.reset();
        }
    }
}