import styles from "./Layout.module.css";
import { useState, useEffect, useCallback } from "react";
import { Outlet } from "react-router-dom"
import { matchRoutes, useLocation } from "react-router-dom";
import useAircraftContext from "../hooks/useAircraftContext";
import { useHangarPath, PATHS } from "../hooks/useHangarPath";

const routes = [
    { path: "/", styleName: `${styles.sampleAircraft}` },
    { path: "/landing", styleName: null },
    { path: "/login", styleName: `${styles.licence}` },
    { path: "/register", styleName: `${styles.licence}` },
    { path: "/verify/:code", styleName: `${styles.licence}` },
    { path: "/reset-password/:token", styleName: `${styles.licence}` },
    { path: "/leasing", styleName: `${styles.leasing}` },
    { path: "/ops", styleName: `${styles.ops}` },
    { path: "/help", styleName: `${styles.rules}` },
    { path: "/help/:index", styleName: `${styles.rules}` },
    { path: "/admin", styleName: `${styles.admin}` },
    { path: "/unauthorised", styleName: `${styles.unauthorised}` }
];

const apronRoutes = [
    { path: "/apron", styleName: `${styles.apron}` },
    { path: "/apron/:id", styleName: `${styles.apron}`, checkAircraft: true },
    { path: "/apron/:id/category/:categoryIndex/operation/:operationIndex", styleName: `${styles.apron}`, checkAircraft: true }
];

const hangarRoutes = [
    { path: "/hangar", styleName: `${styles.hangar}` },
    { path: "/hangar/:id", styleName: `${styles.hangar}`, checkAircraft: true },
    { path: "/hangar/:id/category/:categoryIndex/operation/:operationIndex", styleName: `${styles.hangar}`, checkAircraft: true }
];

const defaultClassName = `${styles.Layout}`;

const Layout = () => {
    const location = useLocation();
    const { aircraft, isLoading } = useAircraftContext();
    const { pathRoot } = useHangarPath();
    const [className, setClassName] = useState(defaultClassName);
    const [allRoutes, setAllRoutes] = useState(routes);

    useEffect(() => {
        const arr = [...routes];
        switch (pathRoot) {
            case PATHS.Apron:
                arr.push(...apronRoutes);
                break;
            case PATHS.Hangar:
                arr.push(...hangarRoutes);
                break;
            // no default
        }
        setAllRoutes(arr);
    }, [pathRoot]);

    const getClassNames = useCallback(() => {
        const matchingRoutes = matchRoutes(allRoutes, location);
        let name = defaultClassName;
        if (matchingRoutes) {
            const route = matchingRoutes[0].route;
            if (route.styleName) {
                if (route.checkAircraft) {
                    name += ` ${(aircraft || isLoading) ? route.styleName : styles.missing}`;
                }
                else {
                    name += ` ${route.styleName}`;
                }
            }
        }
        else {
            name += ` ${styles.missing}`;
        }
        return name;
    }, [location, aircraft, isLoading, allRoutes]);

    useEffect(() => {
        const name = getClassNames();
        setClassName(name);
    }, [getClassNames]);

    return (
        <main className={className}>
            <Outlet />
        </main>
    )
}

export default Layout;