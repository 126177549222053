import styles from "./CostPresenter.module.css";

const options = { style: "currency", currency: "GBP" };

const CostPresenter = ({ cost }) => {
    const isFree = cost === 0;
    let amount;
    if (!isFree) {
        amount = new Intl.NumberFormat(undefined, options).format(cost);
    }
    
    return (
        <div className={styles.CostPresenter}>
            {isFree && <p>£<span className={styles.free}>FREE</span></p>}
            {!isFree && <p>{amount}</p>}
        </div>
    )
};

export default CostPresenter;