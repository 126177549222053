import styles from "./Leasing.module.css";
import { useState, useEffect, useCallback } from "react";
import useAccessibleAircraftContext from "../hooks/useAccessibleAircraftContext";
import useAircraftContext from "../hooks/useAircraftContext";
import useAllAircraftContext from "../hooks/useAllAircraftContext";
import useCartContext from "../hooks/useCartContext";
import { sort } from "../utils/aircraftSorter";
import AircraftSelector from "./AircraftSelector";
import LeasingAction from "./LeasingAction";
import { isSomething } from "../lib/common/utilities";
import CartIcon from "./CartIcon";
import Carousel from "./Carousel";
import Cart from "./Cart";
import useMediaQuery from "../hooks/useMediaQuery";
import SETTINGS from "../config";

const SOURCE = "leasing";
const CART_ITEM_TYPES = {
    NEW_LEASE: "new lease",
    LEASE_EXTENSION: "lease extension"
};

const Leasing = () => {
    const {
        aircraftSummaries: stockAircraftSummaries,
        error: stockAircraftError,
        isLoading: stockAircraftLoading } = useAllAircraftContext();
    const {
        aircraftSummaries: accessibleAircraftSummaries,
        error: accessibleAircraftError,
        isLoading: accessibleAircraftLoading,
        refresh: refreshAccessibleAircraft} = useAccessibleAircraftContext();
    
    const [aircraftSummaries, setAircraftSummaries] = useState([]);
    const [matchingAircraft, setMatchingAircraft] = useState([]);
    const [activeAircraftIndexOverride, setActiveAircraftIndexOverride] = useState(0);
    const [selectedAircraftId, setSelectedAircraftId] = useState(null);
    const [selectedAircraftSummary, setSelectedAircraftSummary] = useState(null);
    const [allAircraftMessage, setAllAircraftMessage] = useState(null);
    const [carouselContainerClassName, setCarouselContainerClassName] = useState(null);
    const [cartIconContainerClassName, setCartIconContainerClassName] = useState(null);
    const [selectedAircraftMessage, setSelectedAircraftMessage] = useState(null);
    const [activeIndexOverride, setActiveIndexOverride] = useState(0);
    const [cartIconSize, setCartIconSize] = useState(0);
    const { loadAircraftById, clearAircraft, aircraft, error: selectedAircraftError, isLoading: selectedAircraftLoading } = useAircraftContext();
    const { cart, add, removeRange } = useCartContext();
    const mediumWidth = useMediaQuery("(min-width: 481px)");

    useEffect(() => {
        document.title = "Avaid - Leasing";
    }, []);

    useEffect(() => {
        const size = mediumWidth ? 50 : 40;
        setCartIconSize(size);
    }, [mediumWidth]);

    useEffect(() => {
        if (accessibleAircraftSummaries && stockAircraftSummaries && !accessibleAircraftError && !stockAircraftError) {
            let summaries = accessibleAircraftSummaries.union(stockAircraftSummaries, a => a.id);

            // Ensure that the cart only contains valid items

            // Step 1: ensure that "leasing" cart item product ids match known aircraft ids
            const leasingCartItems = Array.from(cart.entries()).where(([, value]) => value.source === SOURCE);
            const leasingCartClone = new Map(leasingCartItems);
            const summaryIds = new Set(summaries.map(s => s.id));
            const invalidCartItemKeys = new Set(leasingCartItems.where(([, item]) => !summaryIds.has(item.productId)).map(([key]) => key));
            invalidCartItemKeys.forEach(key => leasingCartClone.delete(key));

            // Step 2: ensure that the cart item actions accord with the state of the aircraft
            const accessibleAircraftSummaryIds = new Set(accessibleAircraftSummaries.map(s => s.id));
            const now = new Date();
            leasingCartClone.forEach((value, key) => {
                const aircraftId = value.productId;
                let invalid = false;
                switch (value.type) {
                    case CART_ITEM_TYPES.NEW_LEASE:
                        if (accessibleAircraftSummaryIds.has(aircraftId)) {
                            // Already leased, so can't buy anew
                            invalid = true;
                        }
                        break;
                    case CART_ITEM_TYPES.LEASE_EXTENSION:
                        if (!accessibleAircraftSummaryIds.has(aircraftId)) {
                            // Not leased, so can't extend
                            invalid = true;
                        }
                        else {
                            const summary = accessibleAircraftSummaries.firstOrUndefined(s => s.id === aircraftId);
                            const daysToExpiry = Math.floor((new Date(summary.valid_to) - now) / (1000 * 3600 * 24));
                            if (daysToExpiry > SETTINGS.LEASE_EXPIRY_WINDOW_DAYS) {
                                // Outside lease-extension window
                                invalid = true;
                            }
                        }
                        break;
                    // no default
                }
                if (invalid) {
                    invalidCartItemKeys.add(key);
                    leasingCartClone.delete(key);
                }
            });

            removeRange(invalidCartItemKeys);

            const cartLeasingAircraftIds = Array.from(leasingCartClone.values()).map(value => value.productId);
            summaries = summaries.except(cartLeasingAircraftIds, summary => summary.id);
            summaries = sort(summaries);
            setAircraftSummaries(summaries);
        }
        
    }, [stockAircraftSummaries, accessibleAircraftSummaries, cart]);

    const getCarouselIndex = useCallback((aircraftId) => {
        let index = 0;
        while (index < matchingAircraft.length) {
            if (matchingAircraft[index].id === aircraftId) {
                break;
            }
            index++;
        }
        if (index >= matchingAircraft.length) {
            index = 0;
        }
        return index;
    }, [matchingAircraft]);

    useEffect(() => {
        const index = getCarouselIndex(selectedAircraftId);
        setActiveAircraftIndexOverride(index);
    }, [getCarouselIndex, selectedAircraftId]);

    useEffect(() => {
        if (aircraftSummaries.length === 0) {
            setSelectedAircraftId(null);
            setSelectedAircraftSummary(null);
        }
        else {
            let index = 0;
            const count = aircraftSummaries.length;
            while (index < count) {
                if (aircraftSummaries[index].id === selectedAircraftId) {
                    break;
                }
                index++;
            }
            if (index >= count) {
                setSelectedAircraftId(null);
                setSelectedAircraftSummary(null);
            }
            else {
                setSelectedAircraftSummary(aircraftSummaries[index]);
            }
        }
    }, [selectedAircraftId, aircraftSummaries]);

    useEffect(() => {
        if (isSomething(selectedAircraftId)) {
            loadAircraftById(selectedAircraftId, true);
        }
        else {
            clearAircraft();
        }
    }, [selectedAircraftId]);

    useEffect(() => {
        const updateMessage = () => {
            if (stockAircraftLoading || accessibleAircraftLoading) {
                setAllAircraftMessage("Fetching available aircraft details...");
            }
            else if (stockAircraftError) {
                setAllAircraftMessage(stockAircraftError);
            }
            else if (accessibleAircraftError) {
                setAllAircraftMessage(accessibleAircraftError);
            }
            else if (aircraftSummaries?.length > 0) {
                setAllAircraftMessage(null);
            }
            else {
                setAllAircraftMessage("No aircraft found");
            }
        };
        updateMessage();
    }, [stockAircraftLoading, accessibleAircraftLoading, stockAircraftError, accessibleAircraftError, aircraftSummaries]);

    useEffect(() => {
        const updateMessage = () => {
            if (selectedAircraftSummary) {
                if (selectedAircraftLoading) {
                    setSelectedAircraftMessage("Fetching selected aircraft details...");
                }
                else if (selectedAircraftError || !aircraft) {
                    setSelectedAircraftMessage("There is a problem with this aircraft");
                }
                else {
                    setSelectedAircraftMessage(null);
                }
            }
            else {
                setSelectedAircraftMessage(null);
            }
        };
        updateMessage();
    }, [aircraft, selectedAircraftError, selectedAircraftLoading, selectedAircraftSummary]);

    useEffect(() => {
        let className = `${styles.carouselContainer}`;
        if (isSomething(allAircraftMessage)) {
            className += ` ${styles.hidden}`;
        }
        setCarouselContainerClassName(className);
    }, [allAircraftMessage]);

    useEffect(() => {
        let className = `${styles.cartIconContainer}`;
        if (activeIndexOverride === 1) {
            className += ` ${styles.hidden}`;
        }
        setCartIconContainerClassName(className);
    }, [activeIndexOverride]);

    const handleRequest = () => {
        const isLeased = !!Number(selectedAircraftSummary.is_leased)
        if (isLeased) {
            addLeaseExtensionToCart(selectedAircraftSummary);
        }
        else {
            addNewLeaseToCart(selectedAircraftSummary);
        }
    };

    const addNewLeaseToCart = (aircraftSummary) => {
        return addToCart(aircraftSummary, CART_ITEM_TYPES.NEW_LEASE, "New 12-month lease");
    };
    
    const addLeaseExtensionToCart = (aircraftSummary) => {
        return addToCart(aircraftSummary, CART_ITEM_TYPES.LEASE_EXTENSION, "Extend existing lease by 12 months");
    };

    const addToCart = (aircraftSummary, type, action) => {
        const info = {
            source: SOURCE,
            productId: aircraftSummary.id,
            type: type,
            productDescription1: aircraftSummary.description,
            productDescription2: aircraftSummary.manufacturer,
            productAction: action,
	        price: 0
        };
        return add(info);
    };
    
    const handleCartClick = () => {
        setActiveIndexOverride(1);
    };

    const handleBackButtonClick = useCallback(() => {
        setActiveIndexOverride(0);
    }, []);

    const handleCheckoutComplete = useCallback(() => {
        refreshAccessibleAircraft();
    }, []);

    return (
        <section className={styles.Leasing}>
            {allAircraftMessage &&
                <div className={styles.messageContainer}>
                    <p>{allAircraftMessage}</p>
                </div>
            }
            <div className={carouselContainerClassName}>
                <div className={cartIconContainerClassName} onClick={handleCartClick}>
                    <CartIcon width={`${cartIconSize}px`} height={`${cartIconSize}px`} />
                </div>
                <Carousel viewportItemCount={1} activeIndexOverride={activeIndexOverride} allowUserNavigation={false}>
                    <div className={styles.aircraftContainer} inert={activeIndexOverride === 1 ? "" : undefined}>
                        <AircraftSelector
                            title="Welcome to Leasing"
                            expanded={true}
                            aircraftSummaries={aircraftSummaries}
                            matchingAircraft={matchingAircraft}
                            setMatchingAircraft={setMatchingAircraft}
                            activeAircraftIndexOverride={activeAircraftIndexOverride}
                            selectedAircraftId={selectedAircraftId}
                            handleSelect={(id) => setSelectedAircraftId(id)} />
                        {(!aircraft || !selectedAircraftSummary) && matchingAircraft.length > 0 &&
                            <div className={styles.messageContainer}>
                                <p>Select an aircraft from the carousel</p>
                            </div>
                        }
                        {aircraft && selectedAircraftSummary &&
                            <section className={styles.selectedAircraft}>
                                <section className={styles.aircraftSummary}>
                                    <p className={styles.manufacturer}>{selectedAircraftSummary.manufacturer}</p>
                                    <h2 className={styles.description}>{selectedAircraftSummary.description}</h2>
                                </section>
                                <hr />
                                {selectedAircraftMessage &&
                                    <div className={styles.messageContainer}>
                                        <p>{selectedAircraftMessage}</p>
                                    </div>
                                }
                                {!selectedAircraftMessage && aircraft.id === selectedAircraftId &&
                                <LeasingAction
                                    aircraft={aircraft} 
                                    aircraftSummary={selectedAircraftSummary}
                                    onAction={handleRequest} />
                                }
                            </section>
                        }
                    </div>
                    <div className={styles.cartContainer} inert={activeIndexOverride === 0 ? "" : undefined}>
                        <Cart handleBackButtonClick={handleBackButtonClick} handleCheckoutComplete={handleCheckoutComplete} />
                    </div>
                </Carousel>
            </div>
        </section>
    )
}

export default Leasing;