import { areSomething } from "../common/utilities";
import { AltitudeCalculator } from "./altitude-calculator";

// Requires altitudes in feet and temperatures in degrees Celsius.
// See https://edwilliams.org/avform147.htm for calculation of TAS based on density altitude.
export class TrueAirspeedCalculator {
    static #densityAltitudeCalculator = new AltitudeCalculator();

    getTas(calibratedAirspeed, pressureAltitude, temperature) {
        if (areSomething(calibratedAirspeed, pressureAltitude, temperature)) {
            const densityAltitude = TrueAirspeedCalculator.#densityAltitudeCalculator.getDensityAltitude(pressureAltitude, temperature);
            return calibratedAirspeed / (1 - 0.0000068755856 * densityAltitude) ** 2.127940
        }
        return null;
    }
}