import { OptionalFactor } from "./optional-factor";
import { areSomething } from "../common/utilities";

export class OptionalScaledFactor extends OptionalFactor {
    static #checkDivisor(enabled, value) {
        if (value === 0 && enabled) {
            throw new Error("Divisor cannot be equal to 0.");
        }
        return value;
    }

    #getScalar(enabled, factor, amount, divisor) {
        if (areSomething(factor, amount, divisor)) {
            return enabled ? 1 + (factor - 1) * amount / divisor : factor;
        }
        return null;
    }

    getOutput(enabled, input, amount, factor, divisor) {
        OptionalScaledFactor.#checkDivisor(enabled, divisor);
        const scalar = this.#getScalar(enabled, factor, amount, divisor);
        return super.getOutput(enabled, input, scalar);
    }

    getInput(enabled, output, amount, factor, divisor) {
        OptionalScaledFactor.#checkDivisor(enabled, divisor);
        const scalar = this.#getScalar(enabled, factor, amount, divisor);
        return super.getInput(enabled, output, scalar);
    }
}