import "../array-extensions";
import { Message } from "./message";
import { Severity } from "./severity";
import { isSomething } from "./utilities";

export class MessageLog {
    #messages = [];
    
    addMessage(text) {
        this.add(Severity.Info, text);
    }

    addWarning(text) {
        this.add(Severity.Warning, text);
    }

    addError(text) {
        this.add(Severity.Error, text);
    }

    add(severity, text) {
        const message = new Message(severity, text);
        this.#messages.push(message);
    }

    hasErrors() {
        return this.hasMessagesOfSeverity(Severity.Error);
    }

    hasWarnings() {
        return this.hasMessagesOfSeverity(Severity.Warning);
    }

    hasMessages() {
        return this.hasMessagesOfSeverity(Severity.Info);
    }

    hasMessagesOfSeverity(severity) {
        return this.getMessagesOfSeverity(severity).length > 0;
    }

    getErrors() {
        return this.getMessagesOfSeverity(Severity.Error);
    }

    getWarnings() {
        return this.getMessagesOfSeverity(Severity.Warning);
    }

    getMessages() {
        return this.getMessagesOfSeverity(Severity.Info);
    }

    getMessagesOfSeverity(severity) {
        return this.#messages.where(m => m.severity === severity).map(m => m.text);
    }

    getAllMessages() {
        return this.#messages.slice();
    }

    invoke(action) {
        let result;
        try {
            action();
            result = true;
        }
        catch (e) {
            let msg;
            if (typeof e === "string" || !isSomething(e.stack)) {
                msg = e;
            }
            else {
                msg = e.stack;
            }
            this.addError(msg);
            result = false;
        }
        return result;
    }
}