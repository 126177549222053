export class OptionalFactor {
    getOutput(enabled, input, factor) {
        if (input === null || (enabled && factor === null)) {
            return null;
        }
        return enabled ? input * factor : input;
    }

    getInput(enabled, output, factor) {
        if (output === null || (enabled && factor === null)) {
            return null;
        }
        return enabled ? output / factor : output;
    }
}