import { Outlet } from 'react-router-dom';
import { AllAircraftProvider } from "../context/AllAircraftContext";
import { AircraftProvider } from '../context/AircraftContext';

const AllAircraftLayout = () => {
    return (
        <AllAircraftProvider>
            <AircraftProvider>
                <Outlet />
            </AircraftProvider>
        </AllAircraftProvider>
    );
};

export default AllAircraftLayout;