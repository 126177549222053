import { isSomething } from "../common/utilities";
import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterDirection } from "../transforms/parameter-direction";

export class TableTransform extends Transform {
    #inputKeyCache;
    #allInputsSet = null;
    #inputs;
    
    constructor(tableContainer) {
        super();
        this.tableContainer = tableContainer;

        let count = tableContainer.inputKeys.length;
        let i = 0;
        const inputs = [];
        while (i++ < count) {
            inputs.push(null);
        }
        this.#inputs = inputs;

        const cache = new Map();
        let index = 0;
        tableContainer.inputKeys.forEach(key => {
            cache.set(key.name, { index: index++, key: key });
        });
        this.#inputKeyCache = cache;
    }

    _sourceParameters() {
        const parameters = [];
        this.tableContainer.inputKeys.forEach(key => {
            parameters.push(new Parameter(key.name, this.id, ParameterDirection.In));
        });
        this.tableContainer.outputKeys.forEach(key => {
            parameters.push(new Parameter(key.name, this.id, ParameterDirection.Out));
        });
        return parameters;
    }

    _allInputsSet() {
        return this.#allInputsSet ??= this.#allInputsSet = this.#inputs.every(v => v !== null);
    }

    _onParameterValueReceived(parameter, value) {
        const tuple = this.#inputKeyCache.get(parameter.name);
        const index = tuple.index;
        const key = tuple.key;
        if (this.#inputs[index] === null) {
            this.#allInputsSet = null;
        }
        this.#inputs[index] = isSomething(value) ? key.createValue(value) : key.createUndefinedValue();

        if (this._allInputsSet()) {
            const outputs = this.tableContainer.getRow(this.#inputs);
            outputs.forEach(output => {
                const v = output.undefined ? null : output.value;
                this._emitParameterValue(output.key.name, v);
            });
        }
    }

    reset() {
        super.reset();
        this.#allInputsSet = null;
    }
}