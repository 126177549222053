import styles from "./Parameter.module.css";
import commonStyles from "./Common.module.css";
import { isSomething } from "../lib/common/utilities";
import { useState, useEffect, useCallback, useRef } from "react";
import useParameterContext from "../hooks/useParameterContext";
import useInputAutofocus from "../hooks/useInputAutofocus";

function limitCharacters(e) {
    const target = e.target;
    const value = target.value;
    if (value && value.length > target.maxLength) {
        target.value = value.slice(0, target.maxLength);
    }
};

function getMaximumCharacterCount(minimumValue) {
    return 8 + (isSomething(minimumValue) && minimumValue < 0 ? 1 : 0);
}

const NumericInputParameter = ({
    parameterMapping,
    refreshToggle,
    isGrouped = false,
    groupName = null,
    handleChange = null,
    handleKeyPress = null,
    focusOnParameterChange = true }
) => {
    const [name, setName] = useState(null);
    const [minimumValue, setMinimumValue] = useState(0);
    const [maximumValue, setMaximumValue] = useState(0);
    const [maxInputCharacters, setMaxInputCharacters] = useState(0);
    const [value, setValue] = useState(0);
    const [isInvalid, setIsInvalid] = useState(false);
    const [inputClassName, setInputClassName] = useState(null);
    const { active, available, setCachedInfo } = useParameterContext();
    const inputRef = useRef();
    useInputAutofocus(inputRef, focusOnParameterChange, [parameterMapping]);
    
    useEffect(() => {
        const mappingName = parameterMapping.name;
        setName(mappingName);
        const minValue = parameterMapping.minimumValue;
        setMinimumValue(minValue);
        setMaximumValue(parameterMapping.maximumValue);
        setMaxInputCharacters(getMaximumCharacterCount(minValue));
        setValue(parameterMapping.getMappingValue(true));
        setIsInvalid(parameterMapping.isInvalid);
    }, [parameterMapping, refreshToggle]);

    const updateCache = useCallback((newValue) => {
        if (!active || !available) return;

        const mappingName = parameterMapping.name;
        const info = { value: newValue.toString() };
        if (groupName) {
            setCachedInfo(groupName, mappingName, info);
        }
        else {
            setCachedInfo(mappingName, null, info);
        }
    }, [parameterMapping, groupName, active, available, setCachedInfo]);

    useEffect(() => {
        let className = `${commonStyles.input} ${commonStyles.input___parameter}`;
        if (isGrouped) {
            className += ` ${styles.input___groupedNumeric}`;
        }
        else {
            className += ` ${styles.input___numeric} ${styles.noUnits}`;
        }
        if (isInvalid) {
            className += ` ${commonStyles.input___invalid}`;
        }
        setInputClassName(className);
    }, [isInvalid, isGrouped]);

    const updateParameterMapping = useCallback((value) => {
        parameterMapping.setMappingValue(value);
        if (handleChange) {
            handleChange();
        }
    }, [parameterMapping, handleChange]);

    const handleValueChange = useCallback((e) => {
        const newValue = Number(e.target.value);
        updateParameterMapping(newValue);
        setValue(newValue);
        setIsInvalid(parameterMapping.isInvalid);
        updateCache(newValue);
    }, [updateParameterMapping, updateCache, parameterMapping.isInvalid]);

    const handleKeyDown = useCallback((e) => {
        limitCharacters(e);
        if (handleKeyPress) {
            handleKeyPress(e);
        }
    }, [handleKeyPress]);

    return <>
        {!isGrouped && <label htmlFor="value" className={styles.paddedLabel}>{name}</label>}
        <input
            ref={inputRef}
            id="value"
            className={inputClassName}
            type="number"
            onInput={limitCharacters}
            maxLength={maxInputCharacters}
            value={value}
            onChange={handleValueChange}
            onKeyDown={handleKeyDown}
            min={minimumValue}
            max={maximumValue}
            step={parameterMapping.step} />
    </>
}

export default NumericInputParameter;