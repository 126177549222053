import styles from "./ModalDialogue.module.css";
import commonStyles from "./Common.module.css";
import { useCallback, useEffect, useRef } from "react";

const hideDocumentOverflow = () => {
    setDocumentOverflow("hidden");
}

const restoreDocumentOverflow = () => {
    setDocumentOverflow("unset");
}

const setDocumentOverflow = (value) => {
    document.body.style.overflow = value;
}

const ModalDialogue = ({ isOpen, requestClose, buttonInfo = null, children, header = null }) => {
    const dialogueRef = useRef();
    const scrollAreaRef = useRef();

    useEffect(() => {
        const dialogue = dialogueRef.current;
        if (dialogue) {
            if (isOpen) {
                hideDocumentOverflow();
                dialogue.showModal();
                setTimeout(() => scrollAreaRef.current?.scrollTo(0, 0));
            }
            else {
                dialogue.close();
                restoreDocumentOverflow();
            }
        }
    }, [isOpen]);

    const handleButtonCloseRequest = useCallback((key) => {
        requestClose(key);
    }, [requestClose]);

    const handleCloseRequest = useCallback(() => {
        requestClose(null);
    }, [requestClose]);

    const createButton = useCallback((key, caption, hasSiblings) => {
        let className = `${commonStyles.button} ${styles.button}`;
        if (hasSiblings) {
            className += ` ${styles.button___multiple}`;
        }
        return <button
            key={key}
            className={className}
            onClick={() => handleButtonCloseRequest(key)}>{caption}</button>;
    }, [handleButtonCloseRequest]);

    return (
        <dialog className={styles.ModalDialogue} ref={dialogueRef} onClose={handleCloseRequest}>
            {header}
            <div ref={scrollAreaRef} className={styles.scrollArea}>
                <div className={styles.contentContainer}>
                    {children}
                </div>
                <div className={styles.buttonContainer}>
                    {buttonInfo && buttonInfo.map(info => {return createButton(info.key, info.caption, true)})}
                    {!buttonInfo && createButton(null, "Close", false)}
                </div>
            </div>
        </dialog>
    )
};

export default ModalDialogue;