import { createContext, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HelpNavigator from "../utils/help/helpNavigator";

const HelpContext = createContext({});
const helpNavigator = new HelpNavigator();

export const HelpProvider = ({ children }) => {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(null);

    const getElement = (index) => {
        return helpNavigator.getElement(index);
    };

    const getRootElements = () => {
        return helpNavigator.getRootElements();
    };

    const getChildElements = (index) => {
        return helpNavigator.getChildElements(index);
    };

    const getPreviousElement = (index) => {
        return helpNavigator.getPreviousElement(index);
    };

    const getPreviousLeafElement = (index) => {
        return helpNavigator.getPreviousLeafElement(index);
    };

    const getNextElement = (index) => {
        return helpNavigator.getNextElement(index);
    };

    const getNextLeafElement = (index) => {
        return helpNavigator.getNextLeafElement(index);
    };

    const navigateToMissing = useCallback(() => {
        navigate("/*", { replace: true });
    }, [navigate]);

    const navigateTo = useCallback((index) => {
        const element = helpNavigator.getElement(index);
        if (element) {
            if (selectedIndex !== index) {
                navigate(`/help/${index}`);
            }
        }
        else {
            setSelectedIndex(null);
            navigateToMissing();
        }
    }, [selectedIndex, navigate, navigateToMissing]);

    useEffect(() => {
        if (selectedIndex) {
            const element = helpNavigator.getElement(selectedIndex);
            if (!element) {
                setSelectedIndex(null);
                navigateToMissing();
            }
        }
    }, [selectedIndex, navigateToMissing]);

    const getParentElement = (element) => {
        const parentIndex = element?.parentIndex;
        return parentIndex ? helpNavigator.getElement(parentIndex) : null;
    };

    const getAncestralElements = (index) => { 
        const elements = [];
        const element = helpNavigator.getElement(index);
        let parent = getParentElement(element);
        while (parent) {
            elements.push(parent);
            parent = getParentElement(parent);
        }
        return elements;
    };

    const getAncestralIndexes = (index) => {
        return getAncestralElements(index).map(e => e.index);
    };

    return (
        <HelpContext.Provider value={{
            getElement,
            getRootElements,
            getChildElements,
            getPreviousElement,
            getPreviousLeafElement,
            getNextElement,
            getNextLeafElement,
            getAncestralElements,
            getAncestralIndexes,
            selectedIndex,
            setSelectedIndex,
            navigateTo
        }}>
            {children}
        </HelpContext.Provider>
    )
}

export default HelpContext;