//https://rosettacode.org/wiki/Ray-casting_algorithm
export class RayCastingAlgorithm {
    // points: an array of {x,y} coordinates defining a polygon
    static contains(points, x, y) {
        let count = 0;
        for (let i = 0; i < points.length; i++) {
            const point1 = points[i];
            const point2 = points[(i + 1) % points.length];
            if (RayCastingAlgorithm.#isToTheLeft(point1, point2, x, y)) {
                count++;
            }
        }
        return count % 2 === 1;
    }

    /* Returns true if {x,y} is left of the line segment connecting point1 and point2.
       It starts by testing whether {x,y} is outside the rectangle defined by point1 and point2.
       If it is, the method returns false.  Otherwise the gradient of point1 to {x,y} is compared 
       with the gradient of point1 to point2: if the gradient of point1 to {x,y} is greater then
       {x,y} must be to the left.

       The problem scenario is when x equals point1.x (hence denominator (x - point1.x) equals zero).
       The rosetta implementation relies on the resulting division returning +Infinity.  However,
       if x is minus-zero (-0) then, even though 0 === -0 in JavaScript, the resulting division 
       returns -Infinity, which is not equal to +Infinity.  Hence I've added an additional check.
     */
    static #isToTheLeft(point1, point2, x, y) {
        let result;
        if (point1.y <= point2.y) {
            if (y <= point1.y || y > point2.y || (x >= point1.x && x >= point2.x)) {
                result = false;
            }
            else if (x < point1.x && x < point2.x) {
                result = true;
            }
            else if (x === point1.x) {
                result = point2.x > point1.x;
            }
            else {
                result = (y - point1.y) / (x - point1.x) > (point2.y - point1.y) / (point2.x - point1.x);
            }
        }
        else {
            result = RayCastingAlgorithm.#isToTheLeft(point2, point1, x, y);
        }
        return result;
    }
}