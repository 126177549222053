import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";
import { OptionalFactor } from "./optional-factor";

export class OptionalFactorTransform extends Transform {
    #optionalFactor = new OptionalFactor();
    
    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterEnabledName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterInName, this.id),
            new Parameter(ParameterNames.ParameterOutName, this.id),
            new Parameter(ParameterNames.ParameterFactorName, this.id, ParameterDirection.In)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const parameterName = parameter.name;
        switch (parameterName) {
            case ParameterNames.ParameterInName:
                this._clearParameterValue(ParameterNames.ParameterOutName);
                break;
            case ParameterNames.ParameterOutName:
                this._clearParameterValue(ParameterNames.ParameterInName);
                break;
            // no default
        }

        const count = this._setParameterValueCount;
        if (count === 3) {
            const outputParameterName = this.getUnsetParameterNames()[0];
            let result;
            switch (outputParameterName) {
                case ParameterNames.ParameterInName:
                    result = this.#calculateInput();
                    break;
                case ParameterNames.ParameterOutName:
                    result = this.#calculateOutput();
                    break;
                default:
                    throw new Error(`Unknown output parameter: '${outputParameterName}'.`);
            }
            this._emitParameterValue(outputParameterName, result);
        }
    }

    #calculateInput() {
        const enabled = this._getParameterValue(ParameterNames.ParameterEnabledName);
        const output = this._getParameterValue(ParameterNames.ParameterOutName);
        const factor = this._getParameterValue(ParameterNames.ParameterFactorName);
        return this.#optionalFactor.getInput(enabled, output, factor);
    }

    #calculateOutput() {
        const enabled = this._getParameterValue(ParameterNames.ParameterEnabledName);
        const input = this._getParameterValue(ParameterNames.ParameterInName);
        const factor = this._getParameterValue(ParameterNames.ParameterFactorName);
        return this.#optionalFactor.getOutput(enabled, input, factor);
    }
}