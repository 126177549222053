import styles from "./BreadcrumbBar.module.css";
import useHelpContext from "../../hooks/useHelpContext";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const BreadcrumbBar = () => {
    const { selectedIndex, getAncestralElements } = useHelpContext();
    const [show, setShow] = useState(false);
    const [elements, setElements] = useState([]);
    const [containerClassName, setContainerClassName] = useState(styles.BreadcrumbBar);

    useEffect(() => {
        if (selectedIndex) {
            var ancestors = getAncestralElements(selectedIndex).reverse();
            ancestors.shift(); // remove root element
            setElements(ancestors);
            setShow(true);
        }
        else {
            setElements([]);
            setShow(false);
        }
    }, [selectedIndex, getAncestralElements]);

    useEffect(() => {
        let className = styles.BreadcrumbBar;
        if (!show) {
            className += ` ${styles.hidden}`;
        }
        setContainerClassName(className);
    }, [show]);

    return (
        <div className={containerClassName}>
            <ol>
                <li><Link className={styles.link} to="/help">Help</Link></li>
                {elements.map(e =>
                    <li key={e.index} >
                        <Link className={styles.link} to={`/help/${e.index}`}>{e.title}</Link>
                    </li>
                )}
            </ol>
        </div>
    );
};

export default BreadcrumbBar;