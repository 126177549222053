import styles from "./Parameter.module.css";
import UnitInputParameter from "./UnitInputParameter";
import NumericInputParameter from "./NumericInputParameter";
import BooleanInputParameter from "./BooleanInputParameter";

const ENABLED_MAPPING_NAME = "Enabled";

const OptionalParameterGroup = ({ group, refreshToggle, parameterMappingKeys, handleChange = null, handleKeyPress = null }) => {
    const descriptionInfo = group.parseDescriptionPlaceholders();
    const placeholderCount = descriptionInfo.placeholderSequence.length;
    if (parameterMappingKeys.length !== placeholderCount) {
        throw new Error(`Expected ${placeholderCount} parameter(s), received ${parameterMappingKeys.length}.`);
    }

    const enabledParameterMapping = group.getParameterMapping(ENABLED_MAPPING_NAME);

    return <div className={styles.optionalParameterGroupContainer}>
        <BooleanInputParameter
            key={enabledParameterMapping.name}
            parameterMapping={enabledParameterMapping}
            title={`Select ${group.name}`}
            refreshToggle={refreshToggle}
            isGrouped={true}
            groupName={group.name}
            handleChange={handleChange}
            handleKeyPress={handleKeyPress} />
        {descriptionInfo.placeholderSequence.map((parameterIndex, fragmentIndex) => {
            const key = parameterMappingKeys[parameterIndex];
            const parameterMapping = group.getParameterMapping(key);
            let component;
            switch (parameterMapping.typeName) {
                case "UnitParameterMapping":
                case "UnitMultiParameterMapping":
                    component = <UnitInputParameter
                        parameterMapping={parameterMapping}
                        refreshToggle={refreshToggle}
                        isGrouped={true}
                        groupName={group.name}
                        handleChange={handleChange}
                        handleKeyPress={handleKeyPress}
                        focusOnParameterChange={false} />
                    break;
                case "NumericParameterMapping":
                case "NumericMultiParameterMapping":
                    component = <NumericInputParameter
                        parameterMapping={parameterMapping}
                        refreshToggle={refreshToggle}
                        isGrouped={true}
                        groupName={group.name}
                        handleChange={handleChange}
                        handleKeyPress={handleKeyPress}
                        focusOnParameterChange={false}/>
                    break;
                case "BooleanParameterMapping":
                case "BooleanMultiParameterMapping":
                    component = <BooleanInputParameter
                        parameterMapping={parameterMapping}
                        refreshToggle={refreshToggle}
                        isGrouped={true}
                        groupName={group.name}
                        handleChange={handleChange}
                        handleKeyPress={handleKeyPress}
                        focusOnParameterChange={false}/>
                    break;
                default:
                    throw new Error(`"${parameterMapping.typeName}" is not a recognised parameter mapping type.`);
            }
            return <span key={parameterMapping.name}>
                {descriptionInfo.fragments[fragmentIndex]}
                {component}
            </span>
        })}
        {descriptionInfo.fragments[descriptionInfo.fragments.length - 1]}
    </div>
}

export default OptionalParameterGroup;