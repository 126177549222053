import { Outlet } from 'react-router-dom';
import { ParameterProvider } from "../context/ParameterContext";
import { OperationProvider } from '../context/OperationContext';

const ParameterLayout = () => {
    return (
        <OperationProvider>
            <ParameterProvider>
                <Outlet />
            </ParameterProvider>
        </OperationProvider>
    );
};

export default ParameterLayout;