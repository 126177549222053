import { Severity } from "./severity";

export class Message {
    #severity;
    #text;

    constructor(severity, text) {
        if (!Message.#isValid(severity)) {
            throw new Error(`Invalid severity: '${severity}'.`);
        }
        if (typeof text === "string") {
            text = text.trim();
            if (text.length > 0) {
                this.#severity = severity;
                this.#text = text;
                return;
            }
        }
        throw new Error("No text was supplied.");
    }

    static #isValid(severity) {
        return severity === Severity.Info || severity === Severity.Warning || severity === Severity.Error;
    }

    get severity() {
        return this.#severity;
    }

    get text() {
        return this.#text;
    }

    toString() {
        let severity;
        switch (this.severity) {
            case Severity.Info:
                severity = "Info";
                break;
            case Severity.Warning:
                severity = "Warning";
                break;
            default:
                severity = "Error";
                break;
            
        }
        return `${severity}: ${this.text}`;
    }
}