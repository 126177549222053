import styles from "./Carousel.module.css";
import CarouselItem from "./CarouselItem";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { ReactComponent as PreviousFilled } from "../assets/previous-filled.svg";
import { ReactComponent as NextFilled } from "../assets/next-filled.svg";

const Carousel = ({ children, viewportItemCount = 1, activeIndexOverride, allowUserNavigation = true }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [baseOffset, setBaseOffset] = useState(0);
    const [offset, setOffset] = useState(0);

    const { childrenIsArray, childCount } = useMemo(() => {
        const childrenIsArray = Array.isArray(children);
        const childCount = childrenIsArray ? children.length : 1;
        return { childrenIsArray, childCount };
    }, [children]);

    const setIndex = useCallback((value) => {
        const count = childCount;
        if (value < 0) {
            value = count - 1;
        }
        else if (value >= count) {
            value = 0;
        }
        setActiveIndex(value);
    }, [childCount]);

    useEffect(() => {
        setActiveIndex(activeIndexOverride);
    }, [activeIndexOverride]);

    useEffect(() => {
        setBaseOffset((viewportItemCount - 1) * 50);
    }, [viewportItemCount]);

    useEffect(() => {
        const value = (-activeIndex * 100) + baseOffset;
        setOffset(value);
    }, [activeIndex, baseOffset]);

    useEffect(() => {
        const count = childCount;
        if (activeIndex >= count) {
            setActiveIndex(count - 1);
        }
    }, [activeIndex, childCount]);

    const handlers = useSwipeable({
        onSwipedLeft: allowUserNavigation ? () => setIndex(activeIndex + 1) : null,
        onSwipedRight: allowUserNavigation ? () => setIndex(activeIndex - 1) : null
    });

    return (
        <div {...handlers} className={styles.Carousel}>
            {allowUserNavigation && <button className={`${styles.navButton} ${styles.navButton___previous}`} onClick={() => setIndex(activeIndex - 1)}>
                <PreviousFilled />
            </button>}
            {allowUserNavigation && <button className={`${styles.navButton} ${styles.navButton___next}`} onClick={() => setIndex(activeIndex + 1)}>
                <NextFilled />
            </button>}
            <div className={styles.inner} style={{ transform: `translateX(${offset}%)`, width: `${100 / viewportItemCount}%` }}>
                {childrenIsArray && children.map((child, index) => 
                    <CarouselItem key={index}>{child}</CarouselItem>)}
                {!childrenIsArray && <CarouselItem>{children}</CarouselItem>}
            </div>
        </div>
    );
};

export default Carousel;