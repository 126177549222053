import { useState, useEffect } from "react";
import useMediaQuery from "./useMediaQuery";

const useAircraftCarouselViewportItemCount = () => {
    const largeWidth = useMediaQuery("(min-width: 992px)");
    const mediumWidth = useMediaQuery("(min-width: 768px)");
    const [carouselItemCount, setCarouselItemCount] = useState(3);

    useEffect(() => {
        let itemCount;
        if (largeWidth) {
            itemCount = 3;
        }
        else if (mediumWidth) {
            itemCount = 2;
        }
        else {
            itemCount = 1;
        }
        setCarouselItemCount(itemCount);
    }, [largeWidth, mediumWidth]);

    return carouselItemCount;
};

export default useAircraftCarouselViewportItemCount;