export const HELP_TOPICS = {
    ROOT: 1,
    FAQS: 2,
    PERF_DATA: 3,
    IN_FLIGHT: 4,
    LEASING: 5,
    ADMIN: 6,
    ABOUT: 7,
    TERMS: 8,
    CONTACT:9,
    CREATE_ACCOUNT: 10,
    APRON_HANGAR: 11,
    MORE_AIRCRAFT: 12,
    LEASING_INTRODUCTION: 13,
    LEASING_AIRCRAFT: 14,
    CREATE_LEASE: 15,
    LEASING_CAROUSEL: 16,
    LEASING_CART: 17,
    FEEDBACK: 18,
    ACCOUNT_MANAGEMENT: 19,
    REGISTRATION: 20,
    SIGN_IN: 21,
    PASSWORD_RESET: 22,
    TERMS_OF_USE: 23,
    PRIVACY_POLICY: 24,
    PERFORMANCE_DATA_DISCLAIMER: 25,
    ACCESSING_WEBSITE: 26,
    INTELLECTUAL_PROPERTY: 27,
    INFORMATION_ABOUT_YOU: 28,
    OUR_LIABILITY: 29,
    CLOSING_ACCOUNT: 30,
    VIRUSES_HACKING: 31,
    LINKS_TO_FROM: 32,
    GENERAL_TERMS: 33,
    YOUR_INFORMATION: 34,
    USE_OF_YOUR_INFORMATION: 35,
    SHARING_YOUR_INFORMATION: 36,
    SECURING_YOUR_INFORMATION: 37,
    ACCESS_TO_YOUR_INFORMATION: 38,
    USE_OF_COOKIES: 39,
    TRUST_AIRCRAFT_METADATA: 40,
    SEEING_WARNING_TRIANGLES: 41
};