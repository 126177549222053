export class OptionalAdder {
    /* When enabled, returns the sum of input1 and input2.  When not enabled,
       returns input1.
    */
    add(enabled, input1, input2) {
        if (input1 === null || (enabled && input2 === null)) {
            return null;
        }
        return enabled ? input1 + input2 : input1;
    }
}