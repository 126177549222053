import styles from "./SearchBar.module.css";
import commonStyles from "./Common.module.css";
import { useState, useEffect, useCallback } from "react";

const prepareSearchableText = (text) => {
    // Prepend the original text (minus whitespace) because it may include punctuation characters
    // that the user can see and wishes to search on.
    let s = removeWhitespace(text);
    s += removePunctuation(s);
    return s.toLowerCase();
};

// Strip out punctuation (https://stackoverflow.com/questions/4328500/how-can-i-strip-all-punctuation-from-a-string-in-javascript-using-regex))
const removePunctuation = (text) => {
    return text.replace(/[^\p{L}\p{N}\s]/gu, "");
};

const removeWhitespace = (text) => {
    return text.replace(/\s+/g, "");
};

const SearchBar = ({ searchItems, setMatchItems, placeholder = "Search" }) => {
    const [itemCache, setItemCache] = useState({});
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        if (searchItems) {
            const cache = {};
            searchItems.forEach(item => {
                let text = "";
                Object.keys(item).forEach(key => {
                    const value = item[key];
                    if (typeof value === "string") {
                        text += prepareSearchableText(value);
                    }
                });
                cache[text] = item;
            });
            setItemCache(cache);
        }
    }, [searchItems]);

    const search = useCallback((text) => {
        let items;
        text = removeWhitespace(text).toLowerCase();
        if (text) {
            items = Object.keys(itemCache)
                .filter(key => key.includes(text))
                .map(key => itemCache[key]);
        }
        else {
            items = searchItems;
        }
        setMatchItems(items);
    }, [setMatchItems, itemCache, searchItems]);

    useEffect(() => {
        search(searchText);
    }, [search, searchText]);

    const handleSearchTextChanged = useCallback((e) => {
        let text = e.target.value;
        setSearchText(text);
    }, []);

    return (
        <input
            className={`${commonStyles.input} ${styles.SearchBar}`}
            type="search"
            value={searchText}
            maxLength={20}
            placeholder={placeholder}
            onChange={handleSearchTextChanged}/>
    )
};

export default SearchBar;