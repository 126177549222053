import { useState, useEffect } from "react";

/*
    Usage examples include:
        const coarsePointer = useMediaQuery("(any-pointer: coarse)");
        const noHover = useMediaQuery("(hover: none)");
 */

const EVENT_NAME = "change";

const useMediaQuery = (query) => {
    const [matches, setMatches] = useState();
    
    useEffect(() => {
        const handleChange = (e) => setMatches(e.matches);

        const matchQueryList = window.matchMedia(query);
        matchQueryList.addEventListener(EVENT_NAME, handleChange);
        handleChange(matchQueryList); // To initialise the matches
        return () => matchQueryList.removeEventListener(EVENT_NAME, handleChange);
    }, [query]);

    return matches;
}

export default useMediaQuery;