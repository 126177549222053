const PerformanceDataDisclaimerContent = () => {
    return <>
        <ol>
            <li>
                <p>
                    Avaid.aero is a website intended for use by pilots of powered, fixed-wing aircraft.
                </p>
                <p>
                    This website's aircraft performance calculations are based on official sources of
                    performance information such as a pilot's operating handbook published by an aircraft
                    manufacturer.  Where this is not the case then this website will explicitly so indicate.
                </p>
                <p>
                    While every effort has been made to interpret official sources of performance information
                    accurately and completely, no warranty or fitness is provided or implied and the authors and
                    publishers shall have neither liability nor responsibility to any person or entity with
                    respect to any loss or damage arising from use of this website.
                </p>
            </li>
            <li>
                <p>
                    It is the aircraft operator's responsibility to ensure that results obtained from calculations
                    performed by this website accord with equivalent results obtained from official sources of
                    performance information.
                </p>
                <p>
                    Where there is any discrepancy between results obtained from calculations performed by this
                    website and equivalent results obtained from official sources of performance information,
                    results obtained from this website must not be used for performance planning.
                </p>
            </li>
            <li>
                <p>
                    Calculations performed by this website do not take account of aircraft-specific adjustments
                    or adjustments mandated by an aviation authority or other body to which relevant authority has
                    been delegated.  It is the aircraft operator's responsibility to be aware of any such
                    adjustments and apply them manually as necessary.
                </p>
                <p>
                    Results obtained from calculations performed by this website must not be used for
                    performance planning:
                </p>
                <ol>
                    <li>
                        <p>
                            for any aircraft that has deviated from its manufacturer's specification
                            and such deviation is not included in official sources of performance information;
                        </p>
                    </li>
                    <li>
                        <p>
                            unless the aircraft is intended to be operated in accordance with any and all
                            assumptions stated in official sources of performance information, whether or not
                            such assumptions are reiterated by this website.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    For critical phases of flight such as take-off and landing, and where calculations are based
                    on gross or unfactored performance data, the aircraft operator is strongly recommended to
                    apply a suitable safety factor when it is offered as an input parameter to a given
                    calculation.
                </p>
            </li>
            <li>
                <p>
                    By continuing to use this website you indicate that you understand and will abide by the
                    above.
                </p>
            </li>
        </ol>
    </>
};

export default PerformanceDataDisclaimerContent;