import { createContext } from "react";
import { usePublicApiGet } from "../hooks/useApi";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { axiosPost, axiosPublic } from "../api/axios";

const MessageContext = createContext({});

const SUBJECTS_URL = "/subjects";
const MESSAGE_URL = "/message";
const USER_MESSAGE_URL = "/user-message";

export const MessageProvider = ({ children }) => {
    const { data: subjects, error, isLoading } = usePublicApiGet(SUBJECTS_URL, null);
    const axiosPrivate = useAxiosPrivate();

    const submitFeedback = async (subjectId, email, message) => {
        const data = {
            "subjectId": subjectId,
            "message": message
        };
        let axiosInstance;
        let url;

        let result;
        const onSuccess = () => {
            result = { succeeded: true };
        };
        const onError = (err) => {
            let message;
            switch (err.response.status) {
                case 400: // Bad request
                    message = "The information was rejected by the server."
                    break;
                case 401: // Unauthorised
                    message = "Not authorised.";
                    break;
                case 404:
                    message = "User not found."
                    break;
                default:
                    message = "Unknown error.";
                    break;
            }
            result = {
                succeeded: false,
                message: message
            };
        }

        if (email) {
            data.email = email;
            axiosInstance = axiosPublic;
            url = MESSAGE_URL;
        }
        else {
            axiosInstance = axiosPrivate;
            url = USER_MESSAGE_URL;
        }
        await axiosPost(axiosInstance, url, data, onSuccess, onError);
        return result;
    };

    return (
        <MessageContext.Provider value={{ subjects, error, isLoading, submitFeedback }}>
            {children}
        </MessageContext.Provider>
    )
}

export default MessageContext;