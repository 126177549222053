import { UnitBase, Quantity } from "./units";

export default class UnitConverter {
    static #createMetadataMap() {
        const types = Quantity.getAllImplementingTypes();
        return new Map(types.map(type => {
            const unit = UnitBase.create(type, 0);
            return [unit.getAbbreviation(), type];
        }));
    }

    static getTypeFromAbbreviation(abbreviation) {
        return this.metadataMap.get(abbreviation);
    }

    static metadataMap = this.#createMetadataMap();

    // Assumes the supplied string has not been locale-formatted (e.g. "1234.5 kg").
    static createFrom(str) {
        const {value, abbreviation} = this.#getUnitInfo(str);
        const type = this.metadataMap.get(abbreviation);
        return type ? UnitBase.create(type, value) : null;
    }

    static #numberRelated = new Set(["-", ".", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",])

    static #getUnitInfo(str) {
        let value = "";
        let abbreviation = "";
        for (let i = 0; i < str.length; i++) {
            const c = str[i];
            if (this.#numberRelated.has(c)) {
                value += c;
            }
            else {
                abbreviation = str.substring(i).trim();
                break;
            }
        }
        return {value: Number(value), abbreviation};
    }
}