import { ParameterMappingBase } from "./parameter-mapping-base";

/* An instances of this class does not distinguish between the underlying 
   raw parameter value and the real-world mapping value.
*/
export class NullOpParameterMapping extends ParameterMappingBase {
    #value;
    #invalidValue;
    
    constructor(name, parameter) {
        super(name, parameter);
        this.#value = undefined;
        this.#invalidValue = null;
    }

    get typeName() {
        return "NullOpParameterMapping";
    }

    _getDefaultValue() {
        this._throwOverrideRequired();
    }

    _isValidValue(value) {
        this._throwOverrideRequired();
    }

    _getRawValue() {
        return this.getMappingValue();
    }

    _onParameterValueEmitted(value) {
        this.#setValue(value);
    }

    _setMappingValueInternal(value) {
        this.#setValue(value);
    }

    #setValue(value) {
        const isInvalid = !this._isValidValue(value);
        this.#value = isInvalid ? null : value;
        this.#invalidValue = isInvalid ? value : null;
        this._setIsInvalid(isInvalid);
    }

    getMappingValue(allowInvalid = false) {
        if (this.#value === undefined) {
            this.#value = this._getDefaultValue();
        }
        return this.isInvalid && allowInvalid ? this.#invalidValue : this.#value;
    }
}