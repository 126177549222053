const NameHint = () => {
    return <>
        <p>The name:</p>
        <ul>
            <li>must be between 3 and 20 characters</li>
            <li>must start and end with an alphanumeric character</li>
            <li>must not have a two-character combination of _ or - or . or ' or space</li>
            <li>may include a-z A-Z 0-9 _ - . ' and space characters</li>
        </ul>
    </>
}

const EmailHint = () => {
    return <p>An email address of the form "a@b.c"</p>
}

const PasswordHint = () => {
    return <>
        <p>The password must:</p>
        <ul>
            <li>be between 8 and 15 characters</li>
            <li>have at least one lowercase letter</li>
            <li>have at least one uppercase letter</li>
            <li>have at least one numeric digit</li>
            <li>have at least one special character</li>
        </ul>
    </>
}

const ConfirmPasswordHint = () => {
    return <p>Ensure that this matches the previously entered password</p>
}

export { NameHint, EmailHint, PasswordHint, ConfirmPasswordHint }