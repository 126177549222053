export const Symbols = Object.freeze(
    {
        Micro: "\u00B5",
        Degree: "\u00B0",
        Ohm: "\u2126",
        SuperscriptMinus: "\u207B",
        Infinity: "\u221E",
        Superscript1: "\u00B9",
        Superscript2: "\u00B2",
        Superscript3: "\u00B3",

        getNumberSuperscript(num) {
            let result = "";
            let digits = num.toString();
            if (num < 0) {
                result += Symbols.SuperscriptMinus;
                digits = digits.substr(1, digits.length - 1);
            }
            for (const s of digits) {
                const index = parseInt(s);
                result += superscriptDigits[index];
            };
            return result;
        }
    }
);

const superscriptDigits = [
    "\u2070",
    Symbols.Superscript1,
    Symbols.Superscript2,
    Symbols.Superscript3,
    "\u2074",
    "\u2075",
    "\u2076",
    "\u2077",
    "\u2078",
    "\u2079",
];