import styles from "./Parameter.module.css";
import commonStyles from "./Common.module.css";
import "../lib/array-extensions";
import { DimensionlessUnit, UnitBase } from "../lib/units/units";
import { isSomething } from "../lib/common/utilities";
import { useState, useEffect, useCallback, useRef } from "react";
import useParameterContext from "../hooks/useParameterContext";
import useInputAutofocus from "../hooks/useInputAutofocus";

function createUnitInfos(notDimensionless, mappingType) {
    let unitInfos;
    if (notDimensionless) {
        const mappingUnit = UnitBase.create(mappingType, 0);
        const unitTypes = mappingUnit.getImplementingTypesOfSameQuantity();
        unitInfos = unitTypes.map(unitType => {
            const u = UnitBase.create(unitType, 0);
            return ({
                unitType: unitType,
                abbreviation: u.getAbbreviation()
            })
        });
    }
    else {
        unitInfos = [];
    }
    return unitInfos;
};

function limitCharacters(e) {
    const target = e.target;
    const value = target.value;
    if (value && value.length > target.maxLength) {
        target.value = value.slice(0, target.maxLength);
    }
};

const UnitInputParameter = ({
    parameterMapping,
    refreshToggle,
    isGrouped = false,
    groupName = null,
    handleChange = null,
    handleKeyPress = null,
    focusOnParameterChange = true }
) => {
    const [hasDetents, setHasDetents] = useState(false);
    const [detents, setDetents] = useState([]);
    const [selectedDetent, setSelectedDetent] = useState(null);
    const [unit, setUnit] = useState(null);
    const [minimumUnit, setMinimumUnit] = useState(null);
    const [maximumUnit, setMaximumUnit] = useState(null);
    const [selectedUnitInfo, setSelectedUnitInfo] = useState(null);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isNotDimensionless, setIsNotDimensionless] = useState(true);
    const [unitInfos, setUnitInfos] = useState([]);
    const { active, available, setCachedInfo } = useParameterContext();
    const [inputClassName, setInputClassName] = useState(null);
    const [unitSelectorClassName, setUnitSelectorClassName] = useState(null);
    const [detentSelectorClassName, setDetentSelectorClassName] = useState(null);
    const inputRef = useRef();
    useInputAutofocus(inputRef, focusOnParameterChange, [parameterMapping, hasDetents]);

    useEffect(() => {
        const initUnit = parameterMapping.getMappingValue(true);
        const mappingType = initUnit?.constructor ?? parameterMapping.mappingType;
        setHasDetents(parameterMapping.hasDetents);
        if (parameterMapping.hasDetents) {
            setDetents(parameterMapping.detents);
            setSelectedDetent(isSomething(initUnit) ? parameterMapping.detents.single(d => d.value.equals(initUnit)) : null);
            setMinimumUnit(null);
            setMaximumUnit(null);
        }
        else {
            setDetents([]);
            setSelectedDetent(null);
            setMinimumUnit(parameterMapping.minimumUnit?.convert(mappingType));
            setMaximumUnit(parameterMapping.maximumUnit?.convert(mappingType));
        }
        setUnit(initUnit);
        const quantityType = parameterMapping.quantityType;
        const notDimensionless = quantityType !== DimensionlessUnit;
        setIsNotDimensionless(notDimensionless);
        const uis = createUnitInfos(notDimensionless, mappingType);
        setUnitInfos(uis);
        if (uis.length > 0) {
            setSelectedUnitInfo(uis.single(ui => ui.unitType === mappingType));
        }
        setIsInvalid(parameterMapping.isInvalid);
    }, [parameterMapping, refreshToggle]);

    const updateCache = useCallback((newUnit) => {
        if (!active || !available) return;

        const name = parameterMapping.name;
        const info = { value: newUnit.toString(false, false) };
        if (groupName) {
            setCachedInfo(groupName, name, info);
        }
        else {
            setCachedInfo(name, null, info);
        }
    }, [parameterMapping, groupName, active, available, setCachedInfo]);

    useEffect(() => {
        let className = `${commonStyles.input} ${commonStyles.input___parameter} ${isGrouped ? styles.input___groupedNumeric : styles.input___numeric}`;
        if (!isNotDimensionless) {
            className += ` ${styles.noUnits}`;
        }
        if (isInvalid) {
            className += ` ${commonStyles.input___invalid}`;
        }
        setInputClassName(className);
    }, [isInvalid, isGrouped, isNotDimensionless]);

    useEffect(() => {
        setUnitSelectorClassName(`${commonStyles.selector} ${styles.selector} ${isGrouped ? styles.selector___groupedUnit : styles.selector___inputUnit}`);
        setDetentSelectorClassName(`${commonStyles.selector} ${styles.selector} ${isGrouped ? styles.selector___groupedDetent : styles.selector___detent}`);
    }, [isGrouped]);

    const updateParameterMapping = useCallback((unit) => {
        parameterMapping.setMappingValue(unit);
        if (handleChange) {
            handleChange();
        }
    }, [parameterMapping, handleChange]);

    const handleUnitTypeChange = useCallback((e) => {
        const abbreviation = e.target.value;
        const info = unitInfos.single(i => i.abbreviation === abbreviation);
        const newUnit = unit.convert(info.unitType);
        const type = newUnit.constructor;
        const minUnit = minimumUnit?.convert(type);
        const maxUnit = maximumUnit?.convert(type);
        setSelectedUnitInfo(info);
        setUnit(newUnit);
        setMinimumUnit(minUnit);
        setMaximumUnit(maxUnit);
        updateParameterMapping(newUnit);
        updateCache(newUnit);
    }, [unitInfos, unit, minimumUnit, maximumUnit, updateParameterMapping, updateCache]);

    const handleDetentChange = useCallback((e) => {
        const label = e.target.value;
        const detent = detents.single(i => i.label === label);
        const detentUnit = detent.value;
        const newUnit = isSomething(unit) ? UnitBase.create(unit.constructor, detentUnit.value) : null;
        setSelectedDetent(detent);
        setUnit(newUnit);
        updateParameterMapping(newUnit);
        updateCache(newUnit);
    }, [detents, unit, updateParameterMapping, updateCache]);

    const handleUnitValueChange = useCallback((e) => {
        const value = e.target.value;
        const newUnit = UnitBase.create(unit.constructor, value);
        updateParameterMapping(newUnit);
        setUnit(newUnit);
        setIsInvalid(parameterMapping.isInvalid);
        updateCache(newUnit);
    }, [updateParameterMapping, unit, updateCache, parameterMapping.isInvalid]);

    const handleKeyDown = useCallback((e) => {
        limitCharacters(e);
        if (handleKeyPress) {
            handleKeyPress(e);
        }
    }, [handleKeyPress]);

    return <>
        {!isGrouped && <label htmlFor="value" className={styles.paddedLabel}>{parameterMapping.name}</label>}
        {!hasDetents && (
            <>
                <input
                    ref={inputRef}
                    id="value"
                    className={inputClassName}
                    type="number"
                    onInput={limitCharacters}
                    maxLength="18"
                    value={unit?.value ?? 0}
                    onChange={handleUnitValueChange}
                    onKeyDown={handleKeyDown}
                    min={minimumUnit?.value}
                    max={maximumUnit?.value}
                    step={parameterMapping.step} />
                {isNotDimensionless && (
                    <select
                        className={unitSelectorClassName}
                        value={selectedUnitInfo?.abbreviation}
                        onChange={handleUnitTypeChange}
                        onKeyDown={handleKeyDown}>
                        {unitInfos.map(i => (
                            <option key={i.unitType.name} value={i.abbreviation}>
                                {i.abbreviation}
                            </option>
                        ))}
                    </select>
                )}
            </>
        )}
        {hasDetents && (
            <select
                ref={inputRef}
                id="value"
                className={detentSelectorClassName}
                value={selectedDetent?.label}
                onChange={handleDetentChange}
                onKeyDown={handleKeyDown}>
                {detents.map(i => (
                    <option key={i.label} value={i.label}>
                        {i.label}
                    </option>
                ))}
            </select>
        )}
    </>
}

export default UnitInputParameter;