import { areSomething, isSomething } from "../common/utilities";

export class OneToManyCurveSet {
    #curves = new Map();
    
    set(name, curve) {
        if (areSomething(name, curve)) {
            const curves = this.#curves;
            if (curves.has(name)) {
                throw new Error(`A curve with the name '${name}' has already been defined.`);
            }
            curves.set(name, curve);
        }
        else {
            throw new Error("Name and curve must be supplied.");
        }
    }

    getCurveNames() {
        return Array.from(this.#curves.keys());
    }

    getX(y, name) {
        if (areSomething(y, name)) {
            const curve = this.#curves.get(name);
            return isSomething(curve) ? curve.getX(y, false) : null;
        }
        return null;
    }

    getY(x, name) {
        if (areSomething(x, name)) {
            const curve = this.#curves.get(name);
            return isSomething(curve) ? curve.getY(x, false) : null;
        }
        return null;
    }
}