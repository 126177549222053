import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";

export class OneToManyCurveSetTransform extends Transform {
    #curveNames;
    
    constructor(oneToManyCurveSet) {
        super();
        this.oneToManyCurveSet = oneToManyCurveSet;
        this.#curveNames = oneToManyCurveSet.getCurveNames();
    }

    _sourceParameters() {
        const parameters = [new Parameter(ParameterNames.ParameterYName, this.id)];
        this.oneToManyCurveSet.getCurveNames().forEach(name => {
            parameters.push(new Parameter(name, this.id));
        });
        return parameters;
    }

    _onParameterValueReceived(parameter, value) {
        if (parameter.name === ParameterNames.ParameterYName) {
            this.#curveNames.forEach(name => {
                const x = this.oneToManyCurveSet.getX(value, name);
                this._emitParameterValue(name, x);
            });
        }
        else {
            const y = this.oneToManyCurveSet.getY(value, parameter.name);
            this._emitParameterValue(ParameterNames.ParameterYName, y);
            this.#curveNames.forEach(name => {
                if (name !== parameter.name) {
                    const x = this.oneToManyCurveSet.getX(y, name);
                    this._emitParameterValue(name, x);
                }
            });
        }
    }
}