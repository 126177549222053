import { CurveSetIdentifier } from "./curve-set-identifier"
import { RangeCurveSet } from "./range-curve-set"

export class RangeCurveTwinSet {
    constructor(interpolatorFactory) {
        this.curveTwinSet = new Map([
            [CurveSetIdentifier.One, new RangeCurveSet(interpolatorFactory)],
            [CurveSetIdentifier.Two, new RangeCurveSet(interpolatorFactory)]
        ]);
    }

    set(identifier, rangeValue, curve) {
        if (!Object.values(CurveSetIdentifier).includes(identifier)) {
            throw new Error("Invalid curve set identifier.  Use either CurveSetIdentifier.One or CurveSetIdentifier.Two.");
        }
        const rangeCurveSet = this.getRangeCurveSet(identifier);
        rangeCurveSet.set(rangeValue, curve);
    }

    getRangeCurveSet(identifier) {
        return this.curveTwinSet.get(identifier);
    }

    getCurve(identifier, rangeValue) {
        const rangeCurveSet = this.getRangeCurveSet(identifier);
        return rangeCurveSet.getCurve(rangeValue);
    }
}