import styles from "./InputParameterSequencer.module.css";
import commonStyles from "./Common.module.css";
import { useEffect, useState, useCallback, useMemo } from "react";
import UnitInputParameter from "./UnitInputParameter";
import NumericInputParameter from "./NumericInputParameter";
import BooleanInputParameter from "./BooleanInputParameter";
import OptionalParameterGroup from "./OptionalParameterGroup";
import Expander from "./Expander";
import ParameterMappingSummary from "./ParameterMappingSummary";
import useParameterContext from "../hooks/useParameterContext";
import applyCachedValues from "./CachedInfoHelper";
import { ReactComponent as PreviousBlock } from "../assets/previous-block.svg";
import { ReactComponent as PreviousItem } from "../assets/previous-item.svg";
import { ReactComponent as NextItem } from "../assets/next-item.svg";
import { ReactComponent as NextBlock } from "../assets/next-block.svg";

const InputParameterSequencer = ({ operation }) => {
    const [dataError, setDataError] = useState(true);
    const [currentCategoryName, setCurrentCategoryName] = useState("");
    const [currentCategoryParameterCount, setCurrentCategoryParameterCount] = useState(0);
    const [currentCategoryParameterIndex, setCurrentCategoryParameterIndex] = useState(0);
    const [currentParameterMapping, setCurrentParameterMapping] = useState(null);
    const [hasNextCategory, setHasNextCategory] = useState(false);
    const [hasPreviousCategory, setHasPreviousCategory] = useState(false);
    const [hasNextParameter, setHasNextParameter] = useState(false);
    const [hasPreviousParameter, setHasPreviousParameter] = useState(false);
    const [inputsTitle, setInputsTitle] = useState();
    const [selectedSummaryItemIndex, setSelectedSummaryItemIndex] = useState(-1);
    const [updateRequestIndexes, setUpdateRequestIndexes] = useState(new Set());
    const [parameterMappingCount, setParameterMappingCount] = useState(0);
    const { active, available, getCachedInfo } = useParameterContext();
    const [inputsExpanderChecked, setInputsExpanderChecked] = useState(true);
    const [cacheAccessed, setCacheAccessed] = useState(false);
    const [refreshToggle, setRefreshToggle] = useState(false);

    const { orderedCategories, categoryCount, perCategoryMappingCounts, allParameterMappings } = useMemo(() => {
        const orderedCategories = operation.getInputParameterCategories();
        const categoryCount = orderedCategories.length;
        const perCategoryMappingCounts = [];
        const allParameterMappings = [];
        orderedCategories.forEach(category => {
            const mappings = category.getParameterMappings();
            allParameterMappings.push(...mappings);
            perCategoryMappingCounts.push(mappings.length);
        });
        setSelectedSummaryItemIndex(categoryCount > 0 ? 0 : -1);
        setCacheAccessed(false);
        return {orderedCategories, categoryCount, perCategoryMappingCounts, allParameterMappings};
    }, [operation]);
    
    useEffect(() => {
        setParameterMappingCount(allParameterMappings.length);
    }, [allParameterMappings]);
    
    useEffect(() => {
        if (active && available && !cacheAccessed) {
            applyCachedValues(getCachedInfo, allParameterMappings);
            setCacheAccessed(true);
            setRefreshToggle(toggle => !toggle);
            setUpdateRequestIndexes(new Set());
        }
    }, [active, available, cacheAccessed, getCachedInfo, allParameterMappings]);

    const calculateIndices = useCallback(() => {
        let categoryIndex = 0;
        let parameterIndex = selectedSummaryItemIndex;
        while (parameterIndex > 0) {
            const paramCount = perCategoryMappingCounts[categoryIndex];
            if (parameterIndex >= paramCount) {
                parameterIndex -= paramCount;
                categoryIndex++;
            }
            else {
                break;
            }
        }
        return { categoryIndex, parameterIndex };
    }, [selectedSummaryItemIndex, perCategoryMappingCounts]);

    useEffect(() => {
        if (selectedSummaryItemIndex >= 0) {
            const { categoryIndex, parameterIndex } = calculateIndices();
            const category = orderedCategories[categoryIndex];
            if (category) {
                setCurrentCategoryName(category.name);
                setCurrentCategoryParameterCount(category.getParameterMappingCount());
                setCurrentCategoryParameterIndex(parameterIndex);
                setCurrentParameterMapping(allParameterMappings[selectedSummaryItemIndex]);
                setDataError(false);
            }
            setHasPreviousCategory(categoryIndex > 0);
            setHasPreviousParameter(selectedSummaryItemIndex > 0);
            setHasNextParameter(selectedSummaryItemIndex < allParameterMappings.length - 1);
            setHasNextCategory(categoryIndex < categoryCount - 1);
        }
    }, [operation, selectedSummaryItemIndex, calculateIndices]);

    useEffect(() => {
        setInputsTitle(inputsExpanderChecked ? `${currentCategoryName} (${currentCategoryParameterIndex + 1} of ${currentCategoryParameterCount})` : "Inputs");
    }, [inputsExpanderChecked, currentCategoryName, currentCategoryParameterIndex, currentCategoryParameterCount]);

    const onSelectInputParameter = useCallback((index) => {
        setSelectedSummaryItemIndex(index);
    }, []);

    const getLastSummaryItemIndexOfPreviousCategory = useCallback(() => {
        let { categoryIndex } = calculateIndices();
        let index = 0;
        while (--categoryIndex >= 0) {
            index += perCategoryMappingCounts[categoryIndex];
        }
        return index - 1;
    }, [calculateIndices, perCategoryMappingCounts]);

    const onMoveToPreviousCategory = useCallback(() => {
        const index = getLastSummaryItemIndexOfPreviousCategory();
        setSelectedSummaryItemIndex(index);
    }, [getLastSummaryItemIndexOfPreviousCategory]);
    
    const getFirstSummaryItemIndexOfNextCategory = useCallback(() => {
        let { categoryIndex } = calculateIndices();
        let index = 0;
        while (categoryIndex >= 0) {
            index += perCategoryMappingCounts[categoryIndex--];
        }
        return index;
    }, [calculateIndices, perCategoryMappingCounts]);

    const onMoveToNextCategory = useCallback(() => {
        const index = getFirstSummaryItemIndexOfNextCategory();
        setSelectedSummaryItemIndex(index);
    }, [getFirstSummaryItemIndexOfNextCategory]);

    const updateParameterMappingSummary = useCallback(() => {
        setUpdateRequestIndexes(new Set([selectedSummaryItemIndex]));
    }, [selectedSummaryItemIndex]);

    const onMoveToPreviousParameter = useCallback(() => {
        setSelectedSummaryItemIndex(index => index - 1);
    }, []);

    const onMoveToNextParameter = useCallback(() => {
        setSelectedSummaryItemIndex(index => index + 1);
    }, []);

    const handleKeyPress = useCallback((e) => {
        if (e.keyCode === 13) {
            if (e.shiftKey) {
                if (hasPreviousParameter || hasPreviousCategory) {
                    onMoveToPreviousParameter();
                }
            }
            else if (hasNextParameter || hasNextCategory) {
                onMoveToNextParameter();
            }
        }
    }, [hasPreviousParameter, hasPreviousCategory, hasNextParameter, hasNextCategory, onMoveToPreviousParameter, onMoveToNextParameter]);

    return (
        !dataError && <section className={styles.InputParameterSequencer}>
            <Expander title={inputsTitle} checked={inputsExpanderChecked} setChecked={setInputsExpanderChecked}>
                {currentParameterMapping && <div className={styles.parameterContainer}>
                    {(() => {
                        switch (currentParameterMapping.typeName) {
                            case "UnitParameterMapping":
                            case "UnitMultiParameterMapping":
                                return <UnitInputParameter
                                    parameterMapping={currentParameterMapping}
                                    refreshToggle={refreshToggle}
                                    handleChange={updateParameterMappingSummary}
                                    handleKeyPress={handleKeyPress} />
                            case "NumericParameterMapping":
                            case "NumericMultiParameterMapping":
                                return <NumericInputParameter
                                    parameterMapping={currentParameterMapping}
                                    refreshToggle={refreshToggle}
                                    handleChange={updateParameterMappingSummary}
                                    handleKeyPress={handleKeyPress} />
                            case "BooleanParameterMapping":
                            case "BooleanMultiParameterMapping":
                                return <BooleanInputParameter
                                    parameterMapping={currentParameterMapping}
                                    title="Select"
                                    refreshToggle={refreshToggle}
                                    handleChange={updateParameterMappingSummary}
                                    handleKeyPress={handleKeyPress} />
                            case "ParameterMappingGroup":
                                switch (currentParameterMapping.type) {
                                    case "OptionalFactorParameterGroup":
                                        return <OptionalParameterGroup
                                            group={currentParameterMapping}
                                            refreshToggle={refreshToggle}
                                            parameterMappingKeys={["Factor"]}
                                            handleChange={updateParameterMappingSummary}
                                            handleKeyPress={handleKeyPress} />
                                    case "OptionalScaledFactorParameterGroup":
                                        return <OptionalParameterGroup
                                            group={currentParameterMapping}
                                            refreshToggle={refreshToggle}
                                            parameterMappingKeys={["Amount", "Factor", "Divisor"]}
                                            handleChange={updateParameterMappingSummary}
                                            handleKeyPress={handleKeyPress} />
                                    default:
                                        throw new Error(`"${currentParameterMapping.type}" is not a recognised parameter mapping group type.`);
                                }
                            default:
                                throw new Error(`"${currentParameterMapping.typeName}" is not a recognised parameter mapping type.`);
                        }
                    })()}
                </div>}
            
                <div className={styles.buttonContainer}>
                    {categoryCount > 1 &&
                        <button
                            className={`${commonStyles.button} ${styles.navButton}`}
                            disabled={!hasPreviousCategory}
                            onClick={onMoveToPreviousCategory}>
                            <PreviousBlock className={styles.navImage} />
                        </button>}
                    {(parameterMappingCount > 1) &&
                        <button
                            className={`${commonStyles.button} ${styles.navButton}`}
                            disabled={!hasPreviousCategory && !hasPreviousParameter}
                            onClick={onMoveToPreviousParameter}>
                            <PreviousItem className={styles.navImage} />
                        </button>}
                    {(parameterMappingCount > 1) &&
                        <button
                            className={`${commonStyles.button} ${styles.navButton}`}
                            disabled={!hasNextCategory && !hasNextParameter}
                            onClick={onMoveToNextParameter}>
                            <NextItem className={styles.navImage} />
                        </button>}
                    {categoryCount > 1 &&
                        <button
                            className={`${commonStyles.button} ${styles.navButton}`}
                            disabled={!hasNextCategory}
                            onClick={onMoveToNextCategory}>
                            <NextBlock className={styles.navImage} />
                        </button>}
                </div>
            </Expander>

            {parameterMappingCount > 1 && <Expander title="Inputs Summary" checked={true}>
                {allParameterMappings.map((mapping, index) =>
                    <div
                        key={`${operation.name} ${mapping.name}`}
                        className={`${styles.parameterSummaryItem}` + (index === selectedSummaryItemIndex ? ` ${styles.parameterSummaryItem___selected}` : "")}>
                        
                        <label>
                            <input
                                className={styles.parameterSummaryItemSelector}
                                type="radio"
                                id={mapping.name}
                                checked={index === selectedSummaryItemIndex ? true : false}
                                onChange={() => onSelectInputParameter(index)}
                            />
                            <ParameterMappingSummary
                                parameterMapping={mapping}
                                index={index}
                                updateRequestIndexes={updateRequestIndexes}
                                selected={index === selectedSummaryItemIndex}/>
                        </label>
                    </div>
                )}
            </Expander>}
        </section>
    );
}

export default InputParameterSequencer;