import styles from "./LandingPage.module.css";
import commonStyles from "./Common.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useSoleOrSelectedAircraftId from "../hooks/useSoleOrSelectedAircraftId";
import { useHangarPath } from "../hooks/useHangarPath";
import { ReactComponent as Fast } from "../assets/fast.svg";
import { ReactComponent as Idea } from "../assets/idea.svg";
import { ReactComponent as Goal } from "../assets/goal.svg";
import { ReactComponent as Hourglass } from "../assets/hourglass.svg";
import { ReactComponent as Maze } from "../assets/maze.svg";
import { ReactComponent as Error } from "../assets/error.svg";

const LandingPage = () => {
    const navigate = useNavigate();
    const { aircraftId } = useSoleOrSelectedAircraftId();
    const { pathRoot } = useHangarPath();

    useEffect(() => {
        document.title = "Avaid - Start Here";
    }, []);
    
    const onStart = () => {
        const root = `/${pathRoot}`;
        const to = aircraftId ? `${root}/${aircraftId}` : root;
        navigate(to);
    };

    return <section className={styles.LandingPage}>
        <div className={styles.bannerContainer} />

        <div className={styles.logoContainer}>
            <img
                className={styles.logo}
                src="/Images/Logo-531x112.png"
                alt="Avaid logo"
                width="531"
                height="112" />
        </div>

        <div className={styles.titleContainer}>
            <h1>Flight Performance Planning</h1>
        </div>
        
        <div className={styles.body}>
            <div className={`${styles.body___item} ${styles.body___from}`}>
                <h2>From...</h2>
                <figure className={styles.figure}>
                    <Hourglass className={`${styles.icon} ${styles.icon___inverted}`} />
                    <figcaption>slow</figcaption>
                </figure>
                <figure className={styles.figure}>
                    <Maze className={`${styles.icon} ${styles.icon___inverted}`} />
                    <figcaption>confusing</figcaption>
                </figure>
                <figure className={styles.figure}>
                    <Error className={`${styles.icon} ${styles.icon___inverted}`} />
                    <figcaption>error-prone</figcaption>
                </figure>
            </div>

            <button
                className={`${commonStyles.button} ${styles.callToActionButton}`}
                type="button"
                onClick={onStart}>Start Now</button>

            <div className={`${styles.body___item} ${styles.body___to}`}>
                <h2>... to...</h2>
                <figure className={styles.figure}>
                    <figcaption>fast</figcaption>
                    <Fast className={styles.icon} />
                </figure>
                <figure className={styles.figure}>
                    <figcaption>clear</figcaption>
                    <Idea className={styles.icon} />
                </figure>
                <figure className={styles.figure}>
                    <figcaption>error-free</figcaption>
                    <Goal className={styles.icon} />
                </figure>
            </div>
        </div>
    </section>
}

export default LandingPage;