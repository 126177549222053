import { useState, useEffect } from "react";
import { isSomething } from "../lib/common/utilities";

const getLocalValue = (key, initValue, deserializer = null) => {
    const item = localStorage.getItem(key);
    let localValue;
    if (isSomething(item)) {
        try {
            const value = deserializer ? deserializer(item) : JSON.parse(item);
            localValue = value;
        }
        catch {
            localValue = null;
        }
    }
    else {
        localValue = null;
    }
    if (isSomething(localValue)) return localValue;
    return initValue instanceof Function ? initValue() : initValue;
}

const usePersistedState = (key, initValue, serializer = null, deserializer = null) => {
    const [value, setValue] = useState(() => getLocalValue(key, initValue, deserializer));

    useEffect(() => {
        const item = serializer ? serializer(value) : JSON.stringify(value);
        localStorage.setItem(key, item);
    }, [key, value])

    return [value, setValue];
}

export default usePersistedState;