import styles from "./OperationContainer.module.css";
import commonStyles from "./Common.module.css";

const OperationContainer = ({ category, handleSelect }) => {
    return (
        <section>
            <ul className={styles.operationList}>
                {category.getOperations().map((operation, operationIndex) => 
                    <button
                        key={operationIndex}
                        className={`${commonStyles.navigationButton} ${styles.selectOperationButton}`}
                        onClick={() => handleSelect(operationIndex)}>
                        <p>{operation.description}</p>
                    </button>
                )}
            </ul>
        </section>
    )
}

export default OperationContainer;