import { createContext, useEffect, useState, useCallback } from "react";
import useAuthContext from "../hooks/useAuthContext";
import useAccessibleAircraftContext from "../hooks/useAccessibleAircraftContext";
import usePersistedState from "../hooks/usePersistedState";
import { MessageLog } from "../lib/common/message-log";
import { Aircraft } from "../lib/builders/models/aircraft";
import { writeMessageLogContentsToConsole } from "../lib/builders/builder-helper";
import { useApiGet } from "../hooks/useApi";
import { useHangarPath, PATHS } from "../hooks/useHangarPath";
import { isSomething } from "../lib/common/utilities";

const AircraftContext = createContext({});

export const AircraftProvider = ({ children }) => {
    const { auth } = useAuthContext();
    const { aircraftCount, aircraftSummaries } = useAccessibleAircraftContext();
    const [aircraftMetadata, setAircraftMetadata] = usePersistedState("selectedAircraftMetadata", null);
    const [aircraftId, setAircraftId] = usePersistedState("selectedAircraftId", null);
    const [requestedAircraftId, setRequestedAircraftId] = useState(() => aircraftMetadata?.summary?.index || null);
    const [accessToken, setAccessToken] = useState(auth.accessToken);
    const [url, setUrl] = useState(null);
    const [urlIsValid, setUrlIsValid] = useState(false);
    const [apronOverride, setApronOverride] = useState(false);
    const { pathRoot } = useHangarPath();

    const createAircraftFromMetadata = useCallback(() => {
        let instance;
        const messageLog = new MessageLog();
        if (aircraftMetadata) {
            messageLog.invoke(() => instance = Aircraft.create(aircraftMetadata, messageLog));
        }
        else {
            instance = null;
        }
        if (messageLog.hasErrors() || messageLog.hasWarnings()) {
            instance = null;
            writeMessageLogContentsToConsole(messageLog);
        }
        return instance;
    }, [aircraftMetadata]);
    
    const [aircraft, setAircraft] = useState(() => createAircraftFromMetadata());

    /* apronOverride allows the caller to request an aircraft from stock.  This
       ensures that the caller can access an aircraft that hasn't been leased
       when a user is logged in.  This is permissible if the caller intends for 
       the loaded aircraft to be used for non-operational reasons (e.g. leasing). */
    const loadAircraftById = useCallback((id, apronOverride = false) => {
        setApronOverride(apronOverride);
        id = Number(id);
        if (requestedAircraftId !== id) {
            setRequestedAircraftId(id);
        }
    }, [requestedAircraftId]);

    const clearAircraft = useCallback(() => {
        setRequestedAircraftId(null);
    }, []);

    const reload = useCallback(() => {
        const id = aircraftId;
        if (isSomething(id)) {
            clearAircraft();
            loadAircraftById(id);
        }
    }, [aircraftId, clearAircraft, loadAircraftById]);

    const { data, error, isLoading } = useApiGet(url, accessToken, null, null, urlIsValid);
    
    useEffect(() => {
        const root = apronOverride ? PATHS.Apron : pathRoot;
        const isValid = root !== null && requestedAircraftId !== null;
        if (isValid) {
            setUrl(`/${root}/${requestedAircraftId}`);
        }
        setUrlIsValid(isValid);
    }, [apronOverride, pathRoot, requestedAircraftId]);

    useEffect(() => {
        if ((urlIsValid && requestedAircraftId !== aircraftId) || aircraftMetadata !== data?.aircraft) {
            setAircraftMetadata(data?.aircraft);
        }
    }, [data]);
    
    useEffect(() => {
        const instance = createAircraftFromMetadata();
        setAircraft(instance);
        setAircraftId(instance?.id);
    }, [createAircraftFromMetadata]);

    useEffect(() => {
        setAccessToken(auth.accessToken);
    }, [auth]);
    
    useEffect(() => {
        clearAircraft();
    }, [accessToken]);

    useEffect(() => {
        if (!aircraft && aircraftCount === 1 && requestedAircraftId === null) {
            const id = aircraftSummaries[0].id;
            loadAircraftById(id);
        }
    }, [aircraft, aircraftSummaries, loadAircraftById]);

    return (
        <AircraftContext.Provider value={{ loadAircraftById, reload, clearAircraft, aircraft, error, isLoading }}>
            {children}
        </AircraftContext.Provider>
    )
}

export default AircraftContext;