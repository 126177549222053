import { isSomething } from "../common/utilities";
import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";

export class CurveTransform extends Transform {
    constructor(curve) {
        super();
        this.curve = curve;
    }

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterXName, this.id),
            new Parameter(ParameterNames.ParameterYName, this.id)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        switch (parameter.name) {
            case ParameterNames.ParameterXName:
                this.#calculateY(value);
                break;
            case ParameterNames.ParameterYName:
                this.#calculateX(value);
                break;
            // no default
        }
    }

    #calculateX(y) {
        const x = isSomething(y) ? this.curve.getX(y, false) : null;
        this._emitParameterValue(ParameterNames.ParameterXName, x);
    }

    #calculateY(x) {
        const y = isSomething(x) ? this.curve.getY(x, false) : null;
        this._emitParameterValue(ParameterNames.ParameterYName, y);
    }
}