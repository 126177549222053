export const ParameterNames = Object.freeze(
    {
        ParameterXName: "X",
        ParameterX0Name: "X0",
        ParameterX1Name: "X1",
        ParameterYName: "Y",
        ParameterY0Name: "Y0",
        ParameterY1Name: "Y1",
        ParameterRangeCurveSetGroupValueName: "RangeCurveSetGroupValue",
        ParameterRangeCurveSetValueName: "RangeCurveSetValue",
        ParameterDetentCurveSetValueName: "DetentCurveSetValue",
        ParameterRangeCurveTwinSet1ValueName: "RangeCurveTwinSet1Value",
        ParameterRangeCurveTwinSet2ValueName: "RangeCurveTwinSet2Value",
        ParameterInName: "In",
        ParameterIn1Name: "In1",
        ParameterIn2Name: "In2",
        ParameterOutName: "Out",
        ParameterFactorName: "Factor",
        ParameterDivisorName: "Divisor",
        ParameterEnabledName: "Enabled",
        ParameterAmountName: "Amount",
        ParameterAdjustmentAmountName: "AdjustmentAmount",
        ParameterAltitudeName: "Altitude",
        ParameterIndicatedAltitudeName: "IndicatedAltitude",
        ParameterPressureAltitudeName: "PressureAltitude",
        ParameterTemperatureName: "Temperature",
        ParameterTemperatureCelsiusName: "TemperatureCelsius",
        ParameterTemperatureFahrenheitName: "TemperatureFahrenheit",
        ParameterIsaDifferenceName: "IsaDifference",
        ParameterQnhName: "QNH",
        ParameterRunwayElevationName: "RunwayElevation",
        ParameterTakeOffDistanceName: "TakeOffDistance",
        ParameterObstacleDistanceName: "ObstacleDistance",
        ParameterObstacleTrueAltitudeName: "ObstacleTrueAltitude",
        ParameterClimbCalibratedAirspeedName: "ClimbCalibratedAirspeed",
        ParameterClimbRateName: "ClimbRate",
        ParameterMeanClimbWindComponentName: "MeanClimbWindComponent",
        ParameterTrueAirspeedName: "TrueAirspeed",
        ParameterGroundSpeedName: "GroundSpeed",
        ParameterIndicatedAltitudeAtObstacleName: "IndicatedAltitudeAtObstacle",
        ParameterTrueAltitudeAtObstacleName: "TrueAltitudeAtObstacle",
        ParameterObstacleClearanceHeightName: "ObstacleClearanceHeight",
        ParameterTestName: "Test",
        ParameterPassName: "Pass",
        ParameterFailName: "Fail"
    }
);