import styles from "./CartItem.module.css";
import commonStyles from "./Common.module.css";
import CostPresenter from "./CostPresenter";
import { ReactComponent as Cross } from "../assets/cross.svg";

const CartItem = ({ id, item, remove, error }) => {
    const handleRemoveButton = () => {
        remove(id);
    };

    return (
        <div className={styles.CartItem}>
            <div className={styles.productDescription}>
                <p>{item.productDescription1}</p>
                <p>{item.productDescription2}</p>
                {error && <p className={styles.error}>{error}</p>}
            </div>
            <div className={styles.productAction}>
                <p>{item.type}</p>
                <p>{item.productAction}</p>
            </div>
            <div className={styles.priceAndRemoveContainer}>
                <CostPresenter cost={item.price} />
                <button className={commonStyles.button} onClick={handleRemoveButton}>
                    <Cross className={styles.removeButtonImage} />
                </button>
            </div>
        </div>
    )
};

export default CartItem;