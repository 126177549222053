import styles from "./SidebarItem.module.css";
import commonStyles from "../Common.module.css";
import useHelpContext from "../../hooks/useHelpContext";
import { useState, useEffect, useCallback } from "react";

const SidebarItem = ({ element }) => {
    const { getChildElements, getAncestralIndexes, selectedIndex, navigateTo } = useHelpContext();
    const [hasChildElements, setHasChildElements] = useState(false);
    const [childElements, setChildElements] = useState([]);
    const [childIndexes, setChildIndexes] = useState([]);
    const [childIsSelected, setChildIsSelected] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [contentContainerClassName, setContentContainerClassName] = useState(styles.contentContainer);

    useEffect(() => {
        var childElements = getChildElements(element.index);
        setHasChildElements(childElements.length > 0);
        setChildElements(childElements);
        setChildIndexes(childElements.map(e => e.index));
    }, [element, getChildElements]);

    const getItemClassName = useCallback((index, includeExpanded = false, isChild = false) => {
        let className = `${commonStyles.navigationButton} ${styles.navigationButton}`;
        const indexes = getAncestralIndexes(selectedIndex);
        const selected = selectedIndex === index || indexes.includes(index);
        if (selected && !(isChild ^ childIsSelected)) {
            className += ` ${styles.item___selected}`;
        }
        if (includeExpanded && expanded) {
            className += ` ${styles.expanded}`;
        }
        if (isChild) {
            className += ` ${styles.small}`;
        }
        return className;
    }, [selectedIndex, getAncestralIndexes, expanded, childIsSelected]);

    useEffect(() => {
        const indexes = getAncestralIndexes(selectedIndex);
        let selected = false;
        for (let i of childIndexes) {
            selected = selectedIndex === i || indexes.includes(i);
            if (selected) break;
        }
        setChildIsSelected(selected);
    }, [selectedIndex, getAncestralIndexes, childIndexes]);

    const toggleExpander = useCallback(() => {
        setExpanded(e => !e);
    }, []);

    const toggleExpanderAndNavigateTo = useCallback((index) => {
        navigateTo(index);
        toggleExpander();
    }, [toggleExpander, navigateTo]);

    useEffect(() => {
        if (childIsSelected) {
            setExpanded(true);
        }
    }, [childIsSelected]);

    useEffect(() => {
        let className = styles.contentContainer;
        if (expanded) {
            className += ` ${styles.contentContainer___expanded}`;
        }
        setContentContainerClassName(className);
    }, [expanded]);

    return (
        <section className={styles.SidebarItem}>
            {hasChildElements && <>
                <button
                    className={getItemClassName(element.index, true)}
                    onClick={() => toggleExpanderAndNavigateTo(element.index)}>
                    
                    <label className={styles.toggleLabel}>{element.title}</label>
                </button>
                <div className={contentContainerClassName}>
                    {childElements.map((childElement) =>
                        <button
                            key={childElement.index}
                            onClick={() => navigateTo(childElement.index)}
                            className={getItemClassName(childElement.index, false, true)}>{childElement.title}</button>)}
                </div>
            </>}
            {!hasChildElements &&
                <button
                    onClick={() => navigateTo(element.index)}
                    className={getItemClassName(element.index)}>{element.title}</button>
            }
        </section>
    )
};

export default SidebarItem;