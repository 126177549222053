/* Defines the range of range of methods by which a curve can be identified
   from a CurveSet by using a point (x, y).
*/
export const CurveSelectionMethod = Object.freeze(
    {
        None: Symbol(), // A curve was not able to be selected
        Interpolation: Symbol(), // A curve was interpolated by using the two curves either side of the point
        Shadow: Symbol(), // A curve was extrapolated from a boundary curve
        LowerBoundary: Symbol(), // The lower boundary curve was selected because the point was below it
        UpperBoundary: Symbol() // The upper boundary curve was selected because the point was above it
    }
);