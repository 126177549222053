import styles from "./Expander.module.css";
import { useState, useEffect } from "react";

let id = 0;
export function getId() {
    return id++;
}

const Expander = ({ title, children, checked = null, setChecked = null, withContainer = true }) => {
    const inputId = getId();
    const [checkedInternal, setCheckedInternal] = useState(checked === true);
    const [expanderClassName, setExpanderClassName] = useState();
    const [toggleLabelClassName, setToggleLabelClassName] = useState();
    const [contentContainerClassName, setContentContainerClassName] = useState();
    const [contentClassName, setContentClassName] = useState();

    useEffect(() => {
        setCheckedInternal(checked === true);
    }, [checked]);

    const handleCheckedChanged = (e) => {
        const value = e.target.checked;
        if (setChecked) {
            setChecked(value);
        }
        else {
            setCheckedInternal(value);
        }
    };

    useEffect(() => {
        let className = styles.Expander;
        if (withContainer) {
            className += ` ${styles.Expander___withContainer}`;
        }
        setExpanderClassName(className);

        className = styles.contentContainer;
        if (withContainer) {
            className += ` ${styles.contentContainer___withContainer}`;
        }
        setContentContainerClassName(className);

        className = styles.toggleLabel;
        if (!withContainer) {
            className += ` ${styles.notDisplayed}`;
        }
        setToggleLabelClassName(className);
    }, [withContainer]);

    useEffect(() => {
        const className = withContainer && !checkedInternal
            ? `${styles.content___withContainer} ${styles.hidden}`
            : withContainer
                ? `${styles.content___withContainer}`
                : !checkedInternal
                    ? `${styles.hidden}` : "";
        setContentClassName(className);
    }, [withContainer, checkedInternal]);

    return (
        <div className={expanderClassName}>
            <input id={inputId} className={styles.toggle} type="checkbox" checked={checkedInternal} onChange={handleCheckedChanged} />
            <label htmlFor={inputId} className={toggleLabelClassName}>{title}</label>
            <div className={contentContainerClassName}>
                <div className={contentClassName}>
                    {children}
                </div>
            </div>
        </div>
    )
};

export default Expander;