import { useState, useEffect, useCallback } from "react";
import usePersistedState from "../hooks/usePersistedState";
import PerformanceDataDisclaimerDialogue from "./PerformanceDataDisclaimerDialogue";

const performanceDataDisclaimerVersion = 1;

const PerformanceDataDisclaimerAutoDialogue = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentDisclaimerVersion, setCurrentDisclaimerVersion] = usePersistedState("currentDisclaimerVersion", null);

    const requestClose = useCallback(() => {
        setIsOpen(false);
        setCurrentDisclaimerVersion(performanceDataDisclaimerVersion);
    }, [setCurrentDisclaimerVersion]);

    useEffect(() => {
        if (performanceDataDisclaimerVersion > currentDisclaimerVersion) {
            setIsOpen(true);
        }
    }, [currentDisclaimerVersion]);

    return <PerformanceDataDisclaimerDialogue isOpen={isOpen} requestClose={requestClose} />
};

export default PerformanceDataDisclaimerAutoDialogue;