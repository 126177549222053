import UnitConverter from "../lib/units/unit-converter";
import { isSomething } from "../lib/common/utilities";

const applyCachedValues = (
    getCachedInfo,
    mappings,
    getMappingAndSubMappingNames = mapping => ({ parameterName: mapping.name, subParameterName: null })) => {
    
    mappings.forEach(mapping => {
        const { parameterName, subParameterName } = getMappingAndSubMappingNames(mapping);
        switch (mapping.typeName) {
            case "UnitParameterMapping":
            case "UnitMultiParameterMapping": {
                const info = getCachedInfo(parameterName, subParameterName);
                const value = info?.value;
                if (isSomething(value)) {
                    try {
                        const unit = mapping.getMappingValue(true);
                        const mappingType = unit?.constructor ?? mapping.mappingType;
                        const cachedUnit = UnitConverter.createFrom(value);
                        if (cachedUnit.isOfSameQuantity(mappingType)) {
                            if (unit) {
                                cachedUnit.setPrecision(unit.getPrecision());
                            }
                            mapping.setMappingValue(cachedUnit);
                        }
                    }
                    catch { }
                }
                break;
            }

            case "NumericParameterMapping":
            case "NumericMultiParameterMapping":
                const info = getCachedInfo(parameterName, subParameterName);
                const value = info?.value;
                if (isSomething(value)) {
                    mapping.setMappingValue(Number(value));
                }
                break;
            
            case "BooleanParameterMapping":
            case "BooleanMultiParameterMapping": {
                const info = getCachedInfo(parameterName, subParameterName);
                const value = info?.value;
                if (isSomething(value)) {
                    mapping.setMappingValue(value === true || value === "true");
                }
                break;
            }

            case "ParameterMappingGroup":
                switch (mapping.type) {
                    case "OptionalFactorParameterGroup": 
                    case "OptionalScaledFactorParameterGroup": {
                        const keys = mapping.getKeys();
                        const subMappings = keys.map(key => mapping.getParameterMapping(key));
                        const namesFn = m => ({ parameterName, subParameterName: m.name });
                        applyCachedValues(getCachedInfo, subMappings, namesFn);
                        break;
                    }
                    default:
                        throw new Error(`"${mapping.type}" is not a recognised parameter mapping group type.`);
                }
                break;
            default:
                throw new Error(`"${mapping.typeName}" is not a recognised parameter mapping type.`);
        }
    });
}

export default applyCachedValues;