import { Symbols } from "../units/symbols"

export const DefaultUnitSymbols = Object.freeze(
    {
        MetrePerSecondPerSecond: "m/s" + Symbols.Superscript2,
        Foot: "ft",
        Kilometre: "km",
        Metre: "m",
        StatuteMile: "mi",
        NauticalMile: "NM",
        Yard: "yd",
        Kilogramme: "kg",
        Pound: "lb",
        Tonne: "t",
        Kilowatt: "kW",
        Megawatt: "MW",
        Watt: "W",
        Pascal: "Pa",
        Hectopascal: "hPa",
        Kilopascal: "kPa",
        PoundPerSquareInch: "psi",
        MercuryInch: "inHg",
        DegreeCelsius: Symbols.Degree + "C",
        DegreeFahrenheit: Symbols.Degree + "F",
        Kelvin: "K",
        KilometrePerHour: "km/h",
        MetrePerSecond: "m/s",
        MilePerHour: "mph",
        Knot: "kt",
        FootPerMinute: "ft/min",
        Radian: "rad",
        ArcDegree: Symbols.Degree,
        CubicMetre: "m" + Symbols.Superscript3,
        Litre: "L",
        Millilitre: "mL",
        ImperialGallon: "gal",
        USGallon: "USG",
        Second: "s",
        Minute: "min",
        Hour: "hr",
        CubicMetrePerSecond: "m" + Symbols.Superscript3 + "/s",
        USGallonPerHour: "USG/hr",
        LitrePerHour: "L/hr"
    }
);