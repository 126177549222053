/* Multiplies an "adjustment amount" by a fixed fractional value (the "scalar") 
   to determine the proportion of an input value to output.

   For example, if the fractional value is 4 and the received adjustment amount 
   is 3 then an input of 100 will yield an output of 100 - (4 * 3)) = 88.

   Negative adjustment amounts result in outputs that are greater than inputs.
   In the above example, if the adjustment amount is -2 then the output is 
   100 - (4 * -2)) = 108.

   Solves problems like "decrease rate of climb by 20 ft for each 5 degrees above 
   ISA".  In this case the scalar is 20 / 5 = 4, and the adjustment amount is the
   ISA deviation.

   Can also be used to increase inputs.  To achieve this, set asIncreaser to true 
   in the constructor call.

   Works in reverse too.
*/
export class FixedQuantityAdjuster {
    #scalar;
    #asIncreaser;

    constructor(scalar, asIncreaser = false) {
        this.#scalar = Number(scalar);
        this.#asIncreaser = asIncreaser;
    }

    get scalar() {
        return this.#scalar;
    }

    getOutput(input, adjustmentAmount) {
        if (input === null || adjustmentAmount === null) {
            return null;
        }
        if (this.#asIncreaser) {
            adjustmentAmount = -adjustmentAmount;
        }
        return input - (adjustmentAmount * this.#scalar);
    }

    getInput(output, adjustmentAmount) {
        if (output === null || adjustmentAmount === null) {
            return null;
        }
        if (this.#asIncreaser) {
            adjustmentAmount = -adjustmentAmount;
        }
        return output + (adjustmentAmount * this.#scalar);
    }
}