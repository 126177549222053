import styles from "./Cart.module.css";
import commonStyles from "./Common.module.css";
import useCartContext from "../hooks/useCartContext";
import CartItem from "./CartItem";
import CostPresenter from "./CostPresenter";
import { useState, useEffect } from "react";
import { isSomething } from "../lib/common/utilities";
import { ReactComponent as BackArrow } from "../assets/back-arrow.svg";

const getErrorMessage = (result) => {
    let message;
    if (result.error) {
        message = "Database error";
    }
    else if (isSomething(result.alreadyLeased)) {
        message = result.alreadyLeased ? "Already leased" : "No lease found";
    }
    else {
        message = "Unexpected error";
    }
    return message;
};

const Cart = ({ handleBackButtonClick, handleCheckoutComplete }) => {
    const [total, setTotal] = useState(0);
    const [checkoutInProgress, setCheckoutInProgress] = useState(false);
    const [statusMessage, setStatusMessage] = useState(null);
    const [statusMessageClassName, setStatusMessageClassName] = useState(null);
    const [formClassName, setFormClassName] = useState(styles.itemContainer);
    const [itemErrorMessages, setItemErrorMessages] = useState(new Map());
    const { itemCount, cart, remove, removeRange, checkout } = useCartContext();

    useEffect(() => {
        const sum = Array.from(cart.values()).reduce((temp, item) => temp + item.price, 0);
        setTotal(sum);
    }, [cart]);

    useEffect(() => {
        setFormClassName(itemCount > 0 ? styles.itemContainer : `${styles.itemContainer} ${styles.hidden}`);
    }, [itemCount]);

    const clearItemErrorMessages = () => {
        if (itemErrorMessages.size) {
            setItemErrorMessages(new Map());
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setCheckoutInProgress(true);
        setStatusMessage("Please wait...");
        setStatusMessageClassName(styles.statusMessage);
        clearItemErrorMessages();
        
        const result = await checkout();

        setCheckoutInProgress(false);

        // result is either an array of objects (1 per cart item) or a single object
        if (result.succeeded === false) {
            setStatusMessage(result.message);
            setStatusMessageClassName(`${styles.statusMessage} ${styles.error}`);
        }
        else {
            setStatusMessage(null);
            const messageCache = new Map();
            const removeKeys = [];
            result.forEach(r => {
                if (r.succeeded) {
                    removeKeys.push(r.key);
                }
                else {
                    const info = getErrorMessage(r);
                    messageCache.set(r.key, info);
                }
            });
            removeRange(removeKeys);
            if (messageCache.size) {
                setItemErrorMessages(messageCache);
            }
            if (removeKeys.length) {
                handleCheckoutComplete();
            }
        }
    }

    const handleRemoveCartItem = (key) => {
        remove(key);
        const map = new Map(itemErrorMessages);
        if (map.delete(key)) {
            setItemErrorMessages(map);
        }
    };

    return (
        <div className={styles.Cart}>
            <button
                className={`${commonStyles.button} ${styles.backButton}`}
                onClick={handleBackButtonClick}>
                <BackArrow className={styles.backButtonImage} />
            </button>
            
            <h1 className={`${styles.title} ${styles.centre}`}>Cart</h1>
            {itemCount === 0 && <p className={`${styles.message} ${styles.centre}`}>Your cart is empty</p>}
            
            <form className={formClassName} onSubmit={handleSubmit}>
                <ul className={styles.itemList}>
                    {Array.from(cart.entries()).map(([key, value]) => {
                        return <li key={key}>
                            <CartItem id={key} item={value} remove={handleRemoveCartItem} error={itemErrorMessages.get(key)} />
                        </li>
                    })}
                </ul>
                <div className={styles.checkoutDetail}>
                    <div className={styles.amountContainer}>
                        <p>Total:</p>
                        <CostPresenter cost={total} />
                    </div>
                    <button
                        className={`${commonStyles.button} ${styles.checkoutButton}`}
                        type="submit"
                        disabled={checkoutInProgress}>Checkout</button>
                </div>
                {statusMessage && <p className={statusMessageClassName}>{statusMessage}</p>}
            </form>
        </div>
    )
};

export default Cart;