import useAircraftContext from "./useAircraftContext";
import useAccessibleAircraftContext from "./useAccessibleAircraftContext";
import { useState, useEffect } from "react";

const useSoleOrSelectedAircraftId = () => {
    const { aircraft } = useAircraftContext();
    const { aircraftCount, aircraftSummaries } = useAccessibleAircraftContext();

    const [aircraftId, setAircraftId] = useState(null);

    useEffect(() => {
        let id;
        if (aircraft) {
            id = aircraft.id;
        }
        else if (aircraftCount === 1) {
            id = aircraftSummaries[0].id;
        }
        else {
            id = null;
        }
        setAircraftId(id);
    }, [aircraft, aircraftCount, aircraftSummaries]);

    return { aircraftId };
}

export default useSoleOrSelectedAircraftId;