import { createContext, useEffect, useState } from "react";
import usePersistedState from "../hooks/usePersistedState";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { axiosPost } from "../api/axios";

const ITEM_VERSION = 1;
const serialize = (map) => JSON.stringify(Array.from(map));
const deserialize = (value) => new Map(JSON.parse(value));

const CartContext = createContext({});

export const CartProvider = ({ children }) => {
    const [cart, setCart] = usePersistedState("cart", new Map(), serialize, deserialize);
    const [nextKey, setNextKey] = useState(1);
    const [itemCount, setItemCount] = useState(0);
    const axiosPrivate = useAxiosPrivate();
    
    useEffect(() => {
        const size = cart.size;
        setItemCount(size);
        let nk;
        if (size === 0) {
            nk = 0;
        }
        else {
            const keys = Array.from(cart.keys());
            nk = Math.max(...keys) + 1;
        }
        setNextKey(nk);
    }, [cart]);

    const add = (item) => {
        item.version = ITEM_VERSION;
        setCart(map => {
            setNextKey(key => {
                map.set(key, item);
                return key + 1;
            });
            return new Map(map);
        });
        return nextKey;
    };

    const remove = (key) => {
        if (cart.delete(key)) {
            setCart(new Map(cart));
        }
    };

    const removeRange = (keys) => {
        let removed = false;
        keys.forEach(key => removed |= cart.delete(key));
        if (removed) {
            setCart(new Map(cart));
        }
    };

    const checkout = async () => {
        const items = [];
        Array.from(cart.entries()).forEach(([key, item]) => {
            items.push(
                {
                    key: key,
                    type: item.type,
                    productId: item.productId,
                    price: item.price
                }
            );
        });
        if (items.length > 0) {
            let result;
            const onSuccess = (response) => {
                result = response;
            };
            const onError = (err) => {
                let message;
                switch (err.response?.status) {
                    case 400: // Bad request
                        message = "The information was rejected by the server."
                        break;
                    case 401: // Unauthorised
                        message = "Not authorised.";
                        break;
                    case 404:
                        message = "User not found."
                        break;
                    case 500:
                        message = "The server encountered a problem that prevented it from completing the request."
                        break;
                    default:
                        message = "Unknown error.";
                        break;
                }
                result = {
                    succeeded: false,
                    message: message
                };
            }
            await axiosPost(axiosPrivate, "/checkout-cart", items, onSuccess, onError);
            return result;
        }
    };

    return (
        <CartContext.Provider value={{ itemCount, cart, add, remove, removeRange, checkout }}>
            {children}
        </CartContext.Provider>
    )
}

export default CartContext;