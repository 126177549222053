import styles from "./Home.module.css";
import commonStyles from "./Common.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useSoleOrSelectedAircraftId from "../hooks/useSoleOrSelectedAircraftId";
import { useHangarPath } from "../hooks/useHangarPath";
import { ReactComponent as Fast } from "../assets/fast.svg";
import { ReactComponent as Idea } from "../assets/idea.svg";
import { ReactComponent as Goal } from "../assets/goal.svg";
import { ReactComponent as Features } from "../assets/features.svg";

const Home = () => {
    const navigate = useNavigate();
    const { aircraftId } = useSoleOrSelectedAircraftId();
    const actionText = "Start Now";
    const { pathRoot } = useHangarPath();

    useEffect(() => {
        document.title = "Avaid - Home";
    }, []);

    const onStart = () => {
        const root = `/${pathRoot}`;
        const to = aircraftId ? `${root}/${aircraftId}` : root;
        navigate(to);
    };

    const getRandomFontStyle = () => {
        var remSize = Math.random() * 1.5 + 1;
        const style = { "fontSize": `${remSize}rem` };
        if (Math.random() < 0.4) {
            style["fontStyle"] = "italic";
        }
        else if (Math.random() < 0.4) {
            style["fontWeight"] = "bold";
        }
        return style;
    };

    const quotesStock = [
        "By pilots for pilots.",
        "Escape the tyranny of paper-based performance planning.",
        "Encourages planning over assumptions.",
        "What-if scenarios are easy.",
        "Use it on the go – even in flight.",
        "Reams of data in the palm of your hand."
    ];

    const getQuotes = (number) => {
        const maxLength = quotesStock.length;
        if (number > maxLength) {
            number = maxLength;
        }
        const indexes = getRandomQuoteIndexSequence(number);
        const quotes = [];
        indexes.forEach(index => {
            const quote = quotesStock[index];
            quotes.push(quote);
        });
        return quotes;
    };

    const getRandomQuoteIndexSequence = (number) => {
        const ordered = [...Array(quotesStock.length).keys()];
        const sequence = [];
        while (number-- > 0) {
            const index = Math.floor(Math.random() * ordered.length);
            sequence.push(ordered[index]);
            ordered.splice(index, 1);
        }
        return sequence;
    };

    return (
        <section className={styles.Home}>
            <h1 className={styles.title}>Flight performance planning <span className={styles.nowrap}>made easy</span></h1>
            
            <div className={styles.banner}>
                <article className={`${styles.article} ${styles.inverted}`}>
                    <hr className={styles.rule} />
                    <h2>Lay to rest the traditional planning tools</h2>
                    <p>
                        Performance graphs and data tables are a pilot's staple.  Pencil, eraser, ruler
                        and calculator are familiar instruments.  But planning with such tools is <span className={styles.nowrap}>
                        slow, confusing and error-prone</span>.
                    </p>
                    <p>
                        Put those things to one side with Avaid.  Make flight performance planning <strong className={styles.nowrap}>
                        fast, intuitive and consistent</strong>.
                    </p>
                    <button className={`${commonStyles.button} ${styles.startButton}`} type="button" onClick={onStart}>{actionText}</button>
                </article>
            </div>

            <hr className={styles.rule} />

            <div className={styles.features}>
                <article className={styles.feature}>
                    <div className={styles.articleText}>
                        <h3>Fast</h3>
                        <p>
                            Accuracy matters, so performance planning cannot be rushed.  Lines drawn
                            on a graph need to be perpendicular to the axes, starting and ending at
                            the right locations.  Where interpolation is needed then eye-balling the
                            required fix is unlikely to be satisfactory, especially if the output axes
                            are small-scale and will magnify even little drawing errors.
                        </p>
                        <p>
                            Avaid takes care of the complex interim steps, leaving you to enter the
                            starting values.  Results are instantaneous: change any input parameter and
                            the results are updated immediately.
                        </p>
                    </div>
                    <Fast className={styles.icon} />
                </article>

                <article className={styles.feature}>
                    <div className={styles.articleText}>
                        <h3>Intuitive</h3>
                        <p>
                            Graphs and data tables can be daunting, particularly if you haven't used
                            them for a while.  Esoteric rules covering edge-cases are easily forgotten.
                            And complex aircraft sometimes require even more involved techniques for
                            calculating performance.
                        </p>
                        <p>
                            Avaid knows the rules so you don't need to worry about them. For any given
                            calculation you simply enter the starting values.
                        </p>
                    </div>
                    <Idea className={styles.icon} />
                </article>

                <article className={styles.feature}>
                    <div className={styles.articleText}>
                        <h3>Consistent</h3>
                        <p>
                            Traditional methods of performance planning are intrinsically imprecise and 
                            subject to error.  This can lead to inconsistent results.
                        </p>
                        <p>
                            Calculations controlled by software do not suffer from this problem, so with
                            Avaid you will get the same results for the same inputs.
                        </p>
                    </div>
                    <Goal className={styles.icon} />
                </article>

                <article className={styles.feature}>
                    <div className={styles.articleText}>
                        <h3>Key features</h3>
                        <ul>
                            <li>Performance data derived <strong>only from official sources</strong>.</li>
                            <li>Choose from a continually growing database of aircraft.</li>
                            <li>Consistent presentation of inputs and outputs for all aircraft.</li>
                            <li>Enter parameter values step-by-step or directly.</li>
                            <li>Use units of measurement that suit you rather than the manufacturer.</li>
                            <li>Results are calculated "on the fly".</li>
                            <li>Inputs and selections are stored for re-use between sessions and across all devices (for registered users).</li>
                            <li>Request new aircraft.</li>
                            <li>Be involved: send us your feedback.</li>
                        </ul>
                    </div>
                    <Features className={styles.icon} />
                </article>

            </div>

            <hr className={styles.rule} />

            <button className={`${commonStyles.button} ${styles.startButton}`} type="button" onClick={onStart}>{actionText}</button>

            <hr className={styles.rule} />

            <article className={styles.quotes}>
                <ul>
                    {getQuotes(3).map((quote, index) => {
                        return <li key={index} style={getRandomFontStyle()}>
                            <span className={styles.quoteMark}> &#8220;</span>{quote}<span className={styles.quoteMark}>&#8221; </span>
                        </li>
                    })}
                </ul>
            </article>
        </section>
    )
}

export default Home;