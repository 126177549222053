import { NameHint, EmailHint } from "./InputHints";

export const nameOptions = {
    type: "text",
    placeholder: "Name",
    autoComplete: "off",
    maxLength: 20,
    required: true,
    focusWhenAble: true,
    helpHtml: <NameHint />
};

export const emailOptions = {
    type: "text",
    placeholder: "Email",
    autoComplete: "off",
    maxLength: 254,
    required: true,
    focusWhenAble: false,
    helpHtml: <EmailHint />
};