import "../array-extensions";
import { getCurveFunnel } from "./extensions";

export class RangeCurveSet {
    constructor(interpolatorFactory) {
        this.interpolatorFactory = interpolatorFactory;
        this.curveSet = new Map();
    }

    set(rangeValue, curve) {
        this.curveSet.set(rangeValue, curve);
    }

    getCurve(rangeValue) {
        const keys = this.#getOrderedRangeValues();
        
        let low, high;
        for(const key of keys) {
            if (key <= rangeValue) {
                low = key;
            }
            else {
                break;
            }
        }
        for(const key of keys.reverse()) {
            if (key >= rangeValue) {
                high = key;
            }
            else {
                break;
            }
        }
        if (low !== undefined && high !== undefined) {
            const curveSet = this.curveSet;
            let curve;
            if (low === high) {
                curve = curveSet.get(rangeValue);
            }
            else {
                const uc = curveSet.get(high);
                const lc = curveSet.get(low);
                const fractionalDistance = (rangeValue - low) / (high - low);
                curve = lc.createInterpolatedCurveByFractionalDistance(uc, fractionalDistance, this.interpolatorFactory);
            }
            return curve;
        }
        return null;
    }

    getRangeValue(point) {
        const curves = this.#getCurvesOrderedByRangeValue();
        const funnel = getCurveFunnel(curves, point);
        const uc = funnel.upperCurve;
        const lc = funnel.lowerCurve;
        if (uc === undefined || lc === undefined) {
            return null;
        }
        const upperRangeValue = this.#getRegisteredRangeValue(uc);
        const lowerRangeValue = this.#getRegisteredRangeValue(lc);
        let result;
        if (upperRangeValue === lowerRangeValue) {
            result = lowerRangeValue;
        }
        else {
            const px = point.x;
            const uy = uc.getY(px);
            const ly = lc.getY(px);
            const f = (point.y - ly) / (uy - ly);
            result = lowerRangeValue + f * (upperRangeValue - lowerRangeValue);
        }
        return result;
    }

    #getRegisteredRangeValue(curve) {
        for (const [key, value] of this.curveSet.entries()) {
            if (value === curve) {
                return key;
            }
        }
        throw new Error("Curve not registered.");
    }

    #getOrderedRangeValues() {
        return Array.from(this.curveSet.keys()).order();
    }

    #getCurvesOrderedByRangeValue() {
        let curves = [];
        this.#getOrderedRangeValues().forEach(k => {
            curves.push(this.curveSet.get(k));
        });
        return curves;
    }
}