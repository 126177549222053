import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { Adder } from "../calculators/adder";

export class AdderTransform extends Transform {
    #adder = new Adder();
    
    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterIn1Name, this.id),
            new Parameter(ParameterNames.ParameterIn2Name, this.id),
            new Parameter(ParameterNames.ParameterOutName, this.id)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const count = this._setParameterValueCount;
        if (count === 2) {
            const outputParameterName = this.getUnsetParameterNames()[0];
            let result;
            switch (outputParameterName) {
                case ParameterNames.ParameterIn1Name:
                    result = this.#calculateInput1();
                    break;
                case ParameterNames.ParameterIn2Name:
                    result = this.#calculateInput2();
                    break;
                case ParameterNames.ParameterOutName:
                    result = this.#calculateOutput();
                    break;
                default:
                    throw new Error(`Unknown output parameter: '${outputParameterName}'.`);
            }
            this._emitParameterValue(outputParameterName, result);
        }
        else if (count > 2) {
            this._throwCannotIdentifyOutputParameters();
        }
    }

    #calculateInput1() {
        const output = this._getParameterValue(ParameterNames.ParameterOutName);
        const input = this._getParameterValue(ParameterNames.ParameterIn2Name);
        return this.#adder.getOtherInput(input, output);
    }

    #calculateInput2() {
        const output = this._getParameterValue(ParameterNames.ParameterOutName);
        const input = this._getParameterValue(ParameterNames.ParameterIn1Name);
        return this.#adder.getOtherInput(input, output);
    }

    #calculateOutput() {
        const input1 = this._getParameterValue(ParameterNames.ParameterIn1Name);
        const input2 = this._getParameterValue(ParameterNames.ParameterIn2Name);
        return this.#adder.getOutput(input1, input2);
    }
}