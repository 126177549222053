import { createContext, useCallback } from "react";
import useAuthContext from "../hooks/useAuthContext";
import { useAuthorisedApiGet } from "../hooks/useApi";
import { isSomething } from "../lib/common/utilities";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { axiosPut } from "../api/axios";

const AdminContext = createContext({});

export const AdminProvider = ({ children }) => {
    const { auth } = useAuthContext();
    const axiosPrivate = useAxiosPrivate();

    const logLevelInfo = useAuthorisedApiGet("/admin/log-levels", auth.accessToken, null, null, isSomething(auth.accessToken));

    const setLogLevel = useCallback(async (level) => {
        let result;
        const onSuccess = () => {
            result = { succeeded: true };
        };
        const onError = (err) => {
            let message;
            switch (err.response.status) {
                case 400: // Bad request
                    message = "The new information was rejected by the server."
                    break;
                case 401: // Unauthorised
                    message = "Not authorised.";
                    break;
                default:
                    message = "Unknown error.";
                    break;
            }
            result = {
                succeeded: false,
                message: message
            };
        }
        const url = `/admin/selected-log-level/${level}`;
        await axiosPut(axiosPrivate, url, {}, onSuccess, onError);
        return result;
    }, [axiosPrivate]);

    return (
        <AdminContext.Provider value={{ logLevelInfo, setLogLevel }}>
            {children}
        </AdminContext.Provider>
    )
}

export default AdminContext;