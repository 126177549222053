export class TableUtilities {
    static getAdjacentValues(value, orderedValues, reverseOrderedValues = null) {
        if (orderedValues === null) {
            throw new Error("orderedValues cannot be null.");
        }
        reverseOrderedValues = reverseOrderedValues ?? orderedValues.slice().reverse();
        let low = null;
        let high = null;

        orderedValues.every(v => {
            const result = v < value;
            if (result) {
                low = v;
            }
            return result;
        });
        reverseOrderedValues.every(v => {
            const result = v > value;
            if (result) {
                high = v;
            }
            return result;
        });
        // Either lowValue or highValue may be null
        return {
            lowValue: low,
            highValue: high
        }
    }

    static mergeRows(highRow, lowRow, proportion) {
        const lowRowCache = new Map();
        lowRow.forEach(tv => lowRowCache.set(tv.key, tv));
        
        const row = [];
        highRow.forEach(htv => {
            let tv;
            const highKey = htv.key;
            const ltv = lowRowCache.get(highKey);
            if (htv.undefined || ltv.undefined) {
                tv = highKey.createUndefinedValue();
            }
            else {
                const lowValue = ltv.value;
                const newValue = (htv.value - lowValue) * proportion + lowValue;
                tv = highKey.createValue(newValue);
            }
            row.push(tv);
        });
        return row;
    }

    static createUndefinedRow(tableKeys) {
        const row = [];
        tableKeys.forEach(key => row.push(key.createUndefinedValue()));
        return row;
    }
}