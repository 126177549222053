import { areSomething } from "../common/utilities";

export class Adder {
    getOutput(input1, input2) {
        return areSomething(input1, input2) ? input1 + input2 : null;
    }

    getOtherInput(input, output) {
        return areSomething(input, output) ? output - input : null;
    }
}