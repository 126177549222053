import styles from "./UserEntry.module.css";
import Login from "./Login";
import Register from "./Register";
import Tabs from "./Tabs";
import { useEffect } from "react";

const UserEntry = ({ path }) => {
    const signInLabel = "Sign In";
    const registerLabel = "Register";

    let initialTabLabel;
    switch (path) {
        case "login":
            initialTabLabel = signInLabel;
            break;
        case "register":
            initialTabLabel = registerLabel;
            break;
        default:
            initialTabLabel = null;
            break;
    }

    useEffect(() => {
        document.title = "Avaid - Sign In";
    }, []);
    
    return <section className={styles.UserEntry}>
        <Tabs initialTabLabel={initialTabLabel}>
            <Login label={signInLabel} />
            <Register label={registerLabel} />
        </Tabs>
    </section>
}

export default UserEntry;