//#region Imports

import "../../array-extensions";
import { areSomething, isSomething } from "../../common/utilities";
import { isArrayWithAtLeastOneElement, getElementCount } from "../../array-extensions";
import { ensureProperties, ensureProperty } from "../builder-helper";
import { TransformGroup } from "../../transforms/transform-group";
import { Curve } from "../../graphs/curve";
import { Point } from "../../graphs/point";
import { CurveTransform } from "../../graphs/curve-transform";
import { LinearInterpolatorFactory } from "../../graphs/linear-interpolator-factory";
import { CurveSet } from "../../graphs/curve-set";
import { CurveSetBoundaryPolicy } from "../../graphs/curve-set-boundary-policy";
import { CurveSetTransform } from "../../graphs/curve-set-transform";
import { CurveSelectionMethod } from "../../graphs/curve-selection-method";
import { RangeCurveSet } from "../../graphs/range-curve-set";
import { RangeCurveSetTransform } from "../../graphs/range-curve-set-transform";
import { DetentCurveSet } from "../../graphs/detent-curve-set";
import { DetentCurveSetTransform } from "../../graphs/detent-curve-set-transform";
import { RangeCurveSetGroup } from "../../graphs/range-curve-set-group";
import { RangeCurveSetGroupTransform } from "../../graphs/range-curve-set-group-transform";
import { RangeCurveTwinSet } from "../../graphs/range-curve-twin-set";
import { RangeCurveTwinSetTransform } from "../../graphs/range-curve-twin-set-transform";
import { OneToManyCurveSet } from "../../graphs/one-to-many-curve-set";
import { OneToManyCurveSetTransform } from "../../graphs/one-to-many-curve-set-transform";
import { OptionalFactorTransform } from "../../calculators/optional-factor-transform";
import { OptionalScaledFactor } from "../../calculators/optional-scaled-factor";
import { OptionalScaledFactorTransform } from "../../calculators/optional-scaled-factor-transform";
import { OptionalFixedFactorTransform } from "../../calculators/optional-fixed-factor-transform";
import { ParameterMappingGroup } from "../../transforms/parameter-mapping-group";
import { UnitParameterMapping } from "../../transforms/unit-parameter-mapping";
import { UnitMultiParameterMapping } from "../../transforms/unit-multi-parameter-mapping";
import { NumericParameterMapping } from "../../transforms/numeric-parameter-mapping";
import { NumericMultiParameterMapping } from "../../transforms/numeric-multi-parameter-mapping";
import { UnitBase } from "../../units/units";
import { Detent } from "../../transforms/detent";
import { BooleanParameterMapping } from "../../transforms/boolean-parameter-mapping";
import { BooleanMultiParameterMapping } from "../../transforms/boolean-multi-parameter-mapping";
import { PerformanceCategory } from "./performance-category";
import { Operation } from "./operation";
import { ParameterCategory } from "./parameter-category";
import { TableContainer } from "../../tables/table-container";
import { TableTransform } from "../../tables/table-transform";
import { TableKey } from "../../tables/table-key";
import { QuantityAdjuster } from "../../calculators/quantity-adjuster";
import { QuantityAdjusterTransform } from "../../calculators/quantity-adjuster-transform";
import { FixedQuantityAdjuster } from "../../calculators/fixed-quantity-adjuster";
import { FixedQuantityAdjusterTransform } from "../../calculators/fixed-quantity-adjuster-transform";
import { NullOpTransform } from "../../transforms/null-op-transform";
import { AdderTransform } from "../../calculators/adder-transform";
import { LessThanZero } from "../../transforms/predicates/less-than-zero";
import { LessThanOrEqualToZero } from "../../transforms/predicates/less-than-or-equal-to-zero";
import { GreaterThanOrEqualToZero } from "../../transforms/predicates/greater-than-or-equal-to-zero";
import { GreaterThanZero } from "../../transforms/predicates/greater-than-zero";
import { IsaDifferenceTransform } from "../../calculators/isa-difference-transform";
import { Guard } from "../../logic/guard";
import { GuardTransform } from "../../logic/guard-transform";
import { SetToZero } from "../../transforms/adjusters/set-to-zero";
import { OptionalAdderTransform } from "../../calculators/optional-adder-transform";
import { PressureAltitudeCalculatorTransform } from "../../calculators/pressure-altitude-calculator-transform";
import { ObstacleClearanceCalculatorTransform } from "../../calculators/obstacle-clearance-calculator-transform";
import { ConditionalRouterTransform } from "../../logic/conditional-router-transform";

//#endregion

export class BuilderBase {
    #metadata;
    
    constructor(metadata) {
        this.#metadata = metadata;
    }

    _mustHaveAtLeastOneElement(messageLog, arr, errorMessage) {
        const result = isArrayWithAtLeastOneElement(arr);
        if (!result) {
            messageLog.addError(errorMessage);
        }
        return result;
    }

    _getMetadataById(messageLog, propertyName, id, node = null, nodeName = null) {
        node ??= this.#metadata;
        if (ensureProperty(messageLog, propertyName, node, nodeName)) {
            const children = node[propertyName];
            if (this._mustHaveAtLeastOneElement(messageLog, children, `Node '${propertyName}' is either not an array or an empty array.`)) {
                return children.single(child => child.id === id);
            }
        }
    }

    _getMetadataByIndex(messageLog, propertyName, index, node = null, nodeName = null) {
        node ??= this.#metadata;
        if (ensureProperty(messageLog, propertyName, node, nodeName)) {
            const children = node[propertyName];
            if (getElementCount(children) > index) {
                return children[index];
            }
            messageLog.addError(`Node '${propertyName}' is either not an array or has an insufficient number of elements to return the element at index ${index}.`);
        }
    }

    _getMetadataByPropertyName(messageLog, propertyName, node = null, nodeName = null) {
        node ??= this.#metadata;
        if (ensureProperty(messageLog, propertyName, node, nodeName)) {
            return node[propertyName];
        }
    }

    _getOptionalMetadataByPropertyName(propertyName, node = null) {
        node ??= this.#metadata;
        return node.hasOwnProperty(propertyName) ? node[propertyName] : undefined;
    }
}

export class PerformanceCategoryBuilder extends BuilderBase {
    static #interpolatorFactory = new LinearInterpolatorFactory();

    //#region String Checks

    static #checkEmptyString(messageLog, value, errorMessage) {
        const result = value.length > 0;
        if (!result) {
            messageLog.addError(errorMessage);
        }
        return result;
    }

    static #checkCategoryName(messageLog, value) {
        return this.#checkEmptyString(messageLog, value, "Category name cannot be empty.");
    }

    static #checkOperationName(messageLog, value) {
        return this.#checkEmptyString(messageLog, value, "Operation name cannot be empty.");
    }

    static #checkOperationDescription(messageLog, value) {
        return this.#checkEmptyString(messageLog, value, "Operation description cannot be empty.");
    }

    //#endregion

    createCategories(messageLog) {
        const categories = [];
        const categoriesMetadata = this._getMetadataByPropertyName(messageLog, "performanceCategories");
        if (isArrayWithAtLeastOneElement(categoriesMetadata)) {
            let index = 0;
            categoriesMetadata.forEach(categoryMetadata => {
                const nodeName = `performanceCategories[${index++}]`;
                const category = this.#createCategory(messageLog, categoryMetadata, nodeName);
                if (category) {
                    categories.push(category);
                }
            });
        }
        else {
            messageLog.addWarning("No performance categories have been defined.");
        }
        return categories;
    }

    #createCategory(messageLog, categoryMetadata, nodeName) {
        if (ensureProperties(messageLog, categoryMetadata, nodeName, "name", "operationIds")) {
            const name = categoryMetadata.name.trim();
            if (PerformanceCategoryBuilder.#checkCategoryName(messageLog, name) && 
                this._mustHaveAtLeastOneElement(messageLog, categoryMetadata.operationIds, `No operations have been defined for ${nodeName}.`)) {

                const category = new PerformanceCategory(name);
                this.#setOperations(messageLog, category, categoryMetadata.operationIds);
                return category;
            }
        }
    }

    #setOperations(messageLog, category, operationIds) {
        operationIds.forEach(id => {
            const operationMetadata = this._getMetadataById(messageLog, "operations", id);
            if (operationMetadata) {
                const nodeName = `operation.id = ${id}`;
                this.#setOperation(messageLog, category, operationMetadata, nodeName);
            }
        });
    }

    #setOperation(messageLog, category, operationMetadata, nodeName) {
        if (ensureProperties(messageLog, operationMetadata, nodeName, "name", "description", "transformGroupId", "outputParameterMappingIds")) {
            const name = operationMetadata.name.trim();
            const description = operationMetadata.description.trim();
            if (PerformanceCategoryBuilder.#checkOperationName(messageLog, name) &&
                PerformanceCategoryBuilder.#checkOperationDescription(messageLog, description) &&
                this._mustHaveAtLeastOneElement(messageLog, operationMetadata.outputParameterMappingIds, `No output parameter mappings have been defined for ${nodeName}.`)) {
                
                const operation = new Operation(name, description);
                this.#hydrateOperation(messageLog, operation, operationMetadata.transformGroupId, operationMetadata.outputParameterMappingIds);
                category._addOperation(operation);
                this.#addNotes(messageLog, operation, operationMetadata.id);
                operation.pushAllInputParameterValues();
            }
        }
    }

    #addNotes(messageLog, operation, operationId) {
        const notesMetadata = this._getOptionalMetadataByPropertyName("notes");
        if (notesMetadata && Array.isArray(notesMetadata)) {
            let index = 0;
            const nodeName = `notes[${index++}]`;
            notesMetadata.forEach(noteMetadata => {
                if (ensureProperty(messageLog, "operationIds", noteMetadata, nodeName)) {
                    const operationIds = noteMetadata.operationIds;
                    if (Array.isArray(operationIds) && operationIds.includes(operationId)) {
                        const note = noteMetadata.note;
                        if (note) {
                            operation._addNote(note);
                        }
                    }
                }
            });
        }
    }

    #hydrateOperation(messageLog, operation, transformGroupId, outputParameterMappingIds) {
        const transformGroupMetadata = this._getMetadataById(messageLog, "transformGroups", transformGroupId);
        if (transformGroupMetadata) {
            const nodeName = `transformGroup.id = ${transformGroupId}`;
            const info = this.#createTransformGroup(messageLog, transformGroupMetadata, nodeName);
            const group = info.group;
            const transformCache = info.transformCache;
            if (group) {
                operation._setTransformGroup(group);
            }
            if (transformCache) {
                this.#setParameterCategories(messageLog, operation, transformCache, outputParameterMappingIds);
            }
        }
    }

    #setParameterCategories(messageLog, operation, transformCache, outputParameterMappingIds) {
        // Create a new input category cache for each new operation
        const inputCategoryCache = this.#createInputParameterCategoryCache(messageLog);
        const transformIds = Array.from(transformCache.keys());
        const associatedParameterMappingIds = this.#getParameterMappingIds(messageLog, transformIds, operation);
        const parameterMappingCache = this.#createParameterMappingCache(messageLog, associatedParameterMappingIds, transformCache);
        const parameterMappingGroupsMetadata = this._getOptionalMetadataByPropertyName("parameterMappingGroups");
        const groupMappingIds = new Set();
        let duplicateIdsNotReported = true;

        if (parameterMappingGroupsMetadata && Array.isArray(parameterMappingGroupsMetadata)) {
            let index = 0;
            parameterMappingGroupsMetadata.forEach(parameterMappingGroupMetadata => {
                const nodeName = `parameterMappingGroups[${index++}]`;
                const groupParameterMappingIds = PerformanceCategoryBuilder.#getMappingGroupParameterIds(messageLog, parameterMappingGroupMetadata, nodeName);
                if (groupParameterMappingIds) {
                    const commonIds = new Set(groupParameterMappingIds.intersect(associatedParameterMappingIds));
                    if (commonIds.size === groupParameterMappingIds.length) {
                        // The parameter mapping group is to be included in the operation
                        const mappingGroup = PerformanceCategoryBuilder.#createParameterMappingGroup(messageLog, parameterMappingGroupMetadata, parameterMappingCache, nodeName);
                        const inputParameterCategoryId = parameterMappingGroupMetadata.inputParameterCategoryId;
                        const parameterCategory = inputCategoryCache.get(inputParameterCategoryId);
                        if (parameterCategory) {
                            parameterCategory._addParameterMapping(mappingGroup);
                        }
                        else {
                            messageLog.addError(`${nodeName} has an input parameter category id, '${inputParameterCategoryId}', that does not map to an input parameter category.`);
                        }
                    }
                    else if (commonIds.length > 0) {
                        messageLog.addError(`${nodeName} cross-references parameter mapping ids, not all of which are associated with the transforms for operation '${operation.name}'.`);
                    }
                    commonIds.forEach(id => {
                        if (duplicateIdsNotReported && groupMappingIds.has(id)) {
                            duplicateIdsNotReported = false;
                            messageLog.addError(`Some parameter mapping groups associated with operation '${operation.name}' are referencing the same parameter mappings.`);
                        }
                        groupMappingIds.add(id);
                    });
                }
            });
        }
        // Get the ids of the parameter mappings that aren't associated with parameter mapping groups
        const remainingParameterMappingIds = associatedParameterMappingIds.except(Array.from(groupMappingIds));
        remainingParameterMappingIds.forEach(id => {
            if (!outputParameterMappingIds.includes(id)) {
                const parameterMapping = parameterMappingCache.get(id);
                // If parameterMapping is undefined it is because the parameter mapping associated with 
                // the supplied id is "fixed"(and therefore excluded from the cache).
                if (parameterMapping) {
                    const parameterMappingMetadata = this._getMetadataById(messageLog, "parameterMappings", id);
                    const inputParameterCategoryId = parameterMappingMetadata.inputParameterCategoryId;
                    const parameterCategory = inputCategoryCache.get(inputParameterCategoryId);
                    if (parameterCategory) {
                        parameterCategory._addParameterMapping(parameterMapping);
                    }
                    else {
                        const nodeName = `parameterMapping.id = ${id}`;
                        messageLog.addError(`${nodeName} has an input parameter category id, '${inputParameterCategoryId}', that does not map to an input parameter category.`);
                    }
                }
            }
        });
        for (let category of inputCategoryCache.values()) {
            if (category.getParameterMappingCount() > 0) {
                operation._addInputParameterCategory(category);
            }
        }
        const outputCategory = new ParameterCategory("Outputs");
        outputParameterMappingIds.forEach(id => {
            const parameterMapping = parameterMappingCache.get(id);
            if (isSomething(parameterMapping)) {
                outputCategory._addParameterMapping(parameterMapping);
            }
            else {
                messageLog.addError(`An output parameter mapping with id = ${id} is not defined.`);
            }
        });
        operation._setOutputParameterCategory(outputCategory);
    }

    #createInputParameterCategoryCache(messageLog) {
        const categoryCache = new Map();
        const inputCategoriesMetadata = this._getMetadataByPropertyName(messageLog, "inputParameterCategories");
        if (isArrayWithAtLeastOneElement(inputCategoriesMetadata)) {
            let index = 0;
            inputCategoriesMetadata.forEach(categoryMetadata => {
                const nodeName = `inputParameterCategories[${index++}]`;
                if (ensureProperties(messageLog, categoryMetadata, nodeName, "id", "name", "sequence")) {
                    const category = new ParameterCategory(categoryMetadata.name, categoryMetadata.sequence);
                    categoryCache.set(categoryMetadata.id, category);
                }
            });
        }
        else {
            messageLog.addWarning("No input parameter categories have been defined.");
        }
        return categoryCache;
    }

    #createParameterMappingCache(messageLog, parameterMappingIds, transformCache) {
        const parameterMappingCache = new Map();
        parameterMappingIds.forEach(id => {
            const parameterMappingMetadata = this._getMetadataById(messageLog, "parameterMappings", id);
            if (parameterMappingMetadata) {
                const nodeName = `parameterMapping.id = ${id}`;
                const parameterMapping = this.#createParameterMapping(messageLog, parameterMappingMetadata, nodeName, transformCache);
                // If the parameter is "fixed" then its value is not intended to be changed,
                // so at this point it has served its purpose and is not included in the cache.
                if (parameterMapping) {
                    if (parameterMappingMetadata.isFixed === true) {
                        parameterMapping.pushValue();
                    }
                    else {
                        parameterMappingCache.set(id, parameterMapping);
                    }
                }
            }
        });
        return parameterMappingCache;
    }

    #createParameterMapping(messageLog, parameterMappingMetadata, nodeName, transformCache) {
        if (ensureProperty(messageLog, "mappingName", parameterMappingMetadata, nodeName)) {
            let parameterMapping;

            switch (parameterMappingMetadata.type) {
                case "BooleanMultiParameterMapping":
                case "NumericMultiParameterMapping":
                case "UnitMultiParameterMapping":
                    parameterMapping = this.#createMultiParameterMapping(messageLog, parameterMappingMetadata, transformCache, nodeName);
                    break;
                default:
                    parameterMapping = this.#createSingleParameterMapping(messageLog, parameterMappingMetadata, transformCache, nodeName);
                    break;
            }
            const sequence = parameterMappingMetadata.sequence;
            if (isSomething(sequence)) {
                parameterMapping.sequence = sequence;
            }
            return parameterMapping;
        }
    }

    #createMultiParameterMapping(messageLog, parameterMappingMetadata, transformCache, nodeName) {
        const parametersMetadata = this._getMetadataByPropertyName(messageLog, "parameters", parameterMappingMetadata, nodeName);
        if (parametersMetadata && Array.isArray(parametersMetadata)) {
            const transforms = parametersMetadata.map(parameterMetadata => parameterMetadata.transformId).map(id => transformCache.get(id));
            let parameterMapping;
            let isUnitMapping = false;
            switch (parameterMappingMetadata.type) {
                case "BooleanMultiParameterMapping":
                    parameterMapping = new BooleanMultiParameterMapping(parameterMappingMetadata.mappingName);
                    PerformanceCategoryBuilder.#setInitialValue(parameterMappingMetadata, parameterMapping);
                    break;
                case "NumericMultiParameterMapping":
                    parameterMapping = new NumericMultiParameterMapping(
                        parameterMappingMetadata.mappingName,
                        parameterMappingMetadata.minimum,
                        parameterMappingMetadata.maximum,
                        parameterMappingMetadata.step);
                    PerformanceCategoryBuilder.#setInitialValue(parameterMappingMetadata, parameterMapping);
                    break;
                case "UnitMultiParameterMapping":
                    isUnitMapping = true;
                    parameterMapping = PerformanceCategoryBuilder.#createUnitMultiParameterMapping(messageLog, transforms, parameterMappingMetadata, nodeName);
                    const convertValue = initialValue => UnitBase.create(parameterMapping.mappingType, initialValue);
                    PerformanceCategoryBuilder.#setInitialValue(parameterMappingMetadata, parameterMapping, convertValue);
                    break;
                default:
                    messageLog.addError(`Parameter mapping type '${parameterMappingMetadata.type}' is not recognised.`);
                    break;
            }
            if (parameterMapping) {
                parametersMetadata.forEach(parameterMetadata => {
                    const transformId = parameterMetadata.transformId;
                    const parameterName = parameterMetadata.parameterName;
                    const transform = transformCache.get(transformId);
                    const parameter = transform.getParameter(parameterName);
                    if (isUnitMapping) {
                        const factor = isSomething(parameterMetadata.factor) ? parameterMetadata.factor : null;
                        const offset = isSomething(parameterMetadata.offset) ? parameterMetadata.offset : null;
                        parameterMapping.add(parameter, factor, offset);
                    }
                    else {
                        parameterMapping.add(parameter);
                    }
                });
            }
            return parameterMapping;
        }
    }

    #createSingleParameterMapping(messageLog, parameterMappingMetadata, transformCache, nodeName) {
        if (ensureProperties(messageLog, parameterMappingMetadata, nodeName, "transformId", "parameterName")) {
            const transform = transformCache.get(parameterMappingMetadata.transformId);
            const parameter = transform.getParameter(parameterMappingMetadata.parameterName);
            let parameterMapping;
            let convertValue;
            switch (parameterMappingMetadata.type) {
                case "UnitParameterMapping":
                    parameterMapping = PerformanceCategoryBuilder.#createUnitParameterMapping(messageLog, parameter, transform, parameterMappingMetadata, nodeName);
                    convertValue = initialValue => UnitBase.create(parameterMapping.mappingType, initialValue);
                    break;
                case "NumericParameterMapping":
                    parameterMapping = new NumericParameterMapping(
                        parameterMappingMetadata.mappingName,
                        parameter,
                        parameterMappingMetadata.minimum,
                        parameterMappingMetadata.maximum,
                        parameterMappingMetadata.step);
                    break;
                case "BooleanParameterMapping":
                    parameterMapping = new BooleanParameterMapping(parameterMappingMetadata.mappingName, parameter);
                    break;
                default:
                    messageLog.addError(`Parameter mapping type '${parameterMappingMetadata.type}' is not recognised.`);
                    break;
            }
            PerformanceCategoryBuilder.#setInitialValue(parameterMappingMetadata, parameterMapping, convertValue);
            return parameterMapping;
        }
    }

    static #setInitialValue(parameterMappingMetadata, parameterMapping, convertValue = undefined) {
        let initialValue = parameterMappingMetadata.initialValue;
        if (isSomething(initialValue)) {
            initialValue = convertValue ? convertValue(initialValue) : initialValue;
            parameterMapping.setMappingValue(initialValue, false);
        }
    }

    static #getUnitParameterMappingConstructorArgs(parameterMappingMetadata) {
        const quantityType = UnitBase.getUnitTypeFromName(parameterMappingMetadata.quantityType);
        const mappingType = UnitBase.getUnitTypeFromName(parameterMappingMetadata.mappingType);
        const minimumUnit = isSomething(parameterMappingMetadata.minimum) ? UnitBase.create(mappingType, parameterMappingMetadata.minimum) : null;
        const maximumUnit = isSomething(parameterMappingMetadata.maximum) ? UnitBase.create(mappingType, parameterMappingMetadata.maximum) : null;
        return {
            quantityType: quantityType,
            mappingType: mappingType,
            minimumUnit: minimumUnit,
            maximumUnit: maximumUnit
        };
    }

    static #createUnitParameterMapping(messageLog, parameter, transform, parameterMappingMetadata, nodeName) {
        if (ensureProperties(messageLog, parameterMappingMetadata, nodeName, "quantityType", "mappingType")) {
            const args = PerformanceCategoryBuilder.#getUnitParameterMappingConstructorArgs(parameterMappingMetadata);
            const parameterMapping = new UnitParameterMapping(parameterMappingMetadata.mappingName, parameter, args.quantityType, args.mappingType, args.minimumUnit, args.maximumUnit);
            PerformanceCategoryBuilder.#configureUnitParameterMapping(messageLog, parameterMapping, parameterMappingMetadata, [transform], nodeName);
            return parameterMapping;
        }
    }

    static #createUnitMultiParameterMapping(messageLog, transforms, parameterMappingMetadata, nodeName) {
        if (ensureProperties(messageLog, parameterMappingMetadata, nodeName, "quantityType", "mappingType")) {
            const args = PerformanceCategoryBuilder.#getUnitParameterMappingConstructorArgs(parameterMappingMetadata);
            const parameterMapping = new UnitMultiParameterMapping(parameterMappingMetadata.mappingName, args.quantityType, args.mappingType, args.minimumUnit, args.maximumUnit);
            PerformanceCategoryBuilder.#configureUnitParameterMapping(messageLog, parameterMapping, parameterMappingMetadata, transforms, nodeName);
            return parameterMapping;
        }
    }

    static #configureUnitParameterMapping(messageLog, parameterMapping, parameterMappingMetadata, transforms, nodeName) {
        const mappingFactor = parameterMappingMetadata.mappingFactor;
        if (isSomething(mappingFactor)) {
            parameterMapping.setMappingFactor(mappingFactor);
        }
        const mappingOffset = parameterMappingMetadata.mappingOffset;
        if (isSomething(mappingOffset)) {
            parameterMapping.mappingOffset = mappingOffset;
        }
        const precision = parameterMappingMetadata.precision;
        if (isSomething(precision)) {
            parameterMapping.precision = precision;
        }
        const step = parameterMappingMetadata.step;
        if (isSomething(step)) {
            parameterMapping.step = step;
        }
        if (parameterMappingMetadata.hasDetents === true) {
            const detents = parameterMappingMetadata.detents;
            if (isArrayWithAtLeastOneElement(detents)) {
                let valid = true;
                transforms.forEach(transform => {
                    if (transform.getDetents) { // The transform supports a function named "getDetents"
                        const transformDetents = transform.getDetents();
                        detents.forEach(detent => {
                            if (!transformDetents.any(value => value === detent.value)) {
                                valid = false;
                                messageLog.addError(`A detent labelled '${detent.label}' has a value that cannot be matched with any of the associated transform's detents.`);
                            }
                        });
                    }
                });
                if (valid) {
                    const mappingDetents = detents.map(d => {
                        const value = UnitBase.create(parameterMapping.quantityType, d.value);
                        return new Detent(d.label, value);
                    });
                    parameterMapping.setDetents(mappingDetents);
                }
            }
            else {
                messageLog.addError(`${nodeName} is flagged as having detents but none has been defined.`);
            }
        }
    }

    static #createParameterMappingGroup(messageLog, parameterMappingGroupMetadata, parameterMappingCache, nodeName) {
        ensureProperties(messageLog, parameterMappingGroupMetadata, nodeName, "type", "name", "description");
        const parameterMappingGroup = new ParameterMappingGroup(
            parameterMappingGroupMetadata.type,
            parameterMappingGroupMetadata.name,
            parameterMappingGroupMetadata.description);
        
        const sequence = parameterMappingGroupMetadata.sequence;
        if (isSomething(sequence)) {
            parameterMappingGroup.sequence = sequence;
        }
        
        /* If parameterMappingCache does not contain an id it is because the associated parameter is "fixed" 
           (and therefore excluded from the cache).  Parameter mappings associated with groups must all be 
           able to be changed by the user.
        */
        let referencesFixedParameterMappings = false;
        switch (parameterMappingGroupMetadata.type) {
            case "OptionalFactorParameterGroup":
                {
                    const pm1 = parameterMappingCache.get(parameterMappingGroupMetadata.enabledParameterMappingId);
                    const pm2 = parameterMappingCache.get(parameterMappingGroupMetadata.factorParameterMappingId);
                    if (areSomething(pm1, pm2)) {
                        parameterMappingGroup._register("Enabled", pm1);
                        parameterMappingGroup._register("Factor", pm2);
                    }
                    else {
                        referencesFixedParameterMappings = true;
                    }
                }
                break;
            case "OptionalScaledFactorParameterGroup":
                {
                    const pm1 = parameterMappingCache.get(parameterMappingGroupMetadata.enabledParameterMappingId);
                    const pm2 = parameterMappingCache.get(parameterMappingGroupMetadata.amountParameterMappingId);
                    const pm3 = parameterMappingCache.get(parameterMappingGroupMetadata.factorParameterMappingId);
                    const pm4 = parameterMappingCache.get(parameterMappingGroupMetadata.divisorParameterMappingId);
                    if (areSomething(pm1, pm2, pm3, pm4)) {
                        parameterMappingGroup._register("Enabled", pm1);
                        parameterMappingGroup._register("Amount", pm2);
                        parameterMappingGroup._register("Factor", pm3);
                        parameterMappingGroup._register("Divisor", pm4);
                    }
                    else {
                        referencesFixedParameterMappings = true;
                    }
                }
                break;
            default:
                messageLog.addError(`"${parameterMappingGroupMetadata.type}" is not a recognised parameter mapping group type.`);
        }
        if (referencesFixedParameterMappings === true) {
            messageLog.addError(`One or more parameter mappings referenced by ${nodeName} are "fixed".`);
        }
        return parameterMappingGroup;
    }

    static #getMappingGroupParameterIds(messageLog, parameterMappingGroupMetadata, nodeName) {
        const groupParameterMappingIds = [];
        switch (parameterMappingGroupMetadata.type) {
            case "OptionalFactorParameterGroup":
                if (ensureProperties(messageLog, parameterMappingGroupMetadata, nodeName, "enabledParameterMappingId", "factorParameterMappingId")) {
                    groupParameterMappingIds.push(
                        parameterMappingGroupMetadata.enabledParameterMappingId,
                        parameterMappingGroupMetadata.factorParameterMappingId
                    );
                }
                break;
            case "OptionalScaledFactorParameterGroup":
                if (ensureProperties(messageLog, parameterMappingGroupMetadata, nodeName, "enabledParameterMappingId", "amountParameterMappingId", "factorParameterMappingId", "divisorParameterMappingId")) {
                    groupParameterMappingIds.push(
                        parameterMappingGroupMetadata.enabledParameterMappingId,
                        parameterMappingGroupMetadata.amountParameterMappingId,
                        parameterMappingGroupMetadata.factorParameterMappingId,
                        parameterMappingGroupMetadata.divisorParameterMappingId
                    );
                }
                break;
            default:
                messageLog.addError(`Parameter mapping group type '${parameterMappingGroupMetadata.type}' is not recognised.`);
                break;
        }
        return groupParameterMappingIds;
    }

    #getParameterMappingIds(messageLog, transformIds, operation) {
        const parameterMappingsMetadata = this._getMetadataByPropertyName(messageLog, "parameterMappings");
        if (parameterMappingsMetadata && Array.isArray(parameterMappingsMetadata)) {
            const ids = parameterMappingsMetadata
                .where(parameterMappingMetadata => transformIds.includes(parameterMappingMetadata.transformId))
                .map(parameterMappingMetadata => parameterMappingMetadata.id);
            const idCache = new Set(ids);

            const multiMappingsMetadata = parameterMappingsMetadata.where(parameterMappingMetadata => {
                const parametersMetadata = parameterMappingMetadata.parameters;
                return isSomething(parametersMetadata) && Array.isArray(parametersMetadata);
            });

            multiMappingsMetadata.forEach(multiMappingMetadata => {
                const parameterTransformIds = multiMappingMetadata.parameters.map(p => p.transformId);
                const commonIds = parameterTransformIds.intersect(transformIds);
                if (commonIds.length === parameterTransformIds.length) {
                    idCache.add(multiMappingMetadata.id);
                }
                else if (commonIds.length > 0) {
                    const nodeName = `parameterMapping.id = ${multiMappingMetadata.id}`;
                    messageLog.addError(`${nodeName} cross-references transform ids, not all of which are associated with the transforms for operation '${operation.name}'.`);
                }
            });

            return Array.from(idCache);
        }
    }

    #createTransformGroup(messageLog, transformGroupMetadata, nodeName) {
        if (ensureProperties(messageLog, transformGroupMetadata, nodeName, "transformIds") &&
            this._mustHaveAtLeastOneElement(messageLog, transformGroupMetadata.transformIds, `No transforms have been defined for ${nodeName}.`)) {
            
            const group = new TransformGroup();
            const transformCache = new Map();
            transformGroupMetadata.transformIds.forEach(id => {
                const transform = this.#createTransform(messageLog, id);
                if (transform) {
                    group.add(transform);
                    transformCache.set(id, transform);
                }
            });
            this.#setParameterAssociations(messageLog, group, transformCache, transformGroupMetadata.parameterAssociations);
            return {
                group: group,
                transformCache: transformCache
            };
        }
    }

    static #undefinedOrNumber(property) {
        return isSomething(property) ? Number(property) : undefined;
    }

    #setParameterAssociations(messageLog, transformGroup, transforms, parameterAssociationsMetadata) {
        if (Array.isArray(parameterAssociationsMetadata)) {
            let index = 0;
            parameterAssociationsMetadata.forEach(parameterAssociationMetadata => {
                const nodeName = `parameterAssociations[${index++}]`;
                if (ensureProperties(messageLog, parameterAssociationMetadata, nodeName, "transform1Id", "parameter1Name", "transform2Id", "parameter2Name")) {
                    const parameter1 = PerformanceCategoryBuilder.#getTransformParameter(messageLog, transforms, parameterAssociationMetadata.transform1Id, parameterAssociationMetadata.parameter1Name);
                    const parameter2 = PerformanceCategoryBuilder.#getTransformParameter(messageLog, transforms, parameterAssociationMetadata.transform2Id, parameterAssociationMetadata.parameter2Name);
                    if (parameter1 && parameter2) {
                        const factor = PerformanceCategoryBuilder.#undefinedOrNumber(parameterAssociationMetadata.factor);
                        const offset = PerformanceCategoryBuilder.#undefinedOrNumber(parameterAssociationMetadata.offset);
                        transformGroup.associate(parameter1, parameter2, factor, offset);
                    }
                }
            });
        }
    }

    static #getTransformParameter(messageLog, transforms, transformId, parameterName) {
        const transform = transforms.get(transformId);
        if (transform) {
            return transform.getParameter(parameterName);
        }
        messageLog.addError(`Failed to identify parameter '${parameterName}' for transform.id = ${transformId}.`);
    }

    //#region Transform Creation

    #createTransform(messageLog, transformId) {
        const transformMetadata = this._getMetadataById(messageLog, "transforms", transformId);
        if (transformMetadata) {
            let transform;
            switch (transformMetadata.type) {
                case "Clone":
                    transform = this.#createClonedTransform(transformMetadata, messageLog);
                    break;
                case "Curve":
                    transform = PerformanceCategoryBuilder.#createCurveTransform(transformMetadata);
                    break;
                case "CurveSet":
                    transform = PerformanceCategoryBuilder.#createCurveSetTransform(transformMetadata, messageLog);
                    break;
                case "RangeCurveSet":
                    transform = PerformanceCategoryBuilder.#createRangeCurveSetTransform(transformMetadata);
                    break;
                case "DetentCurveSet":
                    transform = PerformanceCategoryBuilder.#createDetentCurveSetTransform(transformMetadata);
                    break;
                case "RangeCurveSetGroup":
                    transform = PerformanceCategoryBuilder.#createRangeCurveSetGroupTransform(transformMetadata);
                    break;
                case "RangeCurveTwinSet":
                    transform = PerformanceCategoryBuilder.#createRangeCurveTwinSetTransform(transformMetadata);
                    break;
                case "OneToManyCurveSet":
                    transform = PerformanceCategoryBuilder.#createOneToManyCurveSetTransform(transformMetadata);
                    break;
                case "OptionalFactor":
                    transform = PerformanceCategoryBuilder.#createOptionalFactorTransform();
                    break;
                case "OptionalScaledFactor":
                    transform = PerformanceCategoryBuilder.#createOptionalScaledFactorTransform();
                    break;
                case "OptionalFixedFactor":
                    transform = PerformanceCategoryBuilder.#createOptionalFixedFactorTransform(transformMetadata);
                    break;
                case "Table":
                    transform = PerformanceCategoryBuilder.#createTableTransform(transformMetadata);
                    break;
                case "QuantityAdjuster":
                    transform = PerformanceCategoryBuilder.#createQuantityAdjusterTransform(transformMetadata);
                    break;
                case "FixedQuantityAdjuster":
                    transform = PerformanceCategoryBuilder.#createFixedQuantityAdjusterTransform(transformMetadata);
                    break;
                case "NullOp":
                    transform = PerformanceCategoryBuilder.#createNullOpTransform();
                    break;
                case "Adder":
                    transform = PerformanceCategoryBuilder.#createAdderTransform();
                    break;
                case "OptionalAdder":
                    transform = PerformanceCategoryBuilder.#createOptionalAdderTransform();
                    break;
                case "IsaDifference":
                    transform = PerformanceCategoryBuilder.#createIsaDifferenceTransform();
                    break;
                case "Guard":
                    transform = PerformanceCategoryBuilder.#createGuardTransform(transformMetadata, messageLog);
                    break;
                case "PressureAltitudeCalculator":
                    transform = PerformanceCategoryBuilder.#createPressureAltitudeCalculatorTransform();
                    break;
                case "ObstacleClearanceCalculator":
                    transform = PerformanceCategoryBuilder.#createObstacleClearanceCalculatorTransform();
                    break;
                case "ConditionalRouter":
                    transform = PerformanceCategoryBuilder.#createConditionalRouterTransform(transformMetadata, messageLog);
                    break;
                default:
                    messageLog.addError(`Unrecognised transform type: '${transformMetadata.type}'.`);
                    break;
            }
            if (transform && isArrayWithAtLeastOneElement(transformMetadata.predicates)) {
                PerformanceCategoryBuilder.#registerPredicates(messageLog, transform, transformMetadata.predicates);
            }
            return transform;
        }
    }

    static #registerPredicates(messageLog, transform, transformMetadata) {
        transformMetadata.forEach((predicateMetadata, index) => {
            const nodeName = `predicates[${index}]`;
            if (ensureProperties(messageLog, predicateMetadata, nodeName, "parameterName", "type")) {
                const predicate = PerformanceCategoryBuilder.#createPredicate(messageLog, predicateMetadata.type);
                transform.registerPredicate(predicateMetadata.parameterName, predicate);
            }
        });
    }

    static #createPredicate(messageLog, type) {
        let predicate;
        switch (type) {
            case "LessThanZero":
                predicate = new LessThanZero();
                break;
            case "LessThanOrEqualToZero":
                predicate = new LessThanOrEqualToZero();
                break;
            case "GreaterThanOrEqualToZero":
                predicate = new GreaterThanOrEqualToZero();
                break;
            case "GreaterThanZero":
                predicate = new GreaterThanZero();
                break;
            default:
                messageLog.addError(`Unrecognised predicate type: '${type}'.`);
                break;
        }
        return predicate;
    }

    static #createAdjuster(messageLog, type) {
        let adjuster;
        switch (type) {
            case "SetToZero":
                adjuster = new SetToZero();
                break;
            default:
                messageLog.addError(`Unrecognised adjuster type: '${type}'.`);
                break;
        }
        return adjuster;
    }

    static #createCurve(metadata) {
        /* metadata should be an array of the form:
        [
            [x1, y1],
            [x2, y2],
            etc.
        ]
        */
        const points = metadata.map(arr => new Point(arr[0], arr[1]));
        return new Curve(points, PerformanceCategoryBuilder.#interpolatorFactory);
    }

    #createClonedTransform(metadata, messageLog) {
        const clonedTransformId = metadata.clonedTransformId;
        const cloneSourceMetadata = this._getMetadataById(messageLog, "transforms", clonedTransformId);
        if (cloneSourceMetadata.type === "Clone") {
            messageLog.addError(`Transform id ${metadata.id} is a clone of transform id ${clonedTransformId}, which is itself a clone.`);
        }
        else {
            return this.#createTransform(messageLog, clonedTransformId);
        }
    }

    static #createCurveTransform(metadata) {
        const curve = PerformanceCategoryBuilder.#createCurve(metadata.points);
        return new CurveTransform(curve);
    }

    static #createCurveSetTransform(metadata, messageLog) {
        const boundaryPolicy = PerformanceCategoryBuilder.#getCurveSetBoundaryPolicy(metadata.boundaryBehaviour, messageLog);
        const curveSet = new CurveSet(PerformanceCategoryBuilder.#interpolatorFactory, boundaryPolicy);
        metadata.curves.forEach(o => {
            const curve = PerformanceCategoryBuilder.#createCurve(o.points);
            curveSet.add(curve);
        });
        const safeBoundaryCurveCombinations = boundaryPolicy === CurveSetBoundaryPolicy.UseBoundaryCurve
            ? PerformanceCategoryBuilder.#createSafeBoundaryCurveCombinations(metadata.boundaryBehaviour, messageLog)
            : null;
        return new CurveSetTransform(curveSet, safeBoundaryCurveCombinations);
    }

    static #getCurveSetBoundaryPolicy(metadata, messageLog) {
        const policyName = metadata?.policy;
        const policy = CurveSetBoundaryPolicy[policyName];
        if (policyName && !policy) {
            messageLog.addError(`Curve set boundary policy '${policyName}' is invalid.`);
        }
        return policy ?? CurveSetBoundaryPolicy.Reject;
    }

    static #createSafeBoundaryCurveCombinations(metadata, messageLog) {
        const safeCombinations = metadata?.safeCombinations;
        if (safeCombinations) {
            if (safeCombinations.every(sc => sc.length === 2 && CurveSelectionMethod[sc[1]])) {
                return safeCombinations.map(sc => {
                    const outputParameterName = sc[0];
                    const method = CurveSelectionMethod[sc[1]];
                    return [outputParameterName, method];
                });
            }
            messageLog.addError(`Safe-boundary-curve/output-parameter combinations must be 
            arrays of the following form: [outputParameterName, CurveSelectionMethod].`);
        }
        else {
            messageLog.addError(`At least one safe-boundary-curve/output-parameter combination 
            must be defined for a curve set transform whose encapsulated curve set has a boundary
            policy equal to CurveSetBoundaryPolicy.UseBoundaryCurve.`);
        }
        return null;
    }

    static #createRangeCurveSetTransform(metadata) {
        const rcs = new RangeCurveSet(PerformanceCategoryBuilder.#interpolatorFactory);
        metadata.curves.forEach(o => {
            const curve = PerformanceCategoryBuilder.#createCurve(o.points);
            rcs.set(o.rangeValue, curve);
        });
        return new RangeCurveSetTransform(rcs);
    }

    static #createDetentCurveSetTransform(metadata) {
        const dcs = new DetentCurveSet();
        metadata.curves.forEach(o => {
            const curve = PerformanceCategoryBuilder.#createCurve(o.points);
            dcs.set(o.detent, curve);
        });
        return new DetentCurveSetTransform(dcs);
    }

    static #createRangeCurveSetGroupTransform(metadata) {
        const rcsg = new RangeCurveSetGroup(PerformanceCategoryBuilder.#interpolatorFactory);
        metadata.curves.forEach(o => {
            const curve = PerformanceCategoryBuilder.#createCurve(o.points);
            rcsg.set(o.groupValue, o.rangeValue, curve);
        });
        return new RangeCurveSetGroupTransform(rcsg);
    }

    static #createRangeCurveTwinSetTransform(metadata) {
        const rcts = new RangeCurveTwinSet(PerformanceCategoryBuilder.#interpolatorFactory);
        metadata.curves.forEach(o => {
            const curve = PerformanceCategoryBuilder.#createCurve(o.points);
            rcts.set(o.identifier, o.rangeValue, curve);
        });
        return new RangeCurveTwinSetTransform(rcts);
    }

    static #createOneToManyCurveSetTransform(metadata) {
        const curveSet = new OneToManyCurveSet();
        metadata.curves.forEach(o => {
            const curve = PerformanceCategoryBuilder.#createCurve(o.points);
            curveSet.set(o.name, curve);
        });
        return new OneToManyCurveSetTransform(curveSet);
    }

    static #createOptionalFactorTransform() {
        return new OptionalFactorTransform();
    }

    static #createOptionalScaledFactorTransform() {
        const osf = new OptionalScaledFactor();
        return new OptionalScaledFactorTransform(osf);
    }

    static #createOptionalFixedFactorTransform(metadata) {
        return new OptionalFixedFactorTransform(metadata.factor);
    }

    static #createTableTransform(metadata) {
        const inputKeys = metadata.inputKeys.map(inputKey => PerformanceCategoryBuilder.#createTableKey(inputKey));
        const outputKeys = metadata.outputKeys.map(outputKey => PerformanceCategoryBuilder.#createTableKey(outputKey));
        const container = TableContainer.create(inputKeys, outputKeys);
        metadata.rows.forEach(row => {
            const inputKeyValues = row.inputValues.map((value, index) => PerformanceCategoryBuilder.#createTableValue(inputKeys[index], value));
            const outputKeyValues = row.outputValues.map((value, index) => PerformanceCategoryBuilder.#createTableValue(outputKeys[index], value));
            container.add(inputKeyValues, outputKeyValues);
        });
        return new TableTransform(container);
    }

    static #createTableKey(metadata) {
        const name = metadata.name;
        const detents = isArrayWithAtLeastOneElement(metadata.detents) ? metadata.detents : undefined;
        return new TableKey(name, detents);
    }

    static #createTableValue(tableKey, value) {
        return isSomething(value) ? tableKey.createValue(value) : tableKey.createUndefinedValue();
    }

    static #createQuantityAdjusterTransform(metadata) {
        const asIncreaser = metadata.asIncreaser === true;
        const qa = new QuantityAdjuster(metadata.scalar, asIncreaser);
        return new QuantityAdjusterTransform(qa);
    }

    static #createFixedQuantityAdjusterTransform(metadata) {
        const asIncreaser = metadata.asIncreaser === true;
        const qa = new FixedQuantityAdjuster(metadata.scalar, asIncreaser);
        return new FixedQuantityAdjusterTransform(qa);
    }

    static #createNullOpTransform() {
        return new NullOpTransform();
    }

    static #createAdderTransform() {
        return new AdderTransform();
    }

    static #createOptionalAdderTransform() {
        return new OptionalAdderTransform();
    }

    static #createIsaDifferenceTransform() {
        return new IsaDifferenceTransform();
    }

    static #createGuardTransform(metadata, messageLog) {
        const predicate = PerformanceCategoryBuilder.#createPredicate(messageLog, metadata.predicate);
        const adjuster = PerformanceCategoryBuilder.#createAdjuster(messageLog, metadata.adjuster);
        const guard = new Guard(predicate, adjuster);
        return new GuardTransform(guard);
    }

    static #createPressureAltitudeCalculatorTransform() {
        return new PressureAltitudeCalculatorTransform();
    }

    static #createObstacleClearanceCalculatorTransform() {
        return new ObstacleClearanceCalculatorTransform();
    }

    static #createConditionalRouterTransform(metadata, messageLog) {
        const predicate = PerformanceCategoryBuilder.#createPredicate(messageLog, metadata.predicate);
        return new ConditionalRouterTransform(predicate);
    }

    //#endregion    
}