import { Parameter } from "./parameter";
import { ParameterDirection } from "./parameter-direction";

export class ParameterMappingBase {
    #name;
    #parameter;
    #eventSubscribers;
    #isInvalid = false;
    
    constructor(name, parameter) {
        if (!(parameter instanceof Parameter)) {
            throw new Error("parameter must be an instance of type Parameter.");
        }
        this.#name = name;
        this.#parameter = parameter;
        this.sequence = 0;
        parameter.subscribeValueTransferred((s, e) => this._onParameterValueTransferred(s, e));
        this.#eventSubscribers = new Set();
    }

    get typeName() {
        this._throwOverrideRequired();
    }

    get name() {
        return this.#name;
    }

    get parameter() {
        return this.#parameter;
    }

    get isInvalid() {
        return this.#isInvalid;
    }

    _setIsInvalid(value) {
        this.#isInvalid = value;
    }

    _throwOverrideRequired() {
        throw new Error("This method must be overridden in a derived class.");
    }

    _onParameterValueTransferred(sender, args) {
        if (args.direction === ParameterDirection.Out) {
            this._onParameterValueEmitted(args.value);
            this.#notifySubscribers();
        }
    }

    // Updates the underlying parameter with the current raw value.
    pushValue() {
        const value = this._getRawValue();
        this.#parameter.receive(value);
    }

    // Gets the value to be shared with the underlying parameter.
    _getRawValue() {
        this._throwOverrideRequired();
    }

    // The underlying parameter has emitted a value and this ParameterMappingBase should update itself accordingly.
    _onParameterValueEmitted(value) {
        this._throwOverrideRequired();
    }

    // Sets the real-world value and optionally updates the underlying parameter.
    setMappingValue(value, updateParameter = true) {
        this._setMappingValueInternal(value);
        if (updateParameter) {
            this.pushValue();
        }
    }

    // Sets the real-world value.
    _setMappingValueInternal(value) {
        this._throwOverrideRequired();
    }

    // Gets the real-world value.
    getMappingValue(allowInvalid = false) {
        this._throwOverrideRequired();
    }

    subscribeParameterValueEmitted(callback) {
        this.#eventSubscribers.add(callback);
    }

    unsubscribeParameterValueEmitted(callback) {
        this.#eventSubscribers.delete(callback);
    }

    #notifySubscribers() {
        this.#eventSubscribers.forEach(callback => callback(this));
    }
}