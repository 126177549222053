import styles from "./ResetPassword.module.css";
import commonStyles from "./Common.module.css";
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import PasswordSetter from "./PasswordSetter";

const getErrorInfo = (err) => {
    let message;
    let showForm;
    const response = err?.response;
    if (!response) {
        message = err?.message ? err.message : "The server did not respond.  Please check your network connection.";
        showForm = true;
    }
    else {
        switch (response.status) {
            case 400:
                message = "The password is invalid.";
                showForm = true;
                break;
            case 404:
                message = "Your password-reset token is invalid.";
                showForm = false;
                break;
            case 410:
                message = "Your password-reset token has expired.";
                showForm = false;
                break;
            case 500:
                message = "Internal server error."
                showForm = true;
                break;
            default:
                message = "Password reset failed.";
                showForm = true;
                break;
        }
    }
    return {message, showForm};
};

const ResetPassword = () => {
    const { resetPassword } = useAuthContext();
    const { token } = useParams();
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusMessageClassName, setStatusMessageClassName] = useState(styles.status);
    const [canResetPassword, setCanResetPassword] = useState(false);
    const [showLoginLink, setShowLoginLink] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [loginLinkText, setLoginLinkText] = useState("Click here to sign in");

    useEffect(() => {
        document.title = "Avaid - Reset Password";
    }, []);

    useEffect(() => {
        updateStatusMessage("");
    }, [password, confirmedPassword]);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        clearEntries();
        try {
            await resetPassword(token, password);
            updateStatusMessage("Your password has been reset.");
            setShowLoginLink(true);
            setShowForm(false);
        }
        catch (err) {
            const {message, showForm} = getErrorInfo(err);
            updateStatusMessage(message, true);
            setShowForm(showForm);
            if (!showForm) {
                setShowLoginLink(true);
                setLoginLinkText("Click here to submit another password-reset request");
            }
        }
    };

    const updateStatusMessage = (message, isError = false) => {
        setStatusMessage(message);
        let className = styles.status;
        if (isError) {
            className += ` ${styles.status___error}`;
        }
        setStatusMessageClassName(className);
    };

    const clearEntries = () => {
        updateStatusMessage("");
        setPassword("");
        setConfirmedPassword("");
    };

    return (
        <section className={styles.ResetPassword}>
            {showForm && <form className={styles.form} onSubmit={handleResetPassword}>
                <div className={styles.formInputs}>
                    <PasswordSetter
                        password={password}
                        setPassword={setPassword}
                        confirmedPassword={confirmedPassword}
                        setConfirmedPassword={setConfirmedPassword}
                        setPasswordIsValid={setCanResetPassword} />
                </div>
                <button className={`${commonStyles.button} ${styles.button}`} type="submit" disabled={!canResetPassword}>Reset</button>
            </form>}
            <p className={statusMessageClassName}>{statusMessage}</p>
            {showLoginLink &&
                <p><span><Link className={commonStyles.link} to="/login">{loginLinkText}</Link>.</span></p>
            }
        </section>
    )
};

export default ResetPassword;