import { Point } from "./point";

export class Line {
    static getPoint(point1, point2, fractionalDistance) {
        if (fractionalDistance < 0 || fractionalDistance > 1) {
            throw new Error("The fractional distance must be between 0.0 and 1.0 inclusive.");
        }
        const x = point1.x + fractionalDistance * (point2.x - point1.x);
        const y = point1.y + fractionalDistance * (point2.y - point1.y);
        return new Point(x, y);
    }

    constructor(point1, point2) {
        this.point1 = point1;
        this.point2 = point2;
    }

    #length;

    get length() {
        if (!this.#length) {
            this.#length = Math.sqrt((this.point2.x - this.point1.x) ** 2 + (this.point2.y - this.point1.y) ** 2);
        }
        return this.#length;
    }
}