// https://github.com/gitdagray/react_custom_hooks/blob/main/src/hooks/useAxiosFetch.js

import { useState, useEffect } from "react";
import axios from "axios";
import useAxiosPrivate from "./useAxiosPrivate";
import { axiosGet, axiosPublic } from "../api/axios";

const onSuccess = (output, isMounted, defaultData, setData, setError) => {
    if (isMounted) {
        if (typeof output === "string") {
            // Possibly HTML rather than JSON
            setData(defaultData);
            setError("Unexpected response");
        }
        else {
            setData(output);
            setError(null);
        }
    }
};

const onError = (error, isMounted, defaultData, setData, setError, errorHandler) => {
    if (!axios.isCancel(error) && isMounted) {
        setData(defaultData);
        setError(error.message);
        if (errorHandler) {
            errorHandler(error.response);
        }
    }
};

const useAuthorisedApiGet = (url, accessToken, defaultData, errorHandler = null, shouldFetch = true) => {
    const [data, setData] = useState(defaultData);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshSignal, setRefreshSignal] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    const refresh = () => {
        setRefreshSignal(value => !value);
    }

    useEffect(() => {
        let isMounted = true;
        let abortController;

        const fetchData = async (fetchUrl) => {
            if (accessToken) {
                setIsLoading(true);
                
                abortController = new AbortController();
                const config = {
                    signal: abortController.signal
                };
                const handleSuccess = output => onSuccess(output, isMounted, defaultData, setData, setError);
                const handleError = error => onError(error, isMounted, defaultData, setData, setError, errorHandler);
                const handleConclusion = () => isMounted && setIsLoading(false);

                await axiosGet(axiosPrivate, fetchUrl, config, handleSuccess, handleError, handleConclusion);
            }
            else {
                setData(defaultData);
                setError("Unauthorised");
            }
        }

        if (shouldFetch) {
            fetchData(url);
        }
        else {
            setData(defaultData);
        }

        const cleanUp = () => {
            isMounted = false;
            abortController?.abort();
        }

        return cleanUp;
    }, [url, accessToken, shouldFetch, refreshSignal]);

    return { data, error, isLoading, refresh };
}

const usePublicApiGet = (url, defaultData, errorHandler = null, shouldFetch = true) => {
    const [data, setData] = useState(defaultData);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshSignal, setRefreshSignal] = useState(false);

    const refresh = () => {
        setRefreshSignal(value => !value);
    }

    useEffect(() => {
        let isMounted = true;
        let abortController;

        const fetchData = async (fetchUrl) => {
            setIsLoading(true);

            abortController = new AbortController();
            const config = {
                signal: abortController.signal
            };
            const handleSuccess = output => onSuccess(output, isMounted, defaultData, setData, setError);
            const handleError = error => onError(error, isMounted, defaultData, setData, setError, errorHandler);
            const handleConclusion = () => isMounted && setIsLoading(false);

            await axiosGet(axiosPublic, fetchUrl, config, handleSuccess, handleError, handleConclusion);
        }

        if (shouldFetch) {
            fetchData(url);
        }
        else {
            setData(defaultData);
        }

        const cleanUp = () => {
            isMounted = false;
            abortController?.abort();
        }

        return cleanUp;
    }, [url, refreshSignal]);

    return { data, error, isLoading, refresh };
}

/* Uses public or authorised depending on existence of access token. */
const useApiGet = (url, accessToken = null, defaultData, errorHandler = null, shouldFetch = true) => {
    const [data, setData] = useState(defaultData);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshSignal, setRefreshSignal] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    const refresh = () => {
        setRefreshSignal(value => !value);
    }

    useEffect(() => {
        let isMounted = true;
        let abortController;

        const fetchData = async (fetchUrl) => {
            const axiosInstance = accessToken ? axiosPrivate : axiosPublic;
            setIsLoading(true);

            abortController = new AbortController();
            const config = { signal: abortController.signal };

            const handleSuccess = output => onSuccess(output, isMounted, defaultData, setData, setError);
            const handleError = error => onError(error, isMounted, defaultData, setData, setError, errorHandler);
            const handleConclusion = () => isMounted && setIsLoading(false);

            await axiosGet(axiosInstance, fetchUrl, config, handleSuccess, handleError, handleConclusion);
        }

        if (shouldFetch) {
            fetchData(url);
        }
        else {
            setData(defaultData);
        }

        const cleanUp = () => {
            isMounted = false;
            abortController?.abort();
        }

        return cleanUp;
    }, [url, accessToken, shouldFetch, refreshSignal]);

    return { data, error, isLoading, refresh };
}

export { useAuthorisedApiGet, usePublicApiGet, useApiGet };