import { isSomething } from "../common/utilities";
import "../array-extensions";

export class DetentCurveSet {
    constructor() {
        this.curveSet = new Map();
    }

    set(detent, curve) {
        if (isSomething(detent) && isSomething(curve)) {
            if (this.curveSet.has(detent)) {
                throw new Error(`The detent '${detent}' has already been used.`);
            }
            this.curveSet.set(detent, curve);
        }
        else {
            throw new Error("Both a detent and a curve must be supplied.");
        }
    }

    #getOrderedKeys(map) {
        return Array.from(map.keys()).order();
    }

    getDetents() {
        return this.#getOrderedKeys(this.curveSet);
    }
    
    getCurve(detent) {
        if (isSomething(detent)) {
            const curve = this.curveSet.get(detent);
            if (!isSomething(curve)) {
                throw new Error("The supplied detent is unknown.");
            }
            return curve;
        }
        else {
            throw new Error("No detent was supplied.");
        }
    }
}