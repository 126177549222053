import SETTINGS from "../config";
import styles from "./ExpiryBar.module.css";
import { useState, useEffect } from "react";

const ExpiryBar = ({ startDate, endDate }) => {
    const [percentageFill, setPercentageFill] = useState(0);
    const [barClassName, setBarClassName] = useState(styles.bar);
    const [remainingDays, setRemainingDays] = useState(0);
    const [title, setTitle] = useState(null);

    useEffect(() => {
        const now = new Date();
        let value;
        if (now < startDate) {
            value = 0;
        }
        else if (now > endDate) {
            value = 100;
        }
        else {
            value = 100 * (now - startDate) / (endDate - startDate);
        }
        setPercentageFill(value);

        const remainingDays = Math.trunc((endDate - now) / (1000 * 3600 * 24));
        setRemainingDays(remainingDays);

        let className = `${styles.bar}`;
        let colourClassName;
        if (remainingDays <= SETTINGS.LEASE_EXPIRY_CRITICAL_WINDOW_DAYS) {
            colourClassName = `${styles.danger}`;
        }
        else if (remainingDays <= SETTINGS.LEASE_EXPIRY_WINDOW_DAYS) {
            colourClassName = `${styles.warning}`;
        }
        else {
            colourClassName = `${styles.safe}`;
        }
        className += ` ${colourClassName}`;
        setBarClassName(className);

    }, [startDate, endDate]);

    useEffect(() => {
        let t;
        if (remainingDays <= 0) {
            t = "Expired";
        }
        else {
            t = `${remainingDays} day${remainingDays === 1 ? "" : "s"} remaining`;
        }
        setTitle(t);
    }, [remainingDays]);

    return <div className={styles.ExpiryBar} title={title}>
        <div className={barClassName} style={{width: `${percentageFill}%`}} />
    </div>
};

export default ExpiryBar;