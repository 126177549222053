import styles from "./AircraftSelector.module.css";
import commonStyles from "./Common.module.css";
import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Expander from "./Expander";
import SearchBar from "./SearchBar";
import Carousel from "./Carousel";
import AircraftSummary from "./AircraftSummary";
import useAircraftCarouselViewportItemCount from "../hooks/useAircraftCarouselViewportItemCount";
import { makeSearchable } from "../utils/searchableAircraftMapper";

const AircraftSelector = ({
        title,
        expanded,
        aircraftSummaries,
        matchingAircraft,
        setMatchingAircraft,
        activeAircraftIndexOverride,
        selectedAircraftId,
        handleSelect
    }) => {
    
    const [matchCount, setMatchCount] = useState(0);
    const [searchableSummaries, setSearchableSummaries] = useState();
    const viewportItemCount = useAircraftCarouselViewportItemCount();

    useEffect(() => {
        setMatchCount(matchingAircraft ? matchingAircraft.length : 0);
    }, [matchingAircraft]);

    useEffect(() => {
        if (aircraftSummaries) {
            const summaries = makeSearchable(aircraftSummaries);
            setSearchableSummaries(summaries);
        }
        else {
            setSearchableSummaries([]);
        }
    }, [aircraftSummaries]);

    const reportMatchingAircraft = useCallback((matchItems) => {
        if (aircraftSummaries && matchItems) {
            const ids = new Set(matchItems.map(a => a.id));
            const matches = aircraftSummaries.where(a => ids.has(a.id));
            setMatchingAircraft(matches);
        }
    }, [aircraftSummaries, setMatchingAircraft]);

    return <Expander withContainer={false} checked={expanded}>
        {title && <h1 className={styles.title}>{title}</h1>}
        <div className={styles.searchBarContainer}>
            <SearchBar searchItems={searchableSummaries} setMatchItems={reportMatchingAircraft} />
        </div>
        <hr />
        {matchCount > 0 &&
            <Carousel viewportItemCount={viewportItemCount} activeIndexOverride={activeAircraftIndexOverride}>
                {matchingAircraft.map(a => <AircraftSummary
                    key={a.id}
                    handleClick={() => handleSelect(a.id)}
                    manufacturer={a.manufacturer}
                    model={a.model}
                    description={a.description}
                    validFrom={new Date(a.valid_from)}
                    validTo={new Date(a.valid_to)}
                    leased={!!Number(a.is_leased)}
                    selected={selectedAircraftId === a.id}
                />)}
            </Carousel>
        }
        {matchCount === 0 &&
            <div className={styles.messageContainer}>
                <p className={styles.message}>No matching aircraft found</p>
                <p className={styles.subMessage}>Ask for an aircraft to be added <Link className={commonStyles.link} to="/ops?subject=Request%20an%20aircraft">here</Link>.</p>
            </div>
        }
    </Expander>
};

export default AircraftSelector;