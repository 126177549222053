import useAuthContext from "./useAuthContext";
import { useEffect } from "react";
import { axiosPrivate } from "../api/axios";

const useAxiosPrivate = () => {
    const { auth, refresh } = useAuthContext();

    const getAuthHeaderValue = (accessToken) => `Bearer ${accessToken}`;

    useEffect(() => {
        const AUTH_KEY = "Authorization";
        const interceptors = axiosPrivate.interceptors;

        const requestIntercept = interceptors.request.use(
            config => {
                const headers = config.headers;
                if (!headers[AUTH_KEY]) {
                    headers[AUTH_KEY] = getAuthHeaderValue(auth.accessToken);
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = interceptors.response.use(
            response => response,
            async (error) => {
                const originalRequest = error.config;
                if (error.response?.status === 403 && !originalRequest.attemptingRefresh) {
                    originalRequest.attemptingRefresh = true;
                    // TODO only refresh on condition
                    const newAccessToken = await refresh();
                    originalRequest.headers[AUTH_KEY] = getAuthHeaderValue(newAccessToken);
                    return axiosPrivate(originalRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            interceptors.request.eject(requestIntercept);
            interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;