import styles from "./RootContent.module.css";
import useHelpContext from "../../hooks/useHelpContext";
import RootItem from "./RootItem";

const RootContent = () => {
    const { getRootElements } = useHelpContext();
    return (
        <section className={styles.RootContent}>
            {getRootElements().map(e => <RootItem key={e.index} element={e} />)}
        </section>
    )
};

export default RootContent;