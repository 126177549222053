import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";
import { OptionalAdder } from "./optional-adder";

export class OptionalAdderTransform extends Transform {
    #optionalAdder = new OptionalAdder();

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterEnabledName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterIn1Name, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterIn2Name, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterOutName, this.id, ParameterDirection.Out)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const count = this._setParameterValueCount;
        if (count === 3) {
            const result = this.#calculateOutput();
            this._emitParameterValue(ParameterNames.ParameterOutName, result);
        }
    }

    #calculateOutput() {
        const enabled = this._getParameterValue(ParameterNames.ParameterEnabledName);
        const input1 = this._getParameterValue(ParameterNames.ParameterIn1Name);
        const input2 = this._getParameterValue(ParameterNames.ParameterIn2Name);
        return this.#optionalAdder.add(enabled, input1, input2);
    }
}