export function checkPrecision(value) {
    if (value < 1 || value > 15) {
        throw new Error("value must be between 1 and 15");
    }
    return value;
}

export function toPrecision(number, precision) {
    checkPrecision(precision);
    let result;
    if (approximates(number, 0, 1E-10)) {
        result = 0;
    }
    else {
        const shift = dpShift(number, precision);
        result = round(number, shift);
    }
    return result;
}

function dpShift(number, precision) {
    const log = Math.log10(Math.abs(number));
    const intLog = Math.trunc(log);
    let result = precision - intLog;
    if (log > 0) {
        result--;
    }
    return result;
}

function round(number, placesToShift) {
    let n = number * Math.pow(10, placesToShift);
    n = Math.sign(n) * Math.abs(Math.floor(n + 0.5));
    return n / Math.pow(10, placesToShift);
}

export function approximates(value1, value2, delta) {
    return Math.abs(value1 - value2) <= delta;
}