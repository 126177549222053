import '../array-extensions'
import { approximates } from '../double-extensions';

const APPROX_DELTA = 0.001;

export function getCurveFunnel(curves, point) {
    const x = point.x;
    const y = point.y;
    
    const curveData = curves.map(curve => ({
        curve: curve,
        diff: curve.getY(x) - y
    }));
    
    const upperCurve = curveData
        .where(a => a.diff >= 0 || approximates(a.diff, 0, APPROX_DELTA))
        .orderBy(a => a.diff)
        .map(a => a.curve)
        .firstOrUndefined();
    
    const lowerCurve = curveData
        .where(a => a.diff <= 0 || approximates(a.diff, 0, APPROX_DELTA))
        .orderByDescending(a => a.diff)
        .map(a => a.curve)
        .firstOrUndefined();
    
    return {
        upperCurve: upperCurve,
        lowerCurve: lowerCurve
    };
}