import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";

export class OptionalScaledFactorTransform extends Transform {
    constructor(optionalScaledFactor) {
        super();
        this.optionalScaledFactor = optionalScaledFactor;
    }

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterEnabledName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterAmountName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterInName, this.id),
            new Parameter(ParameterNames.ParameterOutName, this.id),
            new Parameter(ParameterNames.ParameterFactorName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterDivisorName, this.id, ParameterDirection.In)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const parameterName = parameter.name;
        switch (parameterName) {
            case ParameterNames.ParameterInName:
                this._clearParameterValue(ParameterNames.ParameterOutName);
                break;
            case ParameterNames.ParameterOutName:
                this._clearParameterValue(ParameterNames.ParameterInName);
                break;
            // no default
        }

        const count = this._setParameterValueCount;
        if (count === 5) {
            const outputParameterName = this.getUnsetParameterNames()[0];
            let result;
            switch (outputParameterName) {
                case ParameterNames.ParameterInName:
                    result = this.#calculateInput();
                    break;
                case ParameterNames.ParameterOutName:
                    result = this.#calculateOutput();
                    break;
                default:
                    throw new Error(`Unknown output parameter: '${outputParameterName}'.`);
            }
            this._emitParameterValue(outputParameterName, result);
        }
    }

    #calculateInput() {
        const enabled = this._getParameterValue(ParameterNames.ParameterEnabledName);
        const output = this._getParameterValue(ParameterNames.ParameterOutName);
        const amount = this._getParameterValue(ParameterNames.ParameterAmountName);
        const factor = this._getParameterValue(ParameterNames.ParameterFactorName);
        const divisor = this._getParameterValue(ParameterNames.ParameterDivisorName);
        return this.optionalScaledFactor.getInput(enabled, output, amount, factor, divisor);
    }

    #calculateOutput() {
        const enabled = this._getParameterValue(ParameterNames.ParameterEnabledName);
        const input = this._getParameterValue(ParameterNames.ParameterInName);
        const amount = this._getParameterValue(ParameterNames.ParameterAmountName);
        const factor = this._getParameterValue(ParameterNames.ParameterFactorName);
        const divisor = this._getParameterValue(ParameterNames.ParameterDivisorName);
        return this.optionalScaledFactor.getOutput(enabled, input, amount, factor, divisor);
    }
}