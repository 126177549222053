import useAuthContext from "../hooks/useAuthContext";
import { useState, useEffect } from "react";

const PATHS = {
    NotDefined: null,
    Apron: "apron",
    Hangar: "hangar"
};

const useHangarPath = () => {
    const { auth } = useAuthContext();
    const [pathRoot, setPathRoot] = useState(PATHS.NotDefined);
    const [isApron, setIsApron] = useState(null);

    useEffect(() => {
        if (auth.accessToken) {
            setPathRoot(PATHS.Hangar);
            setIsApron(false);
        }
        else {
            setPathRoot(PATHS.Apron);
            setIsApron(true);
        }
    }, [auth]);

    return { pathRoot, isApron };
};

export { useHangarPath, PATHS };