import "../../array-extensions";

export class Operation {
    #name;
    #description;
    #transformGroup;
    #inputParameterCategories = [];
    #outputParameterCategory;
    #notes = [];

    constructor(name, description) {
        this.#name = name;
        this.#description = description;
    }

    get name() {
        return this.#name;
    }

    get description() {
        return this.#description;
    }

    get transformGroup() {
        return this.#transformGroup;
    }

    get outputParameterCategory() {
        return this.#outputParameterCategory;
    }

    getAllOutputParameterMappings() {
        return this.outputParameterCategory.getParameterMappings() ?? [];
    }

    _setTransformGroup(group) {
        this.#transformGroup = group;
    }

    _addInputParameterCategory(category) {
        if (this.#inputParameterCategories.map(category => category.name).includes(category.name)) {
            throw new Error(`A category with the name '${category.name}' has already been added,`);
        }
        const kvps = this.getAllInputParameterMappings().map(pm => [pm.name, undefined]);
        const cache = new Map(kvps);
        if (category.getParameterMappings().map(pm => pm.name).any(name => cache.has(name))) {
            throw new Error("All added input parameter categories must contain parameter mappings with names that are unique to this operation.");
        }
        this.#inputParameterCategories.push(category);
    }

    _setOutputParameterCategory(category) {
        this.#outputParameterCategory = category;
    }

    getInputParameterCategories() {
        return this.#inputParameterCategories.orderBy(c => c.sequence);
    }

    getAllInputParameterMappings() {
        return this.#inputParameterCategories.flatMap(category => category.getParameterMappings());
    }

    _addNote(note) {
        this.#notes.push(note);
    }

    getNotes() {
        return this.#notes.slice();
    }

    pushAllInputParameterValues() {
        this.getAllInputParameterMappings().forEach(pm => pm.pushValue());
    }

    #getParameterMapping(name) {
        let parameterMapping;
        this.#inputParameterCategories.every(category => {
            parameterMapping = category.getParameterMapping(name, false);
            return parameterMapping === undefined;
        });
        if (parameterMapping) {
            return parameterMapping;
        }
        throw new Error(`A parameter mapping with the name '${name}' is not associated with this operation.`);
    }

    setParameterMappingValue(name, value) {
        this.#getParameterMapping(name).setMappingValue(value);
    }

    getParameterMappingValue(name) {
        return this.#getParameterMapping(name).getMappingValue();
    }
}