import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import { useApiGet } from "../hooks/useApi";
import { isSomething } from "../lib/common/utilities";
import { useHangarPath } from "../hooks/useHangarPath";

const AccessibleAircraftContext = createContext({});

export const AccessibleAircraftProvider = ({ children }) => {
    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const [aircraftCount, setAircraftCount] = useState(0);
    const [aircraftSummaries, setAircraftSummaries] = useState(null);
    const [urlIsValid, setUrlIsValid] = useState(false);
    const { pathRoot } = useHangarPath();

    const handleError = (response) => {
        if (!isSomething(response) || response.status === 404) {
            navigate("/*");
        }
    };

    const { data, error, isLoading, refresh } = useApiGet(`/${pathRoot}`, auth.accessToken, null, handleError, urlIsValid);

    useEffect(() => {
        setUrlIsValid(pathRoot !== null);
    }, [pathRoot]);

    useEffect(() => {
        if (urlIsValid) {
            setAircraftSummaries(data);
            setAircraftCount(data?.length ?? 0);
        }
    }, [data]);

    return (
        <AccessibleAircraftContext.Provider value={{ aircraftCount, aircraftSummaries, error, isLoading, refresh }}>
            {children}
        </AccessibleAircraftContext.Provider>
    )
}

export default AccessibleAircraftContext;