import styles from "./Admin.module.css";
import commonStyles from "./Common.module.css";
import { useEffect, useState, useCallback } from "react";
import useAdminContext from "../hooks/useAdminContext";
import Expander from "./Expander";

const Admin = () => {
    const { logLevelInfo, setLogLevel } = useAdminContext();
    const [logLevels, setLogLevels] = useState([]);
    const [selectedLogLevel, setSelectedLogLevel] = useState("");
    const [statusMessage, setStatusMessage] = useState(null);
    const [responseMessage, setResponseMessage] = useState(null);

    useEffect(() => {
        document.title = "Avaid - Admin";
    }, []);

    useEffect(() => {
        if (logLevelInfo?.isLoading) {
            setStatusMessage("Fetching log levels...");
        }
        else {
            const data = logLevelInfo?.data;
            if (logLevelInfo?.error || !data) {
                setStatusMessage("Failed to retrieve log levels");
            }
            else {
                setStatusMessage(null);
                setLogLevels(data.levels);
                setSelectedLogLevel(data.selectedLevel);
            }
        }
    }, [logLevelInfo]);

    const changeLogLevel = useCallback(async (e) => {
        const level = e.target.value;
        const result = await setLogLevel(level);
        if (result.succeeded) {
            setResponseMessage(null);
            setSelectedLogLevel(level);
        }
        else {
            setResponseMessage(result.message);
        }
    }, [setLogLevel]);

    return (
        <section className={styles.Admin}>
            <h1 className={styles.title}>Administration</h1>
            <section className={styles.contentContainer}>
                <Expander title="Server Logging">
                    {statusMessage && <p className={styles.statusMessage}>{statusMessage}</p>}
                    {!statusMessage && logLevels &&
                        <>
                            <label className={styles.fieldLabel}>Log Level</label>
                            <select
                                className={`${commonStyles.selector} ${styles.selector}`}
                                value={selectedLogLevel}
                                onChange={changeLogLevel}>

                                {logLevels.map(level => (
                                    <option key={level} value={level}>
                                        {level}
                                    </option>
                                ))}
                            </select>
                        </>
                    }
                    {responseMessage && <p className={styles.responseMessage}>{responseMessage}</p>}
                </Expander>
            </section>
        </section>
    )
};

export default Admin;