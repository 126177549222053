import { Parameter } from "./parameter";
import { ParameterDirection } from "./parameter-direction";
import { UnitParameterMapping } from "./unit-parameter-mapping";

export class UnitMultiParameterMapping extends UnitParameterMapping {
    #parameterCache = new Set();

    constructor(name, quantityType, mappingType, minimumUnit = null, maximumUnit = null) {
        const parameter = new Parameter("Proxy", Symbol(), ParameterDirection.In);
        super(name, parameter, quantityType, mappingType, minimumUnit, maximumUnit);
    }

    get typeName() {
        return "UnitMultiParameterMapping";
    }

    add(parameter, factor = null, offset = null) {
        if (!(parameter instanceof Parameter)) {
            throw new Error("parameter must be an instance of type Parameter.");
        }
        if (parameter.direction === ParameterDirection.Out) {
            throw new Error("parameter must be capable of receiving data.");
        }
        this.#parameterCache.add({parameter, factor, offset});
    }

    pushValue() {
        const unitValue = this._getUnitValue();
        this.#parameterCache.forEach(info => {
            const rawValue = this._convertToRawValue(unitValue, info.factor, info.offset);
            info.parameter.receive(rawValue);
        });
    }
}