import "../array-extensions";
import { areSomething, isSomething } from "../common/utilities";
import { TableKey } from "./table-key";
import { Table } from "./table";
import { TableUtilities } from "./table-utilities";

export class TableContainer {
    static create(inputKeys, outputKeys) {
        TableContainer.#validate(inputKeys, "inputKeys");
        TableContainer.#validate(outputKeys, "outputKeys");

        const allKeys = inputKeys.concat(outputKeys);
        if (TableKey.distinct(allKeys).length !== allKeys.length) {
            throw new Error("The combined input and output keys contain some duplicates.");
        }
        return new TableContainer(inputKeys, outputKeys);
    }

    static #validate(keys, paramName) {
        if (!isSomething(keys) || keys.length === 0 || !keys.every(tk => tk instanceof TableKey)) {
            throw new Error(`${paramName}: expected an array of at least one TableKey instance.`);
        }
        if (TableKey.distinct(keys).length !== keys.length) {
            throw new Error(`${paramName}: there are some duplicate keys.`);
        }
    }

    #inputKey;
    #nestedInputKeys;
    #outputKeys;
    #containers;
    #table;
    #orderedKeys = null;
    #reverseOrderedKeys = null;

    constructor(inputKeys, outputKeys) {
        this.#inputKey = inputKeys[0];
        this.#nestedInputKeys = inputKeys.slice(1);
        this.#outputKeys = outputKeys.slice();
        if (this.#nestedInputKeys.any()) {
            this.#containers = new Map();
        }
        else {
            this.#table = new Table(outputKeys);
        }
    }

    get #containsTable() {
        return !isSomething(this.#containers);
    }

    get inputKeys() {
        return [this.#inputKey, ...this.#nestedInputKeys];
    }

    get outputKeys() {
        return this.#outputKeys.slice();
    }

    get inputKey() {
        return this.#inputKey;
    }

    #getRelevantInputValue(values, throwIfUndefined = true) {
        const tv = values.single(kv => kv.key.equals(this.#inputKey));
        if (tv.undefined) {
            if (throwIfUndefined) {
                throw new Error("Input values cannot be undefined.");
            }
            return null;
        }
        return tv.value;
    }

    add(inputs, row) {
        const value = this.#getRelevantInputValue(inputs);
        if (this.#containsTable) {
            this.#table.add(value, row);
        }
        else {
            let container = this.#containers.get(value);
            if (container === undefined) {
                container = TableContainer.create(this.#nestedInputKeys, this.#outputKeys);
                this.#containers.set(value, container);
            }
            container.add(inputs, row);
            this.#orderedKeys = this.#reverseOrderedKeys = null;
        }
    }

    #orderKeys() {
        if (!this.#containsTable) {
            const keys = Array.from(this.#containers.keys());
            this.#orderedKeys = keys.order();
            this.#reverseOrderedKeys = keys.orderDescending();
        }
    }

    #getOrderedKeys() {
        if (this.#orderedKeys === null) {
            this.#orderKeys();
        }
        return this.#orderedKeys;
    }

    #getReverseOrderedKeys() {
        if (this.#reverseOrderedKeys === null) {
            this.#orderKeys();
        }
        return this.#reverseOrderedKeys;
    }

    getRow(inputs) {
        const value = this.#getRelevantInputValue(inputs, false);
        if (value !== null) {        
            if (this.#containsTable) {
                return this.#table.getRow(value);
            }
            const container = this.#containers.get(value);
            if (container !== undefined) {
                return container.getRow(inputs);
            }
            const keyValues = TableUtilities.getAdjacentValues(value, this.#getOrderedKeys(), this.#getReverseOrderedKeys());
            const highKeyValue = keyValues.highValue;
            const lowKeyValue = keyValues.lowValue;
            if (areSomething(highKeyValue, lowKeyValue)) {
                const highContainer = this.#containers.get(highKeyValue);
                const lowContainer = this.#containers.get(lowKeyValue);
                const proportion = (value - lowKeyValue) / (highKeyValue - lowKeyValue);
                const highRow = highContainer.getRow(inputs);
                const lowRow = lowContainer.getRow(inputs);
                return TableUtilities.mergeRows(highRow, lowRow, proportion);
            }
        }
        return TableUtilities.createUndefinedRow(this.#outputKeys);
    }
}