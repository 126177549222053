import { Transform } from "./transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";

/* Passes input to output and vice versa.  This supports situations in which
   there is a need to connect multiple output parameters to a parameter mapping.
*/
export class NullOpTransform extends Transform {
    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterInName, this.id),
            new Parameter(ParameterNames.ParameterOutName, this.id),
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const parameterName = parameter.name;
        let outputParameterName;
        switch (parameterName) {
            case ParameterNames.ParameterInName:
                outputParameterName = ParameterNames.ParameterOutName;
                break;
            case ParameterNames.ParameterOutName:
                outputParameterName = ParameterNames.ParameterInName;
                break;
            // no default
        }
        this._emitParameterValue(outputParameterName, value);
    }
}