import { useEffect } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import SETTINGS from "../config";

const useInputAutofocus = (inputRef, enabled, dependencies, delayMilliseconds = SETTINGS.INPUT_AUTOFOCUS_DELAY_MILLISECONDS) => {
    const minimumSizeForAutofocus = useMediaQuery("(min-width: 481px) and (min-height: 850px)");

    // delayMilliseconds can be used to ensure that the UI doesn't jump and any transitions remain smooth

    useEffect(() => {
        if (!enabled) return;

        const input = inputRef.current;
        if (input) {
            setTimeout(() => {
                if (minimumSizeForAutofocus) {
                    input.focus();
                }
                if (input.nodeName === "INPUT") {
                    input.select();
                }
            }, delayMilliseconds)
        };
    }, [inputRef, enabled, minimumSizeForAutofocus, ...dependencies, delayMilliseconds]);
};

export default useInputAutofocus;