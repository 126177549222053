import styles from "./Login.module.css";
import commonStyles from "./Common.module.css";
import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import { emailRegex } from "../utils/validation";

const Login = () => {
    const emailRef = useRef();
    const errRef = useRef();

    const { signIn, requestPasswordReset } = useAuthContext();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/hangar";

    const [email, setEmail] = useState("");
    const [emailIsSomething, setEmailIsSomething] = useState(false);
    const [emailHasErrors, setEmailHasErrors] = useState(true);
    const [emailClassName, setEmailClassName] = useState(null);
    const [password, setPassword] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [canProceed, setCanProceed] = useState(false);
    const [forgottenPassword, setForgottenPassword] = useState(false);
    const [buttonText, setButtonText] = useState("Sign In");
    const [statusMessageClassName, setStatusMessageClassName] = useState(styles.status);

    useEffect(() => {
        emailRef.current.focus();
    }, []);

    useEffect(() => {
        setCanProceed(!emailHasErrors && (forgottenPassword || password !== ""));
    }, [emailHasErrors, password, forgottenPassword]);

    useEffect(() => {
        updateStatusMessage("");
    }, [email, password, forgottenPassword]);

    useEffect(() => {
        setButtonText(forgottenPassword ? "Request Reset" : "Sign In");
    }, [forgottenPassword]);

    useEffect(() => {
        setEmailIsSomething(email?.length > 0);
        setEmailHasErrors(!emailRegex.test(email));
    }, [email]);

    useEffect(() => {
        const className = getClassName(emailIsSomething && emailHasErrors);
        setEmailClassName(className);
    }, [emailIsSomething, emailHasErrors]);

    const getClassName = (invalidCondition) => {
        let className = commonStyles.input;
        if (invalidCondition) {
            className += ` ${commonStyles.input___invalid}`;
        }
        return className;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        clearEntries();
        await (forgottenPassword ? sendPasswordResetRequest() : requestSignIn());
    };

    const sendPasswordResetRequest = async () => {
        try {
            await requestPasswordReset(email);
        }
        catch {
            // 404 (email not found) or 429 (too many requests)
        }
        finally {
            // Display the same message regardless
            updateStatusMessage("A password-reset request has been sent to the supplied email address.");
        }
    };

    const requestSignIn = async () => {
        try {
            await signIn(email, password);
            navigate(from, { replace: true });
        }
        catch (err) {
            const message = getErrorMessage(err);
            updateStatusMessage(message, true);
        }
    };

    const getErrorMessage = (err) => {
        let message;
        const response = err?.response;
        if (!response) {
            message = err?.message ? err.message : "The server did not respond.  Please check your network connection.";
        }
        else if (response.status === 400) { // Bad request
            message = "Missing email address or password.";
        }
        else if (response.status === 401) { // Unauthorised
            message = "Not authorised.";
        }
        else {
            message = "Login failed.";
        }
        return message;
    };

    const updateStatusMessage = (message, isError = false) => {
        setStatusMessage(message);
        let className = styles.status;
        if (isError) {
            className += ` ${styles.status___error}`;
        }
        setStatusMessageClassName(className);
    };

    const clearEntries = () => {
        updateStatusMessage("");
        setEmail("");
        setPassword("");
    };

    const handleValueChange = () => {
        setForgottenPassword(value => !value);
    }

    return (
        <section>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formInputs}>
                    <input
                        className={emailClassName}
                        type="text"
                        placeholder="Email"
                        ref={emailRef}
                        autoComplete="off"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        required
                    />
                    {!forgottenPassword && <input
                        className={commonStyles.input}
                        type="password"
                        placeholder="Password"
                        autoComplete="off"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        required
                    />}
                    <div className={styles.forgottenPasswordContainer}>
                        <input
                            id="forgottenPasswordCheck"
                            className={styles.checkbox}
                            type="checkbox"
                            checked={forgottenPassword}
                            onChange={handleValueChange} />
                        <label htmlFor="forgottenPasswordCheck" className={styles.paddedLabel}>Forgotten password?</label>
                    </div>
                    
                </div>
                <button className={`${commonStyles.button} ${styles.button}`} type="submit" disabled={!canProceed}>{buttonText}</button>
            </form>
            <p ref={errRef} className={statusMessageClassName}>{statusMessage}</p>
        </section>
    )
}

export default Login;