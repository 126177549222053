import { NullOpParameterMapping } from "./null-op-parameter-mapping";
import { isSomething } from "../common/utilities";

export class NumericParameterMapping extends NullOpParameterMapping {
    #minimumValue;
    #maximumValue;
    #step;
    
    constructor(name, parameter, minimumValue = null, maximumValue = null, step = 0.05) {
        super(name, parameter);
        if (isSomething(minimumValue) && isSomething(maximumValue) && minimumValue > maximumValue) {
            throw new Error("Minimum must be less than or equal to maximum.");
        }
        this.#minimumValue = minimumValue;
        this.#maximumValue = maximumValue;
        this.#step = step;
    }

    get typeName() {
        return "NumericParameterMapping";
    }

    get minimumValue() {
        return this.#minimumValue;
    }

    get maximumValue() {
        return this.#maximumValue;
    }

    get step() {
        return this.#step;
    }

    _getDefaultValue() {
        return 0;
    }

    _isValidValue(value) {
        return typeof value === "number" &&
            !isNaN(value) &&
            isFinite(value) &&
            !(this.#isLessThanMinimum(value) || this.#isGreaterThanMaximum(value));
    }

    #isLessThanMinimum(value) {
        return (isSomething(this.minimumValue) && value < this.minimumValue);
    }

    #isGreaterThanMaximum(value) {
        return (isSomething(this.maximumValue) && value > this.maximumValue);
    }
}