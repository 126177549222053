/* Uses a predicate to test a value.  If the predicate is passed then
   the value is returned; otherwise the value is coerced by an adjuster
   and the coerced value is returned.
*/
export class Guard {
    #predicate;
    #adjuster;
    
    constructor(predicate, adjuster) {
        this.#predicate = predicate;
        this.#adjuster = adjuster;
    }

    test(value) {
        if (this.#predicate.test(value)) {
            return value;
        }
        return this.#adjuster.coerce(value);
    }
}