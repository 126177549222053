export class Point {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    static nullPoint = new Point(NaN, NaN);

    equals(other) {
        return this.x === other.x && this.y === other.y;
    }
}