import { createContext, useCallback } from "react";
import usePersistedState from "../hooks/usePersistedState";
import { axiosPublic, axiosPrivate, axiosGet, axiosPost, axiosPut } from "../api/axios";
import { isSomething } from "../lib/common/utilities";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

const LOGIN_URL = "/auth";
const REGISTER_URL = "/register";
const VERIFY_URL = "/verify/:verificationCode";
const REQUEST_PASSWORD_RESET_URL = "/request-password-reset";
const RESET_PASSWORD_URL = "/reset-password";
const REFRESH_URL = "/refresh";
const LOGOUT_URL = "/logout";

const signOutSubscribers = new Set();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = usePersistedState("auth", {});
    
    const setAccessToken = useCallback((accessToken) => {
        setAuth(prev => ({ ...prev, accessToken }));
    }, [setAuth]);

    const clearAuth = useCallback(() => {
        setAuth(prev => {
            delete prev.accessToken;
            delete prev.roles;
            return { ...prev };
        });
    }, [setAuth]);

    const signIn = useCallback(async (email, password) => {
        const response = await axiosPost(axiosPrivate, LOGIN_URL, JSON.stringify({ email, password }), null, (err) => { throw err });
        const data = response?.data;
        const succeeded = isSomething(data);
        if (succeeded) {
            setAccessToken(data.accessToken);
            const roles = data.roles.split(",");
            setAuth(prev => ({ ...prev, roles }));
        }
        else {
            console.log("No data from auth response.");
        }
        return succeeded;
    }, [setAccessToken, setAuth]);

    /* Returns true if details were registered or updated for a pending registration.
       Returns false if the details pertain to an already-verified account.
       Otherwise throws an exception.
    */
    const register = useCallback(async (name, email, password) => {
        const response = await axiosPost(axiosPrivate, REGISTER_URL, JSON.stringify({ name, email, password }), null, (err) => { throw err });
        const data = response?.data;
        return isSomething(data) && data.alreadyVerified !== true;
    }, []);

    const verify = useCallback(async (verificationCode) => {
        const response = await axiosPost(axiosPrivate, VERIFY_URL, JSON.stringify({ verificationCode }), null, (err) => { throw err });
        return response?.data;
    }, []);

    const requestPasswordReset = useCallback(async (email) => {
        await axiosPost(axiosPrivate, REQUEST_PASSWORD_RESET_URL, JSON.stringify({ email }), null, (err) => { throw err });
    }, []);

    const resetPassword = useCallback(async (token, password) => {
        await axiosPut(axiosPrivate, RESET_PASSWORD_URL, JSON.stringify({ token, password }), null, (err) => { throw err });
    }, []);

    const refresh = useCallback(async () => {
        let accessToken;
        const onSuccess = data => {
            accessToken = data.accessToken;
        };
        const onError = () => {
            accessToken = null;
        }
        await axiosGet(axiosPublic, REFRESH_URL, { withCredentials: true }, onSuccess, onError);
        setAccessToken(accessToken);
        return accessToken;
    }, [setAccessToken]);

    const signOut = useCallback(async () => {
        try {
            if (await notifySignOutSubscribers()) {
                clearAuth();
                await axiosPost(axiosPrivate, LOGOUT_URL, undefined, null, (err) => { throw err });
                navigate("/login", { replace: true });
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [clearAuth, navigate]);

    const subscribeSignOut = useCallback((callback) => {
        signOutSubscribers.add(callback);
    }, []);

    const unsubscribeSignOut = useCallback((callback) => {
        signOutSubscribers.delete(callback);
    }, []);

    const notifySignOutSubscribers = async () => {
        const results = await Promise.all(Array.from(signOutSubscribers).map(async (callback) => await callback()));
        return results.every(result => result);
    };

    return (
        <AuthContext.Provider value={{
            auth,
            signIn,
            register,
            verify,
            requestPasswordReset,
            resetPassword,
            refresh,
            signOut,
            subscribeSignOut,
            unsubscribeSignOut
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;