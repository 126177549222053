import styles from "./Hangar.module.css";
import commonStyles from "./Common.module.css";
import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAircraftContext from "../hooks/useAircraftContext";
import useAccessibleAircraftContext from "../hooks/useAccessibleAircraftContext";
import Carousel from "./Carousel";
import { isSomething } from "../lib/common/utilities";
import PerformanceCategoryContainer from "./PerformanceCategoryContainer";
import Operation from "./Operation";
import { useHangarPath } from "../hooks/useHangarPath";
import useOperationContext from "../hooks/useOperationContext";
import PerformanceDataDisclaimerAutoDialogue from "./PerformanceDataDisclaimerAutoDialogue";
import AircraftSelector from "./AircraftSelector";
import { sort } from "../utils/aircraftSorter";
import { Link } from "react-router-dom";
import { ReactComponent as Compress } from "../assets/compress.svg";
import { ReactComponent as Decompress } from "../assets/decompress.svg";

const Hangar = () => {
    const { aircraftCount, aircraftSummaries: unorderedAircraftSummaries, error: hangarAircraftError, isLoading: hangarAircraftLoading } = useAccessibleAircraftContext();
    const [orderedAircraftSummaries, setOrderedAircraftSummaries] = useState([]);
    const [selectedAircraftId, setSelectedAircraftId] = useState(null);
    const [selectedAircraftSummary, setSelectedAircraftSummary] = useState(null);
    const [matchingAircraft, setMatchingAircraft] = useState([]);
    const [allAircraftMessage, setAllAircraftMessage] = useState(null);
    const [showLeasingLink, setShowLeasingLink] = useState(false);
    const [selectedAircraftMessage, setSelectedAircraftMessage] = useState(null);
    const [activeAircraftIndexOverride, setActiveAircraftIndexOverride] = useState(0);
    const [activeDetailIndexOverride, setActiveDetailIndexOverride] = useState(0);
    const { id, categoryIndex, operationIndex } = useParams();
    const { loadAircraftById, clearAircraft, aircraft, error: selectedAircraftError, isLoading: selectedAircraftLoading } = useAircraftContext();
    const [categoryName, setCategoryName] = useState("");
    const [operation, setOperation] = useState(null);
    const [selectedOperationInfo, setSelectedOperationInfo] = useState({ aircraftId: null, categoryIndex: null, operationIndex: null });
    const [headerIsCollapsed, setHeaderIsCollapsed] = useState(false);
    const [allowExpandCollapse, setAllowExpandCollapse] = useState(true);
    const [selectedAircraftClassName, setSelectedAircraftClassName] = useState(styles.selectedAircraft);
    const navigate = useNavigate();
    const { pathRoot, isApron } = useHangarPath();
    const { setIndexes } = useOperationContext();

    useEffect(() => {
        let title = "Avaid - ";
        if (isSomething(selectedAircraftSummary)) {
            title += selectedAircraftSummary.description;
            if (operation && activeDetailIndexOverride === 1) {
                title += " - " + operation.description;
            }
        }
        else if (isApron) {
            title += "Apron";
        }
        else {
            title += "Hangar";
        }
        document.title = title;
    }, [selectedAircraftSummary, activeDetailIndexOverride, isApron, operation]);

    useEffect(() => {
        const summaries = unorderedAircraftSummaries ? sort(unorderedAircraftSummaries) : [];
        setOrderedAircraftSummaries(summaries);
    }, [unorderedAircraftSummaries]);

    const navigateToMissing = useCallback(() => {
        navigate("/*", { replace: true });
    }, [navigate]);

    useEffect(() => {
        if (!isSomething(id)) return;
        const aircraftId = Number(id);
        if (isNaN(aircraftId)) {
            navigateToMissing();
        }
        else if (!aircraft || aircraft.id !== aircraftId) {
            if (unorderedAircraftSummaries) {
                if (unorderedAircraftSummaries.map(s => s.id).includes(aircraftId)) {
                    loadAircraftById(aircraftId);
                }
                else {
                    navigateToMissing();
                }
            }
        }
    }, [id, aircraft, unorderedAircraftSummaries]);

    useEffect(() => {
        const idIsSet = isSomething(id);
        if (idIsSet) {
            const aircraftId = Number(id);
            setSelectedAircraftId(aircraftId);
        }
        else if (aircraftCount === 1 && pathRoot && unorderedAircraftSummaries) {
            navigate(`/${pathRoot}/${unorderedAircraftSummaries[0].id}`, { replace: true });
        }
        else {
            setSelectedAircraftId(null);
            setSelectedAircraftSummary(null);
            clearAircraft();
            setHeaderIsCollapsed(false);
        }
    }, [id, aircraftCount, unorderedAircraftSummaries, pathRoot]);

    useEffect(() => {
        if (isSomething(selectedAircraftId) && isSomething(unorderedAircraftSummaries)) {
            let index = 0;
            while (index < unorderedAircraftSummaries.length) {
                if (unorderedAircraftSummaries[index].id === selectedAircraftId) {
                    break;
                }
                index++;
            }
            if (index >= aircraftCount) {
                setSelectedAircraftId(null);
                setSelectedAircraftSummary(null);
                clearAircraft();
                navigateToMissing();
            }
            else {
                setSelectedAircraftSummary(unorderedAircraftSummaries[index]);
            }
        }
    }, [selectedAircraftId, unorderedAircraftSummaries, aircraftCount]);

    const deselectOperation = useCallback(() => {
        setSelectedOperationInfo(info => ({ ...info, categoryIndex: null, operationIndex: null }));
    }, []);

    useEffect(() => {
        if (isSomething(aircraft) && aircraft.id === selectedAircraftId) {
            const ciSet = isSomething(categoryIndex);
            const oiSet = isSomething(operationIndex);
            if (ciSet && oiSet) {
                const ci = parseInt(categoryIndex);
                const oi = parseInt(operationIndex);
                if (isNaN(categoryIndex) || isNaN(operationIndex)) {
                    deselectOperation();
                    navigateToMissing();
                }
                else {
                    setSelectedOperationInfo({
                        aircraftId: selectedAircraftId,
                        categoryIndex: ci,
                        operationIndex: oi
                    });
                }
            }
            else if (ciSet || oiSet) {
                deselectOperation();
                navigateToMissing();
            }
            else {
                deselectOperation();
            }
        }
        else {
            deselectOperation();
        }
    }, [aircraft, selectedAircraftId, categoryIndex, operationIndex]);

    useEffect(() => {
        const categoryIndex = selectedOperationInfo.categoryIndex;
        const operationIndex = selectedOperationInfo.operationIndex;
        if (aircraft && selectedAircraftId === selectedOperationInfo.aircraftId && isSomething(categoryIndex) && isSomething(operationIndex)) {
            const categories = aircraft.getPerformanceCategories();
            if (categoryIndex >= 0 && categoryIndex < categories.length) {
                const category = categories[categoryIndex];
                setCategoryName(category.name);
                const operations = category.getOperations();
                if (operationIndex >= 0 && operationIndex < operations.length) {
                    setOperation(operations[operationIndex]);
                    setActiveDetailIndexOverride(1);
                    return;
                }
            }
            navigateToMissing();
        }
        else {
            setActiveDetailIndexOverride(0);
        }
    }, [aircraft, selectedAircraftId, selectedOperationInfo]);

    useEffect(() => {
        setIndexes({
            categoryIndex: selectedOperationInfo.categoryIndex,
            operationIndex: selectedOperationInfo.operationIndex
        });
    }, [setIndexes, selectedOperationInfo]);

    useEffect(() => {
        setShowLeasingLink(false);
        if (hangarAircraftLoading) {
            setAllAircraftMessage("Fetching available aircraft details...");
        }
        else if (hangarAircraftError) {
            setAllAircraftMessage(hangarAircraftError);
        }
        else if (aircraftCount > 0) {
            setAllAircraftMessage(null);
        }
        else {
            setAllAircraftMessage("Your hangar is empty");
            setShowLeasingLink(true);
        }
    }, [hangarAircraftLoading, hangarAircraftError, aircraftCount]);

    useEffect(() => {
        if (selectedAircraftSummary) {
            if (selectedAircraftLoading) {
                setSelectedAircraftMessage("Fetching selected aircraft details...");
            }
            else if (selectedAircraftError || !aircraft) {
                setSelectedAircraftMessage("There is a problem with this aircraft");
            }
            else {
                setSelectedAircraftMessage(null);
            }
        }
        else {
            setSelectedAircraftMessage(null);
        }
    }, [aircraft, selectedAircraftError, selectedAircraftLoading, selectedAircraftSummary]);

    const getAircraftCarouselIndex = useCallback((aircraftId) => {
        let index = 0;
        if (matchingAircraft) {
            while (index < matchingAircraft.length) {
                if (matchingAircraft[index].id === aircraftId) {
                    break;
                }
                index++;
            }
            if (index >= matchingAircraft.length) {
                index = 0;
            }
        }
        return index;
    }, [matchingAircraft]);

    const setActiveAircraft = useCallback((aircraftId) => {
        const index = getAircraftCarouselIndex(aircraftId);
        setActiveAircraftIndexOverride(index);
    }, [getAircraftCarouselIndex]);

    const handleSelectOperation = useCallback((categoryIndex, operationIndex) => {
        setSelectedOperationInfo({ aircraftId: selectedAircraftId, categoryIndex: categoryIndex, operationIndex: operationIndex });
        navigate(`/${pathRoot}/${selectedAircraftId}/category/${categoryIndex}/operation/${operationIndex}`);
    }, [pathRoot, selectedAircraftId, navigate]);

    useEffect(() => {
        if (isSomething(aircraft)) {
            setActiveAircraft(aircraft.id);
        }
    }, [aircraft, matchingAircraft, setActiveAircraft]);

    useEffect(() => {
        let className = `${styles.selectedAircraft}`;
        if (headerIsCollapsed) {
            className += ` ${styles.selectedAircraft___collapsedHeader}`;
        }
        setSelectedAircraftClassName(className);
    }, [headerIsCollapsed]);

    useEffect(() => {
        setAllowExpandCollapse(aircraftCount > 1);
        setHeaderIsCollapsed(aircraftCount <= 1);
    }, [aircraftCount]);

    const handleBackButton = useCallback(() => {
        deselectOperation();
        navigate(`/${pathRoot}/${selectedAircraftId}`);
    }, [navigate, pathRoot, selectedAircraftId, deselectOperation]);

    const handleExpandCollapseButton = useCallback(() => {
        setHeaderIsCollapsed(value => !value);
    }, []);

    return (
        <section className={styles.Hangar}>
            {allAircraftMessage &&
                <div className={styles.messageContainer}>
                    <p className={styles.message}>{allAircraftMessage}</p>
                    {showLeasingLink &&
                        <p className={styles.subMessage}>Visit <span className={styles.nowrap}><Link className={commonStyles.link} to="/leasing">Leasing</Link> to add some aircraft.</span></p>
                    }
                </div>
            }
            {!allAircraftMessage &&
                <>
                    <AircraftSelector
                        title="What aircraft are you flying today?"
                        expanded={!headerIsCollapsed}
                        aircraftSummaries={orderedAircraftSummaries}
                        matchingAircraft={matchingAircraft}
                        setMatchingAircraft={setMatchingAircraft}
                        activeAircraftIndexOverride={activeAircraftIndexOverride}
                        selectedAircraftId={selectedAircraftId}
                        handleSelect={(id) => navigate(`/${pathRoot}/${id}`)} />
                    {!selectedAircraftSummary && matchingAircraft.length > 0 &&
                        <div className={styles.messageContainer}>
                            <p className={styles.message}>Select an aircraft from the carousel</p>
                        </div>
                    }
                    {selectedAircraftSummary &&
                        <section className={selectedAircraftClassName}>
                            {allowExpandCollapse && <button
                                className={`${commonStyles.button} ${styles.expandCollapseButton}`}
                                onClick={handleExpandCollapseButton}>
                                {headerIsCollapsed && <Decompress className={styles.expandCollapseImage} />}
                                {!headerIsCollapsed && <Compress className={styles.expandCollapseImage} />}
                            </button>}
                            <section className={styles.aircraftSummary}>
                                <p className={styles.manufacturer}>{selectedAircraftSummary.manufacturer}</p>
                                <h2 className={styles.description}>{selectedAircraftSummary.description}</h2>
                            </section>
                            <hr />
                            {selectedAircraftMessage &&
                                <div className={styles.messageContainer}>
                                    <p className={styles.message}>{selectedAircraftMessage}</p>
                                </div>
                            }
                            {!selectedAircraftMessage &&
                                <Carousel viewportItemCount={1} activeIndexOverride={activeDetailIndexOverride} allowUserNavigation={false}>
                                    <div className={styles.fullWidth} inert={activeDetailIndexOverride === 1 ? "" : undefined}>
                                        <PerformanceCategoryContainer aircraft={aircraft} handleSelectOperation={handleSelectOperation} />
                                    </div>
                                    <div className={styles.fullWidth} inert={activeDetailIndexOverride === 0 ? "" : undefined}>
                                        <Operation
                                            categoryName={categoryName}
                                            operation={operation}
                                            handleBackButtonClick={handleBackButton} />
                                    </div>
                                </Carousel>
                            }
                        </section>
                    }
                    <PerformanceDataDisclaimerAutoDialogue />
                </>
            }
        </section>
    )
}

export default Hangar;