import styles from "./Ops.module.css";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import AccountManager from "./AccountManager";
import Feedback from "./Feedback";
import Tabs from "./Tabs";
import { MessageProvider } from "../context/MessageContext";

const Ops = () => {
    const { auth } = useAuthContext();
    const [searchParams] = useSearchParams();
    const [requestedSubject, setRequestedSubject] = useState(null);
    const [requestedTabLabel, setRequestedTabLabel] = useState("Account");

    useEffect(() => {
        document.title = "Avaid - Ops";
    }, []);

    useEffect(() => {
        const count = searchParams.size;
        if (count === 1) {
            const subject = searchParams.get("subject");
            if (subject) {
                setRequestedTabLabel("Feedback");
                setRequestedSubject(subject);
            }
        }
    }, [searchParams]);

    return (
        <section className={styles.Ops}>
            <MessageProvider>
                {auth.accessToken && 
                    <Tabs initialTabLabel={requestedTabLabel}>
                        <AccountManager label="Account" />
                        <Feedback label="Feedback" selectedSubject={requestedSubject} />
                    </Tabs>
                }
                {!auth.accessToken &&
                    <>
                        <h2 className={styles.feedbackHeading}>Feedback</h2>
                        <Feedback selectedSubject={requestedSubject} />
                    </>
                }
            </MessageProvider>
        </section>
    )
}

export default Ops;