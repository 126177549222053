import { areSomething } from "../common/utilities";
import { IsaDifferenceCalculator } from "./isa-difference-calculator";

// Requires altitudes in feet, temperatures in degrees Celsius and QNH in hPa.
// See https://edwilliams.org/avform147.htm.
export class AltitudeCalculator {
    static #isaDifferenceCalculator = new IsaDifferenceCalculator();
    static #isaSeaLevelPressure = 1013.25; // hPa
    static #isSeaLevelTemperature = 288.15; // K

    static #getAltitudeCorrection(qnh) {
        return 145442.2 * (1 - (qnh / AltitudeCalculator.#isaSeaLevelPressure) ** 0.190261);
    }

    // Returns values in feet.
    getPressureAltitude(indicatedAltitude, qnh) {
        if (areSomething(indicatedAltitude, qnh)) {
            const correction = AltitudeCalculator.#getAltitudeCorrection(qnh);
            return indicatedAltitude + correction;
        }
        return null;
    }

    // Returns values in feet.
    getIndicatedAltitude(pressureAltitude, qnh) {
        if (areSomething(pressureAltitude, qnh)) {
            const correction = AltitudeCalculator.#getAltitudeCorrection(qnh);
            return pressureAltitude - correction;
        }
        return null;
    }

    // Returns values in feet.
    getDensityAltitude(pressureAltitude, temperature) {
        if (areSomething(pressureAltitude, temperature)) {
            const isaDifference = AltitudeCalculator.#isaDifferenceCalculator.getIsaDifference(pressureAltitude, temperature);
            return pressureAltitude + 120 * isaDifference;
        }
        return null;
    }

    // Returns values in feet.
    getTrueAltitude(indicatedAltitude, qnh, pressureDatumElevation, oat) {
        if (areSomething(indicatedAltitude, qnh, pressureDatumElevation, oat)) {
            const pressureAltitude = this.getPressureAltitude(indicatedAltitude, qnh);
            const isaDifference = AltitudeCalculator.#isaDifferenceCalculator.getIsaDifference(pressureAltitude, oat);
            const temperatureErrorCorrection = (indicatedAltitude - pressureDatumElevation) * isaDifference / AltitudeCalculator.#isSeaLevelTemperature;
            return indicatedAltitude + temperatureErrorCorrection;
        }
        return null;
    }
}