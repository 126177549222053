import styles from "./ParameterMappingSummary.module.css";
import { useState, useEffect, useCallback } from "react";
import "../lib/array-extensions";
import { ReactComponent as Warning } from "../assets/warning.svg";

const ParameterMappingSummary = ({ parameterMapping, index, updateRequestIndexes, selected }) => {
    const [enabled, setEnabled] = useState(true);
    const [description, setDescription] = useState("");
    const [outsideLimits, setOutsideLimits] = useState(false);
    const [containerClassName, setContainerClassName] = useState("");
    
    useEffect(() => {
        let className = `${styles.ParameterMappingSummary}`;
        if (!enabled) {
            className += selected ? ` ${styles.selected}` : ` ${styles.disabled}`;
        }
        setContainerClassName(className);
    }, [enabled, selected]);

    const getGroupDescription = useCallback((parameterMappingKeys) => {
        const descriptionInfo = parameterMapping.parseDescriptionPlaceholders();
        const placeholderCount = descriptionInfo.placeholderSequence.length;
        if (parameterMappingKeys.length !== placeholderCount) {
            throw new Error(`Expected ${placeholderCount} parameter(s), received ${parameterMappingKeys.length}.`);
        }

        let description = "";
        let outsideLimits = false;
        descriptionInfo.placeholderSequence.map((parameterIndex, fragmentIndex) => {
            const key = parameterMappingKeys[parameterIndex];
            const childMapping = parameterMapping.getParameterMapping(key);
            const value = childMapping.getMappingValue();
            if (value === null) {
                outsideLimits = true;
                return;
            }
            description += `${descriptionInfo.fragments[fragmentIndex]} ${value}`;
        });
        description += descriptionInfo.fragments[descriptionInfo.fragments.length - 1];
        return outsideLimits ? null : description;
    }, [parameterMapping]);

    const getSummary = useCallback(() => {
        let enabled;
        let description;
        switch (parameterMapping.typeName) {
            case "UnitParameterMapping":
            case "UnitMultiParameterMapping": {
                enabled = true;
                const unit = parameterMapping.getMappingValue();
                if (unit === null) {
                    description = null;
                }
                else if (parameterMapping.hasDetents) {
                    const detent = parameterMapping.detents.firstOrUndefined(d => unit.equals(d.value));
                    description = detent ? detent.label : `${unit}`;
                }
                else {
                    description = `${unit}`;
                }
                break;
            }
            case "NumericParameterMapping":
            case "NumericMultiParameterMapping": {
                enabled = true;
                const value = parameterMapping.getMappingValue();
                if (value === null) {
                    description = null;
                }
                else {
                    description = `${value}`;
                }
                break;
            }
            case "BooleanParameterMapping":
            case "BooleanMultiParameterMapping": {
                enabled = true;
                description = `${parameterMapping.getMappingValue() ? "yes" : "no"}`;
                break;
            }
            case "ParameterMappingGroup":
                const enabledParameterMapping = parameterMapping.getParameterMapping("Enabled");
                enabled = enabledParameterMapping.getMappingValue() === true;
                if (enabled) {
                    let parameterMappingKeys;
                    switch (parameterMapping.type) {
                        case "OptionalFactorParameterGroup": {
                            parameterMappingKeys = ["Factor"];
                            break;
                        }
                        case "OptionalScaledFactorParameterGroup": {
                            parameterMappingKeys = ["Amount", "Factor", "Divisor"];
                            break;
                        }
                        default:
                            throw new Error(`"${parameterMapping.type}" is not a recognised parameter mapping group type.`);
                    }
                    description = getGroupDescription(parameterMappingKeys);
                }
                else {
                    description = "not selected";
                }
                break;

            default:
                throw new Error(`"${parameterMapping.typeName}" is not a recognised parameter mapping type.`);
        }
        
        return {
            enabled,
            description
        };
    }, [parameterMapping, getGroupDescription]);

    const updateState = useCallback(() => {
        const summary = getSummary();
        setEnabled(summary.enabled);
        setDescription(summary.description);
        setOutsideLimits(summary.description === null);
    }, [getSummary]);

    useEffect(() => {
        updateState();
    }, [updateState]);

    useEffect(() => {
        if (updateRequestIndexes.size === 0 || updateRequestIndexes.has(index)) {
            updateState();
        }
    }, [updateState, index, updateRequestIndexes]);

    return <div className={containerClassName}>
        <p className={styles.name}>{parameterMapping.name}:</p>
        {outsideLimits && <Warning title="Outside limits" width="24" height="24" />}
        {!outsideLimits && <p className={styles.description}>{description}</p>}
    </div>
}

export default ParameterMappingSummary;