import { Outlet } from 'react-router-dom';
import { AdminProvider } from '../context/AdminContext';

const AdminLayout = () => {
    return (
        <AdminProvider>
            <Outlet />
        </AdminProvider>
    );
};

export default AdminLayout;