import { NullOpParameterMapping } from "./null-op-parameter-mapping";

export class BooleanParameterMapping extends NullOpParameterMapping {
    get typeName() {
        return "BooleanParameterMapping";
    }
    
    _getDefaultValue() {
        return false;
    }

    _isValidValue(value) {
        return value === true || value === false;
    }
}