import styles from "./Operation.module.css";
import commonStyles from "./Common.module.css";
import OperationDetail from "./OperationDetail";
import useParameterContext from "../hooks/useParameterContext";
import useAircraftContext from "../hooks/useAircraftContext";
import { useState, useCallback } from "react";
import ModalDialogue from "./ModalDialogue";
import { ReactComponent as BackArrow } from "../assets/back-arrow.svg";
import { ReactComponent as Reset } from "../assets/reset.svg";

const BUTTON_KEY_NO = "no";
const BUTTON_KEY_YES = "yes";

const PROMPT_BUTTON_INFO = [
    {
        key: BUTTON_KEY_NO,
        caption: "No"
    },
    {
        key: BUTTON_KEY_YES,
        caption: "Yes"
    }
];

const PROMPT_HEADER = <div className={styles.bannerContainer}>
    <h2>Reset Parameter Settings</h2>
</div>;

const Operation = ({ categoryName, operation, handleBackButtonClick }) => {
    const { active, available, clearOperationInfo } = useParameterContext();
    const { reload } = useAircraftContext();
    const [promptIsOpen, setPromptIsOpen] = useState(false);
    
    const handleResetButton = useCallback(() => {
        setPromptIsOpen(true);
    }, []);

    const requestClose = useCallback((buttonKey) => {
        setPromptIsOpen(false);
        if (buttonKey === BUTTON_KEY_YES) {
            clearOperationInfo();
            reload();
        }
    }, [clearOperationInfo, reload]);

    return (
        <section className={styles.Operation}>
            <button
                className={`${commonStyles.button} ${styles.backButton}`}
                onClick={handleBackButtonClick}>
                <BackArrow className={styles.operationImage} />
            </button>
            {active && operation && <button
                className={`${commonStyles.button} ${styles.resetButton}`}
                onClick={handleResetButton}
                disabled={!available}>
                <Reset className={styles.operationImage} />
            </button>}
            <h2 className={styles.categoryHeading}>{categoryName}</h2>
            <h3 className={styles.operationHeading}>{operation?.description}</h3>
            {operation && <OperationDetail operation={operation} />}
            <ModalDialogue
                isOpen={promptIsOpen}
                requestClose={requestClose}
                buttonInfo={PROMPT_BUTTON_INFO}
                header={PROMPT_HEADER}>
                <p className={styles.promptContent}>This action will delete your parameter settings
                    for the current operation and restore default values.  Are you sure?</p>
            </ModalDialogue>
        </section>
    )
}

export default Operation;