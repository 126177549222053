import axios from "axios";
const location = window.location;
const baseURL = `${location.protocol}//${location.hostname}${location.hostname === "localhost" ? ":3046" : ""}`;

const createApiUrl = (url) => {
    return `/api${url}`;
};

export const axiosPublic = axios.create({
    baseURL: baseURL
});

export const axiosPrivate = axios.create({
    baseURL: baseURL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

const axiosRequest = async (axiosAction, successHandler = null, errorHandler = null, onConclusion = null) => {
    return await axiosAction()
        .then(response => {
            if (successHandler) {
                successHandler(response.data);
            }
        })
        .catch(function (error) {
            if (errorHandler) {
                errorHandler(error);
            }
            else {
                console.log(error.toJSON());
            }
        })
        .finally(() => {
            if (onConclusion) {
                onConclusion();
            }
        });
}

export const axiosGet = async (axiosInstance, url, options = {}, successHandler = null, errorHandler = null, onConclusion = null) => {
    const apiUrl = createApiUrl(url);
    let response;
    await axiosRequest(async () => response = await axiosInstance.get(apiUrl, options), successHandler, errorHandler, onConclusion);
    return response;
}

export const axiosPut = async (axiosInstance, url, data, successHandler = null, errorHandler = null, onConclusion = null) => {
    const apiUrl = createApiUrl(url);
    let response;
    await axiosRequest(async () => response = await axiosInstance.put(apiUrl, data), successHandler, errorHandler, onConclusion);
    return response;
}

export const axiosPost = async (axiosInstance, url, data, successHandler = null, errorHandler = null, onConclusion = null) => {
    const apiUrl = createApiUrl(url);
    let response;
    await axiosRequest(async () => response = await axiosInstance.post(apiUrl, data), successHandler, errorHandler, onConclusion);
    return response;
}