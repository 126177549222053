import { areSomething } from "../common/utilities";
import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";

export class RangeCurveSetGroupTransform extends Transform {
    constructor(rangeCurveSetGroup) {
        super();
        this.rangeCurveSetGroup = rangeCurveSetGroup;
    }

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterRangeCurveSetGroupValueName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterRangeCurveSetValueName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterXName, this.id),
            new Parameter(ParameterNames.ParameterYName, this.id)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const parameterName = parameter.name;
        switch (parameterName) {
            case ParameterNames.ParameterXName:
                this._clearParameterValue(ParameterNames.ParameterYName);
                break;
            case ParameterNames.ParameterYName:
                this._clearParameterValue(ParameterNames.ParameterXName);
                break;
            // no default
        }
        
        const count = this._setParameterValueCount;
        if (count === 3) {
            const outputParameterName = this.getUnsetParameterNames()[0];
            let result;
            switch (outputParameterName) {
                case ParameterNames.ParameterXName:
                    result = this.#calculateX();
                    break;
                case ParameterNames.ParameterYName:
                    result = this.#calculateY();
                    break;
                default:
                    throw new Error(`Unknown output parameter: '${outputParameterName}'.`);
            }
            this._emitParameterValue(outputParameterName, result);
        }
    }

    #calculateX() {
        let result;
        const groupValue = this._getParameterValue(ParameterNames.ParameterRangeCurveSetGroupValueName);
        const rangeValue = this._getParameterValue(ParameterNames.ParameterRangeCurveSetValueName);
        const y = this._getParameterValue(ParameterNames.ParameterYName);
        if (areSomething(groupValue, rangeValue, y)) {
            const curve = this.rangeCurveSetGroup.getCurve(groupValue, rangeValue);
            result = curve?.getX(y, false) ?? null;
        }
        else {
            result = null;
        }
        return result;
    }

    #calculateY() {
        let result;
        const groupValue = this._getParameterValue(ParameterNames.ParameterRangeCurveSetGroupValueName);
        const rangeValue = this._getParameterValue(ParameterNames.ParameterRangeCurveSetValueName);
        const x = this._getParameterValue(ParameterNames.ParameterXName);
        if (areSomething(groupValue, rangeValue, x)) {
            const curve = this.rangeCurveSetGroup.getCurve(groupValue, rangeValue);
            result = curve?.getY(x, false) ?? null;
        }
        else {
            result = null;
        }
        return result;
    }
}