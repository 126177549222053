import styles from "./OperationDetail.module.css";
import Expander from "./Expander";
import OutputParameterCategory from "./OutputParameterCategory";
import InputParameterSequencer from "./InputParameterSequencer";

const OperationDetail = ({ operation }) => {
    const notes = operation.getNotes();
    return <section className={styles.OperationDetail}>
        <InputParameterSequencer operation={operation} />

        <OutputParameterCategory category={operation.outputParameterCategory} />
        
        {notes.length > 0 &&
            <Expander title="Notes">
                <ol>
                    {notes.map((note, index) => <li key={index} className={styles.operationNote}>{note}</li>)}
                </ol>
            </Expander>
        }
    </section>
}

export default OperationDetail;