import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import { rolesIntersect } from "../utils/roleEvaluator";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuthContext();
    const location = useLocation();

    return (
        rolesIntersect(allowedRoles, auth.roles)
            ? <Outlet />
            : auth.accessToken
                ? <Navigate to="/unauthorised" state={{ from: location }} replace={true} />
                : <Navigate to="/login" state={{ from: location }} replace={true} />
    );
}

export default RequireAuth;