import { areSomething } from "../common/utilities";

// Requires altitudes in feet and temperatures in degrees Celsius.
export class IsaDifferenceCalculator {
    static #isaSeaLevelTemperature = 15; // degrees Celsius
    static #lapseRate = 1.98; // degrees Celsius
    static #altitudeScalar = 1000;

    getIsaDifference(pressureAltitude, temperature) {
        if (areSomething(pressureAltitude, temperature)) {
            return temperature - (IsaDifferenceCalculator.#isaSeaLevelTemperature - IsaDifferenceCalculator.#lapseRate * pressureAltitude / IsaDifferenceCalculator.#altitudeScalar);
        }
        return null;
    }

    getTemperature(pressureAltitude, isaDifference) {
        if (areSomething(pressureAltitude, isaDifference)) {
            return isaDifference + IsaDifferenceCalculator.#isaSeaLevelTemperature - IsaDifferenceCalculator.#lapseRate * pressureAltitude / IsaDifferenceCalculator.#altitudeScalar;
        }
        return null;
    }

    getPressureAltitude(temperature, isaDifference) {
        if (areSomething(temperature, isaDifference)) {
            return (IsaDifferenceCalculator.#isaSeaLevelTemperature - temperature + isaDifference) * IsaDifferenceCalculator.#altitudeScalar / IsaDifferenceCalculator.#lapseRate;
        }
        return null;
    }
}