import { Transform } from "../transforms/transform";
import { IsaDifferenceCalculator } from "../calculators/isa-difference-calculator";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";

export class IsaDifferenceTransform extends Transform {
    #isaDifferenceCalculator = new IsaDifferenceCalculator();

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterAltitudeName, this.id),
            new Parameter(ParameterNames.ParameterTemperatureName, this.id),
            new Parameter(ParameterNames.ParameterIsaDifferenceName, this.id)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const count = this._setParameterValueCount;
        if (count === 2) {
            const outputParameterName = this.getUnsetParameterNames()[0];
            let result;
            switch (outputParameterName) {
                case ParameterNames.ParameterAltitudeName:
                    result = this.#calculateAltitude();
                    break;
                case ParameterNames.ParameterTemperatureName:
                    result = this.#calculateTemperature();
                    break;
                case ParameterNames.ParameterIsaDifferenceName:
                    result = this.#calculateIsaDifference();
                    break;
                default:
                    throw new Error(`Unknown output parameter: '${outputParameterName}'.`);
            }
            this._emitParameterValue(outputParameterName, result);
        }
        else if (count > 2) {
            this._throwCannotIdentifyOutputParameters();
        }
    }

    #calculateAltitude() {
        const temperature = this._getParameterValue(ParameterNames.ParameterTemperatureName);
        const isaDifference = this._getParameterValue(ParameterNames.ParameterIsaDifferenceName);
        return this.#isaDifferenceCalculator.getPressureAltitude(temperature, isaDifference);
    }

    #calculateTemperature() {
        const altitude = this._getParameterValue(ParameterNames.ParameterAltitudeName);
        const isaDifference = this._getParameterValue(ParameterNames.ParameterIsaDifferenceName);
        return this.#isaDifferenceCalculator.getTemperature(altitude, isaDifference);
    }

    #calculateIsaDifference() {
        const altitude = this._getParameterValue(ParameterNames.ParameterAltitudeName);
        const temperature = this._getParameterValue(ParameterNames.ParameterTemperatureName);
        return this.#isaDifferenceCalculator.getIsaDifference(altitude, temperature);
    }
}