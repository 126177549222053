import { areSomething } from "../common/utilities";
import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { Point } from "./point";
import { CurveSelectionMethod } from "./curve-selection-method";

/* A graph transform that uses two x-axis parameters and two y-axis parameters.
*/
export class CurveSetTransform extends Transform {
    constructor(curveSet, safeBoundaryCurveCombinations = null) {
        super();
        this.curveSet = curveSet;
        this.safeBoundaryCurveCombinations = safeBoundaryCurveCombinations;
    }

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterY0Name, this.id),
            new Parameter(ParameterNames.ParameterY1Name, this.id),
            new Parameter(ParameterNames.ParameterX0Name, this.id),
            new Parameter(ParameterNames.ParameterX1Name, this.id)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const count = this._setParameterValueCount;
        if (count === 3) {
            const outputParameterName = this.getUnsetParameterNames()[0];
            let result;
            switch (outputParameterName) {
                case ParameterNames.ParameterY0Name:
                    result = this.#calculateY0();
                    break;
                case ParameterNames.ParameterY1Name:
                    result = this.#calculateY1();
                    break;
                case ParameterNames.ParameterX0Name:
                    result = this.#calculateX0();
                    break;
                case ParameterNames.ParameterX1Name:
                    result = this.#calculateX1();
                    break;
                default:
                    throw new Error(`Unknown output parameter: '${outputParameterName}'.`);
            }
            this._emitParameterValue(outputParameterName, result);
        }
        else if (count > 3) {
            this._throwCannotIdentifyOutputParameters();
        }
    }

    #calculateY0() {
        let result;
        const x0 = this._getParameterValue(ParameterNames.ParameterX0Name);
        const curve = this.#getCurve(ParameterNames.ParameterX1Name, ParameterNames.ParameterY1Name, ParameterNames.ParameterY0Name);
        if (areSomething(x0, curve)) {
            result = curve.getY(x0, false);
        }
        else {
            result = null;
        }
        return result;
    }

    #calculateY1() {
        let result;
        const x1 = this._getParameterValue(ParameterNames.ParameterX1Name);
        const curve = this.#getCurve(ParameterNames.ParameterX0Name, ParameterNames.ParameterY0Name, ParameterNames.ParameterY1Name);
        if (areSomething(x1, curve)) {
            result = curve.getY(x1, false);
        }
        else {
            result = null;
        }
        return result;
    }

    #calculateX0() {
        let result;
        const y0 = this._getParameterValue(ParameterNames.ParameterY0Name);
        const curve = this.#getCurve(ParameterNames.ParameterX1Name, ParameterNames.ParameterY1Name, ParameterNames.ParameterX0Name);
        if (areSomething(y0, curve)) {
            result = curve.getX(y0, false);
        }
        else {
            result = null;
        }
        return result;
    }

    #calculateX1() {
        let result;
        const y1 = this._getParameterValue(ParameterNames.ParameterY1Name);
        const curve = this.#getCurve(ParameterNames.ParameterX0Name, ParameterNames.ParameterY0Name, ParameterNames.ParameterX1Name);
        if (areSomething(y1, curve)) {
            result = curve.getX(y1, false);
        }
        else {
            result = null;
        }
        return result;
    }

    #getCurve(parameterXName, parameterYName, outputParameterName) {
        const x = this._getParameterValue(parameterXName);
        const y = this._getParameterValue(parameterYName);
        if (areSomething(x, y)) {
            const point = new Point(x, y);
            const curveInfo = this.curveSet.getCurveInfo(point);
            if (this.#canReturnCurve(curveInfo.method, outputParameterName)) {
                return curveInfo.curve;
            }
        }
        return null;
    }

    #canReturnCurve(selectionMethod, outputParameterName) {
        switch (selectionMethod) {
            case CurveSelectionMethod.Interpolation:
            case CurveSelectionMethod.Shadow:
                return true;
            case CurveSelectionMethod.None:
                return false;
            // no default
        }
        return this.safeBoundaryCurveCombinations?.any(sc => sc[0] === outputParameterName && sc[1] === selectionMethod);
    }
}