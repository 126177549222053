import { IntersectionInfo } from "./intersection-info"
import { IntersectionType } from "./intersection-type"
import { Line } from "./line";
import { Point } from "./point"

export class Segment {
    constructor(point1, point2) {
        this.point1 = point1;
        this.point2 = point2;
        this.x1 = point1.x;
        this.y1 = point1.y;
        this.x2 = point2.x;
        this.y2 = point2.y;
        if (this.x2 <= this.x1) {
            throw new Error("point1 must have an x-value less than that of point2.");
        }
    }

    #line;

    get length() {
        if (!this.#line) {
            this.#line = new Line(this.point1, this.point2);
        }
        return this.#line.length;
    }

    getPoint(fractionalDistance) {
        return Line.getPoint(this.point1, this.point2, fractionalDistance);
    }

    isXInRange(x) {
        return x >= this.point1.x && x <= this.point2.x;
    }

    isYInRange(y) {
        return (y >= this.point1.y && y <= this.point2.y) || (y <= this.point1.y && y >= this.point2.y);
    }

    findIntersection(other) {
        // Get the parameters of both this segment and other.
        const px1 = this.x1;
        const py1 = this.y1;
        const px3 = other.x1;
        const py3 = other.y1;

        const dx12 = this.x2 - px1;
        const dy12 = this.y2 - py1;
        const dx34 = other.x2 - px3;
        const dy34 = other.y2 - py3;

        // Solve for t1 and t2
        const denominator = dy12 * dx34 - dx12 * dy34;
        const t1 = ((px1 - px3) * dy34 + (py3 - py1) * dx34) / denominator;

        let result;
        if (t1 === Infinity) {
            // The lines are broadly parallel
            result = new IntersectionInfo(IntersectionType.None, Point.nullPoint);
        }
        else {
            const t2 = ((px3 - px1) * dy12 + (py1 - py3) * dx12) / -denominator;
            const segmentsIntersect = (t1 >= 0) && (t1 <= 1) && (t2 >= 0) && (t2 <= 1);
            const type = segmentsIntersect ? IntersectionType.Segment : IntersectionType.Line;
            result = new IntersectionInfo(type, new Point(px1 + dx12 * t1, py1 + dy12 * t1));
        }
        return result;
    }
}