export class ParameterMappingGroup {
    #type;
    #name;
    #description;
    #parameterMappings = new Map();
    
    constructor(type, name, description) {
        this.#type = type;
        this.#name = name;
        this.#description = description;
        this.sequence = 0;
    }

    get typeName() {
        return "ParameterMappingGroup";
    }

    get type() {
        return this.#type;
    }

    get name() {
        return this.#name;
    }

    get description() {
        return this.#description;
    }

    _register(key, parameterMapping) {
        this.#parameterMappings.set(key, parameterMapping);
    }

    getKeys() {
        return Array.from(this.#parameterMappings.keys());
    }

    getParameterMapping(key) {
        return this.#parameterMappings.get(key);
    }

    pushValue() {
        for (let pm of this.#parameterMappings.values()) {
            pm.pushValue();
        }
    }

    parseDescriptionPlaceholders() {
        function getPlaceholderIndex(placeholder) {
            const digits = placeholder.substr(1, placeholder.length - 2);
            return Number(digits);
        }

        const text = this.#description;
        const regex = new RegExp(/\{\d+\}/, 'g');
        const matches = [...text.matchAll(regex)];
        let t = text;
        const descriptionFragments = [];
        const placeholderSequence = [];
        if (matches === null) {
            descriptionFragments.push(text);
        }
        else {
            matches.forEach(m => {
                const match = m[0];
                const index = t.indexOf(match);
                const fragment = t.substr(0, index);
                t = t.substring(index + match.length);
                descriptionFragments.push(fragment);
                const placeholderIndex = getPlaceholderIndex(match);
                placeholderSequence.push(placeholderIndex);
            });
            descriptionFragments.push(t);
        }
        return {
            fragments: descriptionFragments,
            placeholderSequence: placeholderSequence
        }
    }
}