import { createContext, useCallback, useEffect, useState, useRef } from "react";
import useAuthContext from "../hooks/useAuthContext";
import useAircraftContext from "../hooks/useAircraftContext";
import { useAuthorisedApiGet } from "../hooks/useApi";
import { isSomething } from "../lib/common/utilities";
import { axiosPost } from "../api/axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useOperationContext from "../hooks/useOperationContext";

const ParameterContext = createContext({});
const CURRENT_STATE_VERSION = 1;
const OPERATION_INFO_URL = "/operation-info";

let state = {};
let stateIsDirty = false;

const setDefaultState = (aircraft) => {
    state = {version: CURRENT_STATE_VERSION};
    if (aircraft) {
        state.aircraftId = aircraft.id;
        const categories = aircraft.getPerformanceCategories();
        state.performanceCategories = categories.map(category => ({
            name: category.name,
            operations: category.getOperations().map(operation => ({ name: operation.description }))
        }));
    }
    else {
        state.aircraftId = null;
    }
};

const upgradeStateIfNecessary = () => {
    if (state.version < CURRENT_STATE_VERSION) {
        // nothing TODO as yet
    }
};

const validateState = (aircraft) => {
    if (Array.isArray(state.performanceCategories)) {
        const categories = aircraft.getPerformanceCategories();
        const statePerformanceCategories = [];
        categories.forEach(category => {
            let stateCategory = state.performanceCategories.firstOrUndefined(c => c.name === category.name);
            if (stateCategory) {
                const operations = category.getOperations();
                const stateOperations = [];
                operations.forEach(operation => {
                    let stateOperation = stateCategory.operations.firstOrUndefined(o => o.name === operation.description);
                    if (stateOperation) {
                        const stateParameters = stateOperation.parameters;
                        if (stateParameters) {
                            const parameterMappings = new Map(operation.getAllInputParameterMappings().concat(operation.getAllOutputParameterMappings()).map(p => [p.name, p]));
                            for (const [stateParameterKey, stateParameterInfo] of Object.entries(stateParameters)) {
                                const parameterMapping = parameterMappings.get(stateParameterKey);
                                if (parameterMapping) {
                                    if (parameterMapping.typeName === "ParameterMappingGroup") {
                                        const subParameters = stateParameterInfo.subParameters;
                                        if (subParameters) {
                                            const subNames = parameterMapping.getKeys().map(key => parameterMapping.getParameterMapping(key).name);
                                            for (const [subParameterKey, _] of Object.entries(subParameters)) {
                                                if (!subNames.includes(subParameterKey)) {
                                                    delete subParameters[subParameterKey];
                                                }
                                            }
                                        }
                                        else {
                                            delete stateParameters[stateParameterKey];        
                                        }
                                    }
                                    else if (stateParameterInfo.subParameters) {
                                        delete stateParameterInfo.subParameters;
                                    }
                                }
                                else {
                                    delete stateParameters[stateParameterKey];
                                }
                            }
                        }
                    }
                    else {
                        stateOperation = {
                            name: operation.description
                        };
                    }
                    stateOperations.push(stateOperation);
                });
                stateCategory.operations = stateOperations;
            }
            else {
                stateCategory = {
                    name: category.name,
                    operations: category.getOperations().map(operation => ({ name: operation.description }))
                };
            }
            statePerformanceCategories.push(stateCategory);
        });
        state.performanceCategories = statePerformanceCategories;
    }
    else {
        setDefaultState(aircraft);
    }
};

export const ParameterProvider = ({ children }) => {
    const { aircraft, error: aircraftError } = useAircraftContext();
    const { auth, subscribeSignOut, unsubscribeSignOut } = useAuthContext();
    const [active, setActive] = useState(false);
    const [available, setAvailable] = useState(false);
    const [url, setUrl] = useState(null);
    const [canFetchParameterInfo, setCanFetchParameterInfo] = useState(false);
    const { indexes } = useOperationContext();
    const saveIfNecessaryRef = useRef();
    
    const axiosPrivate = useAxiosPrivate();
    const { data: parameterInfo, error: parameterError } = useAuthorisedApiGet(url, auth.accessToken, null, null, canFetchParameterInfo);

    const saveIfNecessary = useCallback(async () => {
        const saveState = async () => {
            let result = false;
            const onSuccess = () => result = true;
            const onError = (err) => console.log(`Error while attempting to save operation info: ${err?.message}`);
            await axiosPost(axiosPrivate, `${OPERATION_INFO_URL}/${state.aircraftId}`, state, onSuccess, onError);
            return result;
        };

        let result;
        if (stateIsDirty) {
            stateIsDirty = false;
            result = await saveState();
        }
        else {
            result = true;
        }
        return result;
    }, [axiosPrivate]);

    saveIfNecessaryRef.current = saveIfNecessary;

    useEffect(() => {
        const handleSignOut = async () => {
            return await saveIfNecessaryRef.current();
        };
        subscribeSignOut(handleSignOut);
        return () => {
            unsubscribeSignOut(handleSignOut);
            handleSignOut();
        }
    }, []);
    
    useEffect(() => {
        setActive(isSomething(auth.accessToken));
    }, [auth]);

    useEffect(() => {
        const changeAircraft = async () => {
            await saveIfNecessary();
            if (aircraft && !aircraftError) {
                setUrl(`${OPERATION_INFO_URL}/${aircraft.id}`);
                setDefaultState(aircraft);
                setAvailable(true);
            }
            else {
                setUrl(null);
                setDefaultState();
                setAvailable(false);
            }
        };
        changeAircraft();
    }, [aircraft, aircraftError, saveIfNecessary]);

    useEffect(() => {
        setCanFetchParameterInfo(active && isSomething(url));
    }, [active, url]);

    useEffect(() => {
        try {
            setAvailable(false);
            if (parameterError) {
                setDefaultState();
            }
            else if (parameterInfo !== null) {
                try {
                    state = parameterInfo;
                    upgradeStateIfNecessary();
                    validateState(aircraft);
                }
                catch (err) {
                    console.log(`Failed to upgrade/validate cached parameter data: using default state instead. ${err}.`);
                    setDefaultState(aircraft);
                }
            }
        }
        finally {
            setAvailable(state.aircraftId !== null && !isSomething(parameterError));
        }
    }, [parameterInfo, parameterError]);

    const getCachedInfo = useCallback((parameterName, subParameterName = null) => {
        if (!active || !available) return null;

        const { categoryIndex, operationIndex } = indexes;
        let info;
        let parameters = state.performanceCategories[categoryIndex]?.operations[operationIndex]?.parameters;
        if (parameters) {
            info = parameters[parameterName];
            if (subParameterName) {
                const subParameters = info?.subParameters;
                if (subParameters) {
                    info = subParameters[subParameterName];
                }
                else {
                    info = null;
                }
            }
        }
        return info ?? null;
    }, [indexes, active, available]);

    const getStateOperation = useCallback(() => {
        const { categoryIndex, operationIndex } = indexes;
        const categories = state.performanceCategories;
        const category = categories[categoryIndex];
        if (!category) {
            throw new Error(`categoryIndex (${categoryIndex}) out of range: expected 0 through ${categories.length - 1}.`);
        }
        const operations = category.operations;
        const operation = operations[operationIndex];
        if (!operation) {
            throw new Error(`operationIndex (${operationIndex}) out of range: expected 0 through ${operations.length - 1}.`);
        }
        return operation;
    }, [indexes]);

    const setCachedInfo = useCallback((parameterName, subParameterName = null, info) => {
        if (!active || !available) return;
        
        const operation = getStateOperation();
        let parameters = operation.parameters;
        if (!parameters) {
            parameters = {};
            operation.parameters = parameters;
        }
        if (subParameterName === null) {
            parameters[parameterName] = info;
        }
        else {
            let parameter = parameters[parameterName];
            if (!parameter) {
                parameter = {};
                parameters[parameterName] = parameter;
            }
            let subParameters = parameter.subParameters;
            if (!subParameters) {
                subParameters = {};
                parameter.subParameters = subParameters;
            }
            subParameters[subParameterName] = info;
        }
        stateIsDirty = true;
    }, [getStateOperation, active, available]);

    const clearOperationInfo = useCallback(() => {
        if (!active || !available) return;

        const operation = getStateOperation();
        delete operation.parameters;
        stateIsDirty = true;
    }, [getStateOperation, active, available]);

    return (
        <ParameterContext.Provider value={{ active, available, getCachedInfo, setCachedInfo, clearOperationInfo }}>
            {children}
        </ParameterContext.Provider>
    )
}

export default ParameterContext;