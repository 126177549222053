import styles from "./Nav.module.css";
import useAuthContext from "../hooks/useAuthContext";
import { useState, useEffect, useCallback } from "react";
import { Link, matchRoutes, useLocation } from "react-router-dom";
import { isSomething } from "../lib/common/utilities";
import { useHangarPath, PATHS } from "../hooks/useHangarPath";
import { ROLES, rolesIntersect } from "../utils/roleEvaluator";

const NAV_ITEMS = {
    HOME: 0,
    LOGIN: 1,
    LEASING: 2,
    OPS: 3,
    HELP: 4,
    APRON_HANGAR: 5,
    ADMIN: 6
};

const routes = [
    { path: "/", navItem: NAV_ITEMS.HOME },
    { path: "/login", navItem: NAV_ITEMS.LOGIN },
    { path: "/register", navItem: NAV_ITEMS.LOGIN },
    { path: "/leasing", navItem: NAV_ITEMS.LEASING },
    { path: "/ops", navItem: NAV_ITEMS.OPS },
    { path: "/help", navItem: NAV_ITEMS.HELP },
    { path: "/help/:index", navItem: NAV_ITEMS.HELP },
    { path: "/admin", navItem: NAV_ITEMS.ADMIN }
];

const apronRoutes = [
    { path: "/apron", navItem: NAV_ITEMS.APRON_HANGAR },
    { path: "/apron/:id", navItem: NAV_ITEMS.APRON_HANGAR },
    { path: "/apron/:id/category/:categoryIndex/operation/:operationIndex", navItem: NAV_ITEMS.APRON_HANGAR }
];

const hangarRoutes = [
    { path: "/hangar", navItem: NAV_ITEMS.APRON_HANGAR },
    { path: "/hangar/:id", navItem: NAV_ITEMS.APRON_HANGAR },
    { path: "/hangar/:id/category/:categoryIndex/operation/:operationIndex", navItem: NAV_ITEMS.APRON_HANGAR }
];

const Nav = () => {
    const { auth, signOut } = useAuthContext();
    const [signedIn, setsignedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const location = useLocation();
    const { pathRoot } = useHangarPath();
    const [allRoutes, setAllRoutes] = useState(routes);

    useEffect(() => {
        const hasAccessToken = isSomething(auth.accessToken);
        setsignedIn(hasAccessToken);
        setIsAdmin(hasAccessToken && rolesIntersect([ROLES.Admin], auth.roles));
    }, [auth]);

    useEffect(() => {
        const arr = [...routes];
        switch (pathRoot) {
            case PATHS.Apron:
                arr.push(...apronRoutes);
                break;
            case PATHS.Hangar:
                arr.push(...hangarRoutes);
                break;
            // no default
        }
        setAllRoutes(arr);
    }, [pathRoot]);

    const getClassName = useCallback((item) => {
        const matchingRoutes = matchRoutes(allRoutes, location);
        let name = `${styles.listItem}`;
        if (matchingRoutes) {
            const route = matchingRoutes[0].route;
            if (route.navItem === item) {
                name += ` ${styles.listItem___selected}`;
            }
        }
        return name;
    }, [location, allRoutes]);

    return (
        <nav className={styles.Nav}>
            <ul className={styles.list}>
                <li className={getClassName(NAV_ITEMS.HOME)}><Link className={styles.link} to="/"><img src="/Images/Logo-133x28.png" alt="" width="133" height="28" /></Link></li>
                {!signedIn &&
                    <li className={getClassName(NAV_ITEMS.APRON_HANGAR)}><Link className={styles.link} to="/apron">Apron</Link></li>
                }
                {signedIn &&
                    <li className={getClassName(NAV_ITEMS.APRON_HANGAR)}><Link className={styles.link} to="/hangar">Hangar</Link></li>
                }
                {signedIn &&
                    <li className={getClassName(NAV_ITEMS.LEASING)}><Link className={styles.link} to="/leasing">Leasing</Link></li>
                }
                <li className={getClassName(NAV_ITEMS.OPS)}><Link className={styles.link} to="/ops">Ops</Link></li>
                <li className={getClassName(NAV_ITEMS.HELP)}><Link className={styles.link} to="/help">Help</Link></li>
            </ul>
            <ul className={styles.list}>
                {isAdmin && 
                    <li className={getClassName(NAV_ITEMS.ADMIN)}><Link className={styles.link} to="/admin">Admin</Link></li>
                }
                {signedIn &&
                    <li className={getClassName()}><Link className={styles.link} onClick={signOut}>Sign Out</Link></li>
                }
                {!signedIn &&
                    <li className={getClassName(NAV_ITEMS.LOGIN)}><Link className={styles.link} to="/login">Sign In</Link></li>
                }
            </ul>
        </nav>
    )
}

export default Nav;