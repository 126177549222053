/* Multiplies an "adjustment amount" by a fixed fractional value (the "scalar") 
   to determine the proportion of an input value to output.

   For example, if the fractional value is 0.1 and the received adjustment amount 
   is 2 then an input of 100 will yield an output of (1 - (0.1 * 2)) * 100 = 80.

   Negative adjustment amounts result in outputs that are greater than inputs.
   In the above example, if the adjustment amount is -1 then the output is 
   (1 - (0.1 * -1)) * 100 = 110.

   Solves problems like "increase take-off distance by 10% for each 2 kts of 
   tailwind".  In this case the scalar is 0.1 / 2 = 0.05, and the adjustment 
   amount is the actual tailwind.

   Can also be used to increase inputs.  To achieve this, set asIncreaser to true 
   in the constructor call.

   Works in reverse too.
*/
export class QuantityAdjuster {
    #scalar;
    #asIncreaser;
    
    constructor(scalar, asIncreaser = false) {
        this.#scalar = Number(scalar);
        this.#asIncreaser = asIncreaser;
    }

    get scalar() {
        return this.#scalar;
    }

    getOutput(input, adjustmentAmount) {
        if (input === null || adjustmentAmount === null) {
            return null;
        }
        if (this.#asIncreaser) {
            adjustmentAmount = -adjustmentAmount;
        }
        return (1 - adjustmentAmount * this.#scalar) * input;
    }

    getInput(output, adjustmentAmount) {
        if (output === null || adjustmentAmount === null) {
            return null;
        }
        if (this.#asIncreaser) {
            adjustmentAmount = -adjustmentAmount;
        }
        return output / (1 - adjustmentAmount * this.#scalar);
    }
}