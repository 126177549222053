import { HELP_TOPICS } from "./helpTopics";

export const helpMap = {
    index: HELP_TOPICS.ROOT,
    children: [
        {
            index: HELP_TOPICS.FAQS,
            parentIndex: 1,
            title: "FAQs",
            imageFileName: "faq",
            children: [
                {
                    index: HELP_TOPICS.CREATE_ACCOUNT,
                    parentIndex: 2,
                    title: "Should I create an account?"
                },
                {
                    index: HELP_TOPICS.APRON_HANGAR,
                    parentIndex: 2,
                    title: "Apron versus Hangar: what's the difference?",
                    relatedTopics: [HELP_TOPICS.LEASING]
                },
                {
                    index: HELP_TOPICS.MORE_AIRCRAFT,
                    parentIndex: 2,
                    title: "Will more aircraft be added?",
                    relatedTopics: [HELP_TOPICS.FEEDBACK]
                },
                {
                    index: HELP_TOPICS.TRUST_AIRCRAFT_METADATA,
                    parentIndex: 2,
                    title: "How can I trust the calculations for my aircraft?",
                    relatedTopics: [HELP_TOPICS.PERFORMANCE_DATA_DISCLAIMER]
                },
                {
                    index: HELP_TOPICS.SEEING_WARNING_TRIANGLES,
                    parentIndex: 2,
                    title: "Why am I seeing warning triangles on my inputs and/or outputs?"
                }
            ]
        },
        {
            index: HELP_TOPICS.PERF_DATA,
            parentIndex: 1,
            title: "Performance data",
            imageFileName: "bar-chart",
            underConstruction: true
        },
        {
            index: HELP_TOPICS.IN_FLIGHT,
            parentIndex: 1,
            title: "Avaid in flight",
            imageFileName: "take-off",
            underConstruction: true
        },
        {
            index: HELP_TOPICS.LEASING,
            parentIndex: 1,
            title: "Leasing",
            imageFileName: "leasing",
            children: [
                {
                    index: HELP_TOPICS.LEASING_INTRODUCTION,
                    parentIndex: HELP_TOPICS.LEASING,
                    title: "Introduction to leasing",
                    relatedTopics: [HELP_TOPICS.CREATE_ACCOUNT]
                },
                {
                    index: HELP_TOPICS.LEASING_AIRCRAFT,
                    parentIndex: HELP_TOPICS.LEASING,
                    title: "Leasing an aircraft",
                    children: [
                        {
                            index: HELP_TOPICS.CREATE_LEASE,
                            parentIndex: HELP_TOPICS.LEASING_AIRCRAFT,
                            title: "Creating and extending leases"
                        },
                        {
                            index: HELP_TOPICS.LEASING_CAROUSEL,
                            parentIndex: HELP_TOPICS.LEASING_AIRCRAFT,
                            title: "The leasing carousel",
                            underConstruction: true
                        },
                        {
                            index: HELP_TOPICS.LEASING_CART,
                            parentIndex: HELP_TOPICS.LEASING_AIRCRAFT,
                            title: "Cart"
                        }
                    ]
                }
            ]
        },
        {
            index: HELP_TOPICS.ADMIN,
            parentIndex: 1,
            title: "Admin",
            imageFileName: "operations",
            children: [
                {
                    index: HELP_TOPICS.FEEDBACK,
                    parentIndex: HELP_TOPICS.ADMIN,
                    title: "Feedback"
                },
                {
                    index: HELP_TOPICS.ACCOUNT_MANAGEMENT,
                    parentIndex: HELP_TOPICS.ADMIN,
                    title: "Account management"
                },
                {
                    index: HELP_TOPICS.REGISTRATION,
                    parentIndex: HELP_TOPICS.ADMIN,
                    title: "Registration",
                    relatedTopics: [HELP_TOPICS.CREATE_ACCOUNT]
                },
                {
                    index: HELP_TOPICS.SIGN_IN,
                    parentIndex: HELP_TOPICS.ADMIN,
                    title: "Sign in"
                },
                {
                    index: HELP_TOPICS.PASSWORD_RESET,
                    parentIndex: HELP_TOPICS.ADMIN,
                    title: "Password reset"
                }
            ]
        },
        {
            index: HELP_TOPICS.ABOUT,
            parentIndex: 1,
            title: "About",
            imageFileName: "about"
        },
        {
            index: HELP_TOPICS.TERMS,
            parentIndex: 1,
            title: "Terms and conditions",
            imageFileName: "rules",
            children: [
                {
                    index: HELP_TOPICS.TERMS_OF_USE,
                    parentIndex: HELP_TOPICS.TERMS,
                    title: "Terms of use",
                    children: [
                        {
                            index: HELP_TOPICS.PERFORMANCE_DATA_DISCLAIMER,
                            parentIndex: HELP_TOPICS.TERMS_OF_USE,
                            title: "Performance data disclaimer"
                        },
                        {
                            index: HELP_TOPICS.ACCESSING_WEBSITE,
                            parentIndex: HELP_TOPICS.TERMS_OF_USE,
                            title: "Accessing this website"
                        },
                        {
                            index: HELP_TOPICS.INTELLECTUAL_PROPERTY,
                            parentIndex: HELP_TOPICS.TERMS_OF_USE,
                            title: "Intellectual property rights"
                        },
                        {
                            index: HELP_TOPICS.INFORMATION_ABOUT_YOU,
                            parentIndex: HELP_TOPICS.TERMS_OF_USE,
                            title: "Information about you and your visits to this website",
                            relatedTopics: [HELP_TOPICS.PRIVACY_POLICY]
                        },
                        {
                            index: HELP_TOPICS.OUR_LIABILITY,
                            parentIndex: HELP_TOPICS.TERMS_OF_USE,
                            title: "Our liability"
                        },
                        {
                            index: HELP_TOPICS.CLOSING_ACCOUNT,
                            parentIndex: HELP_TOPICS.TERMS_OF_USE,
                            title: "Closing your account"
                        },
                        {
                            index: HELP_TOPICS.VIRUSES_HACKING,
                            parentIndex: HELP_TOPICS.TERMS_OF_USE,
                            title: "Viruses, hacking and other offences"
                        },
                        {
                            index: HELP_TOPICS.LINKS_TO_FROM,
                            parentIndex: HELP_TOPICS.TERMS_OF_USE,
                            title: "Links to and from this website",
                            relatedTopics: [HELP_TOPICS.PRIVACY_POLICY]
                        },
                        {
                            index: HELP_TOPICS.GENERAL_TERMS,
                            parentIndex: HELP_TOPICS.TERMS_OF_USE,
                            title: "General terms"
                        }
                    ],
                    relatedTopics: [HELP_TOPICS.PRIVACY_POLICY]
                },
                {
                    index: HELP_TOPICS.PRIVACY_POLICY,
                    parentIndex: HELP_TOPICS.TERMS,
                    title: "Privacy policy",
                    children: [
                        {
                            index: HELP_TOPICS.YOUR_INFORMATION,
                            parentIndex: HELP_TOPICS.PRIVACY_POLICY,
                            title: "The information we collect about you"
                        },
                        {
                            index: HELP_TOPICS.USE_OF_YOUR_INFORMATION,
                            parentIndex: HELP_TOPICS.PRIVACY_POLICY,
                            title: "How we use the information we collect about you"
                        },
                        {
                            index: HELP_TOPICS.SHARING_YOUR_INFORMATION,
                            parentIndex: HELP_TOPICS.PRIVACY_POLICY,
                            title: "Sharing your information with third parties"
                        },
                        {
                            index: HELP_TOPICS.SECURING_YOUR_INFORMATION,
                            parentIndex: HELP_TOPICS.PRIVACY_POLICY,
                            title: "Securing your information"
                        },
                        {
                            index: HELP_TOPICS.ACCESS_TO_YOUR_INFORMATION,
                            parentIndex: HELP_TOPICS.PRIVACY_POLICY,
                            title: "Access to your information"
                        },
                        {
                            index: HELP_TOPICS.USE_OF_COOKIES,
                            parentIndex: HELP_TOPICS.PRIVACY_POLICY,
                            title: "How we use cookies"
                        }
                    ],
                    relatedTopics: [HELP_TOPICS.TERMS_OF_USE]
                }
            ]
        },
        {
            index: HELP_TOPICS.CONTACT,
            parentIndex: 1,
            title: "Contact us",
            imageFileName: "envelope"
        }
    ]
};