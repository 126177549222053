import { areSomething } from "../common/utilities";
import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";

export class DetentCurveSetTransform extends Transform {
    constructor(detentCurveSet) {
        super();
        this.detentCurveSet = detentCurveSet;
    }

    getDetents() {
        return this.detentCurveSet.getDetents();
    }

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterDetentCurveSetValueName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterXName, this.id),
            new Parameter(ParameterNames.ParameterYName, this.id)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const count = this._setParameterValueCount;
        if (count === 2) {
            const outputParameterName = this.getUnsetParameterNames()[0];
            let result;
            switch (outputParameterName) {
                case ParameterNames.ParameterYName:
                    result = this.#calculateY();
                    break;
                case ParameterNames.ParameterXName:
                    result = this.#calculateX();
                    break;
                default:
                    throw new Error(`Unknown output parameter: '${outputParameterName}'.`);
            }
            this._emitParameterValue(outputParameterName, result);
        }
    }

    #calculateY() {
        let result;
        const detentCurveSetValue = this._getParameterValue(ParameterNames.ParameterDetentCurveSetValueName);
        const x = this._getParameterValue(ParameterNames.ParameterXName);
        if (areSomething(detentCurveSetValue, x)) {
            result = this.#getValue(detentCurveSetValue, c => c.getY(x, false));
        }
        else {
            result = null;
        }
        return result;
    }

    #calculateX() {
        let result;
        const detentCurveSetValue = this._getParameterValue(ParameterNames.ParameterDetentCurveSetValueName);
        const y = this._getParameterValue(ParameterNames.ParameterYName);
        if (areSomething(detentCurveSetValue, y)) {
            result = this.#getValue(detentCurveSetValue, c => c.getX(y, false));
        }
        else {
            result = null;
        }
        return result;
    }

    #getValue(detentCurveSetValue, valueAcquirer) {
        const curve = this.detentCurveSet.getCurve(detentCurveSetValue);
        return valueAcquirer(curve);
    }
}