import styles from "./HelpElementContentSource.module.css";
import commonStyles from "../Common.module.css";
import { HELP_TOPICS } from "../../utils/help/helpTopics";
import { Link } from "react-router-dom";
import SETTINGS from "../../config";
import PerformanceDataDisclaimerContent from "../PerformanceDataDisclaimerContent";
import { Symbols } from "../../lib/units/symbols";

const getHelpElementContent = (selectedIndex) => {
    switch (selectedIndex) {
        case HELP_TOPICS.CREATE_ACCOUNT:
            return (
                <section>
                    <p>
                        An account is not required in order to use Avaid.  The entire aircraft database is
                        freely accessible to all site visitors and there are no restrictions on access to
                        the various calculations.
                    </p>
                    <p>
                        An account is used to store all of your aircraft-related inputs and selections.
                        When you sign in and select an aircraft you will find that your most recent inputs
                        and selections from your previous signed-in sessions have been preserved.  This is
                        advantageous because:
                    </p>
                    <ul>
                        <li>
                            it avoids the need to re-enter values for properties that have not changed between
                            planning on a PC and flying using a mobile device;
                        </li>
                        <li>
                            an aircraft manufacturer may publish performance data in units that do not suit
                            you, so selecting alternative units while signed in becomes a one-time-only action.
                        </li>
                    </ul>
                </section>
            );
        case HELP_TOPICS.APRON_HANGAR:
            return (
                <section>
                    <p>
                        The apron provides access to all aircraft in the Avaid database.  A link to the apron
                        is provided in the nav bar for all site visitors who do not sign in.
                    </p>
                    <p>
                        If you sign in to your account, the apron is replaced with your hangar.  The hangar
                        includes only those aircraft that you choose
                        to lease.  This reduces clutter and makes it easier to find the aircraft you're
                        interested in.
                    </p>
                </section>
            );
        case HELP_TOPICS.MORE_AIRCRAFT:
            return (
                <section>
                    <p>
                        New aircraft are being added to the database all the time.  The process is slow &#8212;
                        particularly when assimilating graph-based performance data &#8212; due to the need
                        for accurate data replication and the time given over to testing.
                    </p>
                    <p>
                        We are prioritising the most common training aircraft but will consider any and all
                        requests.  Please visit the <Link className={commonStyles.link} to="/ops">Ops</Link> page
                        to submit a request.
                    </p>
                </section>
            );
        case HELP_TOPICS.TRUST_AIRCRAFT_METADATA:
            return (
                <section>
                    <p>
                        Before using the performance calculations for flight planning, you should satisfy yourself
                        that they yield results in accordance with (to a reasonable tolerance) those obtained from
                        official sources for the same input criteria.
                    </p>
                    <p>
                        On occasion, an aircraft's metadata may change due to a variety of factors.  We will soon
                        be introducing a versioning system, so any such changes will be transparent and you will
                        more readily be able to assess their impacts on your planning regime.
                    </p>
                </section>
            );
        case HELP_TOPICS.SEEING_WARNING_TRIANGLES:
            return (
                <section>
                    <p>An amber warning triangle indicates that something is out of range.</p>
                    <p>
                        Numeric inputs typically have minimum and maximum values, so entering a value outside the
                        range will cause the relevant input summary line item to show a warning.  (The input field
                        itself will be outlined in red and its tooltip will describe the limiting value that has
                        been breached.)  The fact that an input is out of range will similarly affect one or more
                        outputs, depending on the operation.
                    </p>
                    <p>
                        It is also possible to see a warning triangle in place of an output even when each input is
                        valid.  In such cases the combination of inputs has resulted in either:
                    </p>
                    <ul>
                        <li>an output beyond its defined limits, or;</li>
                        <li>an interim calculation that is beyond the limits of the aircraft metadata.</li>
                    </ul>
                    <p>
                        An example of the latter can often be seen in take-off performance graphs.  Typical lower
                        limits for pressure altitude and temperature may be 0 ft and -30{Symbols.Degree}C
                        respectively, but using these values as inputs to the graph will usually yield a point that
                        is beyond the range of the graph's curves.
                    </p>
                </section>
            );
        case HELP_TOPICS.LEASING_INTRODUCTION:
            return (
                <section>
                    <p>
                        To include aircraft in your hangar, you need to lease them.  A lease applies to a single
                        aircraft, is valid for one year and is free.  You can lease as many aircraft as you want
                        and you can lease them whenever you want.  Leases are extendable but only
                        within {SETTINGS.LEASE_EXPIRY_WINDOW_DAYS} days of expiry.  Extending a lease is also free.
                    </p>
                    <p>
                        You need to be a registered user to access your hangar and lease aircraft.
                    </p>
                </section>
            );
        case HELP_TOPICS.CREATE_LEASE:
            return (
                <section>
                    <p>
                        When you first sign in, your hangar will be
                        empty.  Visit <Link className={commonStyles.link} to={"/leasing"}>Leasing</Link> to select
                        those aircraft that you want to include in your hangar.
                    </p>
                    <p>
                        Start by finding and selecting an aircraft using the carousel.
                    </p>
                    <p>
                        If you have yet to lease the selected aircraft, you will be shown a list of its supported
                        operations and you will be able to add the aircraft to your cart as a new lease.
                    </p>
                    <p>
                        If you already lease the selected aircraft, you will be shown its leasing summary.  If the
                        lease is due to expire within {SETTINGS.LEASE_EXPIRY_WINDOW_DAYS} days, you will be able to
                        add the aircraft to your cart as a lease extension.  An extension is valid for one year from
                        the date of expiry of the existing lease.
                    </p>
                </section>
            );
        case HELP_TOPICS.LEASING_CAROUSEL:
            return (
                <section>
                    
                </section>
            );
        case HELP_TOPICS.LEASING_CART:
            return (
                <section>
                    <p>
                        An aircraft added to the cart is removed from the leasing carousel.  The cart symbol
                        indicates the number of aircraft in the cart.  When you are ready to check out the cart
                        items, click the cart symbol.  Each aircraft in the cart is itemised in terms of the
                        nature of the lease and its duration.
                    </p>
                    <p>
                        If you decide not to proceed with an aircraft, click the Remove button (red cross) to
                        remove it from the cart.  This will also restore the aircraft to the leasing carousel.
                    </p>
                    <p>
                        Finally, click Checkout to complete the transaction.
                    </p>
                    <ol>
                        <li>The cart will be emptied.</li>
                        <li>New and/or extended leases will be awarded.</li>
                        <li>The aircraft in the cart will be restored to the leasing carousel, suitably updated.</li>
                        <li>Your hangar will be updated.</li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.ADMIN:
            return (
                <section>
                    <p>
                        Actions of an administrative nature can be performed via
                        the <Link className={commonStyles.link} to={"/ops"}>Ops</Link> page and
                        the <Link className={commonStyles.link} to={"/login"}>Sign In</Link> page.</p>
                </section>
            );
        case HELP_TOPICS.FEEDBACK:
            return (
                <section>
                    <p>
                        All site visitors are welcome to post
                        feedback.  The <Link className={commonStyles.link} to={"/ops"}>Ops</Link> page has a
                        feedback form for this purpose.  Select the subject that most closely represents your
                        feedback and type your message in the box provided.  (A minimum of ten characters are
                        required.)
                    </p>
                    <p>
                        If you are not signed in, you will also need to supply an email address.  It is important
                        to enter your own, valid email address if you expect a reply.
                    </p>
                </section>
            );
        case HELP_TOPICS.ACCOUNT_MANAGEMENT:
            return (
                <section>
                    <p>
                        If you are signed in, you can update your profile via
                        the <Link className={commonStyles.link} to={"/ops"}>Ops</Link> page.  Specifically, your
                        name and password can be changed.  The policies for each field are displayed when its
                        input box has focus.
                    </p>
                    <p>
                        To edit an existing value, click the Edit button (pencil).  Focus is passed to the relevant
                        input box, ready for you to start typing.
                    </p>
                    <p>
                        To cancel changes, click the Cancel button (red cross).  To commit changes, click the Accept
                        button (green tick) or press Enter.  If the Accept button is disabled (and an input box is
                        outlined in red), the entered value does not accord with the associated policies.
                    </p>
                </section>
            );
        case HELP_TOPICS.REGISTRATION:
            return (
                <section>
                    <p>
                        To register for an account:
                    </p>
                    <ol>
                        <li>
                            visit the <Link className={commonStyles.link} to={"/register"}>Sign In</Link> page and
                            select the Register tab if it is not already selected;
                        </li>
                        <li>
                            enter a valid name, email address and password.
                        </li>
                    </ol>
                    <p>
                        When all form inputs are valid, the Register button becomes enabled.  Click this button to
                        submit your details.
                    </p>
                    <p>
                        If the supplied email address has not previously been registered, you will receive an email
                        instructing you to click on the provided link and complete the registration process.  Otherwise,
                        you will be advised on-screen that the email address has already been registered.
                    </p>
                </section>
            );
        case HELP_TOPICS.SIGN_IN:
            return (
                <section>
                    <p>To sign in to your registered account:</p>
                    <ol>
                        <li>
                            visit the <Link className={commonStyles.link} to={"/login"}>Sign In</Link> page and
                            select the Sign In tab if it is not already selected;
                        </li>
                        <li>enter your validated email address and password;</li>
                        <li>click the Submit button.</li>
                    </ol>
                    <p>
                        If the Submit button is disabled, verify that the entered credentials are correct.  An
                        input box outlined in red signals a problem that must be addressed before retrying.
                    </p>
                </section>
            );
        case HELP_TOPICS.PASSWORD_RESET:
            return (
                <section>
                    <p>To reset your password:</p>
                    <ol>
                        <li>
                            visit the <Link className={commonStyles.link} to={"/login"}>Sign In</Link> page and
                            select the Sign In tab if it is not already selected;
                        </li>
                        <li>enter your validated email address;</li>
                        <li>check the "Forgotten password?" box;</li>
                        <li>click the Request Reset button.</li>
                    </ol>
                    <p>
                        If the supplied email address is recognised, a password-reset email will be sent to
                        that address.  When you receive this email, follow the instructions to reset your
                        password.
                    </p>
                </section>
            );
        case HELP_TOPICS.ABOUT:
            return (
                <section>
                    <div className={styles.banner} />
                    <article>
                        <p>
                            I learned to fly powered, fixed-wing aircraft in 2015.  Since then, my aviation journey has taken
                            me to some far-flung places and afforded me the opportunity to acquire the usual medley of ratings
                            that private pilots target.  But the itch to do more &#8212; to continue to plunder the hobby that
                            keeps on giving &#8212; could not go unanswered: in 2020 I decided to take the ATPL exams and dip
                            a toe into the world of commercial aviation.
                        </p>
                        <p>
                            For the theoretical knowledge course, I clearly recall the complex performance graphs and the need
                            to be meticulous in drawing straight, perpendicular lines with a sharp pencil.  Some performance
                            data was presented in tabular form and typically required interpolation.  All of which was
                            time-consuming and error-prone.  But if that wasn't bad enough then imagine the frustration when,
                            during an exam, the end result fell neatly between the two closest of the given answers.  All that
                            manual effort would need to be redone &#8212; with more diligence and an even sharper pencil.
                        </p>
                        <p>
                            The commercial training, which included a multi-engine instrument course, required me to give a
                            briefing before every IFR flight.  The missions were always “A to A” and went like this:
                        </p>
                        <ol>
                            <li>Depart IFR and route to destination.</li>
                            <li>Instrument approach at destination.</li>
                            <li>Missed approach.</li>
                            <li>Simulate engine failure on climb-out.</li>
                            <li>Divert to alternate, simulating one engine inoperative (OEI).</li>
                            <li>Instrument approach at alternate, OEI.</li>
                            <li>Missed approach, all engines operative (AEO).</li>
                            <li>Return to departure aerodrome, AEO.</li>
                        </ol>
                        <p>
                            Naturally each briefing included a section on performance.  There are rules governing the number
                            of landing sites that must be planned for.  Here's the worst case (which happened surprisingly
                            often during my training):
                        </p>
                        <ol>
                            <li>Departure aerodrome (to land in case of take-off emergency).</li>
                            <li>Departure alternate (to land in case of take-off emergency when departure aerodrome is below landing minima).</li>
                            <li>Destination aerodrome.</li>
                            <li>Destination alternate 1.</li>
                            <li>Destination alternate 2.</li>
                            <li>Departure aerodrome for return at end of mission.</li>
                        </ol>
                        <p>
                            So, for a given mission, up to three take-off/go-around scenarios (AEO or OEI) and up to six
                            landing scenarios would have to be planned, all using graphs and tables. 😮
                        </p>
                        <p>
                            Optimistically, if a scenario takes five minutes to plan (analysing meteorological conditions at
                            expected time of departure or arrival, looking up runway characteristics, calculating performance
                            using POH data and primitive techniques), the worst-case scenario would take 45 minutes to complete
                            (coupled with a relatively high risk of error).  Then add the time required to calculate performance
                            for the other phases of flight…  I always needed well over an hour.
                        </p>
                        <p>
                            In short, performance planning is onerous (and it's just one facet of flight planning).  What's
                            more, that onerousness starts to weigh heavily when one plans at length for a flight that gets
                            cancelled for whatever reason &#8212; an irregular occurrence during my training.
                        </p>
                        <p>
                            It soon became apparent that there must be a better way.  That realisation was the catalyst for this
                            website.
                        </p>
                        <p>
                            Whether you're a PPL student, weekend warrior, aspiring commercial pilot, seasoned aircraft operator
                            or instructor, performance planning is an essential component of pre-flight activity.  Making such
                            planning fast, intuitive and consistent is to make it less onerous and more enjoyable.  That means we
                            are more likely to plan properly for scenarios we previously saluted with a finger to the wind.
                        </p>
                        <p>
                            And what else do we get back?  More time for flying, of course.  Welcome to the site: I hope you find
                            it useful.
                        </p>
                        <p>
                            Chris.
                        </p>
                    </article>
                </section>
            );
        case HELP_TOPICS.TERMS_OF_USE:
            return (
                <section>
                    <section>
                        <p>
                            Welcome to the Avaid.aero website.  By using this website you are agreeing to comply with
                            and be bound by the following terms and conditions of use and disclaimers (“Terms”) which,
                            together with our privacy policy, govern our relationship with you in respect of this
                            website.  If you disagree with any part of these Terms or the privacy policy, please do not
                            use this website.
                        </p>
                        <p>
                            These Terms may be updated from time to time without notice.  You should check this page
                            periodically to review these Terms and to ensure that you agree with any changes.  Your
                            continued use of this website will be deemed as acceptance of the updated Terms.
                        </p>
                        <p>
                            These Terms and our privacy policy collectively constitute the entire agreement (the
                            "Agreement") between us.
                        </p>
                        <p>
                            Use of this website is subject to the Terms as described below.  These Terms were last
                            updated on <span className={styles.lastUpdated}>21 October 2023</span>.
                        </p>
                    </section>
                </section>
            );
        case HELP_TOPICS.PERFORMANCE_DATA_DISCLAIMER:
            return (
                <section>
                    <PerformanceDataDisclaimerContent />
                </section>
            );
        case HELP_TOPICS.ACCESSING_WEBSITE:
            return (
                <section>
                    <ol>
                        <li>
                            Access to this website is permitted on a temporary basis and we reserve the right
                            to withdraw, amend or suspend the service we provide via this website without
                            notice.  We will not be liable if, for any reason, this website is unavailable at
                            any time or for any period.
                        </li>
                        <li>
                            You may use this website without registering for a user account.  If you choose to
                            register you will be required to provide, inter alia, a valid password in
                            accordance with our security procedures.  You must treat this as confidential and
                            you must not disclose it to any third party.  We reserve the right to disable a
                            user account at any time if, in our opinion, you have failed to comply with any of
                            the provisions of these Terms.
                        </li>
                        <li>The content of this website is subject to change without notice.</li>
                        <li>
                            Neither we nor any third party provides any warranty or guarantee as to the
                            accuracy, timeliness, performance, completeness or suitability of such content,
                            or any information or materials found or offered on this website for any
                            particular purpose.  You acknowledge that such content, information and materials
                            may contain inaccuracies or errors and we expressly exclude liability for any
                            such inaccuracies or errors to the fullest extent permitted by law.
                        </li>
                        <li>
                            This website contains material which is owned by or licensed to us.  This material
                            includes, but is not limited to, the design, layout, look, appearance and graphics
                            of this website.  Reproduction is prohibited other than in accordance with these
                            Terms.  This material is protected by copyright laws around the world.
                        </li>
                        <li>
                            You may print one copy, and download extracts, of any page(s) from this website
                            for your personal reference.
                        </li>
                        <li>
                            You may not otherwise reproduce, modify, copy, publish, distribute, use or make
                            available to the public for any reason whatsoever (whether in print or electronic
                            form) any of the content, information, logos, designs, pictures, text, graphics,
                            copyrights, trademarks, service marks or any other materials on or from this
                            website without our prior written consent.
                        </li>
                        <li>
                            If you would like to make any use of the material on this website other than as set
                            out here, please contact us via the <Link className={commonStyles.link} to="/ops">Ops</Link> page.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.INTELLECTUAL_PROPERTY:
            return (
                <section>
                    <ol>
                        <li>
                            You acknowledge and agree that all rights governing this website, including intellectual
                            property rights and content, are vested with the owner and nothing in these Terms grants
                            you any proprietary rights in this website or its content.  You may use this website and
                            its content only as permitted by these Terms and as necessary to enable you to access any
                            services we provide to you through this website.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.INFORMATION_ABOUT_YOU:
            return (
                <section>
                    <ol>
                        <li>
                            We process information about you in accordance with our privacy policy.  By using this
                            website you are deemed to have read our privacy policy and to consent to such
                            processing and you warrant that any and all data provided by you is accurate.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.OUR_LIABILITY:
            return (
                <section>
                    <ol>
                        <li>
                            <p>
                                To the extent permitted by law, we and any third parties connected to us hereby
                                expressly exclude:
                            </p>
                            <ol>
                                <li>
                                    all conditions, warranties and other terms which may otherwise be implied
                                    by statute, common law or the law of equity;
                                </li>
                                <li>
                                    <p>
                                        any liability for any indirect or consequential loss or damage incurred by
                                        any user in connection with this website or in connection with the use,
                                        inability to use, or results of the use of, this website, any websites
                                        linked to it and any materials posted on it, including, without limitation
                                        any liability for:
                                    </p>
                                    <ul>
                                        <li>loss of income or revenue;</li>
                                        <li>loss of business;</li>
                                        <li>loss of profit (whether considered to be a direct or indirect loss);</li>
                                        <li>loss of anticipated savings;</li>
                                        <li>loss of data;</li>
                                        <li>loss of goodwill;</li>
                                        <li>wasted management or office time.</li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Our liability for any other loss or damage of any kind, however arising and whether
                            caused by tort (including negligence), breach of contract or otherwise, even if
                            foreseeable, is limited to £10.
                        </li>
                        <li>
                            Nothing in this Agreement excludes our liability for death or personal injury arising
                            from our negligence, nor our liability for fraudulent misrepresentation or
                            misrepresentation as to a fundamental matter, nor any other liability which cannot be
                            excluded or limited under applicable law.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.CLOSING_ACCOUNT:
            return (
                <section>
                    <ol>
                        <li>
                            If you have registered for a user account, you may subsequently close this account by
                            sending us a message via the <Link className={commonStyles.link} to="/ops">Ops</Link> page
                            while logged into the account you wish to close.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.VIRUSES_HACKING:
            return (
                <section>
                    <ol>
                        <li>
                            You must not misuse this website by knowingly introducing viruses, trojans, worms, logic
                            bombs or other material which is malicious or technologically harmful.  You must not
                            attempt to gain unauthorised access to this website, the server on which this website is
                            stored or any server, computer or database connected to this website.  You must not attack
                            this website via a denial-of-service attack or a distributed denial-of service attack.
                        </li>
                        <li>
                            We will not be liable for any loss or damage caused by a denial-of-service attack or
                            distributed denial-of-service attack, viruses or other technologically harmful material
                            that may infect your computer equipment, computer programs, data or other proprietary
                            material due to your use of this website or on any website linked to it.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.LINKS_TO_FROM:
            return (
                <section>
                    <ol>
                        <li>
                            You may link to any page on this website provided you do so in a way that is fair, legal
                            and does not damage our reputation or take advantage of it.  However, you must not
                            establish a link in such a way as to suggest any form of association, approval or
                            endorsement on our part where none exists.
                        </li>
                        <li>
                            This website must not be framed on any other website.  We reserve the right to withdraw
                            linking permission without notice.  The website from which you are linking must comply in
                            all respects with these Terms and
                            our privacy policy.
                        </li>
                        <li>
                            From time to time this website may also include links to other websites.  These links are
                            included with the aim of accessing related contextual information in a convenient manner.  The
                            inclusion of such links does not signify that we endorse the websites.  We have no responsibility
                            for the content of linked websites, nor for any loss or damage that may arise from your use of them.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.GENERAL_TERMS:
            return (
                <section>
                    <ol>
                        <li>
                            We reserve the right to assign our benefits or subcontract all or any of our obligations
                            under these Terms.
                        </li>
                        <li>
                            Neither you nor us is liable to the other for failure to perform any obligation under
                            these Terms to the extent that the failure is caused by any factor beyond the reasonable
                            control of the parties.
                        </li>
                        <li>
                            This Agreement supersedes all other agreements or arrangements, whether written or oral,
                            express or implied, between us.  Each of us agrees that, in entering into this Agreement,
                            it does not rely on, and will have no remedy in respect of, any statement, representation,
                            warranty or understanding of any person (whether party to these Terms or not) other than
                            as expressly repeated in this Agreement.
                        </li>
                        <li>No person who is not a party to this Agreement will have any right to enforce it.</li>
                        <li>
                            <p>
                                If any provision of these Terms is or becomes illegal, invalid or unenforceable under the
                                law of any jurisdiction, that will not affect or impair either:
                            </p>
                            <ol>
                                <li>
                                    the legality, validity or enforceability in that jurisdiction of any other
                                    provision of these Terms, or
                                </li>
                                <li>
                                    the legality, validity or enforceability under the law of any other jurisdiction
                                    of that or any other provision of these Terms.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Your use of this website and any dispute arising out of such use of this website is subject
                            to the laws of England and Wales.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.PRIVACY_POLICY:
            return (
                <section>
                    <p>
                        Welcome to the Avaid.aero website.  This privacy policy applies only to this website and contains
                        important information regarding how we will collect, secure and use your personal data. It should
                        be read in conjunction with
                        our terms of use.
                    </p>
                    <p>
                        This privacy policy may be updated from time to time without notice.  You should check this page
                        periodically to review the policy and to ensure that you agree with any changes.  Your continued
                        use of this website will be deemed as acceptance of the updated policy.
                    </p>
                    <p>
                        This privacy policy was last updated on <span className={styles.lastUpdated}>21 October 2023</span>.
                    </p>
                </section>
            );
        case HELP_TOPICS.YOUR_INFORMATION:
            return (
                <section>
                    <ol>
                        <li>
                            We collect information about you when, as an unregistered user, you post feedback comments.  We
                            do this via an online form.
                        </li>
                        <li>
                            We collect information about you when you register for a user account.  We do this via an
                            online form.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.USE_OF_YOUR_INFORMATION:
            return (
                <section>
                    <ol>
                        <li>
                            When, as an unregistered user, you post feedback comments, your email address may be used
                            to contact you if and when such contact is merited.
                        </li>
                        <li>
                            We use the details you provide during registration to create a user account for you.  If,
                            as a registered user, you post feedback comments, the email address you provided at
                            registration may be used to contact you if and when such contact is merited.
                        </li>
                        <li>
                            If you are a registered user, the email address you provided at registration may be used
                            to contact you for context-specific reasons which may include, but are not limited to,
                            notifications, periodic email validation and your use of this website.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.SHARING_YOUR_INFORMATION:
            return (
                <section>
                    <ol>
                        <li>We do not share any of your personal information with anyone.</li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.SECURING_YOUR_INFORMATION:
            return (
                <section>
                    <ol>
                        <li>We store information collected about you on secure third-party data servers.</li>
                        <li>
                            We implement technical safeguards such as automatic back-up and recovery, encrypted
                            passwords and encrypted sessions to secure this information.
                        </li>
                        <li>
                            We have measures in place to ensure that your information can only be accessed or
                            altered by an authorised person for a legitimate purpose.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.ACCESS_TO_YOUR_INFORMATION:
            return (
                <section>
                    <ol>
                        <li>
                            You have the right to request a copy of the information that we hold about you. You
                            may also ask us to correct information you think is inaccurate.
                        </li>
                        <li>
                            If you would like a copy of your personal information or would like a correction to be
                            made, please contact us via the <Link className={commonStyles.link} to="/ops">Ops</Link> page.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.USE_OF_COOKIES:
            return (
                <section>
                    <ol>
                        <li>
                            Cookies are small text files placed on your computer.  We only use cookies that are strictly
                            necessary for this website to function and respond to you as an individual.  These cookies
                            do not store any personally identifiable information.
                        </li>
                    </ol>
                </section>
            );
        case HELP_TOPICS.CONTACT:
            return (
                <section>
                    <p>
                        You can contact us either by posting a message on the <Link className={commonStyles.link} to="/ops">Ops</Link> page
                        or by sending an email to <a href={`mailto:${SETTINGS.EMAIL_ADDRESS_OPS}`}>{SETTINGS.EMAIL_ADDRESS_OPS}</a>.
                    </p>
                </section>
            );
        default:
            return null;
    }
};

const HelpElementContentSource = ({ index }) => {
    return getHelpElementContent(index);
};

export default HelpElementContentSource;