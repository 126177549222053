import { ParameterDirection } from "./parameter-direction";

export class Parameter {
    #name;
    #hostTransformId;
    #direction;
    #eventSubscribers;
    
    constructor(name, hostTransformId, direction = ParameterDirection.Both) {
        this.#name = name;
        this.#hostTransformId = hostTransformId;
        this.#direction = direction;
        this.#eventSubscribers = new Set();
    }

    get name() {
        return this.#name;
    }

    get hostTransformId() {
        return this.#hostTransformId;
    }

    get direction() {
        return this.#direction;
    }

    subscribeValueTransferred(callback) {
        this.#eventSubscribers.add(callback);
    }

    #onValueTransferred(direction, value) {
        const args = {
            direction: direction,
            value: value
        };
        this.#eventSubscribers.forEach(callback => callback(this, args));
    }

    #checkDirection(direction) {
        let throwException = false;
        switch (this.#direction) {
            case ParameterDirection.In:
                throwException = direction === ParameterDirection.Out;
                break;
            case ParameterDirection.Out:
                throwException = direction === ParameterDirection.In;
                break;
            // no default
        }
        if (throwException) {
            throw new Error("Parameter direction is incompatible with emitted/received value.");
        }
    }

    #checkDirectionAndRaiseEvent(direction, value) {
        this.#checkDirection(direction);
        this.#onValueTransferred(direction, value);
    }

    receive(value) {
        this.#checkDirectionAndRaiseEvent(ParameterDirection.In, value);
    }

    emit(value) {
        this.#checkDirectionAndRaiseEvent(ParameterDirection.Out, value);
    }
}