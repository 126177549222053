import Expander from "./Expander";
import UnitOutputParameter from "./UnitOutputParameter";
import NumericOutputParameter from "./NumericOutputParameter";
import BooleanOutputParameter from "./BooleanOutputParameter";

const OutputParameterCategory = ({ category }) => {
    return <Expander title={category.name} checked={true}>
        {category.getParameterMappings().map(parameterMapping => {
            const name = parameterMapping.name;
            switch (parameterMapping.typeName) {
                case "UnitParameterMapping":
                    return <UnitOutputParameter
                        key={name}
                        parameterMapping={parameterMapping} />
                case "NumericParameterMapping":
                    return <NumericOutputParameter
                        key={name}
                        parameterMapping={parameterMapping} />
                case "BooleanParameterMapping":
                    return <BooleanOutputParameter
                        key={name}
                        parameterMapping={parameterMapping} />
                default:
                    throw new Error(`"${parameterMapping.typeName}" is not a recognised parameter mapping type.`);
            }
        })}
    </Expander>
}

export default OutputParameterCategory;