import "../array-extensions";
import { RangeCurveSet } from "./range-curve-set";

export class RangeCurveSetGroup {
    constructor(interpolatorFactory) {
        this.interpolatorFactory = interpolatorFactory;
        this.curveSetGroup = new Map();
    }

    set(groupValue, rangeValue, curve) {
        const csg = this.curveSetGroup;
        let rangeCurveSet;
        if (csg.has(groupValue)) {
            rangeCurveSet = csg.get(groupValue);
        }
        else {
            rangeCurveSet = new RangeCurveSet(this.interpolatorFactory);
            csg.set(groupValue, rangeCurveSet);
        }
        rangeCurveSet.set(rangeValue, curve);
    }

    #getLowerRangeCurveSet(groupValue) {
        const dic = this.curveSetGroup;
        const keys = Array.from(dic.keys()).order();
        let low = null;

        keys.every(key => {
            if (key <= groupValue) {
                low = key;
                return true;
            }
            return false;
        });
        return low === null ? null : {
            rangeCurveSet: dic.get(low),
            lowGroupValue: low
        };
    }

    #getUpperRangeCurveSet(groupValue) {
        const dic = this.curveSetGroup;
        const keys = Array.from(dic.keys()).orderDescending();
        let high = null;

        keys.every(key => {
            if (key >= groupValue) {
                high = key;
                return true;
            }
            return false;
        });
        return high === null ? null : {
            rangeCurveSet: dic.get(high),
            highGroupValue: high
        };
    }

    getCurve(groupValue, rangeValue) {
        const upperInfo = this.#getUpperRangeCurveSet(groupValue);
        const lowerInfo = this.#getLowerRangeCurveSet(groupValue);
        if (upperInfo === null || lowerInfo === null) {
            return null;
        }
        const urcs = upperInfo.rangeCurveSet;
        const lrcs = lowerInfo.rangeCurveSet;
        
        let curve;
        if (urcs === lrcs) {
            curve = urcs.getCurve(rangeValue);
        }
        else {
            const highGroupValue = upperInfo.highGroupValue;
            const lowGroupValue = lowerInfo.lowGroupValue;
            const uc = urcs.getCurve(rangeValue);
            const lc = lrcs.getCurve(rangeValue);
            if (uc === null || lc === null) {
                curve = null;
            }
            else {
                const fractionalDistance = (groupValue - lowGroupValue) / (highGroupValue - lowGroupValue);
                curve = lc.createInterpolatedCurveByFractionalDistance(uc, fractionalDistance, this.interpolatorFactory);
            }
        }
        return curve;
    }
}