import styles from "./FieldEditor.module.css";
import commonStyles from "./Common.module.css";
import { useState, useEffect } from "react";
import { ReactComponent as Edit } from "../assets/edit.svg";
import { ReactComponent as Tick } from "../assets/tick.svg";
import { ReactComponent as Cross } from "../assets/cross.svg";

const FieldEditor = ({ name, value, isReadOnly, editDataHasErrors, commit, cancel, editor = null, focusRef = null }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [awaitingCommit, setAwaitingCommit] = useState(false);

    useEffect(() => {
        if (isEditing) {
            setErrorMessage(null);
            const current = focusRef?.current;
            if (current) {
                current.focus();
                try {
                    current.select();
                }
                catch { }
            }
        }
    }, [isEditing, focusRef]);
    
    const handleEditButton = () => {
        setIsEditing(true);
    };

    const handleCommit = async (e) => {
        e.preventDefault();
        setAwaitingCommit(true);
        try {
            setIsEditing(false);
            const result = await commit();
            if (result && !result.succeeded) {
                setErrorMessage(result.message);
            }
        }
        finally {
            setAwaitingCommit(false);
        }
    };

    const handleCancelButton = () => {
        setIsEditing(false);
        cancel();
    };

    return (
        <form className={styles.FieldEditor} onSubmit={handleCommit}>
            <div className={styles.header}>
                <p className={styles.fieldName}>{name}</p>

                {!isEditing && !isReadOnly &&
                    <button className={`${commonStyles.button} ${styles.button}`} onClick={handleEditButton} disabled={awaitingCommit}>
                        <Edit className={styles.buttonImage} />
                    </button>
                }
                
                {isEditing &&
                    <div className={styles.okCancelContainer}>
                        <button
                            className={`${commonStyles.button} ${styles.button}`}
                            type="submit"
                            disabled={editDataHasErrors}>
                            <Tick className={styles.buttonImage} />
                        </button>
                        <button
                            className={`${commonStyles.button} ${styles.button}`}
                            onClick={handleCancelButton}>
                            <Cross className={styles.buttonImage} />
                        </button>
                    </div>
                }
            </div>
            <div className={styles.value}>
                {!isEditing &&
                    <>
                        <p className={styles.fieldValue}>{value}</p>
                        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                    </>
                }
                {isEditing &&
                    <div className={styles.editor}>
                        {editor}
                    </div>
                }
            </div>
        </form>
    )
};

export default FieldEditor;