import { Transform } from "../transforms/transform";
import { ObstacleClearanceCalculator } from "./obstacle-clearance-calculator";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";

export class ObstacleClearanceCalculatorTransform extends Transform {
    #obstacleClearanceCalculator = new ObstacleClearanceCalculator();

    // Only one of the two temperature-related parameters needs to be set.
    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterRunwayElevationName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterTakeOffDistanceName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterObstacleDistanceName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterObstacleTrueAltitudeName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterQnhName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterTemperatureCelsiusName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterTemperatureFahrenheitName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterClimbCalibratedAirspeedName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterClimbRateName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterMeanClimbWindComponentName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterTrueAirspeedName, this.id, ParameterDirection.Out),
            new Parameter(ParameterNames.ParameterGroundSpeedName, this.id, ParameterDirection.Out),
            new Parameter(ParameterNames.ParameterIndicatedAltitudeAtObstacleName, this.id, ParameterDirection.Out),
            new Parameter(ParameterNames.ParameterTrueAltitudeAtObstacleName, this.id, ParameterDirection.Out),
            new Parameter(ParameterNames.ParameterObstacleClearanceHeightName, this.id, ParameterDirection.Out)
        ];
    }

    static #getCalculatorPropertyName(parameter) {
        switch (parameter.name) {
            case ParameterNames.ParameterRunwayElevationName:
                return "runwayElevation";
            case ParameterNames.ParameterTakeOffDistanceName:
                return "takeOffDistance";
            case ParameterNames.ParameterObstacleDistanceName:
                return "obstacleDistance";
            case ParameterNames.ParameterObstacleTrueAltitudeName:
                return "obstacleTrueAltitude";
            case ParameterNames.ParameterQnhName:
                return "qnh";
            case ParameterNames.ParameterTemperatureCelsiusName:
                return "aerodromeTemperatureCelsius";
            case ParameterNames.ParameterTemperatureFahrenheitName:
                return "aerodromeTemperatureFahrenheit";
            case ParameterNames.ParameterClimbCalibratedAirspeedName:
                return "climbCalibratedAirspeed";
            case ParameterNames.ParameterClimbRateName:
                return "climbRate";
            case ParameterNames.ParameterMeanClimbWindComponentName:
                return "meanClimbWindComponent";
            default:
                throw new Error(`The parameter name '${parameter.name}' is invalid.`);
        }
    }

    _onParameterValueReceived(parameter, value) {
        const propertyName = ObstacleClearanceCalculatorTransform.#getCalculatorPropertyName(parameter);
        this.#obstacleClearanceCalculator[propertyName] = value;
        if (this._setParameterValueCount >= 9) {
            const info = this.#obstacleClearanceCalculator.getObstacleClearance();
            this._emitParameterValue(ParameterNames.ParameterTrueAirspeedName, info?.trueAirspeed ?? null);
            this._emitParameterValue(ParameterNames.ParameterGroundSpeedName, info?.groundSpeed ?? null);
            this._emitParameterValue(ParameterNames.ParameterIndicatedAltitudeAtObstacleName, info?.indicatedAltitudeAtObstacle ?? null);
            this._emitParameterValue(ParameterNames.ParameterTrueAltitudeAtObstacleName, info?.trueAltitudeAtObstacle ?? null);
            this._emitParameterValue(ParameterNames.ParameterObstacleClearanceHeightName, info?.obstacleClearanceHeight ?? null);
        }
    }
}