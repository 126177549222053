import "../../lib/array-extensions";
import { helpMap } from "./helpMap";
import { HELP_TOPICS } from "./helpTopics";

export default class HelpNavigator {
    #cache;

    static #createCache() {
        const cache = new Map();
        cache.set(helpMap.index, helpMap);
        HelpNavigator.#buildCache(cache, helpMap.children);
        return cache;
    }

    static #buildCache(cache, elements) {
        if (elements) {
            for (const element of elements) {
                const index = element.index;
                if (cache.has(index)) {
                    console.warn(`Duplicate help elements exist with index = ${index}.`);
                }
                cache.set(index, element);
                HelpNavigator.#buildCache(cache, element.children);
            }
        }
    }
    
    constructor() {
        this.#cache = HelpNavigator.#createCache();
    }

    getRootElements() {
        return this.getChildElements(helpMap.index);
    }

    getChildElements(index) {
        const element = this.getElement(index);
        return element?.children || [];
    }

    getElement(index) {
        return index ? this.#cache.get(index) : null;
    }

    getPreviousLeafElement(index) {
        const element = this.getElement(index);
        return element ? this.#findPreviousLeafElement(element, false) : null;
    }

    #findPreviousLeafElement(element, elementIsCandidate = true) {
        if (elementIsCandidate) {
            if (element.children) {
                return this.#findPreviousLeafElement(element.children.last());
            }
            return element;
        }
        const sibling = this.#getPreviousSibling(element);
        if (sibling) {
            return this.#findPreviousLeafElement(sibling);
        }
        const ancestorSibling = this.#getPreviousAncestorSibling(element);
        if (ancestorSibling) {
            return this.#findPreviousLeafElement(ancestorSibling);
        }
        return elementIsCandidate ? element : null;
    }

    #getPreviousAncestorSibling(element) {
        const parent = this.getElement(element.parentIndex);
        if (parent) {
            const parentSibling = this.#getPreviousSibling(parent);
            return parentSibling ?? this.#getPreviousAncestorSibling(parent);
        }
        return null;
    }
    
    #getPreviousSibling(element) {
        var sibling = null;
        const parent = this.getElement(element.parentIndex);
        if (parent) {
            const index = element.index;
            const children = parent.children;
            for (var i = children.length - 1; i > 0; i--) {
                if (children[i].index === index) {
                    sibling = children[i - 1];
                    break;
                }
            }
        }
        return sibling;
    }

    getNextLeafElement(index) {
        const element = this.getElement(index);
        return element ? this.#findNextLeafElement(element, false) : null;
    }

    #findNextLeafElement(element, elementIsCandidate = true) {
        if (element.children) {
            return this.#findNextLeafElement(element.children[0]);
        }
        if (elementIsCandidate) {
            return element;
        }
        const sibling = this.#getNextSibling(element);
        if (sibling) {
            return this.#findNextLeafElement(sibling);
        }
        const ancestorSibling = this.#getNextAncestorSibling(element);
        if (ancestorSibling) {
            return this.#findNextLeafElement(ancestorSibling);
        }
        return null;
    }

    #getNextAncestorSibling(element) {
        const parent = this.getElement(element.parentIndex);
        if (parent) {
            const parentSibling = this.#getNextSibling(parent);
            return parentSibling ?? this.#getNextAncestorSibling(parent);
        }
        return null;
    }

    #getNextSibling(element) {
        var sibling = null;
        const parent = this.getElement(element.parentIndex);
        if (parent) {
            const index = element.index;
            const children = parent.children;
            for (var i = 0; i < children.length - 1; i++) {
                if (children[i].index === index) {
                    sibling = children[i + 1];
                    break;
                }
            }
        }
        return sibling;
    }

    getPreviousElement(index) {
        const element = this.getElement(index);
        return element ? this.#findPreviousElement(element, false) : null;
    }

    #findPreviousElement(element, elementIsCandidate = true) {
        if (elementIsCandidate) {
            if (element.children) {
                return this.#findPreviousElement(element.children.last());
            }
            return element;
        }
        const sibling = this.#getPreviousSibling(element);
        if (sibling) {
            return this.#findPreviousElement(sibling);
        }
        const parentIndex = element.parentIndex;
        return parentIndex === HELP_TOPICS.ROOT ? null : this.getElement(parentIndex);
    }

    getNextElement(index) {
        const element = this.getElement(index);
        return element ? this.#findNextElement(element, false) : null;
    }

    #findNextElement(element, elementIsCandidate = true) {
        if (element.children) {
            return element.children[0];
        }
        if (elementIsCandidate) {
            return element;
        }
        const sibling = this.#getNextSibling(element);
        if (sibling) {
            return sibling;
        }
        return this.#getNextAncestorSibling(element);
    }
}