import styles from "./HelpNode.module.css";
import commonStyles from "../Common.module.css";
import useHelpContext from "../../hooks/useHelpContext";
import { useEffect, useState, useCallback } from "react";
import { ReactComponent as PreviousItem } from "../../assets/previous-item.svg";
import { ReactComponent as NextItem } from "../../assets/next-item.svg";

const HelpNode = ({ element, children }) => {
    const { getElement, getChildElements, getPreviousElement, getNextElement, navigateTo } = useHelpContext();
    const [childElements, setChildElements] = useState([]);
    const [hasDescendants, setHasDescendants] = useState(false);
    const [relatedTopicElements, setRelatedTopicElements] = useState([]);
    const [hasRelatedTopics, setHasRelatedTopics] = useState(false);
    const [previousElement, setPreviousElement] = useState(null);
    const [nextElement, setNextElement] = useState(null);
    const [underConstruction, setUnderConstruction] = useState(true);

    useEffect(() => {
        var index = element?.index;
        var descendants = getChildElements(index);
        setChildElements(descendants);
        setHasDescendants(descendants.length > 0);
        const relatedTopicIndexes = element?.relatedTopics ?? [];
        const relatedElements = relatedTopicIndexes.map(i => getElement(i));
        setRelatedTopicElements(relatedElements);
        setHasRelatedTopics(relatedElements.length > 0);
        setUnderConstruction(element?.underConstruction || false);
    }, [element, getElement, getChildElements]);

    useEffect(() => {
        var index = element?.index;
        const previous = getPreviousElement(index);
        setPreviousElement(previous);
        const next = getNextElement(index);
        setNextElement(next);
    }, [hasDescendants, element, getPreviousElement, getNextElement]);

    const handleNavigateTo = useCallback((targetElement) => {
        navigateTo(targetElement.index);
    }, [navigateTo]);

    const getTopicNavigationButtonClassName = useCallback((targetElement, forceRight = false) => {
        let className = `${commonStyles.navigationButton} ${styles.topicNavigationButton}`;
        if (targetElement === null) {
            className += ` ${styles.hidden}`;
        }
        if (forceRight) {
            className += ` ${styles.alignRight}`;
        }
        return className;
    }, []);
    
    return (
        <div className={styles.HelpNode}>
            <div className={styles.topicNavigationContainer}>
                <button
                    className={getTopicNavigationButtonClassName(previousElement)}
                    onClick={() => handleNavigateTo(previousElement)}>
                    <PreviousItem className={styles.topicNavigationImage} />
                    <p className={`${styles.topicNavigationCaption} ${styles.textLeft}`}>{previousElement?.title}</p>    
                </button>
                <button
                    className={getTopicNavigationButtonClassName(nextElement, true)}
                    onClick={() => handleNavigateTo(nextElement)}>
                    <p className={`${styles.topicNavigationCaption} ${styles.textRight}`}>{nextElement?.title}</p>
                    <NextItem className={styles.topicNavigationImage} />
                </button>
            </div>
            <h1>{element?.title}</h1>
            {!underConstruction &&
                <section>{children}</section>
            }
            {underConstruction && 
                <div className={styles.underConstructionContainer}>
                    <img className={styles.underConstruction} src="/Images/under-construction-500x453.png" alt="Under construction" />
                    <p className={styles.imageAccreditation}>Designed by Freepik: http://www.freepik.com/</p>
                </div>
            }
            {hasDescendants &&
                <section>
                    <h2>In this section:</h2>
                    <div className={styles.topicLinkContainer}>
                        {childElements.map(e => 
                            <button key={e.title} className={`${commonStyles.navigationButton} ${styles.navigationButton}`} onClick={() => handleNavigateTo(e)}>
                                <p>{e.title}</p>
                            </button>)
                        }
                    </div>
                </section>
            }
            {hasRelatedTopics && 
                <section>
                    <h2>Related topics:</h2>
                    <div className={styles.topicLinkContainer}>
                        {relatedTopicElements.map(e =>
                            <button key={e.title} className={`${commonStyles.navigationButton} ${styles.navigationButton}`} onClick={() => handleNavigateTo(e)}>
                                <p>{e.title}</p>
                            </button>)
                        }
                    </div>
                </section>
            }
        </div>
    )
};

export default HelpNode;