import styles from "./Parameter.module.css";
import { useState, useEffect, useCallback } from "react";
import { isSomething } from "../lib/common/utilities";
import { ReactComponent as Warning } from "../assets/warning.svg";

const BooleanOutputParameter = ({ parameterMapping }) => {
    const [value, setValue] = useState(null);
    const [valueSet, setValueSet] = useState(false);

    const handleParameterValueEmitted = useCallback((sender) => {
        const newValue = sender.getMappingValue();
        setValue(newValue);
        setValueSet(isSomething(newValue));
    }, []);

    useEffect(() => {
        const initValue = parameterMapping.getMappingValue();
        setValue(initValue);
        setValueSet(isSomething(initValue));

        parameterMapping.subscribeParameterValueEmitted(handleParameterValueEmitted);
        return () => parameterMapping.unsubscribeParameterValueEmitted(handleParameterValueEmitted);
    }, [parameterMapping, handleParameterValueEmitted]);

    return <div>
        <label htmlFor="value" className={styles.paddedLabel}>{parameterMapping.name}</label>
        <div className={styles.outputResult}>
            {!valueSet && <Warning title="Parameters outside limits" width="24" height="24" />}
            {valueSet && 
                <input
                    id="value"
                    className={styles.checkbox}
                    readOnly
                    type="checkbox"
                    value={value} />
            }
        </div>
    </div>
}

export default BooleanOutputParameter;