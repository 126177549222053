import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { ParameterDirection } from "../transforms/parameter-direction";
import { isSomething } from "../common/utilities";

export class GuardTransform extends Transform {
    #guard;
    
    constructor(guard) {
        super();
        this.#guard = guard;
    }

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterInName, this.id, ParameterDirection.In),
            new Parameter(ParameterNames.ParameterOutName, this.id, ParameterDirection.Out)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const result = isSomething(value) ? this.#guard.test(value) : null;
        this._emitParameterValue(ParameterNames.ParameterOutName, result)
    }
}