import styles from "./Help.module.css";
import { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import useHelpContext from "../../hooks/useHelpContext";
import SidebarItem from "./SidebarItem";
import RootContent from "./RootContent";
import HelpNode from "./HelpNode";
import HelpElementContentSource from "./HelpElementContentSource";
import BreadcrumbBar from "./BreadcrumbBar";

const Help = () => {
    const { getElement, getRootElements, selectedIndex, setSelectedIndex } = useHelpContext();
    const { index } = useParams();
    
    useEffect(() => {
        let title = "Avaid - Help";
        const element = getElement(selectedIndex);
        if (element) {
            title += ` - ${element.title}`;
        }
        document.title = title;
    }, [selectedIndex, getElement]);

    useEffect(() => {
        if (index) {
            const i = Number(index);
            if (i !== selectedIndex) {
                setSelectedIndex(i);
            }
        }
        else {
            setSelectedIndex(null);
        }
    }, [index, selectedIndex, setSelectedIndex]);

    const createHelpNode = useCallback((index) => {
        const element = getElement(index);
        const content = <HelpElementContentSource index={index} />
        return <HelpNode element={element}>{content}</HelpNode>;
    }, [getElement]);

    const getHelpElement = useCallback(() => {
        return selectedIndex ? createHelpNode(selectedIndex) : <RootContent />;
    }, [selectedIndex, createHelpNode]);

    return (
        <section className={styles.Help}>
            <Sidebar>
                {getRootElements().map(e => <SidebarItem key={e.index} element={e} />)}
            </Sidebar>
            <div className={styles.outerContentContainer}>
                <BreadcrumbBar />
                <div className={styles.contentContainer}>
                    <section className={styles.content}>
                        {getHelpElement()}
                    </section>
                </div>
            </div>
        </section>
    )
};

export default Help;