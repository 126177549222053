import styles from "./RootItem.module.css";
import commonStyles from "../Common.module.css";
import useHelpContext from "../../hooks/useHelpContext";
import { useCallback, useState, useEffect } from "react";

const RootItem = ({ element }) => {
    const { navigateTo } = useHelpContext();
    const [imageUrl, setImageUrl] = useState(null);

    // https://stackoverflow.com/questions/67689159/react-import-svg-as-component-dynamically
    const fetchIcon = async (iconName) => {
        await import(`../../assets/${iconName}.svg`)
            .then(data => setImageUrl(data.default))
            .catch(err => console.log(err));
    }

    useEffect(() => {
        fetchIcon(element.imageFileName ?? "idea");
    }, [element]);

    const handleClick = useCallback(() => {
        navigateTo(element.index);
    }, [navigateTo, element.index]);

    return (
        <article className={styles.RootItem}>
            <button className={`${commonStyles.navigationButton} ${styles.button}`} onClick={handleClick}>
                <img className={styles.image} src={imageUrl} alt="" />
                <p className={styles.title}>{element.title}</p>
            </button>
        </article>
    )
};
export default RootItem;