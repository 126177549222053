import styles from "./Feedback.module.css";
import commonStyles from "./Common.module.css";
import useMessageContext from "../hooks/useMessageContext";
import useAuthContext from "../hooks/useAuthContext";
import { useState, useEffect } from "react";
import InputWithHint from "./InputWithHint";
import { emailOptions } from "./InputWithHintOptions";
import { emailRegex } from "../utils/validation";
import "../lib/array-extensions";

const subjectPlaceholderId = -1;
const minMessageLength = 10;
const maxMessageLength = 1000;

const Feedback = ({ selectedSubject }) => {
    const { auth } = useAuthContext();
    const [selectedSubjectId, setSelectedSubjectId] = useState(subjectPlaceholderId);
    const [statusMessage, setStatusMessage] = useState(null);
    const [email, setEmail] = useState("");
    const [emailHasErrors, setEmailHasErrors] = useState(true);
    const [message, setMessage] = useState("");
    const [subjectSelectorClassName, setSubjectSelectorClassName] = useState(`${styles.selector}`);
    const [messageClassName, setMessageClassName] = useState(null);
    const [hasErrors, setHasErrors] = useState(true);
    const [responseMessage, setResponseMessage] = useState(null);
    const [responseMessageClassName, setResponseMessageClassName] = useState(null);
    const [awaitingSubmit, setAwaitingSubmit] = useState(false);
    const [remainingCharacters, setRemainingCharacters] = useState(maxMessageLength);
    
    const { subjects, error, isLoading, submitFeedback } = useMessageContext();

    useEffect(() => {
        if (subjects && selectedSubject) {
            const target = subjects.firstOrUndefined(s => s.name === selectedSubject);
            if (target) {
                setSelectedSubjectId(target.id);
                setEmail("");
                setMessage("");
            }
        }
    }, [subjects, selectedSubject])

    useEffect(() => {
        if (isLoading) {
            setStatusMessage("Fetching subjects...");
        }
        else if (error || !subjects) {
            setStatusMessage("Failed to retrieve subjects");
        }
        else {
            setStatusMessage(null);
        }
    }, [subjects, error, isLoading]);

    useEffect(() => {
        let className = `${commonStyles.selector} ${styles.selector}`;
        if (selectedSubjectId === subjectPlaceholderId) {
            className += ` ${styles.selector___placeholder}`;
        }
        setSubjectSelectorClassName(className);
    }, [selectedSubjectId]);

    useEffect(() => {
        const hasErrors = !auth.accessToken && !emailRegex.test(email);
        setEmailHasErrors(hasErrors);
    }, [email, auth]);

    useEffect(() => {
        setHasErrors(emailHasErrors || message.length < minMessageLength || selectedSubjectId === subjectPlaceholderId);
    }, [emailHasErrors, message, selectedSubjectId]);

    useEffect(() => {
        let className = `${commonStyles.input} ${styles.textArea}`;
        const length = message.length;
        if (length > 0 && length < minMessageLength) {
            className += ` ${commonStyles.input___invalid}`;
        }
        setMessageClassName(className);
        setRemainingCharacters(maxMessageLength - length);
    }, [message]);

    const changeSubject = (e) => {
        const id = Number(e.target.value);
        setSelectedSubjectId(id);
    };

    const handleCommentsChanged = (e) => {
        const text = e.target.value;
        setMessage(text);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAwaitingSubmit(true);
        try {
            const result = await submitFeedback(selectedSubjectId, auth.accessToken ? null : email, message);
            if (result) {
                if (result.succeeded) {
                    clearFields();
                    setResponseMessage("Thank you, your message has been received.");
                    setResponseMessageClassName(styles.responseMessage);
                }
                else {
                    setResponseMessage(result.message);
                    setResponseMessageClassName(`${styles.responseMessage} ${styles.error}`);
                }
            }
        }
        finally {
            setAwaitingSubmit(false);
        }
    };

    const clearFields = () => {
        setSelectedSubjectId(subjectPlaceholderId);
        setEmail("");
        setMessage("");
    };

    return (
        <section className={styles.Feedback}>
            {statusMessage && <p className={styles.statusMessage}>{statusMessage}</p>}
            {!statusMessage && subjects &&
                <form className={styles.fieldContainer} onSubmit={handleSubmit}>
                    <select
                        className={subjectSelectorClassName}
                        value={selectedSubjectId}
                        onChange={changeSubject}>
                        
                        <option
                            key={subjectPlaceholderId}
                            value={subjectPlaceholderId}
                            disabled
                            hidden>Subject</option>
                        {subjects.map(subject => (
                            <option key={subject.id} value={subject.id}>
                                {subject.name}
                            </option>
                        ))}
                    </select>
                    {!auth.accessToken && 
                        <InputWithHint
                            options={emailOptions}
                            value={email}
                            setValue={setEmail}
                            valueHasErrors={emailHasErrors} />
                    }
                    <textarea
                        className={messageClassName}
                        maxLength={maxMessageLength}
                        placeholder="Type your message here"
                        value={message}
                        onChange={handleCommentsChanged} />
                    {remainingCharacters < maxMessageLength &&
                        <p className={styles.messageHint}>{remainingCharacters} character{remainingCharacters === 1 ? "" : "s"} remaining</p>}
                    
                    <button className={`${commonStyles.button} ${styles.button}`} type="submit" disabled={hasErrors || awaitingSubmit}>Submit</button>
                    {responseMessage && <p className={responseMessageClassName}>{responseMessage}</p>}
                </form>
            }
        </section>
    )
}

export default Feedback;