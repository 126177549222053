import "../array-extensions";
import { Point } from "./point";
import { Segment } from "./segment";
import { IntersectionType } from "./intersection-type";
import { Line } from "./line";
import { RayCastingAlgorithm } from "./ray-casting-algorithm";

export class Curve {
    constructor(points, interpolatorFactory) {
        this.points = points.orderBy(p => p.x);
        this.interpolator = interpolatorFactory.create(this);
    }

    getY(x, extrapolateIfNecessary = true) {
        return this.interpolator.interpolateY(x, extrapolateIfNecessary);
    }

    getX(y, extrapolateIfNecessary = true) {
        return this.interpolator.interpolateX(y, extrapolateIfNecessary);
    }

    isXInRange(x) {
        return this.getSegments().any(s => s.isXInRange(x));
    }

    isYInRange(y) {
        return this.getSegments().any(s => s.isYInRange(y));
    }

    createShadowCurve(point, curveIsBelowPoint, interpolatorFactory) {
        const points = [point];
        const x = point.x;
        let y = this.getY(x);
        const yDiff = curveIsBelowPoint ? point.y - y : y - point.y;
        this.points.forEach(p => {
            const x1 = p.x;
            if (x1 !== x) {
                y = yDiff + p.y;
                points.push(new Point(x1, y));
            }
        });
        return new Curve(points, interpolatorFactory);
    }

    createInterpolatedCurve(otherCurve, point, interpolatorFactory) {
        const xOverlapRange = this.findOverlappingXRange(otherCurve);
        let yOverlapRange = null;
        let rangeToUse = null;
        if (xOverlapRange.overlap) {
            if (this.points[0].x === otherCurve.points[0].x && this.points.last().x === otherCurve.points.last().x) {
                rangeToUse = "x";
            }
        }
        if (rangeToUse === null) {
            yOverlapRange = this.findOverlappingYRange(otherCurve);
            if (yOverlapRange.overlap) {
                if (this.points[0].y === otherCurve.points[0].y && this.points.last().y === otherCurve.points.last().y) {
                    rangeToUse = "y";
                }
            }
        }
        let curve;
        switch (rangeToUse) {
            case "x":
                curve = this.#createInterpolatedCurveUsingDiffY(
                    otherCurve, point, xOverlapRange.lowerX, xOverlapRange.upperX, interpolatorFactory);
                break;
            case "y":
                curve = this.#createInterpolatedCurveUsingDiffX(
                    otherCurve, point, yOverlapRange.lowerY, yOverlapRange.upperY, interpolatorFactory);
                break;
            default:
                curve = this.createInterpolatedCurveByPoint(otherCurve, point, interpolatorFactory);
                break;
        }
        return curve;
    }

    #createInterpolatedCurveUsingDiffY(otherCurve, point, minX, maxX, interpolatorFactory) {
        const points = [point];
        const x = point.x;
        let y0 = this.getY(x);
        let y1 = otherCurve.getY(x);
        let p;
        if (y0 === y1) {
            p = 0.5; // Assume curve bisects the supplied pair
        }
        else {
            p = (y0 - point.y) / (y0 - y1);
        }
        const xs = Curve.getAllCurvePoints(this, otherCurve).map(p => p.x).where(x => x >= minX && x <= maxX).distinct();
        xs.forEach(x1 => {
            if (x1 !== x) {
                y0 = this.getY(x1);
                y1 = otherCurve.getY(x1);
                const y = y0 - (y0 - y1) * p;
                points.push(new Point(x1, y));
            }
        });
        return new Curve(points, interpolatorFactory);
    }

    #createInterpolatedCurveUsingDiffX(otherCurve, point, minY, maxY, interpolatorFactory) {
        const points = [point];
        const y = point.y;
        let x0 = this.getX(y);
        let x1 = otherCurve.getX(y);
        let p;
        if (x0 === x1) {
            p = 0.5; // Assume curve bisects the supplied pair
        }
        else {
            p = (x0 - point.x) / (x0 - x1);
        }
        const ys = Curve.getAllCurvePoints(this, otherCurve).map(p => p.y).where(y => y >= minY && y <= maxY).distinct();
        ys.forEach(y1 => {
            if (y1 !== y) {
                x0 = this.getX(y1);
                x1 = otherCurve.getX(y1);
                const x = x0 - (x0 - x1) * p;
                points.push(new Point(x, y1));
            }
        });
        return new Curve(points, interpolatorFactory);
    }

    // Each returned line's point1 is a point on this curve; point2 is a point on otherCurve.
    #getLinesToAndFrom(otherCurve) {
        const lines = [];
        this.getCurveInfo().forEach(info => {
            const point1 = info.point;
            const point2 = otherCurve.getPoint(info.fractionalDistance);
            lines.push({ point1, point2 });
        });
        otherCurve.getCurveInfo().forEach(info => {
            const point1 = this.getPoint(info.fractionalDistance);
            const point2 = info.point;
            lines.push({ point1, point2 });
        });
        return lines;
    }

    static #getPolygons(lines) {
        const polygons = [];
        const upperBound = lines.length - 1;
        for (let i = 0; i < upperBound; i++) {
            const line1 = lines[i];
            const line2 = lines[i + 1];
            const polygon = [line1.point1, line2.point1, line2.point2, line1.point2];
            polygons.push(polygon);
        }
        return polygons;
    }

    static #POINT_SEPARATION_DIVISOR = 200;

    createInterpolatedCurveByPoint(otherCurve, point, interpolatorFactory) {
        let lines = this.#getLinesToAndFrom(otherCurve);
        
        // Filter out duplicate lines (if any)
        const map = new Map();
        lines.forEach(line => {
            const point1Key = Curve.#createKeyFromPoint(line.point1);
            const point2Key = Curve.#createKeyFromPoint(line.point2);
            const key = `${point1Key}|${point2Key}`;
            map.set(key, line);
        });
        lines = Array.from(map.values()).orderBy(l => l.point1.x);

        const xValues = lines.map(l => l.point1.x).concat(lines.map(l => l.point2.x));
        const yValues = lines.map(l => l.point1.y).concat(lines.map(l => l.point2.y));
        const minX = xValues.min();
        const minY = yValues.min();
        const maxX = xValues.max();
        const maxY = yValues.max();
        const xSeparation = (maxX - minX) / Curve.#POINT_SEPARATION_DIVISOR;
        const ySeparation = (maxY - minY) / Curve.#POINT_SEPARATION_DIVISOR;

        const polygons = Curve.#getPolygons(lines);
        const polygon = Curve.#getContainingPolygon(polygons, point);
        if (polygon) {
            const fractionalDistance = Curve.#getFractionalDistanceFromPolygon(polygon, point, xSeparation, ySeparation);
            return this.createInterpolatedCurveByFractionalDistance(otherCurve, fractionalDistance, interpolatorFactory, lines);
        }
        return null;
    }

    static #getContainingPolygon(polygons, point) {
        return polygons.firstOrUndefined(p => RayCastingAlgorithm.contains(p, point.x, point.y));
    }

    static #areClose(point1, point2, xSeparation, ySeparation) {
        return Math.abs(point2.x - point1.x) <= xSeparation && Math.abs(point2.y - point1.y) <= ySeparation;
    }

    static #FRACTIONAL_DISTANCE_TOLERANCE = 0.03;

    static #getFractionalDistanceFromPolygon(polygon, point, xSeparation, ySeparation) {
        const point1 = polygon[0]; // point on this curve, on a line with point4
        const point2 = polygon[1]; // point on this curve, on a line with point3
        const point3 = polygon[2]; // point on the other curve, on a line with point2
        const point4 = polygon[3]; // point on the other curve, on a line with point1
        if (Curve.#areClose(point1, point2, xSeparation, ySeparation) && 
            Curve.#areClose(point3, point4, xSeparation, ySeparation)) {
            const line1a = new Line(point1, point);
            const line1b = new Line(point, point4);
            const line1Length = line1a.length + line1b.length;
            let fractionalDistance1;
            if (line1Length === 0) {
                fractionalDistance1 = 0.5;
            }
            else {
                fractionalDistance1 = line1a.length / line1Length;
            }
            const line2a = new Line(point2, point);
            const line2b = new Line(point, point3);
            const line2Length = line2a.length + line2b.length;
            let fractionalDistance2;
            if (line2Length === 0) {
                fractionalDistance2 = 0.5;
            }
            else {
                fractionalDistance2 = line2a.length / line2Length;
            }
            if (Math.abs(fractionalDistance2 - fractionalDistance1) < Curve.#FRACTIONAL_DISTANCE_TOLERANCE) {
                return (fractionalDistance1 + fractionalDistance2) / 2;
            }
        }
        // Split the polygon in two and repeat
        const midPoint1 = Line.getPoint(point1, point2, 0.5);
        const midPoint2 = Line.getPoint(point3, point4, 0.5);
        const polygon1 = [point1, midPoint1, midPoint2, point4];
        const polygon2 = [midPoint1, point2, point3, midPoint2];
        const containingPolygon = Curve.#getContainingPolygon([polygon1, polygon2], point);
        return Curve.#getFractionalDistanceFromPolygon(containingPolygon, point, xSeparation, ySeparation);
    }

    // Prevents rounding errors when using stringified points as keys to filter out duplicates
    static #createKeyFromPoint(point) {
        const precision = 8;
        const roundedPoint = { x: point.x.toPrecision(precision), y: point.y.toPrecision(precision) };
        return JSON.stringify(roundedPoint);
    }

    /* Creates a curve that is the interpolation of this and otherCurve
       at an offset from this curve that is proportional to the supplied 
       fractional distance.  So if fractionalDistance = 0.3 then the 
       resulting curve will be at 30% of the distance between this and 
       otherCurve.
     */
    createInterpolatedCurveByFractionalDistance(otherCurve, fractionalDistance, interpolatorFactory, lines = null) {
        if (fractionalDistance < 0 || fractionalDistance > 1) {
            throw new Error("The fractional distance must be between 0.0 and 1.0 inclusive.");
        }
        lines = lines ?? this.#getLinesToAndFrom(otherCurve);
        const map = new Map();
        lines.map(l => Line.getPoint(l.point1, l.point2, fractionalDistance)).forEach(point => {
            const key = Curve.#createKeyFromPoint(point);
            map.set(key, point);
        });
        const points = Array.from(map.values());
        return new Curve(points, interpolatorFactory);
    }

    static getAllCurvePoints(...curves) {
        let points = [];
        curves.forEach(c => points = points.concat(c.points));
        return points;
    }

    #segments;

    getSegments() {
        if (!this.#segments) {
            const points = this.points;
            const count = points.length - 1;
            const segments = [];
            for (let i = 0; i < count; i++) {
                segments.push(new Segment(points[i], points[i + 1]));
            }
            this.#segments = segments;
        }
        return this.#segments;
    }

    #length;

    get length() {
        if (!this.#length) {
            this.#length = Array.from(this.getSegments().map(s => s.length)).reduce((temp, length) => temp + length, 0);
        }
        return this.#length;
    }

    #curveInfo;

    /* Returns each curve point coupled with the fractional distance (between
       0.0 and 1.0) along the curve at which the point occurs. */
    getCurveInfo() {
        if (!this.#curveInfo) {
            const curveLength = this.length;
            let cumulativeLength = 0;
            const infos = [];
            const segments = this.getSegments();
            infos.push({
                point: segments[0].point1,
                fractionalDistance: 0
            });
            segments.forEach(s => {
                cumulativeLength += s.length;
                const info = {
                    point: s.point2,
                    fractionalDistance: cumulativeLength / curveLength
                };
                infos.push(info);
            });
            this.#curveInfo = infos;
        }
        return this.#curveInfo;
    }

    getPoint(fractionalDistance) {
        if (fractionalDistance < 0 || fractionalDistance > 1) {
            throw new Error("The fractional distance must be between 0.0 and 1.0 inclusive.");
        }
        const infos = this.getCurveInfo();
        const segments = this.getSegments();
        for (let i = 1; i < infos.length; i++) {
            const infoFractionalDistance = infos[i].fractionalDistance;
            if (infoFractionalDistance >= fractionalDistance) {
                const segment = segments[i - 1];
                const fd = 1 - (infoFractionalDistance - fractionalDistance) / (infoFractionalDistance - infos[i - 1].fractionalDistance);
                return segment.getPoint(fd);
            }
        }
        throw new Error();
    }

    findIntersections(other) {
        const curveSegments = this.getSegments();
        const otherSegments = other.getSegments();
        const intersectionPoints = [];
        curveSegments.forEach(s1 => {
            otherSegments.forEach(s2 => {
                const info = s1.findIntersection(s2);
                if (info.intersectionType === IntersectionType.Segment) {
                    intersectionPoints.push(info.point);
                }
            });
        });
        return intersectionPoints;
    }

    findOverlappingXRange(other) {
        const segments1 = this.getSegments();
        const segments2 = other.getSegments();
        const xValues = [];
        segments1.forEach(s1 => {
            segments2.forEach(s2 => {
                if (s1.isXInRange(s2.x1)) {
                    xValues.push(s2.x1);
                }
                if (s1.isXInRange(s2.x2)) {
                    xValues.push(s2.x2);
                }
            });
        });
        segments2.forEach(s2 => {
            segments1.forEach(s1 => {
                if (s2.isXInRange(s1.x1)) {
                    xValues.push(s1.x1);
                }
                if (s2.isXInRange(s1.x2)) {
                    xValues.push(s1.x2);
                }
            });
        });
        const result = xValues.any();
        const lowerX = result ? xValues.min() : 0;
        const upperX = result ? xValues.max() : 0;
        return {
            overlap: result,
            lowerX: lowerX,
            upperX: upperX
        }
    }

    findOverlappingYRange(other) {
        const segments1 = this.getSegments();
        const segments2 = other.getSegments();
        const yValues = [];
        segments1.forEach(s1 => {
            segments2.forEach(s2 => {
                if (s1.isYInRange(s2.y1)) {
                    yValues.push(s2.y1);
                }
                if (s1.isYInRange(s2.y2)) {
                    yValues.push(s2.y2);
                }
            });
        });
        segments2.forEach(s2 => {
            segments1.forEach(s1 => {
                if (s2.isYInRange(s1.y1)) {
                    yValues.push(s1.y1);
                }
                if (s2.isYInRange(s1.y2)) {
                    yValues.push(s1.y2);
                }
            });
        });
        const result = yValues.any();
        const lowerY = result ? yValues.min() : 0;
        const upperY = result ? yValues.max() : 0;
        return {
            overlap: result,
            lowerY: lowerY,
            upperY: upperY
        }
    }
}