import { checkPrecision, toPrecision } from "../double-extensions";
import { DefaultUnitSymbols } from "./default-unit-symbols";
import { Symbols } from "./symbols";

const defaultPrecision = 5;

export class UnitBase {
    #precision = defaultPrecision;

    constructor(value = 0) {
        Object.defineProperty(this, "value", {
            value: Number(value),
            writable: false
        });
    }

    getPrecision() {
        return this.#precision;
    }

    setPrecision(value) {
        this.#precision = checkPrecision(value);
    }

    getRoundedValue() {
        const value = this.value;
        if (value === Infinity || isNaN(value)) {
            return value;
        }
        const precision = this.getPrecision();
        return toPrecision(value, precision);
    }

    static _throwMissingImplementation(message = "This method must be overridden in a derived class.") {
        throw new Error(message);
    }

    getAbbreviation() {
        UnitBase._throwMissingImplementation(`This method must be overridden in a derived class, returning a string to be appended
        to the value (e.g. 'kg').`);
    }

    convertToBaseUnit() {
        UnitBase._throwMissingImplementation(`This method must be overridden in a derived class, returning a new UnitBase instance
        with the appropriate value.`);
    }

    toString(rounded = true, formatted = true) {
        const value = rounded ? this.getRoundedValue() : this.value;
        const rvs = UnitBase.#getStringRepresentation(value, formatted);
        const abbreviation = this.getAbbreviation();
        const space = abbreviation && this._includeSpaceBetweenValueAndAbbreviation() ? " " : "";
        return `${rvs}${space}${abbreviation}`;
    }

    _includeSpaceBetweenValueAndAbbreviation() {
        return true;
    };

    static #getStringRepresentation(value, formatted) {
        let rvs;
        if (value === Infinity) {
            rvs = Symbols.Infinity;
        }
        else if (isNaN(value)) {
            rvs = "(NaN)";
        }
        else if (formatted) {
            rvs = value.toLocaleString();
        }
        else {
            rvs = value.toString();
        }
        return rvs;
    }

    clone() {
        const clone = new this.constructor(this.value);
        clone.setPrecision(this.getPrecision());
        return clone;
    }

    equals(other) {
        return this.#equals_internal(other, u => u.value);
    }

    approximates(other) {
        return this.#equals_internal(other, u => u.getRoundedValue());
    }

    #equals_internal(other, getValue) {
        let result;
        if (this === other) {
            result = true;
        }
        else if (!other || !(other instanceof UnitBase)) {
            result = false;
        }
        else if (this instanceof Quantity && this.isOfSameQuantity(other)) {
            const testUnit = this.constructor.name === other.constructor.name ? this : this.convert(other.constructor);
            result = getValue(testUnit) === getValue(other);
        }
        else {
            const u1 = this.convertToBaseUnit();
            const u2 = other.convertToBaseUnit();
            result = u1.constructor.name === u2.constructor.name;
            if (result) {
                const u1Value = getValue(u1);
                const u2Value = getValue(u2);
                result = u1Value === u2Value;
            }
        }
        return result;
    }

    static #checkIsUnitBaseType(type) {
        UnitBase._checkIsSubtypeOf(type, UnitBase);
    }

    static _checkIsSubtypeOf(type, baseType) {
        if (type === baseType || !(type.prototype instanceof baseType)) {
            throw new Error(`The type must be a sub-type of ${baseType.name}.`);
        }
    }

    static getUnitTypeFromName(name) {
        return eval(name);
    }

    static convertFromTypeStringIfNecessary(type) {
        return typeof type === "string" ? UnitBase.getUnitTypeFromName(type) : type;
    }

    static create(type, value, precision = defaultPrecision) {
        type = UnitBase.convertFromTypeStringIfNecessary(type);
        UnitBase.#checkIsUnitBaseType(type);
        const unit = new type(value);
        unit.setPrecision(precision);
        return unit;
    }

    convert(toType) {
        UnitBase._throwMissingImplementation();
    }
}

export class Quantity extends UnitBase {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        UnitBase._throwMissingImplementation();
    }

    getBaseUnitType() {
        UnitBase._throwMissingImplementation();
    }

    convertToBaseUnit() {
        UnitBase._throwMissingImplementation();
    }

    // other is either another unit or a unit type (e.g. Foot).
    isOfSameQuantity(other) {
        if (!(other instanceof Quantity)) {
            other = UnitBase.create(other, 0);
        }
        return this.getQuantityType() === other.getQuantityType();
    }

    compareTo(other) {
        let result;
        if (other === null) {
            result = 1;
        }
        else if (this.isOfSameQuantity(other)) {
            const bu1 = this.convertToBaseUnit();
            const bu2 = other.convertToBaseUnit();
            if (bu1.value === bu2.value) {
                result = 0;
            }
            else {
                result = bu1.value < bu2.value ? -1 : 1;
            }
        }
        else {
            throw new Error("other must be a quantity of the same type.");
        }
        return result;
    }

    lessThan(other) {
        return this.compareTo(other) < 0;
    }

    lessThanOrEqual(other) {
        return this.compareTo(other) <= 0;
    }

    greaterThanOrEqual(other) {
        return this.compareTo(other) >= 0;
    }

    greaterThan(other) {
        return this.compareTo(other) > 0;
    }

    add(other) {
        return this.#combine(other, (u1, u2) => u1.value + u2.value);
    }

    subtract(other) {
        return this.#combine(other, (u1, u2) => u1.value - u2.value);
    }

    #combine(other, combineValues) {
        if (this.isOfSameQuantity(other)) {
            const baseUnitType = this.getBaseUnitType();
            const targetType = this.constructor;
            const baseUnit1 = this.convertToBaseUnit();
            const baseUnit2 = other.convertToBaseUnit();
            const value = combineValues(baseUnit1, baseUnit2);
            const newBaseUnit = new baseUnitType(value);
            newBaseUnit.setPrecision(this.getPrecision());
            return newBaseUnit.convert(targetType);
        }
        throw new Error("other must be a quantity of the same type.");
    }

    divideBy(other) {
        if (this.isOfSameQuantity(other)) {
            const baseUnit1 = this.convertToBaseUnit();
            const baseUnit2 = other.convertToBaseUnit();
            const value = baseUnit1.value / baseUnit2.value;
            return new DimensionlessUnit(value);
        }
        throw new Error("other must be a quantity of the same type.");
    }

    getImplementingTypesOfSameQuantity() {
        const quantityType = this.getQuantityType();
        return Quantity.#getImplementingTypes(quantityType);
    }

    static getAllImplementingTypes() {
        return [
            DimensionlessUnit,
            Length,
            Mass,
            Pressure,
            Temperature,
            Time,
            Velocity,
            VolumeFlow,
            Volume,
            Angle
        ].flatMap(quantityType => this.#getImplementingTypes(quantityType));
    }

    static #getImplementingTypes(quantityType) {
        let implementingTypes;
        switch (quantityType) {
            case DimensionlessUnit:
                implementingTypes = [DimensionlessUnit];
                break;
            case Length:
                implementingTypes = [Metre, Foot, Kilometre, NauticalMile, StatuteMile, Yard];
                break;
            case Mass:
                implementingTypes = [Kilogramme, Pound, Tonne];
                break;
            case Pressure:
                implementingTypes = [Pascal, Kilopascal, Hectopascal, MercuryInch, PoundPerSquareInch];
                break;
            case Temperature:
                implementingTypes = [Kelvin, DegreeCelsius, DegreeFahrenheit];
                break;
            case Time:
                implementingTypes = [Second, Minute, Hour];
                break;
            case Velocity:
                implementingTypes = [MetrePerSecond, FootPerMinute, KilometrePerHour, Knot, MilePerHour];
                break;
            case VolumeFlow:
                implementingTypes = [CubicMetrePerSecond, LitrePerHour, USGallonPerHour];
                break;
            case Volume:
                implementingTypes = [CubicMetre, ImperialGallon, Litre, Millilitre, USGallon];
                break;
            case Angle:
                implementingTypes = [TrigonometricDegree];
                break;
            default:
                throw new Error(`Quantity '${quantityType}' is not recognised.`);
        }
        return implementingTypes;
    }
}

export class LinearQuantity extends Quantity {
    constructor(value = 0) {
        super(value);
    }

    convert(toType) {
        const quantityType = this.getQuantityType();
        toType = UnitBase.convertFromTypeStringIfNecessary(toType);
        UnitBase._checkIsSubtypeOf(toType, quantityType);
        const baseUnit = this.convertToBaseUnit();
        let unit = new toType(); // Just to get the conversion factor - then it will be thrown away
        const value = this._getConvertedValue(baseUnit.value, unit);
        unit = new toType(value);
        unit.setPrecision(baseUnit.getPrecision());
        return unit;
    }

    _getConvertedValue(baseUnitValue, targetUnit) {
        return baseUnitValue / targetUnit._getBaseUnitConversionFactor();
    }

    convertToBaseUnit() {
        const value = this.value * this._getBaseUnitConversionFactor();
        const type = this.getBaseUnitType();
        return UnitBase.create(type, value, this.getPrecision());
    }

    _getBaseUnitConversionFactor() {
        UnitBase._throwMissingImplementation();
    }
}

export class DimensionlessUnit extends Quantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return DimensionlessUnit;
    }

    getBaseUnitType() {
        return DimensionlessUnit;
    }

    convertToBaseUnit() {
        return this.clone();
    }

    convert(toType) {
        if (toType === DimensionlessUnit || toType === DimensionlessUnit.name) {
            return this.clone();
        }
        throw new Error("Cannot convert a DimensionlessUnit to any other type.");
    }

    getAbbreviation() {
        return "";
    }
}

//#region Lengths

export class Length extends LinearQuantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return Length;
    }

    getBaseUnitType() {
        return Metre;
    }
}

export class Metre extends Length {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Metre;
    }
}

export class Foot extends Length {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.3048;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Foot;
    }
}

export class Kilometre extends Length {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1000;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Kilometre;
    }
}

export class NauticalMile extends Length {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1852;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.NauticalMile;
    }
}

export class StatuteMile extends Length {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1609.344;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.StatuteMile;
    }
}

export class Yard extends Length {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.9144;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Yard;
    }
}

//#endregion

//#region Masses

export class Mass extends LinearQuantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return Mass;
    }

    getBaseUnitType() {
        return Kilogramme;
    }
}

export class Kilogramme extends Mass {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Kilogramme;
    }
}

export class Pound extends Mass {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.453592;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Pound;
    }
}

export class Tonne extends Mass {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1000;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Tonne;
    }
}

//#endregion

//#region Pressures

export class Pressure extends LinearQuantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return Pressure;
    }

    getBaseUnitType() {
        return Pascal;
    }
}

export class Pascal extends Pressure {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Pascal;
    }
}

export class Kilopascal extends Pressure {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1000;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Kilopascal;
    }
}

export class Hectopascal extends Pressure {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 100;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Hectopascal;
    }
}

export class MercuryInch extends Pressure {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 3386.38866667;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.MercuryInch;
    }
}

export class PoundPerSquareInch extends Pressure {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 6894.75729;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.PoundPerSquareInch;
    }
}

//#endregion

//#region Temperatures

export class Temperature extends LinearQuantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return Temperature;
    }

    getBaseUnitType() {
        return Kelvin;
    }

    _getKelvinOffset() {
        UnitBase._throwMissingImplementation();
    }

    _getFahrenheitOffset() {
        UnitBase._throwMissingImplementation();
    }

    convertToBaseUnit() {
        const value = (this.value - this._getFahrenheitOffset()) * this._getBaseUnitConversionFactor() - this._getKelvinOffset();
        const baseUnit = new Kelvin(value);
        baseUnit.setPrecision(this.getPrecision());
        return baseUnit;
    }

    _getConvertedValue(baseUnitValue, targetUnit) {
        return ((baseUnitValue + targetUnit._getKelvinOffset()) / targetUnit._getBaseUnitConversionFactor()) + targetUnit._getFahrenheitOffset();
    }
}

export class Kelvin extends Temperature {
    constructor(value = 0) {
        super(value);
    }

    static AbsoluteZero = -273.15;

    _getBaseUnitConversionFactor() {
        return 1;
    }

    _getKelvinOffset() {
        return 0;
    }

    _getFahrenheitOffset() {
        return 0;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Kelvin;
    }
}

export class DegreeCelsius extends Temperature {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1;
    }

    _getKelvinOffset() {
        return Kelvin.AbsoluteZero;
    }

    _getFahrenheitOffset() {
        return 0;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.DegreeCelsius;
    }
}

export class DegreeFahrenheit extends Temperature {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 5 / 9;
    }

    _getKelvinOffset() {
        return Kelvin.AbsoluteZero;
    }

    _getFahrenheitOffset() {
        return 32;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.DegreeFahrenheit;
    }
}

//#endregion

//#region Times

export class Time extends LinearQuantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return Time;
    }

    getBaseUnitType() {
        return Second;
    }
}

export class Second extends Time {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Second;
    }
}

export class Minute extends Time {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 60;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Minute;
    }
}

export class Hour extends Time {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 3600;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Hour;
    }
}

//#endregion

//#region Velocities

export class Velocity extends LinearQuantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return Velocity;
    }

    getBaseUnitType() {
        return MetrePerSecond;
    }
}

export class MetrePerSecond extends Velocity {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.MetrePerSecond;
    }
}

export class FootPerMinute extends Velocity {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.00508;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.FootPerMinute;
    }
}

export class KilometrePerHour extends Velocity {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1 / 3.6;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.KilometrePerHour;
    }
}

export class Knot extends Velocity {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.514444;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Knot;
    }
}

export class MilePerHour extends Velocity {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.44704;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.MilePerHour;
    }
}

//#endregion

//#region Volume Flows

export class VolumeFlow extends LinearQuantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return VolumeFlow;
    }

    getBaseUnitType() {
        return CubicMetrePerSecond;
    }
}

export class CubicMetrePerSecond extends VolumeFlow {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.CubicMetrePerSecond;
    }
}

export class LitrePerHour extends VolumeFlow {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 2.77778E-7;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.LitrePerHour;
    }
}

export class USGallonPerHour extends VolumeFlow {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1.05150327E-6;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.USGallonPerHour;
    }
}

//#endregion

//#region Volumes

export class Volume extends LinearQuantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return Volume;
    }

    getBaseUnitType() {
        return CubicMetre;
    }
}

export class CubicMetre extends Volume {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 1;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.CubicMetre;
    }
}

export class ImperialGallon extends Volume {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.00454609;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.ImperialGallon;
    }
}

export class Litre extends Volume {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.001;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Litre;
    }
}

export class Millilitre extends Volume {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.000001;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.Millilitre;
    }
}

export class USGallon extends Volume {
    constructor(value = 0) {
        super(value);
    }

    _getBaseUnitConversionFactor() {
        return 0.00378541;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.USGallon;
    }
}

//#endregion

//#region Volumes

export class Angle extends LinearQuantity {
    constructor(value = 0) {
        super(value);
    }

    getQuantityType() {
        return Angle;
    }

    getBaseUnitType() {
        return TrigonometricDegree;
    }
}

export class TrigonometricDegree extends Angle {
    _getBaseUnitConversionFactor() {
        return 1;
    }

    getAbbreviation() {
        return DefaultUnitSymbols.ArcDegree;
    }

    _includeSpaceBetweenValueAndAbbreviation() {
        return false;
    };
}

//#endregion