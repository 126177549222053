import { isSomething, areSomething } from "../common/utilities";
import { Transform } from "../transforms/transform";
import { Parameter } from "../transforms/parameter";
import { ParameterNames } from "../transforms/parameter-names";
import { Point } from "./point";
import { CurveSetIdentifier } from "./curve-set-identifier";

export class RangeCurveTwinSetTransform extends Transform {
    constructor(rangeCurveTwinSet) {
        super();
        this.rangeCurveTwinSet = rangeCurveTwinSet;
    }

    _sourceParameters() {
        return [
            new Parameter(ParameterNames.ParameterRangeCurveTwinSet1ValueName, this.id),
            new Parameter(ParameterNames.ParameterRangeCurveTwinSet2ValueName, this.id),
            new Parameter(ParameterNames.ParameterYName, this.id)
        ];
    }

    _onParameterValueReceived(parameter, value) {
        const count = this._setParameterValueCount;
        if (count === 2) {
            const outputParameterName = this.getUnsetParameterNames()[0];
            let result;
            switch (outputParameterName) {
                case ParameterNames.ParameterRangeCurveTwinSet1ValueName:
                    result = this.#calculateRangeValue1();
                    break;
                case ParameterNames.ParameterRangeCurveTwinSet2ValueName:
                    result = this.#calculateRangeValue2();
                    break;
                case ParameterNames.ParameterYName:
                    result = this.#calculateY();
                    break;
                default:
                    throw new Error(`Unknown output parameter: '${outputParameterName}'.`);
            }
            this._emitParameterValue(outputParameterName, result);
        }
        else if (count > 2) {
            this._throwCannotIdentifyOutputParameters();
        }
    }

    #calculateRangeValue1() {
        return this.#calculateRangeValue(
            CurveSetIdentifier.Two,
            CurveSetIdentifier.One,
            ParameterNames.ParameterRangeCurveTwinSet2ValueName);
    }

    #calculateRangeValue2() {
        return this.#calculateRangeValue(
            CurveSetIdentifier.One,
            CurveSetIdentifier.Two,
            ParameterNames.ParameterRangeCurveTwinSet1ValueName);
    }

    #calculateRangeValue(from, to, parameterName) {
        let result = null;
        const rangeValue = this._getParameterValue(parameterName);
        const y = this._getParameterValue(ParameterNames.ParameterYName);
        if (areSomething(rangeValue, y)) {
            const rcsFrom = this.rangeCurveTwinSet.getRangeCurveSet(from);
            const curve = rcsFrom.getCurve(rangeValue);
            const x = curve.getX(y, false);
            if (isSomething(x)) {
                const p = new Point(x, y);
                const rcsTo = this.rangeCurveTwinSet.getRangeCurveSet(to);
                result = rcsTo.getRangeValue(p);
            }
        }
        return result;
    }

    #calculateY() {
        let result;
        const range1Value = this._getParameterValue(ParameterNames.ParameterRangeCurveTwinSet1ValueName);
        const range2Value = this._getParameterValue(ParameterNames.ParameterRangeCurveTwinSet2ValueName);
        if (areSomething(range1Value, range2Value)) {
            const c1 = this.rangeCurveTwinSet.getCurve(CurveSetIdentifier.One, range1Value);
            const c2 = this.rangeCurveTwinSet.getCurve(CurveSetIdentifier.Two, range2Value);
            const points = c1.findIntersections(c2);
            result = points.single().y;
        }
        else {
            result = null;
        }
        return result;
    }
}