import "../array-extensions";
import { isSomething } from "../common/utilities";
import { TableValue } from "./table-value";

export class TableKey {
    #name;
    #detentsOnly;
    #detents;
    
    constructor(name, detents) {
        name = name?.trim();
        if (!isSomething(name) || name === "") {
            throw new Error("Name must be non-null and not empty.");
        }
        if (isSomething(detents) && detents.distinct().length !== detents.length) {
            throw new Error("Duplicate detents found.");
        }
        this.#name = name;
        this.#detentsOnly = isSomething(detents) && detents.length > 0;
        this.#detents = detents?.slice() ?? TableKey.#EmptyDetents;
    }

    static #EmptyDetents = [];

    get name() {
        return this.#name;
    }

    get detentsOnly() {
        return this.#detentsOnly;
    }

    get detents() {
        return this.#detents;
    }

    isValidValue(value) {
        return !this.#detentsOnly || this.#detents.includes(value);    
    }

    equals(other) {
        return other instanceof TableKey && this.#getComparisonName() === other.#getComparisonName();
    }

    createValue(value) {
        return new TableValue(this, value, false);
    }

    createUndefinedValue() {
        return new TableValue(this, 0, true);
    }

    #getComparisonName() {
        return this.#name.toLowerCase();
    }

    static distinct(keys) {
        return keys.distinct(tk => tk.#getComparisonName());
    }
}